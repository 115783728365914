import { Timestamp } from 'firebase/firestore';
import { chatRepository } from '../../infrastructure/repositories/chat.repository';
import { generateUuid } from '../../infrastructure/uuid';
import { Chat } from '../models/Chat';
import { Message } from '../models/Message';
import { http } from '../../infrastructure/http/axios';

const addChat = (chat: Chat): Chat => ({
  ...chat,
  id: generateUuid(),
});

const loadUserChat = (userId: number): Promise<Chat[]> => {
  return chatRepository.getChatList(userId);
};

const loadMessagesByChat = (chatId: string): Promise<Message[]> => {
  return chatRepository.getMessages(chatId);
};

const loadMoreMessagesBetween = (
  chatId: string,
  messageId: string,
  startAt: number,
  limit: number = 10
): Promise<Message[]> => {
  return chatRepository.getMessages(chatId, startAt, limit, messageId);
};

const createChat = (chat: Chat): Promise<string> => {
  return chatRepository.createChat(chat);
};

const sendMessage = async (
  chat: Chat,
  message: string,
  userId: number
): Promise<void> => {
  await chatRepository.sendMessage(chat, {
    created_at: Timestamp.now(),
    edited: false,
    files: [],
    message,
    updated_at: null,
    status: true,
    view: false,
    user_from_id: userId,
  });
  const toUserId = chat.id_users.find((id) => id !== userId);

  if (toUserId !== undefined) {
    await http.post('/users/notify', {
      user_id: toUserId,
      user_from_id: userId,
      message,
      document_id: chat.id,
    });
  }
  return;
};

const readMessage = (chat: Chat, userId: number): Promise<void> => {
  return chatRepository.readMessage(chat, userId);
};

const sendWriting = (
  chat: Chat,
  userId: number,
  isWriting: boolean
): Promise<void> => {
  return chatRepository.sendWriting(chat, userId, isWriting);
};

export const chatService = {
  addChat,
  createChat,
  loadUserChat,
  loadMessagesByChat,
  sendMessage,
  readMessage,
  sendWriting,
  loadMoreMessagesBetween,
};
