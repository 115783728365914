import { http } from "../http/axios";
import {TipoCampoValor} from "../../domain/models/TipoCampoValor";

export const findAllTipoCampoValor = async (): Promise<TipoCampoValor[]> => {
  return await http.get<TipoCampoValor[]>('/tipoCampoValor');
};

export const createTipoCampoValor = async (body: any): Promise<TipoCampoValor> => {
  return await http.post<TipoCampoValor>('/tipoCampoValor', body);
};

export const findTipoCampoValor = async (
  tipoCampoValorId: number
): Promise<TipoCampoValor> => {
  return await http.get<TipoCampoValor>(`/tipoCampoValor/${tipoCampoValorId}`);
};

export const editTipoCampoValor = async (
  tipoCampoValorId: number, body: any
): Promise<TipoCampoValor> => {
  return await http.put<TipoCampoValor>(`/tipoCampoValor/${tipoCampoValorId}`, body);
};

export const deleteTipoCampoValor = async (
  tipoCampoValorId: number
): Promise<TipoCampoValor> => {
  return await http.delete<TipoCampoValor>(`/tipoCampoValor/${tipoCampoValorId}`);
};
