import './style.css';
import { Col, Row } from 'antd';
import Card from '../../../Card';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useLanguage from '../../../../hooks/useLanguage';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import useUsersOnline from '../../../../hooks/useUsersOnline';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { Themes } from '../../../../store/ui';
import colors from '../../../../const/colors';
import useInterval from '../../../../hooks/useInterval';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type UserOnline = {
  time: Moment;
  app: number;
  web: number;
};

const UsersOnlineWidget = () => {
  const chartRef = useRef<any>(null);
  const currentTheme = useSelector(selectUiTheme);
  const [data, setData] = useState({
    labels: ['Ahora'],
    datasets: [
      {
        label: 'App',
        backgroundColor: 'transparent',
        borderColor: 'rgb(51, 199, 255)',
        data: [0],
        pointRadius: [0],
        pointBackgroundColor: 'rgb(51, 199, 255)',
      },
      {
        label: 'Web',
        backgroundColor: 'transparent',
        borderColor: 'gray',
        data: [0],
        pointRadius: [0],
        pointBackgroundColor: 'gray',
      },
    ],
  });
  const t = useLanguage();
  const [userConnections] = useUsersOnline();
  const [labels] = useState<string[]>([
    '10m',
    '9m',
    '8m',
    '7m',
    '6m',
    '5m',
    '4m',
    '3m',
    '2m',
    '1m',
    'Ahora',
  ]);
  const [totalWeb, setTotalWeb] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [totalApp, setTotalApp] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [usersOnline, setUsersOnline] = useState<UserOnline[]>([]);

  const showUsersOnline = useCallback(() => {
    // Obtener el historial acumulado para web y app
    const webHistory: number[] = [];
    const appHistory: number[] = [];

    // Asegurarse de que el historial tenga la longitud adecuada .slice(-labels.length);
    const usersOnlineCopy = [...usersOnline].slice(-labels.length);

    // Inicializar el historial con ceros
    labels.forEach((_, idx) => {
      webHistory[idx] = 0;
      appHistory[idx] = 0;
    });

    for (let idx = labels.length - 1; idx >= 0; idx--) {
      const time = moment().subtract(parseInt(labels[idx]), 'minutes');
      for (let uidx = usersOnlineCopy.length - 1; uidx >= 0; uidx--) {
        if (labels[idx] !== 'Ahora') {
          if (usersOnlineCopy[uidx].time.isSame(time, 'minutes')) {
            // Actualizar siempre, incluso si el nuevo valor es igual al valor anterior
            webHistory[idx] = usersOnlineCopy[uidx].web;
            appHistory[idx] = usersOnlineCopy[uidx].app;
            break;
          }
        }
      }
    }

    if (usersOnline.length > 0) {
      webHistory[labels.length - 1] =
        usersOnline[usersOnline.length - 1]?.web ||
        webHistory[labels.length - 1] ||
        0;
      appHistory[labels.length - 1] =
        usersOnline[usersOnline.length - 1]?.app ||
        appHistory[labels.length - 1] ||
        0;
    }

    setTotalWeb(webHistory);
    setTotalApp(appHistory);
    setUsersOnline([
      ...usersOnlineCopy,
      {
        app: usersOnlineCopy[usersOnlineCopy.length - 1]?.app || 0,
        web: usersOnlineCopy[usersOnlineCopy.length - 1]?.web || 0,
        time: moment(),
      },
    ]);
  }, [labels, usersOnline]);

  useInterval(() => {
    console.log(usersOnline);
    showUsersOnline();
  }, 60000);

  useEffect(() => {
    setTotalWeb((oldVal) =>
      [...oldVal].map((val, idx) => {
        if (idx === labels.length - 1) {
          return userConnections.web;
        }
        return val;
      })
    );
    setTotalApp((oldVal) =>
      [...oldVal].map((val, idx) => {
        if (idx === labels.length - 1) {
          return userConnections.app;
        }
        return val;
      })
    );
    setUsersOnline((prevUsersOnline) => {
      const uniqueUsersOnline = [...prevUsersOnline, userConnections].reduce(
        (res: any, obj) => {
          const index = res.findIndex((o: any) =>
            o.time.isSame(obj.time, 'minute')
          );
          if (index !== -1) {
            res[index] = obj;
          } else {
            res.push(obj);
          }
          return res;
        },
        []
      );
      return uniqueUsersOnline;
    });
  }, [userConnections]);

  useEffect(() => {
    setData({
      labels: labels,
      datasets: [
        {
          label: 'App',
          backgroundColor: 'transparent',
          borderColor:
            currentTheme === Themes.DARK_THEME
              ? 'rgb(51, 199, 255)'
              : 'rgb(10, 10, 132)',
          data: totalApp,
          pointRadius: labels.map((_, idx) =>
            idx === labels.length - 1 ? 3 : 0
          ),
          pointBackgroundColor:
            currentTheme === Themes.DARK_THEME
              ? 'rgb(51, 199, 255)'
              : 'rgb(10, 10, 132)',
        },
        {
          label: 'Web',
          backgroundColor: 'transparent',
          borderColor: 'gray',
          data: totalWeb,
          pointRadius: labels.map((_, idx) =>
            idx === labels.length - 1 ? 3 : 0
          ),
          pointBackgroundColor: 'gray',
        },
      ],
    });
  }, [currentTheme, labels, totalApp, totalWeb]);

  return (
    <Card>
      <div
        style={{
          padding: '.5rem 1rem',
          width: '100%',
          height: '100%',
          overflowX: 'auto',
        }}
      >
        <Row>
          <Col
            span={24}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            {t('users_online')}
          </Col>
        </Row>
        <div
          style={{
            width: '100%',
            height: '80%',
            position: 'relative',
            paddingTop: '.5rem',
          }}
        >
          <Line
            ref={chartRef}
            options={{
              maintainAspectRatio: false,
              spanGaps: false,
              elements: {
                line: {
                  tension: 0.3,
                },
                point: {
                  radius: 0,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    precision: 0,
                  },
                  grid: {
                    display: false,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    font: {
                      size: 10,
                      weight: 'bold',
                    },
                  },
                },
              },
              color:
                currentTheme === Themes.DARK_THEME
                  ? colors.DARK_THEME.textColor
                  : colors.LIGHT_THEME.textColor,
              responsive: true,
            }}
            data={data}
          />
        </div>
      </div>
    </Card>
  );
};

export default UsersOnlineWidget;
