import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Button, Popconfirm } from 'antd';
import { CopyOutlined, PauseOutlined, LoadingOutlined } from '@ant-design/icons';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { Themes } from '../../../store/ui';
import { useSelector } from 'react-redux';
import { http } from "../../../http/axios";
import ZoneSelect from './zoneSelect'
import Item from './item'

const Box = forwardRef(function Box({ zonesData, user, day, week, copyZones }, ref) {

    const currentTheme = useSelector(selectUiTheme);

    //const frequencyData = user.data;
    const [frequencyData, setFrequencyData] = useState(user.data);
    const [zonesRender, setZonesRender] = useState([]);
    const [loadData, setLoadData] = useState(false);

    // encontrar el siguiente dia de la semana
    const positionDay = week.findIndex(obj => obj.id === day.id && obj.string === day.string);
    const nextDay = week.length !== positionDay + 1 ? week[positionDay + 1] : { id: null, string: '' };

    useEffect(() => {
        const zones = [];
        frequencyData.forEach(frequency => {
            if (frequency[day.id]) {
                const infoZone = zonesData.find(zone => zone.id === frequency.id_zona);
                const zone = {
                    string: (infoZone !== undefined ? infoZone.string : `zone_id: ${frequency.id_zona}`),
                    id_zona_frecuencia: frequency.id_zona_frecuencia,
                    id_zona: frequency.id_zona,
                    user_id: frequency.user_id,
                };
                zones.push(zone);
            }
        });
        setZonesRender(zones);
    }, [frequencyData]);

    const loadFrequencyUser = async () => { // consulta la informacion de las zonas de un usuario
        setLoadData(true);
        try {
            const frequencyZones = await http.get("/frequency-zones/user/" + user.id);
            setFrequencyData(frequencyZones);
            return frequencyZones;
        } catch (error) {
            console.error(error);
            return [];
        } finally {
            setLoadData(false);
        }
    };

    const addZones = (zones) => {
        const filteredZones = zones.filter(zone => {
            // Verificar si el objeto ya existe en zonesRender
            const existingZone = zonesRender.some(renderedZone => renderedZone.id_zona === zone.id_zona);
            return !existingZone; // Retornar true si no existe en zonesRender
        });

        const updatedZones = filteredZones.map(zone => { // filtrar si ya hay zonas_freceunte con este mismo id de zona, y armar nuevo objeto
            const matchingData = frequencyData.find(data => data.id_zona === zone.id);
            const id_zona_frecuencia = matchingData ? matchingData.id_zona_frecuencia : undefined;
            const { id, ...rest } = zone; // Desestructurar el objeto y renombrar la propiedad 'id' a 'id_zona'
            return { newRecord: true, id_zona: id, user_id: matchingData ? matchingData.user_id : user.id, ...rest, id_zona_frecuencia };
        });

        setZonesRender(prevZonesRender => {
            const updatedSet = new Set([...prevZonesRender, ...updatedZones]);
            const updatedArray = Array.from(updatedSet);
            return updatedArray;
        });
    };

    // Asigna la referencia al elemento del componente hijo
    useImperativeHandle(ref, () => ({
        addZones
    }));

    const evenButton = (
        <div className='box-button'>
            <div className='even-button'>
                <Popconfirm
                    overlayClassName={currentTheme === Themes.DARK_THEME ? 'custom-popconfirm-zoneFrequency-dark' : 'custom-popconfirm-zoneFrequency-light'}
                    title={`¿Copiar zonas del ${day.string} a ${nextDay.string}?`}
                    description="Esta acción remplazara las zonas actuales"
                    onConfirm={() => copyZones(nextDay, zonesRender, user)}
                    okText="Confirmar"
                    cancelText="Cancelar"
                >
                    <Button
                        className="copyZones-button"
                        shape="circle"
                        size="small"
                        icon={<PauseOutlined style={{ color: currentTheme === Themes.DARK_THEME ? 'white' : 'black' }} />}
                    ></Button>
                </Popconfirm>
            </div>
        </div>
    )

    return (
        <div className='box'>
            <div className="zoneSelect">
                <ZoneSelect
                    zonesData={zonesData}
                    zonesRender={zonesRender}
                    addZones={addZones}
                />
            </div>
            <div className='zonesList'>
                {zonesRender.map(zone => (
                    <Item
                        key={`${zone.id_zona_frecuencia}-${zone.id_zona}-${day.id}`}
                        loadFrequencyUser={loadFrequencyUser}
                        day={day}
                        zone={zone}
                    />
                ))}
            </div>
            {week.indexOf(day) !== week.length - 1 && zonesRender.length > 0 && evenButton}
        </div>
    )
});

export default Box
