import React, { useState, useEffect } from 'react'
import { message, Input, Button } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { http } from '../../../../http/axios';

function WarrantyItem({ item, onDelete }) {

    const [loading, setLoading] = useState(false);

    if(item.id_garantia === 1) return; // evitar mostrar garantia por defecto
    
    let feedback = () => { };

    const deleteItem = async () => {
        setLoading(true);

        try {
            await http.delete("/warranty/" + item.id_garantia)
            onDelete(item.id_garantia);
        } catch (error) {
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <tr>
            <td>
                <Input
                    placeholder="Marca"
                    defaultValue={item.marca}
                    readOnly={true}
                    bordered={false}
                />
            </td>
            <td>
                <Input
                    placeholder="Subfamilia"
                    defaultValue={item.subfamilia}
                    readOnly={true}
                    bordered={false}
                />
            </td>
            <td>
                <Input
                    placeholder="Meses"
                    defaultValue={item.meses}
                    readOnly={true}
                    bordered={false}
                />
            </td>
            <td className='button-cell'>
                <Button
                    type="text"
                    shape="circle"
                    size="small"
                    onClick={deleteItem}
                    icon={!loading ? <DeleteOutlined /> : <LoadingOutlined />}
                />
            </td>
        </tr>
    )
}

export default WarrantyItem