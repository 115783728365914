import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { ReactComponent as ICON_ADMIN } from '../../../assets/icons/icon_admin.svg';
import { ReactComponent as ICON_CHAT } from '../../../assets/icons/icon_chat.svg';
import { ReactComponent as ICON_ACCOUNTS } from '../../../assets/icons/icon_cuentas.svg';
import { ReactComponent as ICON_DASH } from '../../../assets/icons/icon_dash.svg';
import { ReactComponent as ICON_SIGNOUT } from '../../../assets/icons/icon_exit.svg';
import { ReactComponent as ICON_HELP } from '../../../assets/icons/icon_help.svg';
import { ReactComponent as ICON_ORDERS } from '../../../assets/icons/icon_pedidos.svg';
import { ReactComponent as ICON_PRODUCTS } from '../../../assets/icons/icon_productos.svg';
import { ReactComponent as ICON_REPORTS } from '../../../assets/icons/icon_reportes.svg';
import { ReactComponent as ICON_ROUTES } from '../../../assets/icons/icon_rutas.svg';
import { ReactComponent as ICON_TRACING } from '../../../assets/icons/icon_seguimiento.svg';
import { ReactComponent as ICON_OK } from '../../../assets/icons/ok_icon.svg';
import { ReactComponent as ICON_CLOCK } from '../../../assets/icons/clock_task.svg';
import { ReactComponent as ICON_REFRESH } from '../../../assets/icons/refresh_user_icon.svg';
import { ReactComponent as ICON_GPS } from '../../../assets/icons/gps_user_icon.svg';
import { ReactComponent as ICON_END_GPS } from '../../../assets/icons/destino_icon.svg';
import { ReactComponent as ICON_CONTACT_CENTER } from '../../../assets/icons/contact_center_ico.svg';
import { ReactComponent as ICON_ARROW_UP } from '../../../assets/icons/arrow_up.svg';
import { ReactComponent as ICON_ARROW_DOWN } from '../../../assets/icons/arrow_down.svg';
import { ReactComponent as ICON_USER_ALERT } from '../../../assets/icons/user_alert_solid_icon.svg';
import { ReactComponent as ICON_QUESTION } from '../../../assets/icons/question_icon.svg';
import { ReactComponent as ICON_MORE } from '../../../assets/icons/more_icon.svg';
import { ReactComponent as ICON_ROUTE } from '../../../assets/icons/ruta_icon.svg';
import { ReactComponent as ICON_POINTS } from '../../../assets/icons/puntos_icon.svg';
import { ReactComponent as ICON_CLOCK2 } from '../../../assets/icons/reloj_icon.svg';
import { ReactComponent as ICON_MASS } from '../../../assets/icons/massive.svg';
import LOGO_ANIMATED_DARK from '../../../assets/logo_onfield_dark.gif';
import LOGO_ANIMATED_LIGHT from '../../../assets/logo_onfield_light.gif';
import { Themes } from '../../store/ui/index';

export const LogoAnimated2Icon = (
  props: Partial<CustomIconComponentProps & { theme: string }>
) => (
  <img
    src={
      props.theme === Themes.DARK_THEME
        ? LOGO_ANIMATED_LIGHT
        : LOGO_ANIMATED_DARK
    }
    {...props}
    alt="Logo"
  />
);

export const LogoAnimatedIcon = (props: Partial<CustomIconComponentProps>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="e9BNCV2tuRT1"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 134 134"
  >
    <style>
      {
        '@keyframes e9BNCV2tuRT9_tr__tr{0%,to{transform:translate(40.301519px,57.961725px) rotate(0deg)}50%{transform:translate(40.301519px,57.961725px) rotate(160deg)}}'
      }
    </style>
    <path
      fill="#ff2900"
      fillRule="evenodd"
      d="M117.498 85.869a5.226 5.226 0 0 1-5.078-6.481 47.724 47.724 0 0 0 1.381-11.427c0-26.195-21.312-47.507-47.508-47.507-5.412 0-10.718.901-15.77 2.679a5.228 5.228 0 0 1-3.47-9.862A57.84 57.84 0 0 1 66.293 10c31.961 0 57.962 26.001 57.962 57.961 0 4.711-.567 9.398-1.686 13.932a5.23 5.23 0 0 1-5.071 3.976Z"
    />
    <path
      fill="#ff2900"
      fillRule="evenodd"
      d="M66.267 104.501c-18.822 0-34.847-14.507-36.392-33.591-.414-5.122.383-10.481 2.438-16.382a5.226 5.226 0 0 1 6.655-3.217 5.226 5.226 0 0 1 3.217 6.655c-1.588 4.562-2.189 8.407-1.89 12.1 1.16 14.337 13.787 25.064 28.103 23.895 14.336-1.162 25.055-13.769 23.895-28.104-1.161-14.336-13.778-25.048-28.104-23.895a5.215 5.215 0 0 1-5.632-4.788 5.228 5.228 0 0 1 4.788-5.632c20.079-1.622 37.742 13.389 39.368 33.471 1.625 20.08-13.39 37.741-33.471 39.368-.998.08-1.991.12-2.975.12Z"
    />
    <path
      fillRule="evenodd"
      d="M41.624 46.998a10.816 10.816 0 0 0-3.35.126L9.476 10.581a4.79 4.79 0 0 0-6.728-.797 4.79 4.79 0 0 0-.797 6.728L30.65 52.929a10.79 10.79 0 0 0-1.078 3.567c-.705 5.95 3.547 11.346 9.498 12.052 5.951.705 11.347-3.547 12.052-9.498s-3.547-11.347-9.498-12.052Z"
      style={{
        animation: 'e9BNCV2tuRT9_tr__tr 3000ms linear infinite normal forwards',
      }}
      transform="translate(26 10)"
    />
  </svg>
);

export const RouteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ROUTE as any} {...props} />
);

export const PointsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_POINTS as any} {...props} />
);

export const Clock2Icon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_CLOCK2 as any} {...props} />
);

export const AdminIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ADMIN as any} {...props} />
);

export const ChatIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_CHAT as any} {...props} />
);

export const AccountsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ACCOUNTS as any} {...props} />
);

export const DashIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={ICON_DASH as any} {...props} />;
};

export const SignoutIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_SIGNOUT as any} {...props} />
);

export const HelpIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_HELP as any} {...props} />
);

export const OrdersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ORDERS as any} {...props} />
);

export const ProductsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_PRODUCTS as any} {...props} />
);

export const ReportsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_REPORTS as any} {...props} />
);

export const RoutesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ROUTES as any} {...props} />
);

export const TracingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_TRACING as any} {...props} />
);

export const OkIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_OK as any} {...props} />
);

export const ClockIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_CLOCK as any} {...props} />
);

export const RefreshIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_REFRESH as any} {...props} />
);

export const GpsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_GPS as any} {...props} />
);

export const EndGPSIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_END_GPS as any} {...props} />
);

export const ContactCenterIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_CONTACT_CENTER as any} {...props} />
);

export const ArrowUpIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ARROW_UP as any} {...props} />
);

export const ArrowDownIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_ARROW_DOWN as any} {...props} />
);

export const UserAlert = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_USER_ALERT as any} {...props} />
);

export const QuestionIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_QUESTION as any} {...props} />
);

export const MoreIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_MORE as any} {...props} />
);

export const MassiveIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ICON_MASS as any} {...props} />
);
