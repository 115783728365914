import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { instance } from '../../http/axios';
import { selectCurrentUser } from '../../store/auth/auth.selectors';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import OnfieldFrame from '../OnfieldFrame';
import { Button } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import { Themes } from '../../store/ui';
import colors from '../../const/colors';
import { useNavigate } from 'react-router-dom';
import {
  setCollapsedOnlineTrackingRightPanel,
  setIsOnlineTracking,
} from '../../store/ui/ui.actions';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/firebase';
import { INFO_APP } from '../../const/info';

const OnlineTracking: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentTheme = useSelector(selectUiTheme);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      style={{
        flex: 1,
        height: '100%',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '.5rem',
        paddingBottom: '.5rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="container-title" style={{ position: 'relative' }}>
        {!isTabletOrMobile ? (
          <div
            style={{
              position: 'absolute',
              top: 2,
              left: 10,
              margin: '.1rem',
            }}
          >
            <Button
              size="small"
              htmlType="button"
              onClick={() => {
                //handleFullScreen.enter();
                dispatch(setCollapsedOnlineTrackingRightPanel(true));
                dispatch(setIsOnlineTracking(true));
                navigation('/onlineTracking');
              }}
              icon={<FullscreenOutlined />}
              style={{
                backgroundColor:
                  currentTheme === Themes.DARK_THEME
                    ? colors.DARK_THEME.common.select
                    : colors.LIGHT_THEME.common.select,
                border: 'none',
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
            />
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              top: 2,
              left: 10,
              margin: '.1rem',
            }}
          >
            <Button
              size="small"
              htmlType="button"
              onClick={() => {
                logEvent(analytics, 'right_panel_tracking_close', {
                  instance,
                  version: INFO_APP.VERSION,
                  userId: currentUser?.user_id,
                  groupId: currentUser?.user_group_id,
                });
                dispatch(setCollapsedOnlineTrackingRightPanel(true));
              }}
              icon={<FontAwesomeIcon icon={faXmark} />}
              style={{
                backgroundColor:
                  currentTheme === Themes.DARK_THEME
                    ? colors.DARK_THEME.common.select
                    : colors.LIGHT_THEME.common.select,
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
            />
          </div>
        )}

        <div>Seguimiento en línea</div>
      </div>
      <div style={{ flex: 1, marginTop: '.5rem' }}>
        <OnfieldFrame
          theme={currentTheme}
          url={
            currentUser?.instance?.legacy_url.replace('${instance}', instance) +
            '/index.php?route=rutas/seguimiento_app/onlyMap'
          }
          style={{ height: '100%' }}
        />
      </div>
      <div style={{ textAlign: 'center', color: '#fff' }}>
        <small>by Assertsoft</small>
      </div>
    </div>
  );
};

export default OnlineTracking;
