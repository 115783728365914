import i18next from 'i18next';

i18next.init({
  lng: 'es',
  resources: {
    es: {
      translation: {
        activity: 'Actividad',
        commune: 'Comuna',
        region: 'Región',
        activities: 'Actividades',
        users_online: 'Usuarios en línea',
        active_campaigns: 'Campañas activas',
        tasks: 'Tareas',
        activities_per_day: 'Actividades por día',
        routes: 'Polígonos Rutas',
        previous_period: 'Período anterior',
        orders: 'Pedidos',
        dashboard: 'Panel de control',
      },
      common: {
        save: 'Guardar',
        cancel: 'Cancelar',
        close: 'Cerrar',
        apply: 'Aplicar',
        all: 'Todos',
        today: 'Hoy',
        week: 'Semana',
        week_short: 'Sem',
        month: 'Mes',
        total: 'Total',
        setting: 'Configuración',
        accept: 'Aceptar',
      },
      widgets: {
        average_time: {
          title: 'Tiempo medio',
          pending: 'Pendiente',
          in_progress: 'En curso',
          assign_short: 'Asign.',
          finished_short: 'Term.',
          start_short: 'Ini.',
        },
        map_activities: {
          title: 'Mapa de calor actividades',
          config: {
            show_polygons: 'Mostrar',
            routes_zoom: 'Rutas a mostrar/zoom',
          },
        },
      },
    },
  },
});

export default i18next;
