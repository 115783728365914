import React from 'react';

import './style.css';

const LoadingBar: React.FC = () => {
  return (
    <div className="loader-line"></div>
  );
};

export default LoadingBar;
