import './dashboard.css';
import { SettingOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Sizes } from '../../domain/models/DashboardWidget';
import DashboardWidgetSettings from '../components/DashboardWidgetSettings';
import DashboardWidgets from '../components/widgets/dashboard';
import useLanguage from '../hooks/useLanguage';
import { selectCurrentUser } from '../store/auth/auth.selectors';
import { selectDashboardWidgets } from '../store/dashboard/dashboard.selectors';
import { selectUiTheme } from '../store/ui/ui.selectors';
import { Themes } from '../store/ui';
import colors from '../const/colors';

const Dashboard = () => {
  const currentDashboards = useSelector(selectDashboardWidgets);
  const currentUser = useSelector(selectCurrentUser);
  const currentTheme = useSelector(selectUiTheme);
  const [visible, setVisible] = useState(false);
  const [client, setClient] = useState<number[]>([]);
  const t = useLanguage();

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (currentUser) {
      const sw_relacion_cta_contenedora_usuario =
        currentUser?.instance?.parameters.find(
          (p) => p.parameter_id === 'sw_relacion_cta_contenedora_usuario'
        );

      if (
        currentUser &&
        sw_relacion_cta_contenedora_usuario !== undefined &&
        sw_relacion_cta_contenedora_usuario?.value1 === '1' &&
        !sw_relacion_cta_contenedora_usuario?.value2
          .split(',')
          .includes(currentUser?.user_group_id.toString())
      ) {
        setClient([-1]);
      } else if ([1, 100].includes(currentUser?.user_group_id || 0)) {
        setClient([-1]);
      } else if (
        currentUser?.user_group_id !== 13 &&
        currentUser?.clients?.length > 0
      ) {
        setClient([currentUser.clients[0]?.id_cliente]);
      }
    }
  }, [currentUser]);

  return (
    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <Row gutter={[20, 15]}>
        <Col xs={24} md={{ span: 17 }}>
          <h1
            style={{
              fontSize: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {t('dashboard')}
          </h1>
        </Col>
        <Col
          xs={24}
          md={{ span: 5 }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Select
            showSearch
            maxTagCount={1}
            placeholder="Cuenta"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            style={{ width: '100%', border: 'none' }}
            value={client}
            onChange={(value) => {
              if (value.length === 0) {
                setClient([-1]);
              } else {
                if (value.length > 1) {
                  if (value[value.length - 1] === -1) {
                    setClient([-1]);
                    return;
                  }
                }
                setClient(value.filter((v) => v !== -1));
              }
            }}
            className="container_account text"
            mode="multiple"
          >
            <Select.Option value={-1}>
              {t('all', { ns: 'common' })}
            </Select.Option>
            {(currentUser?.clients || []).length > 0 &&
              currentUser?.clients
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map((client, index) => (
                  <Select.Option key={index} value={client.id_cliente}>
                    {client.nombre}
                  </Select.Option>
                ))}
          </Select>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 2 }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Popover
            content={<DashboardWidgetSettings />}
            trigger="click"
            visible={visible}
            placement="left"
            onVisibleChange={handleVisibleChange}
            className="button_widget_order"
          >
            <Button
              shape="circle"
              icon={<SettingOutlined />}
              style={{
                backgroundColor:
                  currentTheme === Themes.DARK_THEME
                    ? colors.DARK_THEME.common.select
                    : colors.LIGHT_THEME.common.select,
                border: 'none',
              }}
              className="text"
            />
          </Popover>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(20%, 1fr))',
              gridAutoRows: 'minmax(200px, 1fr)',
              gap: '15px',
              maxWidth: '100%',
            }}
            className="dashboard-container"
          >
            {currentDashboards.map((widget) => {
              let style: any = {
                gridColumn: 'span 1',
                gridRow: 'span 1',
                height: '200px',
              };

              if (
                widget.default_size === Sizes.MEDIUM ||
                widget.default_size === Sizes.LARGE
              ) {
                style = {
                  gridColumn: 'span 2',
                  gridRow: 'span 2',
                  height: '415px',
                };
              }

              return (
                <div style={style} key={widget.dash_panel_id}>
                  <DashboardWidgets widget={widget} clientsId={client} />
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
