import { OverlayViewF } from '@react-google-maps/api';
import { Avatar } from 'antd';
import { FC } from 'react';
import { instance } from '../../../http/axios';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { Themes } from '../../../store/ui';

type Props = {
  color: string;
  position: google.maps.LatLng;
  user: any;
  tracksViewChanges?: boolean;
  onClick?: (e: any) => void;
};

const MarkerStart: FC<Props> = ({ color, user, position, onClick }) => {
  const currentTheme = useSelector(selectUiTheme);

  return user.user.image ? (
    <OverlayViewF position={position} zIndex={100} mapPaneName={'floatPane'}>
      <div
        onClick={() => {
          onClick?.(user);
        }}
      >
        <Avatar
          src={`https://${instance}.onfield.cl/efs/${instance}/image/?tipo=user&img=${user.user.image}`}
          size={24}
          style={{
            border: `2px solid ${color}`,
            zIndex: 100,
            transform: 'translate(-50%, -50%)',
          }}
        />
        <small
          style={{
            position: 'absolute',
            top: '5px',
            left: '-50px',
            width: '100px',
            textAlign: 'center',
            color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
          }}
        >
          {user.user.firstname} {user.user.lastname}
        </small>
      </div>
    </OverlayViewF>
  ) : (
    <OverlayViewF position={position} zIndex={100} mapPaneName={'floatPane'}>
      <div
        onClick={() => {
          onClick?.(user);
        }}
      >
        <Avatar
          style={{
            backgroundColor: color,
            transform: 'translate(-50%, -50%)',
            zIndex: 100,
          }}
          size={24}
        >
          {`${user.user.firstname[0]}`.toUpperCase()}
        </Avatar>
        <small
          style={{
            position: 'absolute',
            top: '15px',
            left: '-50px',
            width: '100px',
            textAlign: 'center',
            color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
          }}
        >
          {user.user.firstname} {user.user.lastname}
        </small>
      </div>
    </OverlayViewF>
  );
};

export default MarkerStart;
