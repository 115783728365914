import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as chatTypes from './chat.types';
import * as chatActions from './chat.actions';
import { Chat } from '../../../domain/models/Chat';
import { chatService } from '../../../domain/services/Chat.service';

export function* loadUserChats(props: any) {
  try {
    const chats: Chat[] = yield chatService.loadUserChat(props.payload);
    yield put(chatActions.getChatsSuccess(chats));
  } catch (e: any) {
    console.error(e);
    yield put(chatActions.getChatsFailure(e.message));
  }
}

//------------------------------
export function* onLoadUserChats() {
  yield takeLatest(chatTypes.GET_CHATS, loadUserChats);
}

export function* chatSaga() {
  yield all([call(onLoadUserChats)]);
}
