import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/auth/auth.selectors';
import { Card, Tabs, Alert, Spin, Button, Modal, ConfigProvider, theme } from 'antd';
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import styled from 'styled-components';
import { Themes } from '../../store/ui';

import { http } from '../../http/axios';

import Generals from '../../components/Settings/autoagendameinto/generals';
import OtherParameters from '../../components/Settings/autoagendameinto/otherParameters';
import Warranties from '../../components/Settings/autoagendameinto/warranties';
import AlternativeSSTT from '../../components/Settings/autoagendameinto/alternativeSSTT';

import './settings.css';
import './theme.less';

const { TabPane } = Tabs;

const { confirm } = Modal; // Modal.destroyAll(); // function quitar modales

const DivStyled = styled.div`
  .ant-form .ant-form-item .ant-form-item-label > label {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background-color: ${(props) => (props.theme === Themes.DARK_THEME ? '#000' : '#fff')};
  }

  .ant-input {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? 'white' : 'black')};
    background-color: ${(props) => (props.theme === Themes.DARK_THEME ? '#2A2D2F' : '#fff')};
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-color: #555555;' : '')};
  }

  .ant-input-number-input {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? 'white' : 'black')};
    background-color: ${(props) => (props.theme === Themes.DARK_THEME ? '#2A2D2F' : '#fff')};
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-color: #555555 !important;' : '')};
  }
  
  .ant-input-number {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-color: #555555 !important;' : '')};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: 1px solid #555555;' : '')};
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: 1px solid #555555;' : '')};
  }

  .ant-card-bordered {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-color: #555555 !important;' : '')};
  }

  .ant-select-selection-item {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? 'white' : 'black')};
  }

  .ant-input-number:focus {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-color: #555555 !important;' : '')};
    outline: none;
  }

  .ant-input-number-handler, .ant-input-number-handler-up, .ant-input-number-handler-down {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? 'white' : 'black')};
    background-color: ${(props) => (props.theme === Themes.DARK_THEME ? '#2A2D2F' : '#fff')};
  }

  ant-select-selection-item {
    color: red;
  }

  .anticon {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? 'white' : 'black')};
  }

  .settingsTableBorder {
    border: ${(props) => (props.theme === Themes.DARK_THEME ? '1px solid black' : '1px solid black')};
  }

  .settingsTable th, .settingsTable td {
    border: ${(props) => (props.theme === Themes.DARK_THEME ? '1px solid rgba(0, 0, 0, 0.25)' : '1px solid #ddd')};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .settingsTable th {
    background-color: ${(props) => (props.theme === Themes.DARK_THEME ? 'black' : 'rgba(0, 0, 0, 0.25)')};
  }

  .settingsTable {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }
  
  .settingsTable {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-card {
    border-radius: 0 6px 6px 6px;
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
  }

  .ant-tabs-nav::before {
    all: unset;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    gap: 6px;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    border-radius: 6px 6px 0 0;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    background-color: ${(props) => (props.theme === Themes.DARK_THEME ? '#000' : '#fff')};
  }

  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    color: #666;
  }

  .ant-switch-checked {
    background: rgb(23, 113, 161);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

function SettingsAA() {
  const currentTheme = useSelector(selectUiTheme);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [styleButton, setStyleButton] = useState({});

  const generalsRef = useRef();

  const save = () => {
    setLoadingSave(true);
    // setUnsavedChanges(false);
  };

  const showConfirm = (key) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Hay cambios sin guardar',
      content: <span>¿Está seguro que desea cambiar de pestaña?</span>,
      onOk() {
        setActiveTab(key);
        setUnsavedChanges(false); // activar al cambair funcion y deshacer cambios
      },
      onCancel() {
        // console.log('Cancel');
      },
      className: currentTheme === Themes.DARK_THEME ? 'custom-confirm-dialog-dark' : 'custom-confirm-dialog-light'
    });
  };

  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    if (unsavedChanges) {
      showConfirm(key);
    } else {
      setActiveTab(key);
    }
  };

  return (
    <div className='settingsContainerAA'>
      <h1 className="settingsContainerTitle">
        Configuración módulo autoagendamiento
      </h1>

      <div className={currentTheme === Themes.DARK_THEME ? 'saveDataButton saveDataButton-dark' : 'saveDataButton saveDataButton-light'}>
        <Button
          type="primary"
          icon={<SaveOutlined />}
          loading={loadingSave}
          onClick={() => save()}
          disabled={!unsavedChanges}
          style={unsavedChanges ? ({ backgroundColor: '#1e91cf', borderColor: '#1978ab', borderRadius: '10px', color: 'white' }) : ({ borderRadius: '10px' })}
        >
          Guardar
        </Button>
      </div>


      <DivStyled className="settingsContainer" theme={currentTheme}>
        <Tabs activeKey={activeTab} onChange={handleTabChange} type="card" size={"large"}>
          <TabPane tab="Generales" key="1">
            <Generals setUnsavedChanges={setUnsavedChanges} loadingSave={loadingSave} setLoadingSave={setLoadingSave} unsavedChanges={unsavedChanges} />
          </TabPane>
          <TabPane tab="Garantías" key="2">
            <Warranties setUnsavedChanges={setUnsavedChanges} loadingSave={loadingSave} setLoadingSave={setLoadingSave} unsavedChanges={unsavedChanges} />
          </TabPane>
          <TabPane tab="SSTT Alternativos" key="3">
            <AlternativeSSTT />
          </TabPane>
          <TabPane tab="Otros Parámetros" key="4">
            <OtherParameters setUnsavedChanges={setUnsavedChanges} loadingSave={loadingSave} setLoadingSave={setLoadingSave} unsavedChanges={unsavedChanges} />
          </TabPane>
        </Tabs>
      </DivStyled>
    </div>
  );
}

export default SettingsAA;
