import { Form, Segmented, Select, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { http } from '../../http/axios';
import { Themes } from '../../store/ui';
import { selectUiTheme } from '../../store/ui/ui.selectors';

const FormStepThree: FC<any> = ({ form, routePlanId }) => {
  const [personFilter, setPersonFilter] = useState('Todos');
  const [users, setUsers] = useState<any[]>([]);
  const [skills, setSkills] = useState<any[]>([]);
  const currentTheme = useSelector(selectUiTheme);

  useEffect(() => {
    (async () => {
      const response: any[] = await http.get(
        '/users/technicians?route_plan_id=' + routePlanId
      );
      setUsers(response.sort((a, b) => a.firstname.localeCompare(b.firstname)));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response: any[] = await http.get('/users/skills');
      setSkills(response);
    })();
  }, []);

  return (
    <div className="steps-content" style={{ flex: 1 }}>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ type: 'Todos' }}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
          }}
          name="type"
        >
          <Segmented
            options={['Todos', 'Habilidad', 'Selección']}
            value={personFilter}
            onChange={(val) => {
              form.setFieldsValue({
                type: val.toString(),
              });
              setPersonFilter(val.toString());
            }}
            style={{
              color: currentTheme === Themes.DARK_THEME ? '#ddd' : 'black',
            }}
          />
        </Form.Item>

        {personFilter === 'Habilidad' && (
          <Form.Item
            label={
              <span
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              >
                Habilidades
              </span>
            }
            name="skills"
            rules={[{ required: true, message: 'Seleccione la habilidad' }]}
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Selecciona habilidades"
              style={{
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              }}
            >
              {skills.map((skill) => (
                <Select.Option key={skill.id_grupo} value={skill.id_grupo}>
                  ({skill.abreviacion}) {skill.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {personFilter === 'Selección' && (
          <Form.Item
            label={
              <span
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              >
                Selección
              </span>
            }
            name="users"
            rules={[{ required: true, message: 'Seleccione las personas' }]}
          >
            <Select
              mode="multiple"
              placeholder="Selecciona las personas"
              filterOption={(input, option: any) => {
                if (!option) return false;
                const name = option.children.join(' ').toLowerCase();
                return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              style={{
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              }}
            >
              {users.map((user) => (
                <Select.Option key={user.user_id} value={user.user_id}>
                  {user.firstname} {user.lastname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default FormStepThree;
