import { FC } from 'react';
import { useSelector } from 'react-redux';
import OnfieldFrame from '../components/OnfieldFrame';
import { instance } from '../http/axios';
import { selectCurrentUser } from '../store/auth/auth.selectors';
import { selectUiTheme } from '../store/ui/ui.selectors';

const OnlineTracking: FC = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentTheme = useSelector(selectUiTheme);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        padding: '0 1.5rem 1.5rem 1.5rem',
      }}
    >
      <div>
        <h1
          style={{
            fontSize: '24px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
          }}
        >
          Seguimiento en línea
        </h1>
      </div>
      <OnfieldFrame
        theme={currentTheme}
        url={
          currentUser?.instance?.legacy_url.replace('${instance}', instance) +
          '/index.php?route=rutas/seguimiento_app/onlyMap'
        }
        style={{ height: '97%' }}
      />
    </div>
  );
};

export default OnlineTracking;
