import React, { FC, useEffect, useRef, useState } from 'react';
import { OverlayViewF } from '@react-google-maps/api';
import { Avatar } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { Themes } from '../../../store/ui';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import styled from 'styled-components';

type Props = {
  color: string;
  position: google.maps.LatLng;
  user: any;
  groups: any[];
  onClick?: (u: any) => void;
};

const DivContent = styled('div')`
  ::-webkit-scrollbar {
    width: 8px;
  }
`;

const GroupedMarkerStart: FC<Props> = ({
  color,
  groups,
  position,
  user,
  onClick,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [glosa, setGlosa] = useState('');
  const menuRef = useRef<HTMLDivElement>(null);
  const currentTheme = useSelector(selectUiTheme);
  const currentUser = useSelector(selectCurrentUser);

  const handleMenuToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_plan_ruta = currentUser.instance?.parameters.find(
        (p: any) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (sw_modulo_plan_ruta) {
        const parameters = JSON.parse(sw_modulo_plan_ruta?.value6);

        if (parameters?.glosa_usuario) {
          setGlosa(parameters?.glosa_usuario);
        }
      }
    }
  }, [currentUser]);

  return (
    <OverlayViewF position={position} zIndex={100} mapPaneName={'floatPane'}>
      <div
        style={{
          transform: 'translate(-50%, -50%)',
          cursor: 'pointer',
        }}
        onClick={handleMenuToggle}
      >
        <Avatar
          style={{
            backgroundColor: color,
            zIndex: 100,
          }}
          size={26}
        >
          <UsergroupAddOutlined />
        </Avatar>
        <small
          style={{
            position: 'absolute',
            top: '25px',
            left: '-38px',
            width: '100px',
            textAlign: 'center',
            fontSize: '11px',
            color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
          }}
        >
          {glosa}
        </small>
      </div>
      {isMenuOpen && (
        <DivContent
          ref={menuRef}
          style={{
            position: 'absolute',
            top: '-50px', // Ajusta la posición del menú arriba del marcador
            left: '10px',
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            borderRadius: '4px',
            overflowY: 'auto', // Activa el scroll si es necesario
            maxHeight: '200px',
            width: '150px',
            zIndex: 100,
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              alignItems: 'center',
            }}
            onClick={() => {
              onClick?.(user);
            }}
          >
            <Avatar
              style={{
                backgroundColor: user.color,
                zIndex: 100,
                fontSize: '10px',
              }}
              size={18}
            >
              {`${user.user.firstname[0]}`.toUpperCase()}
            </Avatar>
            <div
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {user.user.firstname} {user.user.lastname}
            </div>
          </div>
          {groups.map((u) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 5,
                  alignItems: 'center',
                }}
                onClick={() => {
                  onClick?.(u);
                }}
								key={u.user_id}
              >
                <Avatar
                  style={{
                    backgroundColor: u.color,
                    zIndex: 100,
                    fontSize: '10px',
                  }}
                  size={18}
                >
                  {`${u.user.firstname[0]}`.toUpperCase()}
                </Avatar>
                <div
                  style={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {u.user.firstname} {u.user.lastname}
                </div>
              </div>
            );
          })}
        </DivContent>
      )}
    </OverlayViewF>
  );
};

export default GroupedMarkerStart;
