import { Chat } from '../../../domain/models/Chat';
import * as chatTypes from './chat.types';

export const getChats = (userId: number) => ({
  type: chatTypes.GET_CHATS,
  payload: userId,
});

export const addUserChat = (chat: Chat) => ({
  type: chatTypes.ADD_CHAT,
  payload: chat,
});

export const addUserChatRealDB = (oldId: string, chat: Chat) => ({
  type: chatTypes.ADD_CHAT_RDB,
  payload: { id: oldId, chat },
});

export const updateUserChat = (chat: Chat) => ({
  type: chatTypes.MODIFY_CHAT,
  payload: chat,
});

export const updateSpecificChat = (id: string, chat: Chat) => ({
  type: chatTypes.MODIFY_SPECIFIC_CHAT,
  payload: {
    id,
    chat,
  },
});

export const removeUserChat = (chat: Chat) => ({
  type: chatTypes.REMOVE_CHAT,
  payload: chat,
});

export const getChatsSuccess = (chats: Chat[]) => ({
  type: chatTypes.GET_CHATS_SUCCESS,
  payload: chats,
});

export const getChatsFailure = (error: any) => ({
  type: chatTypes.GET_CHATS_FAILURE,
  payload: error,
});

export const getOpenedChats = (chats: Chat[]) => ({
  type: chatTypes.GET_OPENED_CHATS,
  payload: chats,
});

export const openChat = (chatId: string, userId: number) => ({
  type: chatTypes.OPEN_CHAT,
  payload: {
    chatId,
    userId,
  },
});

export const openInactiveChat = (chat: Chat, userId: number) => ({
  type: chatTypes.OPEN_INACTIVE_CHAT,
  payload: { chat, userId },
});

export const updateOpenedChat = (chat: Chat) => ({
  type: chatTypes.UPDATE_OPENED_CHAT,
  payload: chat,
});

export const closeChat = (chatId: string) => ({
  type: chatTypes.CLOSE_CHAT,
  payload: chatId,
});

export const toggleChat = (chatId: string, userId: number) => ({
  type: chatTypes.TOGGLE_CHAT,
  payload: { chatId, userId },
});

export const closeAllOpenedChats = () => ({
  type: chatTypes.CLOSE_ALL_OPENED_CHATS,
});

export const massiveChats = (mass: boolean) => ({
  type: chatTypes.SET_MASSIVE,
  payload: mass,
});