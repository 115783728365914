import { TipoCampoValor } from '../../../domain/models/TipoCampoValor';
import * as tipoCampoValorService from '../../../domain/services/tipoCampoValor.service';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useRef } from 'react';
import styled from 'styled-components';
import ExcelJS from 'exceljs';

const ExcelImport = (props: any) => {
  const inputFile: React.MutableRefObject<any> = useRef(null);

  const RoundButton = styled(Button)`
    border-radius: 8px;
  `;

  const createTipoCampoValor = (
    nombreCampo: string,
    idValor: string,
    valorCampo: string,
    estado: boolean
  ) => {
    const body: TipoCampoValor = {
      nombre_campo: nombreCampo,
      valor: valorCampo,
      estado: Number(estado),
      id_tipo_campo: '',
      id_valor: idValor,
      id_tipo_campo_valor: 0,
    };
    tipoCampoValorService
      .createTipoCampoValores(body)
      .then(props.cargaTabla)
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFileUpload = (e: any) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;

      var parts = filename.split('.');
      const fileType = parts[parts.length - 1];

      const file = files[0];

      const reader = new FileReader(); // lee el archivo usando `FileReader` API
      reader.readAsArrayBuffer(file);
      reader.onabort = () =>
        console.warn(`Reading of file ${file.path} was aborted.`);
      reader.onerror = () =>
        console.error(`Reading of file ${file.path} has failed.`);

      reader.onloadend = function (event) {
        var bufferArray = reader.result;

        var workbook = new ExcelJS.Workbook();

        workbook.xlsx.load(bufferArray as Buffer).then((sheet) => {
          const names: any = workbook.worksheets[0].getRow(1).values;
          if (
            names[1] === 'ID' &&
            names[2] === 'Nombre Campo' &&
            names[3] === 'ID Valor' &&
            names[4] === 'Valor'
          ) {
            workbook.worksheets[0].eachRow(
              { includeEmpty: true },
              (row: any) => {
                const colValue1 = row.values[1];
                const colValue2 = row.values[2];
                const colValue3 = row.values[3];
                const colValue4 = row.values[4];
                if (colValue1 !== 'ID') {
                  createTipoCampoValor(colValue2, colValue3, colValue4, true);
                }
                props.cargaTabla();
              }
            );
          } else {
            props.setShowAlert(true);
            setTimeout(() => {
              //message.error("Formato no valido [ID, Nombre Campo, ID Valor, Valor]")
              props.setShowAlert(false);
            }, 7000);
          }
        });
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onInputClick = (event: any) => {
    event.target.value = '';
  };

  return (
    <>
      <input
        style={{ display: 'none' }}
        // acepta=".zip,.rar"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
        onClick={onInputClick}
      />
      <Tooltip title={props.tooltipTitle}>
        <RoundButton
          type="primary"
          style={props.style}
          key="2"
          onClick={onButtonClick}
        >
          <UploadOutlined />
        </RoundButton>
      </Tooltip>
    </>
  );
};

export default ExcelImport;
