import { PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Transfer, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../http/axios';

const FormProfile = () => {
  const [txtName, setTxtName] = useState('');
  const [selPermission, setSelPermission] = useState('3');
  const [menus, setMenus] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [assignedMenus, setAssignedMenus] = useState<string[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const create = () => {
    try {
      if (txtName === '')
        throw new Error('Debe ingresar un nombre para el perfil');
      if (assignedMenus.length === 0)
        throw new Error('Debe asignar al menos un menú al perfil');

      setLoading(true);
      http.post('/roles', {
        name: txtName,
        permission: selPermission,
        menus: assignedMenus
          .map((menuId) =>
            menus.find((menu) => `${menu.menu_id}` === `${menuId}`)
          )
          .filter((menu) => menu !== undefined),
      });
      navigate('/admin/profiles');
    } catch (error: any) {
      const message = error.response?.data?.message || error.message;
      notification['error']({
        message: 'Error',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const update = () => {
    try {
      if (txtName === '')
        throw new Error('Debe ingresar un nombre para el perfil');
      if (assignedMenus.length === 0)
        throw new Error('Debe asignar al menos un menú al perfil');

      setLoading(true);
      http.put('/roles/' + id, {
        name: txtName,
        permission: selPermission,
        menus: assignedMenus
          .map((menuId) =>
            menus.find((menu) => `${menu.menu_id}` === `${menuId}`)
          )
          .filter((menu) => menu !== undefined),
      });
      navigate('/admin/profiles');
    } catch (error: any) {
      const message = error.response?.data?.message || error.message;
      notification['error']({
        message: 'Error',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (menus: any[]) => {
    setAssignedMenus(menus);
  };

  useEffect(() => {
    const getMenus = async () => {
      try {
        setLoading(true);
        const response: any = await http.get(`/menus`);
        setMenus(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (id && id !== 'create') {
      const loadData = async () => {
        try {
          await getMenus();
          setLoading(true);
          const response: any = await http.get(`/roles/${id}`);
          setTxtName(response.name);
          setAssignedMenus(response.menus.map((menu: any) => menu.menu_id));
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      loadData();
    } else {
      getMenus();
    }
  }, [id]);

  return (
    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <Row gutter={[20, 15]}>
        <Col xs={24} md={{ span: 18 }}>
          <h1
            style={{
              fontSize: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
            }}
          >
            Perfil
          </h1>
        </Col>
        <Col
          xs={6}
          md={{ span: 6 }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '.5rem',
          }}
        >
          <Button
            style={{ borderRadius: '5px' }}
            onClick={() => navigate(-1)}
            icon={<RollbackOutlined />}
          />
          <Button
            onClick={() => {
              if (id && id !== 'create') {
                update();
              } else {
                create();
              }
            }}
            type="primary"
            className="onfield-button"
          >
            Guardar
          </Button>
        </Col>
        <Col span={24} md={{ offset: 5, span: 14 }}>
          <label htmlFor="txtName">Nombre</label>
          <Input
            id="txtName"
            value={txtName}
            onChange={(e) => {
              setTxtName(e.target.value);
            }}
            placeholder="Nombre"
          />
        </Col>
        <Col
          span={24}
          md={{ offset: 5, span: 14 }}
          style={{ overflow: 'auto' }}
        >
          <Transfer
            dataSource={menus}
            showSearch
            listStyle={{
              width: 250,
              height: 300,
            }}
            operations={['Asignar', 'Desasignar']}
            targetKeys={assignedMenus}
            onChange={handleChange}
            render={(item) => `${item.label}`}
            filterOption={(inputValue, item) =>
              item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            rowKey={(record) => record.menu_id}
          />
        </Col>
        <Col span={24} md={{ offset: 5, span: 14 }}>
          <label htmlFor="selPermission">Permisos</label>
          <Select
            id="selPermission"
            value={selPermission}
            onChange={(value) => {
              setSelPermission(value);
            }}
            style={{ width: '100%' }}
          >
            <Select.Option value="1">Lectura</Select.Option>
            <Select.Option value="2">Lectura y escritura</Select.Option>
            <Select.Option value="3">
              Lectura, escritura y eliminar
            </Select.Option>
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default FormProfile;
