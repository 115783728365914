import { combineReducers } from 'redux';
import auth, { AuthState } from './auth';
import dashboard, { DashboardState } from './dashboard';
import ui, { UiState } from './ui';
import chat, { ChatState } from './chat';

export interface AppState {
  ui: UiState;
  auth: AuthState;
  dashboard: DashboardState;
  chat: ChatState;
}

const rootReducer = combineReducers({ ui, auth, dashboard, chat });

export default rootReducer;
