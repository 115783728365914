import { Button, Tooltip } from 'antd';
import styled from 'styled-components';
import ExcelJS from 'exceljs';
import { FileExcelOutlined } from '@ant-design/icons';

const ExcelExport = (props: any) => {
  const RoundButton = styled(Button)`
    border-radius: 8px;
  `;
  const handlerClickDownloadButton = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    format: 'xlsx' | 'csv'
  ) => {
    e.preventDefault();

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');

    worksheet.columns = [
      { header: 'ID', key: 'id', width: 15 },
      { header: 'Nombre Campo', key: 'nombreCampo', width: 30 },
      { header: 'ID Valor', key: 'idValor', width: 15 },
      { header: 'Valor', key: 'valor', width: 30 },
    ];

    for (let index = 0; index < props.dataSourceValor.length; index++) {
      const element = props.dataSourceValor[index];
      worksheet.addRows([
        {
          id: element.id_tipo_campo_valor,
          nombreCampo: element.nombre_campo,
          idValor: element.id_valor,
          valor: element.valor,
        },
      ]);
    }

    const uint8Array =
      format === 'xlsx'
        ? await workbook.xlsx.writeBuffer()
        : await workbook.csv.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'TipoCampoValor.' + format;
    a.click();
    a.remove();
  };
  return (
    <>
      <Tooltip title={props.tooltipTitle}>
        <RoundButton
          type="primary"
          style={props.style}
          key="3"
          onClick={(e) => handlerClickDownloadButton(e, 'xlsx')}
        >
          <FileExcelOutlined />
        </RoundButton>
      </Tooltip>
    </>
  );
};

export default ExcelExport;
