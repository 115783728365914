const measurements = {
  leftPanel: {
    width: '260px',
    collapsible: {
      width: 80,
    },
  },
  rightPanel: {
    width: '60px',
    collapsible: {
      collapsedWidth: 0,
      width: 300,
      trackingWidth: 400,
    },
  },
};

export default measurements;
