import './style.css';
import {
  Avatar,
  Col,
  Empty,
  List,
  Row,
  Segmented,
  Skeleton,
  Tag,
  Space,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Card from '../../../Card';
import { FC, useEffect, useState, useRef } from 'react';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { WidgetTop10Visitor } from '../../../../../domain/models/widgets/Top10Visitor';
import { SegmentedValue } from 'antd/lib/segmented';
import { http } from '../../../../http/axios';
import moment from 'moment';
import Progress from './Progress';
import { ClockIcon } from '../../../icons';
import useLanguage from '../../../../hooks/useLanguage';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { Themes } from '../../../../store/ui';
import FormItemInput from 'antd/lib/form/FormItemInput';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingBar from './LoadingBar';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

type Props = {
  clientsId: number[];
};

const Top10Widget: FC<Props> = ({ clientsId }) => {
  const [data, setData] = useState<WidgetTop10Visitor[]>([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingTopData, setLoadingTopData] = useState(true);
  const [moreData, setMoreData] = useState(true);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({
    startAt: moment().format('YYYY-MM-DD'),
    endAt: moment().format('YYYY-MM-DD'),
  });
  const [filterType, setFilterType] = useState('%');
  const [isDesc, setIsDesc] = useState(true);
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  const handleChangeDesc = (isDesc: boolean) => {
    setIsDesc(isDesc);
  };

  const handleChangeSegmentedType = (value: SegmentedValue) => {
    setFilterType(value.toString());
  };

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === t('today', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('week_short', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('week').format('YYYY-MM-DD'),
        endAt: moment().endOf('week').format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('month', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('month').format('YYYY-MM-DD'),
        endAt: moment().endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  const queryData = async (controller?: AbortController, isNew = false) => {
    setLoadingTopData(true);

    const clientsQuery = clientsId
      .map((client) => {
        return `client[]=${client}`;
      })
      .join('&');

    http
      .get<WidgetTop10Visitor[]>(
        `/widgets/visitors-top-10/${filterDate.startAt}/${
          filterDate.endAt
        }/query?${clientsQuery}&filterType=${
          filterType === '%' ? 'PERCENT' : filterType
        }&order=${isDesc ? 'DESC' : 'ASC'}&offset=${isNew ? 0 : offset}`,
        controller && controller.signal
      )
      .then((res) => {
        if (res.length < 10) {
          setMoreData(false);
        }
        if (isNew) {
          setOffset([...res].length);
          setData([...res]);
        } else {
          setOffset([...data, ...res].length);
          setData([...data, ...res]);
        }
        setLoadingTopData(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    setOffset(0);
    setData([]);
    setMoreData(true);
    setLoading(true);
    queryData(controller, true);

    return () => {
      controller.abort();
    };
  }, [filterDate, filterType, isDesc, clientsId]);

  return (
    <Card>
      <div style={{ padding: '.5rem 1rem', width: '100%', height: '110%' }}>
        <Row>
          <Col
            xs={12}
            sm={8}
            md={8}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            Top{' '}
            {loadingTopData && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />}
              />
            )}
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              size="small"
              options={['%', 'C.T', 'C.P']}
              defaultValue={filterType}
              onChange={handleChangeSegmentedType}
            />
          </Col>
          <Col
            xs={12}
            sm={2}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tag.CheckableTag
              checked={isDesc}
              onChange={(checked) => handleChangeDesc(checked)}
              style={{
                backgroundColor:
                  currentTheme !== Themes.DARK_THEME
                    ? '#fff'
                    : 'rgba(0,0,0,0.5)',
                borderColor:
                  currentTheme !== Themes.DARK_THEME
                    ? '#fff'
                    : 'rgba(0,0,0,0.5)',
                color:
                  currentTheme === Themes.DARK_THEME
                    ? '#fff'
                    : 'rgba(0,0,0,0.5)',
              }}
            >
              {isDesc ? <CaretDownOutlined /> : <CaretUpOutlined />}{' '}
              {isDesc ? 'Desc' : 'Asc'}
            </Tag.CheckableTag>
          </Col>
          <Col
            xs={12}
            sm={8}
            md={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              size="small"
              options={[
                t('today', { ns: 'common' }),
                t('week_short', { ns: 'common' }),
                t('month', { ns: 'common' }),
              ]}
              defaultValue={t('today', { ns: 'common' })}
              onChange={handleChangeSegmented}
            />
          </Col>
        </Row>
        <div style={{ padding: '.5rem', overflow: 'auto' }} />
        <Skeleton avatar paragraph={{ rows: 2 }} active loading={loading}>
          {data.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
              description={'Sin datos'}
            />
          ) : (
            <div
              style={{ height: '340px', overflow: 'auto' }}
              id="topList1-container"
            >
              <InfiniteScroll
                dataLength={data.length}
                loader={loadingTopData && <LoadingBar />}
                next={queryData}
                hasMore={moreData}
                scrollableTarget="topList1-container"
              >
                <List
                  id="topList-container"
                  itemLayout="horizontal"
                  dataSource={data}
                  style={{
                    height: '80%',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  renderItem={(item, index) => (
                    <List.Item
                      id={`${item.user_id}`}
                      style={{ padding: '.3rem', width: '-8%' }}
                      actions={[
                        <>
                          {filterType === '%' ? (
                            <Space>
                              <Progress
                                value={item.total}
                                lastValue={item.lastTotal || 0}
                                type="success"
                                showIcon={true}
                                info={`${item.totalCompleted}/${
                                  item.totalCompleted + item.totalPending
                                }`}
                              />
                            </Space>
                          ) : (
                            <>
                              {filterType === 'C.T' ? (
                                <Progress
                                  value={item.total}
                                  label={`${item.totalCompleted}`}
                                  lastValue={item.lastTotal || 0}
                                  type="success"
                                  showIcon={true}
                                />
                              ) : (
                                <Progress
                                  value={item.total}
                                  label={`${item.totalPending}`}
                                  lastValue={item.lastTotal || 0}
                                  icon={<ClockIcon />}
                                  type="error"
                                  showIcon={true}
                                />
                              )}
                            </>
                          )}
                        </>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                width: '25px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'center',
                                justifyContent: 'center',
                              }}
                              className="text"
                            >
                              {index + 1}
                            </span>{' '}
                            {item.picture ? (
                              <>
                                <Avatar
                                  src={item.picture}
                                  size={40}
                                  style={{ flex: 1 }}
                                />
                              </>
                            ) : (
                              <>
                                <Avatar
                                  icon={<UserOutlined />}
                                  size={40}
                                  style={{ flex: 1 }}
                                />
                              </>
                            )}
                          </div>
                        }
                        title={<span className="text">{item.name}</span>}
                        style={{ alignItems: 'center' }}
                      />
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
              {/*loadingTopData && <LoadingBar/>*/}
            </div>
          )}
          <div
            style={{
              display: 'none',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '2rem',
              marginTop: '.2rem',
              gap: '.2rem',
            }}
          >
            <div
              style={{ borderRight: '2px dashed #949494', height: '100%' }}
            ></div>
            <span className="text">{t('previous_period')}</span>
          </div>
        </Skeleton>
      </div>
    </Card>
  );
};

export default Top10Widget;
