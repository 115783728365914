export enum Sizes {
  SMALL = 's',
  MEDIUM = 'm',
  LARGE = 'l',
}

export interface DashboardSizes {
  sizes: Sizes[];
}

export type DashboardWidget = {
  dash_panel_id: number;
  default_size: string;
  description: string;
  has_dynamic_mode: boolean;
  has_fullscreen_mode: boolean;
  is_dockable: boolean;
  name: string;
  position: number;
  sizes: DashboardSizes;
  component: string;
};
