import { FC } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Themes } from "../../../../store/ui";
import { selectUiTheme } from "../../../../store/ui/ui.selectors";
import { OkIcon } from "../../../icons";

type Props = {
  value: number;
  lastValue: number;
  style?: React.CSSProperties;
  type: "success" | "error" | "warning" | "info";
  showIcon?: boolean;
  label?: string;
  icon?: React.ReactNode;
  info?: string;
};

const Progress: FC<Props> = ({
  value,
  lastValue,
  style,
  type,
  showIcon,
  label,
  icon,
  info,
}) => {
  const currentTheme = useSelector(selectUiTheme);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  if (lastValue < 0 || lastValue > 100) {
    lastValue = 0;
  }

  const borderColor = () => {
    if (!type || type === "success")
      return currentTheme === Themes.LIGH_THEME
        ? "rgb(95, 178, 104)"
        : "rgba(95, 178, 104, 0.8)";
    if (type === "info") return "#2980b9";
    if (type === "warning") return "#e67e22";
    if (type === "error") return "#c0392b";
  };

  const backgroundColor = () => {
    if (!type || type === "success")
      return currentTheme === Themes.LIGH_THEME
        ? "rgba(211, 235, 219, 1)"
        : "rgba(95, 178, 104, 0.5)";
    if (type === "info") return "rgba(41, 128, 185,0.2)";
    if (type === "warning") return "rgba(230, 126, 34,0.2)";
    if (type === "error") return "rgba(192, 57, 43,0.1)";
  };

  if (!icon) {
    icon = <OkIcon />;
  }

  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "8px",
    }}
    >
      <div
        style={{
          ...style,
          width: isTabletOrMobile ? "6rem" : "10rem",
          height: "2rem",
          border: "1px solid " + borderColor(),
          borderRadius: ".3rem",
          position: "relative",
          backgroundColor:
            currentTheme === Themes.LIGH_THEME ? "white" : "rgba(0,0,0,0.2)",
        }}
      >
        <div
          style={{
            width: value + "%",
            backgroundColor: backgroundColor(),
            height: "100%",
            display: "block",
            float: "right",
            borderLeft: value < 100 ? "1px solid " + borderColor() : "none",
          }}
        ></div>
        <span
          style={{
            position: "absolute",
            right: 5,
            top: 3,
            color:
              currentTheme === Themes.DARK_THEME &&
              (type === "error" || type === "success")
                ? "#ffffff"
                : "#352443",
          }}
        >
          {label ? label : value.toFixed(0) + "%"} {showIcon && icon}
        </span>
        <div
          style={{
            width: "90%",
            height: "130%",
            top: -4,
            position: "absolute",
            display: "block",
          }}
        >
          <div
            style={{
              width: lastValue + "%",
              height: "100%",
              display: "none", //block
              float: "right",
              borderLeft: "2px dashed #949494",
            }}
          ></div>
        </div>
      </div>
      <span
        style={{
          fontSize: "10px",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "20px",
          marginRight: "1rem",
        }}
        className="text"
      >
        {info}
      </span>
    </div>
  );
};

export default Progress;
