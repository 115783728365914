import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, notification, Row, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import 'moment/locale/es';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { http } from '../../http/axios';
import { AxiosError } from 'axios';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { RoutePlan as ModelRoutePlan } from '../../../domain/models/RoutePlan';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import { Themes } from '../../store/ui';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faFilterCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { selectCurrentUser } from '../../store/auth/auth.selectors';

moment.locale('es');

const DivTable = styled.div`
  background-color: ${(props) =>
    props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  .ant-table-thead > tr > th {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#333333' : '#f0f2f5'};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    border-bottom: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '1px solid #2a2d2f'
        : '1px solid #f0f0f0'};
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#333333' : '#fff'};
    border: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '1px solid #2a2d2f'
        : '1px solid #ddd'};
  }

  .ant-pagination-item-link {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-pagination-item {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#333333' : '#fff'};
  }

  .ant-table-placeholder {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }
`;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: 'ascend' | 'descend';
  filters?: Record<string, FilterValue>;
}

const RoutePlan: FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [error, setError] = useState<any>(null);
  const [customerName, setCustomerName] = useState<string>('Cliente');
  const [loading, setLoading] = useState<boolean>(true);
  const currentTheme = useSelector(selectUiTheme);
  const [filterParams, setFilterParams] = useSearchParams();
  const [datePicker, setDatePicker] = useState<any>(null);
  const [filterName, setFilterName] = useState<any>(null);
  const currentUser = useSelector(selectCurrentUser);
  const [isOnlyRead, setIsOnlyRead] = useState<boolean>(true);
  const [enableCustomer, setEnableCustomer] = useState<boolean>(false);

  const columns: ColumnsType<ModelRoutePlan> = [
    {
      title: 'ID',
      dataIndex: 'id_plan_ruta',
      key: 'id_plan_ruta',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_plan',
      key: 'fecha_plan',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: customerName,
      dataIndex: 'customer',
      key: 'customer',
      render: (value, record) => {
        let customers = '';
        customers = record?.routeCampaigns
          ?.map((rc: any) => rc?.client?.nombre || '')
          .join(', ');

        return customers;
      },
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Puntos',
      dataIndex: 'nro_puntos',
      key: 'nro_puntos',
    },
    {
      title: 'Personas',
      dataIndex: 'nro_personas',
      key: 'nro_personas',
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: (value, record) => {
        let estado = 'Activo';
        if (record.routeCampaigns?.length > 0) {
          if (record.routeCampaigns[0].estado === 2) {
            estado = 'Calculado';
          }
          if (record.routeCampaigns[0].estado === 3) {
            estado = 'Confirmado';
          }
        }
        return estado;
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (value, record) => {
        console.log(isOnlyRead);
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {record.estado && (
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/route-plans/${record.id_plan_ruta}`)
                  }
                  className="onfield-button"
                  icon={<EditOutlined />}
                />
                {!isOnlyRead && (
                  <>
                    {(record.routeCampaigns.length === 0 ||
                      record.routeCampaigns[0].estado < 3) && (
                      <Button
                        type="primary"
                        danger
                        onClick={async () => {
                          // eslint-disable-next-line no-restricted-globals
                          if (confirm('¿Deseas eliminar este plan de rutas?')) {
                            setLoading(true);
                            try {
                              await http.delete(
                                `/route-plans/${record.id_plan_ruta}`
                              );
                              fetchData(
                                tableParams.pagination?.current || 1,
                                tableParams.pagination?.pageSize || 10
                              );
                            } catch (error) {
                              console.error(error);
                              notification['error']({
                                message: 'Error al inactivar el plan de rutas',
                                description: 'Por favor intentelo más tarde',
                              });
                            } finally {
                              setLoading(false);
                            }
                          }
                        }}
                        className="onfield-button"
                        icon={<DeleteOutlined />}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];
  const columnsNoCustomer: ColumnsType<ModelRoutePlan> = [
    {
      title: 'ID',
      dataIndex: 'id_plan_ruta',
      key: 'id_plan_ruta',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_plan',
      key: 'fecha_plan',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Puntos',
      dataIndex: 'nro_puntos',
      key: 'nro_puntos',
    },
    {
      title: 'Personas',
      dataIndex: 'nro_personas',
      key: 'nro_personas',
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      render: (value, record) => {
        let estado = 'Activo';
        if (record.routeCampaigns?.length > 0) {
          if (record.routeCampaigns[0].estado === 2) {
            estado = 'Calculado';
          }
          if (record.routeCampaigns[0].estado === 3) {
            estado = 'Confirmado';
          }
        }
        return estado;
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (value, record) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {record.estado && (
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/route-plans/${record.id_plan_ruta}`)
                  }
                  className="onfield-button"
                  icon={<EditOutlined />}
                />
                {!isOnlyRead && (
                  <>
                    {(record.routeCampaigns.length === 0 ||
                      record.routeCampaigns[0].estado < 3) && (
                      <Button
                        type="primary"
                        danger
                        onClick={async () => {
                          // eslint-disable-next-line no-restricted-globals
                          if (confirm('¿Deseas eliminar este plan de rutas?')) {
                            setLoading(true);
                            try {
                              await http.delete(
                                `/route-plans/${record.id_plan_ruta}`
                              );
                              fetchData(
                                tableParams.pagination?.current || 1,
                                tableParams.pagination?.pageSize || 10
                              );
                            } catch (error) {
                              console.error(error);
                              notification['error']({
                                message: 'Error al inactivar el plan de rutas',
                                description: 'Por favor intentelo más tarde',
                              });
                            } finally {
                              setLoading(false);
                            }
                          }
                        }}
                        className="onfield-button"
                        icon={<DeleteOutlined />}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const fetchData = async (page: number, limit: number) => {
    setLoading(true);
    let params = `/route-plans?page=${page}&limit=${limit}`;

    if (filterParams.get('startAt') && filterParams.get('endAt')) {
      params += `&startAt=${filterParams.get(
        'startAt'
      )}&endAt=${filterParams.get('endAt')}&test=1`;
    }

    if (filterName) {
      params += `&name=${filterName}`;
    }

    try {
      const { items, meta }: any = await http.get(params);
      setData(items);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: meta.currentPage,
          pageSize: meta.itemsPerPage,
          total: meta.totalItems,
        },
      });
    } catch (error: any | AxiosError) {
      if (error instanceof AxiosError) {
        setError(error.response?.data);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
    fetchData(pagination.current || 1, pagination.pageSize || 10);
  };

  const loadData = async () => {
    try {
      fetchData(
        tableParams.pagination?.current || 1,
        tableParams.pagination?.pageSize || 10
      );
    } catch (error: any) {
      if (error instanceof AxiosError) {
        setError(error.response?.data);
      } else {
        setError(error.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const menu = currentUser.role.menus.find(
        (m: any) => m.menu.key === 'route-plans'
      );
      if (menu && menu.permission >= 3) {
        setIsOnlyRead(false);
      }

      const paramRoutePlan = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (paramRoutePlan) {
        let filtro_cuenta = false;
        if (paramRoutePlan.value6) {
          try {
            const paramData = JSON.parse(paramRoutePlan.value6);
            filtro_cuenta = paramData?.pedidos_filtro_cuenta || false;
          } catch (error) {
            console.error(error);
          }
        }
        setEnableCustomer(filtro_cuenta);
      }
      const glosaCliente = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'glosa_cuenta'
      );
      if (glosaCliente) {
        setCustomerName(glosaCliente?.value1);
      }
    }
  }, [currentUser]);

  return (
    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <Row gutter={[20, 15]}>
        <Col xs={18} md={{ span: 18 }}>
          <h1
            style={{
              fontSize: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              height: '100%',
              gap: '2rem',
            }}
          >
            Planes de ruta
            <small>
              {moment().format('dddd')} de {moment().format('DD MMMM')} de{' '}
              {moment().format('YYYY')}
            </small>
          </h1>
        </Col>
        <Col
          xs={6}
          md={{ span: 6 }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={() => navigate('create')}
            type="primary"
            icon={<PlusOutlined />}
            className="onfield-button"
          >
            Nuevo plan
          </Button>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            justifyContent: 'end',
          }}
        >
          <Input
            value={filterName}
            onChange={(val) => setFilterName(val.target.value)}
            placeholder="Buscar nombre"
            style={{
              maxWidth: '50%',
            }}
          />
          <DatePicker.RangePicker
            value={datePicker}
            onChange={(dates) => {
              setDatePicker(dates);
              if (dates === null) {
                setFilterParams({});
                return;
              }

              setFilterParams({
                startAt: dates[0]!.format('YYYY-MM-DD'),
                endAt: dates[1]!.format('YYYY-MM-DD'),
              });
            }}
          />
          <Button
            type="primary"
            className="onfield-button"
            icon={<FontAwesomeIcon icon={faFilter} />}
            onClick={() => {
              loadData();
            }}
          />
          <Button
            type="default"
            icon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
            onClick={() => {
              setDatePicker(null);
              setFilterName(null);
              setFilterParams({});
            }}
          />
        </Col>
        <Col span={24}>
          <DivTable theme={currentTheme}>
            <Table
              columns={enableCustomer ? columns : columnsNoCustomer}
              dataSource={data}
              loading={loading}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
              rowKey={(item) => item.id_plan_ruta}
              rowClassName={(record, index) => {
                return currentTheme === Themes.DARK_THEME
                  ? 'table-row-dark'
                  : 'table-row-light';
              }}
            />
          </DivTable>
        </Col>
      </Row>
    </div>
  );
};

export default RoutePlan;
