import { useEffect, useState } from 'react';
import { database } from '../firebase/firebase';
import { Message } from '../../domain/models/Message';
import {
  equalTo,
  onChildAdded,
  onValue,
  orderByChild,
  query,
  ref,
} from 'firebase/database';
import { instance } from '../http/axios';
import { Timestamp } from 'firebase/firestore';

const useMessage = () => {
  const [chatId, setChatId] = useState('');
  const [message, setMessage] = useState({
    event: '',
    message: {} as Message,
  });

  useEffect(() => {
    if (chatId !== '') {
      const unsubAdded = onChildAdded(
        ref(database, `Chat/${instance}/${chatId}/Message`),
        (snapshot) => {
          if (snapshot.val()) {
            setMessage({
              event: 'added',
              message: {
                ...snapshot.val(),
                id: snapshot.key,
                created_at: Timestamp.fromMillis(
                  snapshot.val().created_at.seconds * 1000
                ),
              } as Message,
            });
          }
        }
      );

      return () => {
        setChatId('');
        unsubAdded();
      };
    }
  }, [chatId, setChatId]);

  return [message, setChatId, chatId] as const;
};

export default useMessage;
