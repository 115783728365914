import React from 'react';
import logo_onfield_negro from '../images/logo_onfield_negro.png';
import { instance, S3_BUCKET } from '../../../http/axios';
import { useState, useEffect } from 'react';
import icon_file_name from '../ios/images/image.512x512.png';

const dataApkFile = S3_BUCKET + instance + '/app/ios/output.json';
//const dataApkFile = `https://onfield-v3-storage.s3.amazonaws.com/beta/app/output.json`;

// Titulo de la Web
const APP_TITLE = 'Onfield';

function Ios() {
  const [DataVersion, getDataVersion] = useState([]);
  //const [DataBuild, getDataBuild] = useState([])
  //const [DataFileName, getFileName] = useState([])
  const URL = dataApkFile;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(URL)
      .then((res) => res.json())

      .then((response) => {
        getDataVersion(response.apkData.versionName);
        //getDataBuild(response.apkData.versionCode);
        //getFileName(response.apkData.outputFile);
      });
  };
  return (
    <body
      className="backgroundPage"
      style={{
        backgroundImage: `url(https://source.unsplash.com/collection/145103/computers-phones-and-tech)`,
      }}
    >
      <div className="sp"></div>
      <div className="box-page login-box-page ">
        <div className="box">
          <div style={{ textAlign: 'center' }}>
            <img
              style={{
                position: `relative`,
                top: `-Xpx`,
                width: `200px`,
              }}
              className="nav_logo"
              src={logo_onfield_negro}
              alt=""
            />
          </div>
          <div className="form-container" style={{ textAlign: 'center' }}>
            <h2>App {APP_TITLE}</h2>
            <h1>Versión iOS {DataVersion}</h1>
            <a href="#">
              <img
                className="appicon"
                src={icon_file_name}
                width="256"
                height="256"
                alt="Download App"
              />
            </a>
            <form id="loginForm" method="post" action="">
              <fieldset>
                <div className="form-group loginfield">
                  <div style={{ width: '100%' }}>
                    {/*<a className="btn btn-sm blue-btn" href='itms-services://?action=download-manifest&url=<?php echo $MANIFEST_FILE_PATH; ?>'>Descargar</a>*/}
                    <a
                      className="btn btn-sm blue-btn"
                      href='https://apps.apple.com/cl/app/onfield/id1644691316'
                      style={{ textDecoration: 'none' }}
                    >
                      Descargar
                    </a>
                  </div>
                </div>
              </fieldset>
              <div>
                <p>
                  <a
                    href="ios/instalar_certificado"
                    style={{ color: '#000000' }}
                  >
                    INSTRUCCIONES Y PASOS PARA HABILITAR LA APP
                  </a>
                </p>
              </div>
              {/*<!--a href="itms-services://?action=download-manifest&url=https://sietecom.assertsoft.cl/app/manifest.plist">Download 2</a-->*/}
              <div>
                <p>
                  Powered by{' '}
                  <a
                    href="https://assertsoft.com"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                  >
                    Assertsoft
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sp"></div>
    </body>
  );
}

export default Ios;
