import React, { useState, useEffect, useRef } from 'react'
import { Form, Switch, Input, InputNumber, Card, message } from 'antd';
import { http } from '../../../http/axios';

function Generals({ setUnsavedChanges, loadingSave, setLoadingSave, unsavedChanges }) {

    const [parameters, setParameters] = useState({});
    const [changes, setChanges] = useState([]);
    const [load, setLoad] = useState(true);

    const loadQuery = async () => {
        try {
            const data = await http.get('/parameter/sw_modulo_autoagendamiento');
            setParameters(data);
            setLoad(false);
        } catch (error) {
            console.error(error);
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
        }
    };

    useEffect(() => {
        loadQuery();
    }, [])

    useEffect(() => {
        if (!unsavedChanges) {
            setChanges([]);            
        }
    }, [unsavedChanges])

    let feedback = () => { };

    const query = async (query) => {

        try {
            const response = await http.put('/parameter/sw_modulo_autoagendamiento', { query });
            setParameters(response);
            setUnsavedChanges(false);
        } catch (error) {
            console.error(error);
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
        } finally {
            // feedback()
            setLoadingSave(false);
        }
    };

    useEffect(() => {
        if (loadingSave) {
            changes.forEach(change => {
                query(change);
            });

            setChanges([]);
        }
    }, [loadingSave])

    const editParameters = (value, name) => {

        let valor
        //const newChanges = [...changes];
        const newChanges = changes.filter(obj => !obj.hasOwnProperty(name)); // filtrar primeros cambios

        if (typeof value === 'boolean') {
            valor = value ? 1 : 0;
        } else {
            valor = value;
        }

        newChanges.push({ [name]: valor });
        //query({ [name]: valor });
        setChanges(newChanges);
        setUnsavedChanges(true);
    };

    return (
        <Card size="small" loading={load}>

            {/*<h2>Generales</h2>*/}

            <Form
                name="generals"
                autoComplete="off"
            >
                <Form.Item className='itemForm'
                    label="Activar módulo autoagendamiento"
                    valuePropName="checked"
                >
                    <Switch
                        defaultChecked={parameters.valor1 === '1' ? true : false}
                        onChange={(e) => editParameters(e, 'valor1')}
                    />
                </Form.Item>

                <Form.Item
                    label="Número de días que se muestran para agendar"
                >
                    <InputNumber
                        min={0}
                        defaultValue={parameters.valor2}
                        onChange={(value) => editParameters(value, 'valor2')}
                    />
                </Form.Item>

                <Form.Item
                    label="Días desde que se puede agendar"
                >
                    <InputNumber
                        min={0}
                        defaultValue={parameters.valor3}
                        onChange={(value) => editParameters(value, 'valor3')}
                    />
                </Form.Item>

                <Form.Item
                    label="Sólo días habiles para agendar"
                    valuePropName="checked"
                >
                    <Switch
                        defaultChecked={parameters.valor4 === '1' ? true : false}
                        onChange={(e) => editParameters(e, 'valor4')}
                    />
                </Form.Item>

                <Form.Item
                    name="url"
                    label="URL PDF productos MKP"
                    rules={[
                        {
                            type: 'url',
                            warningOnly: true,
                        }
                    ]}
                >
                    <Input
                        defaultValue={parameters.valor5}
                        onChange={(e) => editParameters(e.target.value, 'valor5')}
                    />
                </Form.Item>

            </Form>
        </Card>
    )
}

export default Generals

/*
<Space className="selfScheduling-settings otherParameters">
                <OtherParameters value={parameters.value6} />
            </Space>
*/