import React from 'react';
import logo_onfield_negro from '../images/logo_onfield_negro.png';
import { instance, S3_BUCKET } from '../../../http/axios';
import { useState, useEffect } from 'react';
import icon_file_name from '../android/images/image.512x512.png';
import './index.css';
const dataApkFile = S3_BUCKET + instance + '/app/android/output.json';
//const dataApkFile = `https://onfield-v3-storage.s3.amazonaws.com/beta/app/output.json`;

const axios = require('axios');

// Titulo de la Web
const APP_TITLE = 'Onfield';

function Android(): JSX.Element {
  const [DataVersion, getDataVersion] = useState([]);
  const [DataBuild, getDataBuild] = useState([]);
  const [DataFileName, getFileName] = useState([]);

  const [errorData, setErrorData] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(dataApkFile)
      .then((response: { data: any }) => {
        const jsonData = response.data;
        console.log(jsonData.apkData.versionName);

        getDataVersion(jsonData.apkData.versionName);
        getDataBuild(jsonData.apkData.versionCode);
        getFileName(jsonData.apkData.outputFile);;
      })
      .catch((error: any) => {
        console.error('Error al hacer la solicitud:', error);
        setErrorData(true);
      });
  };

  return (
    <body
      className="backgroundPage"
      style={{
        backgroundImage: `url(https://source.unsplash.com/collection/145103/computers-phones-and-tech)`,
      }}
    >
      <div className="sp"></div>
      <div className="box-page login-box-page ">
        <div className="box">
          <div style={{ textAlign: 'center' }}>
            <img
              style={{
                position: `relative`,
                top: `-Xpx`,
                width: `200px`,
              }}
              className="nav_logo"
              src={logo_onfield_negro}
              alt=""
            />
          </div>
          <div className="form-container" style={{ textAlign: 'center' }}>
            <h2>App {APP_TITLE} </h2>
            {!errorData && (
              <>
                <h1>
                  Versión Android {DataVersion} ({DataBuild})
                </h1>
                <a href="#">
                  <img
                    className="appicon"
                    src={icon_file_name}
                    width="256"
                    height="256"
                    alt="Download App"
                  />
                </a>
              </>
            )}
            <form id="loginForm" method="post" action="">
              {!errorData && (
                <fieldset>
                  <div className="form-group loginfield">
                    <div style={{ width: `100%` }}>
                      <a
                        className="btn btn-sm blue-btn"
                        href={
                          S3_BUCKET + instance + '/app/android/' + DataFileName
                        }
                        style={{ textDecoration: 'none' }}
                        download={DataFileName}
                      >
                        Descargar
                      </a>
                    </div>
                  </div>
                </fieldset>
              )}
              <div>
                <p>
                  <a
                    href="android/instalar_certificado"
                    style={{ color: `#000000` }}
                  >
                    INSTRUCCIONES Y PASOS PARA HABILITAR LA APP
                  </a>
                </p>
                <p>
                  <a href="android/codigodebarras" style={{ color: `#000000` }}>
                    En caso de problema con códigos de barra
                  </a>
                </p>
              </div>
              <div>
                <p>
                  Powered by{' '}
                  <a
                    href="https://assertsoft.com"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Assertsoft
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sp"></div>
    </body>
  );
}

export default Android;
