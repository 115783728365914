import { FC } from 'react';
import { useSelector } from 'react-redux';
import useLanguage from '../../../../hooks/useLanguage';
import { Themes } from '../../../../store/ui';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { OkIcon } from '../../../icons';

type Props = {
  style?: React.CSSProperties;
  startAt: string;
  endAt: string;
  averageTime: FC;
};

const AverageTime: FC<Props> = ({ style, startAt, endAt, averageTime }) => {
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  return (
    <div
      style={{
        ...style,
        borderTop: '2px solid #949494',
        borderBottom: '2px solid #949494',
        borderRight: '2px solid #949494',
        borderRadius: '.3rem',
        height: '2rem',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '30%',
          backgroundColor:
            currentTheme === Themes.LIGH_THEME ? '#f2d8da' : '#4e3130',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: currentTheme === Themes.LIGH_THEME ? '#000' : '#fdfdfd',
        }}
      >
        {t('average_time.pending', { ns: 'widgets' })}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '60%',
          backgroundColor:
            currentTheme === Themes.LIGH_THEME ? '#fcece1' : '#5a4d3d',
          height: '100%',
          color: currentTheme === Themes.LIGH_THEME ? '#000' : '#fdfdfd',
        }}
      >
        {t('average_time.in_progress', { ns: 'widgets' })}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '10%',
          backgroundColor:
            currentTheme === Themes.LIGH_THEME
              ? 'rgba(226,240,228,0.5)'
              : '#3b5241',
          height: '100%',
        }}
      >
        <OkIcon />
      </div>
      <div
        style={{
          position: 'absolute',
          top: -7,
          height: '150%',
          width: '100%',
          display: 'flex',
        }}
      >
        <div
          style={{
            borderRight: '2px solid #949494',
            borderLeft: '2px solid #949494',
            height: '100%',
            width: '30%',
            position: 'relative',
          }}
        >
          <span
            style={{ left: -17, top: -17, position: 'absolute' }}
            className="text"
          >
            {t('average_time.assign_short', { ns: 'widgets' })}
          </span>
          <span
            style={{ left: -5, bottom: -17, position: 'absolute' }}
            className="text"
          >
            0
          </span>
        </div>
        <div
          style={{
            borderRight: '2px solid #949494',
            height: '100%',
            width: '60%',
            position: 'relative',
          }}
        >
          <span
            style={{ left: -17, top: -17, position: 'absolute' }}
            className="text"
          >
            {t('average_time.start_short', { ns: 'widgets' })}
          </span>
          <span
            style={{ left: -25, bottom: -17, position: 'absolute' }}
            className="text"
          >
            {startAt}
          </span>

          <span
            style={{
              top: -17,
              left: 0,
              right: 0,
              textAlign: 'center',
              position: 'absolute',
            }}
            className="text"
          >
            {averageTime({})}
          </span>

          <span
            style={{ right: -27, top: -17, position: 'absolute' }}
            className="text"
          >
            {t('average_time.finished_short', { ns: 'widgets' })}
          </span>
          <span
            style={{ right: -25, bottom: -17, position: 'absolute' }}
            className="text"
          >
            {endAt}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AverageTime;
