import { http } from '../http/axios';
import { SignInDTO } from '../http/dto/SignInDTO';
import jwt_decode from 'jwt-decode';
import { User } from '../../domain/models/User';
import moment from 'moment';
import { Menu } from '../../domain/models/Menu';
import { DashboardWidget } from '../../domain/models/DashboardWidget';
import { Client } from '../../domain/models/Client';
import { Instance } from '../../domain/models/Instance';
import { INFO_APP } from '../const/info';

type JwtDecoded = {
  user: User;
  iat: number;
  exp: number;
};

export const userRepository = {
  signIn: async (username: string, password: string, token: string) => {
    const signin = await http.post<SignInDTO>('/signin', {
      username,
      password,
      token,
      version: INFO_APP.VERSION,
    });
    const { user }: JwtDecoded = jwt_decode(signin.token);
    localStorage.setItem('onfieldToken', signin.token);
    localStorage.setItem('onfieldRefreshToken', signin.refreshToken);
    localStorage.setItem('onfieldLegacyToken', signin.cookie);

    console.log(signin);

    return {
      user: {
        ...user,
        role_id: signin.role_id,
        user_group_id: signin.user_group_id,
      },
      clients: signin.clients,
      menus: signin.menus,
      dashboards: signin.dashboards,
      instance: signin.instance,
      homeScreen: signin.homeScreen,
      role: signin.role,
    };
  },
  getCurrentUser: async (): Promise<{
    user: User;
    menus: Menu[];
    dashboards: DashboardWidget[];
    clients: Client[];
    instance: Instance;
    homeScreen: Menu;
    role: any;
  } | null> => {
    const token = localStorage.getItem('onfieldToken');
    if (!token) return null;
    const { user, exp }: JwtDecoded = jwt_decode(token);
    const expDate = new Date(0);
    expDate.setUTCSeconds(exp);
    const currentDate = moment();
    const expirationDate = moment(expDate);

    if (currentDate.isAfter(expirationDate)) {
      userRepository.removeCurrentUser();
      return null;
    }

    const currentUser = await http.get<SignInDTO>('/users/current');

    return {
      user: {
        ...user,
        role_id: currentUser.role_id,
        user_group_id: currentUser.user_group_id,
      },
      menus: currentUser.menus,
      dashboards: currentUser.dashboards,
      clients: currentUser.clients,
      instance: currentUser.instance,
      homeScreen: currentUser.homeScreen,
      role: currentUser.role,
    };
  },
  removeCurrentUser: () => {
    localStorage.removeItem('onfieldToken');
    localStorage.removeItem('onfieldLegacyToken');
    localStorage.removeItem('dashboardWidgets');
    localStorage.removeItem('lastStaySession');
    localStorage.removeItem('onfieldRefreshToken');
  },
  findAllUsers: async (): Promise<User[]> => {
    return await http.get<User[]>('/users');
  },
  findConfiguration: async (): Promise<any[]> => {
    return await http.get<any>('/configurations');
  },
};
