import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import thunk from 'redux-thunk';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const configStoreSync = {
  blacklist: [],
};
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
  thunk,
  createStateSyncMiddleware(configStoreSync),
];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
initMessageListener(store);

export default store;
