import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { http } from '../../http/axios';
import { selectCurrentUser } from '../../store/auth/auth.selectors';
import RoutePlanMapCalendar from './components/RoutePlanMapCalendar';
import RoutePlanMapZones from './components/RoutePlanMapZones';

const IndexMap: FC = () => {
  const [campaign, setCampaign] = useState<any>(null);
  const [defaultLatLng, setDefaultLatLng] = useState<google.maps.LatLng>(
    new google.maps.LatLng(0, 0)
  );
  const [startDate, setStartDate] = useState<string>('01:00:00');
  const [endDate, setEndDate] = useState<string>('24:00:00');
  const [skillsNotIncluded, setSkillsNotIncluded] = useState<number[]>([]);
  const [moduloPlanRutas, setModuloPlanRutas] = useState<any>(null);
  const currentUser = useSelector(selectCurrentUser);
  const { id } = useParams();

  const loadData = async () => {
    const response: any = await http.get(`/route-plans/${id}`);
    setCampaign(response.routeCampaigns[0]);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const modulo_plan_de_ruta = currentUser?.instance?.parameters.find(
        (param) => param.parameter_id === 'modulo_plan_de_ruta'
      );
      setModuloPlanRutas(modulo_plan_de_ruta);
      const hours = modulo_plan_de_ruta?.value3.split('-');
      if (hours) {
        setStartDate(`${hours[0]}:00:00`);
        setEndDate(`${hours[hours.length - 1]}:00:00`);
      }

      if (modulo_plan_de_ruta?.value6) {
        const jsonParam = JSON.parse(modulo_plan_de_ruta?.value6);
        setSkillsNotIncluded(jsonParam.habilidades_no_incluidas);
      }

      const sw_ubicacion = currentUser?.instance?.parameters.find(
        (param) => param.parameter_id === 'ubicacion'
      );

      if (sw_ubicacion) {
        setDefaultLatLng(
          new google.maps.LatLng(
            parseFloat(sw_ubicacion.value1),
            parseFloat(sw_ubicacion.value2)
          )
        );
      }
    }
  }, [currentUser]);

  if (campaign == null) return <></>;

  return (
    <>
      {/* Campaña de tipo agenda y pedido */}
      {[1, 3].includes(campaign.id_plan_tipo_campana) && (
        <>
          <RoutePlanMapCalendar
            defaultCenter={defaultLatLng}
            startCalendarDate={startDate}
            endCalendarDate={endDate}
            idRoutePlan={parseInt(`${id}`)}
            idRoutePlanCampaign={campaign.id_plan_ruta_campana}
            campaign={campaign}
            moduloPlanRutas={moduloPlanRutas}
            skillsNotIncluded={skillsNotIncluded}
            onCalculate={() => {
              loadData();
            }}
          />
        </>
      )}

      {/* Campaña de tipo zonas */}
      {campaign.id_plan_tipo_campana === 2 && (
        <>
          <RoutePlanMapZones
            defaultCenter={defaultLatLng}
            startCalendarDate={startDate}
            endCalendarDate={endDate}
            idRoutePlan={parseInt(`${id}`)}
            idRoutePlanCampaign={campaign.id_plan_ruta_campana}
            campaign={campaign}
            moduloPlanRutas={moduloPlanRutas}
            onConfirm={() => {
              loadData();
            }}
          />
        </>
      )}
    </>
  );
};

export default IndexMap;
