import { FC, useState, useEffect } from 'react';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  notification,
  Segmented,
  Select,
  Spin,
  Table,
} from 'antd';
import { http } from '../../http/axios';
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Themes } from '../../store/ui';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import { selectCurrentUser } from '../../store/auth/auth.selectors';

const Div = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '1px solid #333333'
        : '1px solid #d9d9d9'};
  }

  .ant-table-thead > tr > th {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#333333' : '#f0f2f5'};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    border-bottom: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '1px solid #2a2d2f'
        : '1px solid #f0f0f0'};
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#333333' : '#fff'};
    border: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '1px solid #2a2d2f'
        : '1px solid #ddd'};
  }

  .ant-pagination-item-link {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-pagination-item {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#333333' : '#fff'};
  }

  .ant-table-placeholder > .ant-empty-description {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-table-placeholder {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }

  .ant-picker-input > span.ant-picker-suffix,
  .ant-picker-input > span.ant-picker-clear,
  .ant-picker-input > input {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background: transparent;
  }
`;

const FormStepTwo: FC<any> = ({
  form,
  routePlanId,
  currentDate,
  onChangeCampaign,
}) => {
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [customerContainers, setCustomerContainers] = useState<any[]>([]);
  const [routeCampaigns, setRouteCampaigns] = useState<any[]>([]);
  const [typeCampaignSelected, setTypeCampaignSelected] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [typeCampaign, setTypeCampaign] = useState<string[]>([]);
  const [typeCampaignObject, setTypeCampaignObject] = useState<any[]>([]);
  const [zones, setZones] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const [loadingCustomerContainers, setLoadingCustomerContainers] =
    useState(false);
  const [enableCustomerContainer, setEnableCustomerContainer] = useState(false);
  const [customerContainer, setCustomerContainer] = useState(-1);
  const [loadingTypeCampaigns, setLoadingTypeCampaigns] = useState(false);
  const currentTheme = useSelector(selectUiTheme);
  const currentUser = useSelector(selectCurrentUser);

  const tableHeaders = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: (data: any, d: any) => {
        return d.campaignType.nombre;
      },
    },
    {
      title: 'Campaña',
      dataIndex: 'campaign',
      key: 'campaign',
      render: (data: any, d: any) => {
        return d.campaign.nombre;
      },
    },
    {
      title: 'Datos',
      dataIndex: 'data',
      key: 'data',
      render: (data: any, d: any) => {
        if (d.fecha_agenda && d.zones.length === 0) return d.fecha_agenda;
        return d.zones.map((z: any) => z.glosa).join(', ');
      },
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
      render: (data: any, d: any) => {
        return d.nro_puntos;
      },
    },
    {
      title: 'Acción',
      dataIndex: 'actions',
      key: 'actions',
      render: (value: any, record: any) => (
        <Button
          type="primary"
          danger
          loading={loading}
          onClick={async () => {
            setLoading(true);
            try {
              await http.delete(
                `/route-plans/${record.id_plan_ruta}/campaigns/${record.id_plan_ruta_campana}`
              );
              setRouteCampaigns((oldValue) =>
                [...oldValue].filter(
                  (d) => d.id_plan_ruta_campana !== record.id_plan_ruta_campana
                )
              );
            } catch (error) {
              notification['error']({
                message: 'Error al eliminar la campaña',
                description: 'Por favor intentelo más tarde',
              });
            } finally {
              setLoading(false);
            }
          }}
          className="onfield-button"
          icon={<DeleteOutlined />}
        />
      ),
    },
  ];

  const handleAdd = async () => {
    setError(null);
    try {
      await form.validateFields();
    } catch (error) {
      notification['error']({
        message: 'Error al validar el formulario',
      });
      return;
    }

    const idTypeCampaign = typeCampaignObject.find(
      (item: any) => item.nombre === typeCampaignSelected
    )?.id_plan_tipo_campana;

    try {
      setLoading(true);
      const data = await http.post('/route-plan-campaigns', {
        id_route_plan: routePlanId,
        id_campana: form.getFieldValue('campaign'),
        id_route_type_campaign: idTypeCampaign,
        agenda_dia: form.getFieldValue('day')
          ? form.getFieldValue('day').format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        id_zones: form.getFieldValue('zones')
          ? form.getFieldValue('zones').map((i: string) => parseInt(i))
          : null,
				id_customer: form.getFieldValue('customerContainer') ?? -1,
      });
      if (onChangeCampaign) onChangeCampaign(data);
      setRouteCampaigns([...routeCampaigns, data]);
    } catch (error: any) {
      let message = error.message;

      if (axios.isAxiosError(error)) {
        const axiosResponse: any = error.response?.data;
        message = axiosResponse?.message;
      }

      console.error(error);
      notification['error']({
        message: message,
      });
      setError(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enableCustomerContainer) {
      const fetchCustomerContainers = async () => {
        setLoadingCustomerContainers(true);
        try {
          const response: any[] = await http.get(`/customers`);
          setCustomerContainers(response);
        } catch (error) {
          alert('Error al cargar las cuentas contenedoras');
        } finally {
          setLoadingCustomerContainers(false);
        }
      };
      fetchCustomerContainers();
    }
  }, [enableCustomerContainer]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      setCampaigns([]);
      const response: any[] = await http.get(
        `/campaigns?customer=${customerContainer}`
      );
			if (enableCustomerContainer && customerContainer === -1) {
				setCampaigns([]);
			} else {
				setCampaigns(response);
			}
    };
    fetchCampaigns();
    return () => {
      setCampaigns([]);
    };
  }, [enableCustomerContainer, customerContainer]);

  useEffect(() => {
    setLoadingTypeCampaigns(true);
    const fetchTypeCampaigns = async () => {
      const response: any[] = await http.get('/route-plan-type-campaigns');
      setTypeCampaignObject(response);
      setTypeCampaign(response.map((item) => item.nombre));
      setLoadingTypeCampaigns(false);
    };
    fetchTypeCampaigns();
  }, []);

  useEffect(() => {
    const fetchZones = async () => {
      const response: any[] = await http.get('/zones');
      setZones(response);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (routePlanId) {
      setLoadingCampaigns(true);
      const fetchCampaigns = async () => {
        const response: any[] = await http.get(
          `/route-plans/${routePlanId}/campaigns`
        );
        if (onChangeCampaign && response.length > 0)
          onChangeCampaign(response[0]);
        setRouteCampaigns(response);
        setLoadingCampaigns(false);
      };
      fetchCampaigns();
    }
  }, [routePlanId]);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_plan_de_ruta = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (sw_modulo_plan_de_ruta) {
        try {
          const valor6 = JSON.parse(sw_modulo_plan_de_ruta.value6);
          setEnableCustomerContainer(!!valor6?.pedidos_filtro_cuenta);
        } catch (error) {
          console.error('[Error] JSON no válido valor6 sw_modulo_plan_de_ruta');
        }
      }
    }
  }, [currentUser]);

  return (
    <Div className="steps-content" style={{ flex: 1 }} theme={currentTheme}>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ remember: true, day: currentDate }}
        autoComplete="off"
        form={form}
        colon={false}
      >
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            style={{ marginBottom: '10px' }}
          />
        )}

        {enableCustomerContainer && (
          <Form.Item
            label={
              <span
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              >
                Cuenta
              </span>
            }
            name="customerContainer"
            rules={[
              { required: true, message: 'Ingrese la cuenta contenedora' },
            ]}
          >
            <Select
              showSearch
              placeholder="Selecciona cuenta contenedora"
              loading={loadingCustomerContainers}
              style={{
                width: '100%',
                backgroundColor:
                  currentTheme === Themes.DARK_THEME ? '#1e1e1e' : 'white',
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                borderColor:
                  currentTheme === Themes.DARK_THEME ? '#333333' : '#d9d9d9',
              }}
              onChange={(e) => {
                setCustomerContainer(e);
              }}
            >
              {customerContainers.map((customer) => (
                <Select.Option
                  key={customer.id_cliente}
                  value={customer.id_cliente}
                >
                  {customer.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={
            <span
              style={{
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              }}
            >
              Campaña
            </span>
          }
          name="campaign"
          rules={[{ required: true, message: 'Ingrese la Campaña' }]}
        >
          <Select
            showSearch
            placeholder="Selecciona campaña"
            loading={loadingCampaigns}
            style={{
              width: '100%',
              backgroundColor:
                currentTheme === Themes.DARK_THEME ? '#1e1e1e' : 'white',
              color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              borderColor:
                currentTheme === Themes.DARK_THEME ? '#333333' : '#d9d9d9',
            }}
          >
            {campaigns.map((campaign) => (
              <Select.Option
                key={campaign.id_campana}
                value={campaign.id_campana}
              >
                {campaign.nombre}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label=" ">
          {loadingTypeCampaigns && (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} />
          )}
          <Segmented
            options={typeCampaign}
            defaultChecked
            defaultValue={0}
            onChange={(val) => {
              setTypeCampaignSelected(val.toString());
            }}
            style={{
              color: currentTheme === Themes.DARK_THEME ? '#ddd' : 'black',
            }}
          />
        </Form.Item>

        {typeCampaignSelected === 'Agenda' && (
          <Form.Item
            label={
              <span
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              >
                Día
              </span>
            }
            name="day"
            rules={[{ required: true, message: 'Ingrese el día' }]}
          >
            <DatePicker
              placeholder="Día de la agenda"
              style={{
                width: '100%',
                backgroundColor:
                  currentTheme === Themes.DARK_THEME ? '#1e1e1e' : 'white',
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                borderColor:
                  currentTheme === Themes.DARK_THEME ? '#333333' : '#d9d9d9',
              }}
            />
          </Form.Item>
        )}

        {typeCampaignSelected === 'Pedidos' && (
          <Form.Item
            label={
              <span
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              >
                Día
              </span>
            }
            name="day"
            rules={[{ required: true, message: 'Ingrese el día' }]}
          >
            <DatePicker
              placeholder="Día de entrega"
              style={{
                width: '100%',
                backgroundColor:
                  currentTheme === Themes.DARK_THEME ? '#1e1e1e' : 'white',
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                borderColor:
                  currentTheme === Themes.DARK_THEME ? '#333333' : '#d9d9d9',
              }}
            />
          </Form.Item>
        )}

        {typeCampaignSelected === 'Rutas' && (
          <Form.Item
            label={
              <span
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              >
                Rutas
              </span>
            }
            name="zones"
            rules={[
              { required: true, message: 'Seleccione al menos una ruta' },
            ]}
          >
            <Select
              mode="multiple"
              style={{
                width: '100%',
                backgroundColor:
                  currentTheme === Themes.DARK_THEME ? '#1e1e1e' : 'white',
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                borderColor:
                  currentTheme === Themes.DARK_THEME ? '#333333' : '#d9d9d9',
              }}
              tokenSeparators={[',']}
            >
              {zones.map((zone) => (
                <Select.Option key={zone.id_zona} value={`${zone.id_zona}`}>
                  {zone.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleAdd}
            loading={loading}
            style={{ borderRadius: '5px' }}
            disabled={routeCampaigns.length > 0}
          >
            Agregar
          </Button>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Table
            style={{ width: '100%' }}
            columns={tableHeaders}
            dataSource={routeCampaigns}
            rowKey={(record) => record.id_plan_ruta_campana}
            rowClassName={(record, index) => {
              return currentTheme === Themes.DARK_THEME
                ? 'table-row-dark'
                : 'table-row-light';
            }}
          />
        </Form.Item>
      </Form>
    </Div>
  );
};

export default FormStepTwo;
