import { TipoCampoValor } from '../../domain/models/TipoCampoValor';
import * as tipoCampoValorRepo from '../../infrastructure/repositories/tipoCampoValor.repository';

export const getTipoCampoValores = async (): Promise<TipoCampoValor[]> => {
  return tipoCampoValorRepo.findAllTipoCampoValor();
};

export const createTipoCampoValores = async (body: any): Promise<TipoCampoValor> => {
    return tipoCampoValorRepo.createTipoCampoValor(body);
};

export const findTipoCampoValores = async (tipoCampoValorId: number): Promise<TipoCampoValor> => {
    return tipoCampoValorRepo.findTipoCampoValor(tipoCampoValorId);
};

export const editTipoCampoValores = async (tipoCampoValorId: number, body: any): Promise<TipoCampoValor> => {
    return tipoCampoValorRepo.editTipoCampoValor(tipoCampoValorId, body);
};

export const deleteTipoCampoValores = async (tipoCampoValorId: number): Promise<TipoCampoValor> => {
    return tipoCampoValorRepo.deleteTipoCampoValor(tipoCampoValorId);
};

 