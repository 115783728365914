import React, { useEffect, useState } from 'react';
import * as tipoCampoValorService from '../../../domain/services/tipoCampoValor.service';
import {
  Table,
  PageHeader,
  Button,
  Card,
  Modal,
  Input,
  Form,
  Checkbox,
  Row,
  Col,
  Space,
  Tag,
  Alert,
  Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TipoCampoValor } from '../../../domain/models/TipoCampoValor';
import {
  EditOutlined,
  PlusOutlined,
  DisconnectOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import ExcelExport from './excelExport';
import ExcelImport from './excelImport';
import { Themes } from '../../store/ui';
import colors from '../../const/colors';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../store/ui/ui.selectors';

const RoundButton = styled(Button)`
  border-radius: 8px;
`;

const GreyRoundCard = styled(Card)`
  border-radius: 8px;
  background-color: #FAFAFA;
  #FAFAFA
`;

const PageHeaderTheme = styled(PageHeader)`
  h1 {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.h1
        : colors.DARK_THEME.common.h1};
  }
`;

const TableTheme = styled(Table)`
  .ant-table-container,
  .ant-pagination {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }

  a {
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }

  .ant-table-thead > tr > th,
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right,
  .ant-table-cell-row-hover,
  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-select-selector,
  .ant-pagination-prev.ant-pagination-disabled,
  .ant-pagination-next {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.selectSelection
        : colors.LIGHT_THEME.common.selectSelection} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }
`;

const CardTheme = styled(Card)`
  h1,
  h2,
  h3 {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.h1
        : colors.DARK_THEME.common.h1};
  }

  .ant-card-head,
  .ant-input,
  .ant-card {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
  }

  .ant-btn.ant-btn-default {
    background: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.selectSelection
        : colors.LIGHT_THEME.common.selectSelection} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }
`;

const TipoCampoValorPage: React.FC = () => {
  //filtros
  const currentTheme = useSelector(selectUiTheme);
  const clearAll = () => {
    setValueSearchNombreCampo('');
    setValueSearchValor('');
    cargaTabla();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tipoCampoValores, setTipoCampoValores] = useState<TipoCampoValor[]>(
    []
  );
  const [valueSearchNombreCampo, setValueSearchNombreCampo] = useState('');
  const [valueSearchValor, setValueSearchValor] = useState('');
  const [dataSourceNombreCampo, setdataSourceNombreCampo] =
    useState(tipoCampoValores);
  const [dataSourceValor, setdataSourceValor] = useState(dataSourceNombreCampo);
  const [idTipoCampoValorValue, setIdTipoCampoValorValue] = useState(0);
  const [idTipoCampoValue, setIdTipoCampoValue] = useState('');
  const [nombreCampoValue, setNombreCampoValue] = useState('');
  const [idValorValue, setIdValorValue] = useState('');
  const [valorValue, setValorValue] = useState('');
  const [estadoValue, setEstadoValue] = useState(true);

  const [defaultNombreCampoValue, setDefaultNombreCampoValue] = useState('');
  const [defaultIdValor, setDefaultIdValorValue] = useState('');
  const [defaultValor, setDefaultValorValue] = useState('');
  const [defaultEstado, setDefaultEstadoValue] = useState(true);

  const [modalMode, setModalMode] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  // Columnas de tabla
  const columns: ColumnsType<object> = [
    {
      title: 'ID',
      width: 50,
      dataIndex: 'id_tipo_campo_valor',
      key: 'id_tipo_campo_valor',
      fixed: 'left',
      defaultSortOrder: 'descend',

      sorter: (a: any, b: any) =>
        Number(a.id_tipo_campo_valor) - Number(b.id_tipo_campo_valor),
    },
    {
      title: 'Nombre Campo',
      width: 100,
      dataIndex: 'nombre_campo',
      key: 'nombre_campo',
    },
    {
      title: 'ID Valor',
      dataIndex: 'id_valor',
      key: 'id_valor',
      width: 100,
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      width: 100,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      width: 50,
      render: (_, { estado }: any) => {
        const color = estado === 1 ? 'blue' : 'red';
        const texto = estado === 1 ? 'Habilitado' : 'Deshabilitado';

        return (
          <Tag color={color} key={estado}>
            {texto}
          </Tag>
        );
      },
    },
    {
      title: 'Acción',
      key: 'operation',
      fixed: 'right',
      width: 50,
      align: 'right',
      render: (text, record, index) => (
        <>
          <Space>
            <Tooltip title="Ediatr">
              <RoundButton
                shape="round"
                key="1"
                type="primary"
                onClick={() => showModalEdit(record)}
              >
                <EditOutlined />
              </RoundButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <RoundButton
                key="2"
                type="primary"
                danger
                onClick={() => deleteElemet(record)}
              >
                <DeleteOutlined />
              </RoundButton>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  const createTipoCampoValor = (
    nombreCampo: string,
    idValor: string,
    valorCampo: string,
    estado: boolean
  ) => {
    const body: TipoCampoValor = {
      nombre_campo: nombreCampo,
      valor: valorCampo,
      estado: Number(estado),
      id_tipo_campo: '',
      id_valor: idValor,
      id_tipo_campo_valor: 0,
    };
    tipoCampoValorService
      .createTipoCampoValores(body)
      .then(cargaTabla)
      .catch((error) => {
        console.error(error);
      });
  };

  const editTipoCampoValor = (
    idTipoCampoValor: number,
    idTipoCampo: string,
    nombreCampo: string,
    idValor: string,
    valorCampo: string,
    estado: boolean
  ) => {
    const body: TipoCampoValor = {
      id_tipo_campo_valor: idTipoCampoValor,
      id_tipo_campo: idTipoCampo,
      nombre_campo: nombreCampo,
      id_valor: idValor,
      valor: valorCampo,
      estado: Number(estado),
    };

    tipoCampoValorService
      .editTipoCampoValores(idTipoCampoValor, body)
      .then(cargaTabla)
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteTipoCampoValor = (idTipoCampoValor: number) => {
    tipoCampoValorService
      .deleteTipoCampoValores(idTipoCampoValor)
      .then(cargaTabla)
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    setModalMode('Create');

    setDefaultNombreCampoValue('');
    setDefaultIdValorValue('');
    setDefaultValorValue('');
    setDefaultEstadoValue(true);
    setNombreCampoValue('');
    setValorValue('');
    setEstadoValue(true);
    setIsModalOpen(true);
    setIsModalOpen(true);
    setModalMode('Create');
  };

  const showModalEdit = (record: any) => {
    setDefaultNombreCampoValue(record.nombre_campo);
    setDefaultIdValorValue(record.id_valor);
    setDefaultValorValue(record.valor);
    setDefaultEstadoValue(record.estado);

    setIdTipoCampoValorValue(record.id_tipo_campo_valor);
    setIdTipoCampoValue(record.id_tipo_campo);
    setNombreCampoValue(record.nombre_campo);
    setIdValorValue(record.id_valor);
    setValorValue(record.valor);
    setEstadoValue(record.estado);
    setIsModalOpen(true);
    setModalMode('edit');
  };

  const deleteElemet = (record: any) => {
    deleteTipoCampoValor(record.id_tipo_campo_valor);
  };

  const handleOk = (e: any) => {
    if (
      !(nombreCampoValue === '' || valorValue === '' || idValorValue === '')
    ) {
      modalMode === 'Create'
        ? createTipoCampoValor(
            nombreCampoValue,
            idValorValue,
            valorValue,
            estadoValue
          )
        : editTipoCampoValor(
            idTipoCampoValorValue,
            idTipoCampoValue,
            nombreCampoValue,
            idValorValue,
            valorValue,
            estadoValue
          );

      setIsModalOpen(false);

      setNombreCampoValue('');
      setValorValue('');
      setEstadoValue(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cargaTabla = () => {
    tipoCampoValorService
      .getTipoCampoValores()
      .then((regs) => {
        setTipoCampoValores(regs);
        setdataSourceValor(regs);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    cargaTabla();
  }, []);

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //console.log(e, 'I was closed.');
  };

  return (
    <div>
      <PageHeaderTheme
        theme={currentTheme}
        className="site-page-header"
        title={<h1>Adm. Tipo Campo Valores</h1>}
        subTitle=""
        extra={[
          <div>
            {' '}
            {showAlert && (
              <Alert
                action
                message="Formato no valido [ID, Nombre Campo, ID Valor, Valor] "
                type="error"
                closable
                onClose={onClose}
              />
            )}
          </div>,
          <ExcelImport
            style={{ background: '#8FBB6C', borderColor: '#8FBB6C' }}
            setShowAlert={setShowAlert}
            cargaTabla={cargaTabla}
            tooltipTitle={'Importar'}
          />,
          <ExcelExport
            style={{ background: '#D8D8D8', borderColor: '#D8D8D8' }}
            dataSourceValor={[]}
            tooltipTitle={'Ejemplo y Formato de Archivo a Subir'}
          />,
          <Tooltip title="Agregar">
            <RoundButton key="1" type="primary" onClick={showModal}>
              <PlusOutlined />
            </RoundButton>
          </Tooltip>,
          <ExcelExport
            style={{ background: '#8FBB6C', borderColor: '#8FBB6C' }}
            dataSourceValor={dataSourceValor}
            tooltipTitle={'Exportar'}
          />,
        ]}
      />
      <Row>
        <Col span="1"></Col>
        <Col span="22">
          <CardTheme
            theme={currentTheme}
            title={
              <>
                <Space>
                  <h2>Lista Tipos de campo</h2>
                </Space>
              </>
            }
            style={{ margin: '16px 0' }}
            headStyle={{ backgroundColor: '#FCFCFC', border: 0 }}
          >
            <GreyRoundCard style={{ margin: '16px' }}>
              <Space style={{ margin: 16 }}>
                <div>
                  <h3>Nombre Campo</h3>
                  <Input
                    placeholder=""
                    value={valueSearchNombreCampo}
                    onChange={(e) => {
                      const currValue = e.target.value;
                      setValueSearchNombreCampo(currValue);
                      const filteredData = tipoCampoValores.filter((entry) =>
                        entry.nombre_campo.includes(currValue)
                      );
                      setdataSourceNombreCampo(filteredData);
                      setdataSourceValor(filteredData);
                    }}
                  />
                </div>
                <div>
                  <h3>Valor</h3>
                  <Input
                    placeholder=""
                    value={valueSearchValor}
                    onChange={(e) => {
                      const currValue = e.target.value;
                      setValueSearchValor(currValue);
                      const filteredData = dataSourceNombreCampo.filter(
                        (entry) => entry.valor.includes(currValue)
                      );
                      setdataSourceValor(filteredData);
                    }}
                  />
                </div>
              </Space>
              <div style={{ margin: '16px' }}>
                <RoundButton onClick={clearAll}>
                  <DisconnectOutlined />
                  Limpiar filtros
                </RoundButton>
              </div>
            </GreyRoundCard>

            <TableTheme
              theme={currentTheme}
              dataSource={dataSourceValor}
              scroll={{ x: '80vw', y: '50vh' }}
              style={{ margin: '16px' }}
              bordered
              pagination={{ position: ['bottomLeft'] }}
              columns={columns}
            />
          </CardTheme>
        </Col>
        <Col span="1"></Col>
      </Row>
      <Modal
        title={
          <>
            <EditOutlined /> Lista Tipos de campo
          </>
        }
        visible={isModalOpen}
        onOk={(e) => handleOk(e)}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="Cancelar" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="Guardar" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        <Form>
          <Form.Item
            name="nombre_campo"
            label="Nombre de campo"
            rules={[{ required: true }]}
          >
            <Input
              defaultValue={defaultNombreCampoValue}
              name="nombre_campo"
              onChange={(event) => setNombreCampoValue(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="id_valor"
            label="ID Valor"
            rules={[{ required: true }]}
          >
            <Input
              defaultValue={defaultIdValor}
              name="id_valor"
              onChange={(event) => setIdValorValue(event.target.value)}
            />
          </Form.Item>
          <Form.Item name="valor" label="Valor" rules={[{ required: true }]}>
            <Input
              defaultValue={defaultValor}
              name="valor"
              onChange={(event) => setValorValue(event.target.value)}
            />
          </Form.Item>
          <Form.Item name="estado" label="Estado">
            <Checkbox
              defaultChecked={defaultEstado}
              name="estado"
              onChange={(event) => setEstadoValue(event.target.checked)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TipoCampoValorPage;
