import { Modal, Select, notification } from 'antd';
import { Component } from 'react';
import { http } from '../../../http/axios';

type Props = {
  onChangeSuccess?: () => void;
};

type State = {
  show: boolean;
  currentPerson: any;
  resources: any[];
  selectedPersonId: number | null;
  users: any[];
  loading: boolean;
  routePlanId: number;
  loadingUsers: boolean;
};

class ChangePerson extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
      currentPerson: null,
      resources: [],
      selectedPersonId: null,
      users: [],
      loading: false,
      routePlanId: -1,
      loadingUsers: false,
    };
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      prevState.routePlanId !== this.state.routePlanId ||
      prevState.currentPerson?.id !== this.state.currentPerson?.id
    ) {
      this.setState({
        users: [],
        selectedPersonId: null,
        loadingUsers: true,
      });
      const response: any[] = await http.get(
        '/users/technicians?route_plan_id=' + this.state.routePlanId
      );

      this.setState({
        users: response
          .filter(
            (user) => `${user.user_id}` !== `${this.state.currentPerson?.id}`
          )
          .sort((a, b) => (a.firstname > b.firstname ? 1 : -1)),
        loadingUsers: false,
      });
    }
  }

  async componentDidMount() {
    if (this.state.routePlanId !== -1) {
      try {
        this.setState({
          users: [],
          loadingUsers: true,
        });
        const response: any[] = await http.get(
          '/users/technicians?route_plan_id=' + this.state.routePlanId
        );
        this.setState({
          users: response
            .filter(
              (user) =>
                `${user.user_id}` !== `${this.state.currentPerson?.id}` &&
                !this.state.resources
                  .map((r) => r.id)
                  .includes(`${user.user_id}`)
            )
            .sort((a, b) => (a.firstname > b.firstname ? 1 : -1)),
          loadingUsers: false,
        });
      } catch (error: any) {
        console.error(error);
        alert('[Error] ' + error.message);
      }
    }
  }

  setResources(resources: any[]) {
    this.setState({
      resources,
    });
  }

  setCurrentPerson(currentPerson: any) {
    this.setState({
      currentPerson,
    });
  }

  setRoutePlanId(routePlanId: any) {
    this.setState({
      routePlanId,
    });
  }

  toggleModal(show: boolean) {
    this.setState({
      show,
    });
  }

  render() {
    return (
      <Modal
        visible={this.state.show}
        onCancel={() => {
          this.toggleModal(false);
        }}
        okButtonProps={{ loading: this.state.loading }}
        title={this.state.currentPerson?.title}
        onOk={async () => {
          if (!this.state.selectedPersonId) {
            notification['warning']({
              message: 'Debes seleccionar una persona',
              description:
                'Por favor selecciona una persona para realizar el cambio',
            });
            return;
          }
          this.setState({ loading: true });
          try {
            await http.put(
              `/route-plan-persons/${this.state.currentPerson.extendedProps.id_plan_ruta_persona}/change/user`,
              {
                newUserId: this.state.selectedPersonId,
              }
            );
            this.setState({ show: false });
            if (this.props.onChangeSuccess) this.props.onChangeSuccess();
          } catch (error) {
            notification['error']({
              message: 'Se ha producido un error',
              description: 'No ha sido posible realizar el cambio',
            });
          } finally {
            this.setState({ loading: false });
          }
        }}
      >
        <Select
          style={{ width: '100%' }}
          showSearch
          placeholder="Selecciona una persona +"
          loading={this.state.loadingUsers}
          onChange={(value) => {
            this.setState({
              selectedPersonId: value,
            });
          }}
          value={this.state.selectedPersonId}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: -1,
              label: 'Selecciona una persona +',
              disabled: true,
            },
            ...this.state.users
              /* .filter(
                (user) =>
                  `${user.user_id}` !== this.state.currentPerson?.id &&
                  !this.state.resources
                    .map((r) => r.id)
                    .includes(`${user.user_id}`)
              ) */
              .map((user) => {
                return {
                  value: user.user_id,
                  label: `${user.firstname} ${user.lastname}`,
                };
              }),
          ]}
        />
      </Modal>
    );
  }
}

export default ChangePerson;
