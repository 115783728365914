import { Modal, notification } from 'antd';
import { Component } from 'react';
import { SketchPicker } from 'react-color';
import { http } from '../../../http/axios';

type Props = {
  onChange?: (color: string, personId: string) => void;
};
type State = {
  resource: any;
  show: boolean;
  color: string;
  loading: boolean;
};

class ChangePersonColor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      resource: null,
      show: false,
      color: '',
      loading: false,
    };
  }

  setResource(resource: any) {
    this.setState({ resource, color: resource.extendedProps.color });
  }

  setShow(show: boolean) {
    this.setState({ show });
  }

  render() {
    return (
      <Modal
        visible={this.state.show}
        title={this.state.resource?.title}
        onCancel={() => {
          this.setShow(false);
        }}
        okButtonProps={{ loading: this.state.loading }}
        onOk={async () => {
          if (!this.state.resource) {
            notification['warning']({
              message: 'Debes seleccionar una persona',
              description:
                'Por favor selecciona una persona para realizar el cambio de color',
            });
            return;
          }
          this.setState({ loading: true });
          try {
            await http.put(
              `/route-plan-persons/${this.state.resource?.extendedProps.id_plan_ruta_persona}/change/color`,
              {
                color: this.state.color,
              }
            );
            this.setState({ show: false });
            this.props.onChange?.(
              this.state.color,
              this.state.resource?.extendedProps.id_plan_ruta_persona
            );
          } catch (error) {
            notification['error']({
              message: 'Se ha producido un error',
              description: 'No ha sido posible realizar el cambio de color',
            });
          } finally {
            this.setState({ loading: false });
          }
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SketchPicker
          color={this.state.color}
          onChange={(color) => {
            this.setState({ color: color.hex });
          }}
          disableAlpha
        />
      </Modal>
    );
  }
}

export default ChangePersonColor;
