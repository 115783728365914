import React from "react"
import logo_onfield_negro from '../images/logo_onfield_negro.png'
import paso1 from './images/paso1.png'
import paso2 from './images/paso2.png'
import paso3 from './images/paso3.png'
import paso4 from './images/paso4.png'
import settings from './images/settings.png'
//import './index.css';

function Instalar_certificado() {
    return (
        <body className="backgroundPage fullScroll" style={{
              backgroundImage: `url(https://source.unsplash.com/collection/145103/computers-phones-and-tech)`
        }} >
        <div className="sp"></div>
        <div className="box-page login-box-page ">
          <div className="box">
            <div style={{textAlign:"center"}}>
                <img style={{
                  position: `relative`,
                  top:`-Xpx`,
                  width: `200px`
                }}
                  className="nav_logo" src={logo_onfield_negro}
                  alt=""
                  title=""
                />
              </div>
              <div className="form-container document"  style={{textAlign:"center"}}>
              <br/>
              <h2>Pasos para Habilitar la App luego de Instalada</h2>
              <h1>Habilitar certificado empresarial</h1>
              <form id="loginForm" method="post" action="">
                <fieldset>
                  <div className="form-group loginfield">
                    <div>
                      <div className="section" id="trust-enterprise-certificates">
                        <p className="last">﻿Si la primera vez que entres a la App te sale un mensaje como la siguiente imagen, efectúa los 4 pasos que salen a continuación:</p>
                        <p>
                        <a className="reference internal image-reference" href={paso1}><img alt="images/paso1.png" src={paso1} style={{width:"300px"}} /></a>
                        </p>
                        <ol className="arabic simple">
                          <li style={{textAlign:"left"}}>En tu dispositivo abre la App de "Configuración"</li>
                        </ol>
                        <a className="reference internal image-reference" href={settings}><img alt="Configuración" src={settings} style={{width:"80px"}} /></a>
                        <ol className="arabic simple" start={2}>
                          <li style={{textAlign:"left"}}>Luego selecciona “General” -{">"} “Admón. de dispositivos”.</li>
                        </ol>
                        <ol className="arabic simple" start={3}>
                          <li style={{textAlign:"left"}}>Selecciona el certificado "Assertsoft..." en la sección “App empresarial”.</li>
                        </ol>
                        <p>
                          <a className="reference internal image-reference" href={paso2}><img alt="images/paso2.png" src={paso2} style={{width:"300px"}} /></a>
                        </p>
                        <ol className="arabic simple" start={4}>
                          <li style={{textAlign:"left"}}>Presiona “Confiar en Assertsoft...”.</li>
                        </ol>
                        <p>
                          <a className="reference internal image-reference" href={paso3}><img alt="images/paso3.png" src={paso3} style={{width:"300px"}} /></a>
                        </p>
                        <ol className="arabic simple" start={5}>
                          <li style={{textAlign:"left"}}>Selecciona “Confiar”.</li>
                        </ol>
                        <p>
                          <a className="reference internal image-reference" href={paso4}><img alt="images/paso4.png" src={paso4} style={{width:"300px"}} /></a>
                        </p>
                        <ol className="arabic simple" start={6}>
                          <li style={{textAlign:"left"}}>Luego puedes entrar sin problemas a la App presionando su ícono.</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </fieldset>
              <div style={{width:"100%"}}>
              <a className="btn blue-btn" href="../ios" style={{textDecoration: "none"}} >VOLVER</a>
              </div>
              <br/>
            <div>
            <p>Powered by <a href="https://assertsoft.com" style={{textDecoration: "none"}} target="_blank">Assertsoft</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div className="sp"></div>
</body>
    );
}

export default Instalar_certificado;