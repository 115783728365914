import { Button, Modal, Segmented, Select, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import useLanguage from '../../../../hooks/useLanguage';
import * as mapActivityService from '../../../../../domain/services/widgets/mapActivity.service';
import { Region } from '../../../../../domain/models/Region';
import { Commune } from '../../../../../domain/models/Commune';
import { Zone } from '../../../../../domain/models/Zone';
import styled from 'styled-components';
import { Themes } from '../../../../store/ui';
import colors from '../../../../const/colors';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { selectCurrentUser } from '../../../../store/auth/auth.selectors';

export type ConfigRegion = {
  region: Zone[];
  show: boolean;
  commune: Commune | null;
};
export type ConfigCommune = {
  commune: Commune | Commune[] | null;
  show: boolean;
};
export type ConfigZone = {
  zone: Zone[];
  withZoom: boolean;
};

const Modalx = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.backgroundColor
        : colors.LIGHT_THEME.backgroundColor} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor} !important;

    h3 {
      color: ${(props) =>
        props.theme === Themes.DARK_THEME
          ? colors.DARK_THEME.textColor
          : colors.LIGHT_THEME.textColor} !important;
    }
  }

  .ant-modal-header {
    border-bottom: 1px solid
      ${(props) =>
        props.theme === Themes.DARK_THEME
          ? colors.DARK_THEME.common.select
          : '#f0f0f0'};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor} !important;
    border: 1px solid
      ${(props) =>
        props.theme === Themes.DARK_THEME
          ? ' ' + colors.DARK_THEME.common.selectSelection
          : ' #d9d9d9'};
  }

  .ant-select-dropdown {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.backgroundColor
        : colors.LIGHT_THEME.backgroundColor} !important;
  }

  .ant-select-multiple .ant-select-selection-item {
    background: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.backgroundColor
        : colors.LIGHT_THEME.backgroundColor};
    border: 1px solid
      ${(props) =>
        props.theme === Themes.DARK_THEME
          ? colors.DARK_THEME.backgroundColor
          : colors.LIGHT_THEME.backgroundColor};
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor} !important;
  }

  .ant-segmented-item-selected {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor} !important;
  }

  .ant-segmented-item-label {
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor} !important;
  }

  .ant-segmented-thumb {
    background-color: transparent;
  }
`;

type Props = {
  visible: boolean;
  onCancel?: (visible: boolean) => void;
  onChangeRegion?: (config: ConfigRegion) => void;
  onChangeCommune?: (config: ConfigCommune) => void;
  onChangeZone?: (config: ConfigZone) => void;
  onZoomGeneral?: () => void;
};

const MapConfig: FC<Props> = ({
  visible,
  onCancel,
  onChangeRegion,
  onChangeCommune,
  onChangeZone,
  onZoomGeneral,
}) => {
  const t = useLanguage();
  const [regions, setRegions] = useState<Region[]>([]);
  const [loadingCommunes, setLoadingCommunes] = useState(false);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [config, setConfig] = useState<{ showPolygons: boolean; zone: Zone[] }>(
    {
      showPolygons: false,
      zone: [],
    }
  );
  const [selectedCommune, setSelectedCommune] = useState<number>(-1);
  const [selectedRegion, setSelectedRegion] = useState<number>(-1);
  const [selectedRoutes, setSelectedRoutes] = useState<number[]>([]);
  const currentTheme = useSelector(selectUiTheme);
  const currentUser = useSelector(selectCurrentUser);
  const [glosaRegion, setGlosaRegion] = useState<string>('');
  const [glosaComuna, setGlosaComuna] = useState<string>('');
  const [optionSegmented, setOptionSegmented] =
    useState<string>('Zoom general');

  const handleChangeRegion = async (value: number) => {
    if (value !== -1) {
      setLoadingCommunes(true);
      const [communes, regionsData] = await Promise.all([
        mapActivityService.getCommunesByRegion(value),
        mapActivityService.getZonesByRegion(value),
      ]);

      onChangeRegion?.({
        region: regionsData,
        show: config.showPolygons,
        commune: null,
      });
      setSelectedCommune(-1);
      setCommunes(communes);
      setLoadingCommunes(false);
    } else {
      onChangeRegion?.({
        region: [],
        show: config.showPolygons,
        commune: null,
      });
      setSelectedCommune(-1);
      setCommunes([]);
    }
  };

  const handleChangeCommune = async (value: number) => {
    if (value !== -1) {
      onChangeCommune?.({
        commune: communes.find((c) => c.commune_id === value) ?? null,
        show: config.showPolygons,
      });
      setSelectedCommune(
        communes.find((c) => c.commune_id === value)?.commune_id ?? -1
      );
    } else {
      onChangeCommune?.({
        commune: communes,
        show: config.showPolygons,
      });
      setSelectedCommune(-1);
    }
  };

  const handleApply = () => {
    if (onCancel) onCancel(false);
  };

  useEffect(() => {
    setSelectedRoutes([]);
    setConfig((oldValue) => ({
      showPolygons: false,
      zone: oldValue.zone,
    }));
    onChangeCommune?.({
      commune: [],
      show: false,
    });
    onChangeRegion?.({
      region: [],
      show: false,
      commune: null,
    });

    if (optionSegmented === 'Zoom general') {
      onZoomGeneral?.();
    }
  }, [optionSegmented]);

  useEffect(() => {
    if (currentUser) {
      const sw_glosa_comuna = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'glosa_comuna'
      );
      const sw_glosa_region = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'glosa_region'
      );
      setGlosaComuna(sw_glosa_comuna?.value1 || '');
      setGlosaRegion(sw_glosa_region?.value1 || '');
      setOptionSegmented(`Zoom general`);
    }
  }, [currentUser]);

  useEffect(() => {
    mapActivityService
      .getRegions()
      .then((regs) => {
        setRegions(regs);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const loadZones = async () => {
      const zones = await mapActivityService.getAllZones();
      setConfig({
        ...config,
        zone: zones,
      });
    };

    loadZones();
  }, []);

  useEffect(() => {
    (async () => {
      let zone: Zone[] = [];
      if (selectedRoutes.length === 0) {
        zone = [];
      } else {
        zone = config.zone.filter((z) => selectedRoutes.includes(z.id_zona));
        for (const idxZone in zone) {
          if (zone[idxZone]?.id_zona && !zone[idxZone]?.area) {
            zone[idxZone] = await mapActivityService.getZoneById(
              zone[idxZone]?.id_zona
            );
            setConfig((oldVal) => {
              return {
                ...oldVal,
                zone: oldVal.zone.map((z) => {
                  if (z.id_zona === zone[idxZone]?.id_zona) {
                    return zone[idxZone];
                  }
                  return z;
                }),
              };
            });
          }
        }
      }

      onChangeZone?.({
        zone: zone,
        withZoom: true,
      });
    })();
  }, [selectedRoutes]);

  return (
    <>
      <Modalx
        theme={currentTheme}
        title={
          <h3 style={{ textAlign: 'center' }}>
            {t('setting', { ns: 'common' })} Vista
          </h3>
        }
        footer={null}
        visible={visible}
        closable={true}
        onCancel={() => (onCancel ? onCancel(false) : null)}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Segmented
          options={[
            'Zoom general',
            `${glosaRegion} - ${glosaComuna}`,
            t('routes'),
          ]}
          defaultValue={optionSegmented}
          onChange={(value) => {
            setOptionSegmented(value.toString());
          }}
        />
        {optionSegmented === 'Zoom general' && <></>}
        {optionSegmented === `${glosaRegion} - ${glosaComuna}` && (
          <>
            <div>
              {t('map_activities.config.show_polygons', { ns: 'widgets' })}{' '}
              {t('routes')}{' '}
              <Switch
                defaultChecked={config.showPolygons}
                onChange={async (checked) => {
                  setConfig({ ...config, showPolygons: checked });
                  if (selectedRegion !== -1) {
                    let regionsData: Zone[] = [];

                    if (checked) {
                      regionsData = await mapActivityService.getZonesByRegion(
                        selectedRegion
                      );
                    }
                    onChangeRegion?.({
                      region: regionsData,
                      show: checked,
                      commune:
                        communes.find(
                          (c) => c.commune_id === selectedCommune
                        ) || null,
                    });
                  }

                  if (selectedCommune !== -1) {
                    onChangeCommune?.({
                      commune: communes.find(
                        (c) => c.commune_id === selectedCommune
                      )!,
                      show: checked,
                    });
                  }
                }}
                size="small"
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <span>{glosaRegion}</span>
              <Select
                defaultValue={-1}
                style={{ width: '10rem' }}
                value={selectedRegion}
                placeholder="Seleccione"
                size="small"
                showSearch
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                onChange={(value) => {
                  handleChangeRegion(value);
                  setSelectedRegion(value);
                }}
              >
                <Select.Option value={-1} disabled>
                  Seleccione
                </Select.Option>
                {regions.map((region) => (
                  <Select.Option key={region.zone_id} value={region.zone_id}>
                    {region.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <span>{glosaComuna}</span>
              <Select
                defaultValue={-1}
                style={{ width: '10rem' }}
                placeholder="Seleccione"
                size="small"
                showSearch
                filterOption={(input, option) => {
                  const include = (option!.children as unknown as string)
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(input.toLowerCase());
                  return !!include;
                }}
                loading={loadingCommunes}
                onChange={(value) => {
                  handleChangeCommune(value);
                }}
                value={selectedCommune}
              >
                <Select.Option value={-1} disabled>
                  Seleccione
                </Select.Option>
                {communes.map((c) => (
                  <Select.Option key={c.commune_id} value={c.commune_id}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <small>
              Al seleccionar las opciones el mapa hará zoom a la {glosaComuna}
            </small>
          </>
        )}
        {optionSegmented === t('routes') && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <span>
                {t('map_activities.config.routes_zoom', { ns: 'widgets' })}
              </span>
              <Select
                mode="multiple"
                style={{ width: '10rem' }}
                defaultValue={[-1]}
                placeholder="Seleccione"
                maxTagCount="responsive"
                size="small"
                value={selectedRoutes}
                options={config.zone.map((zone) => ({
                  label: zone.label,
                  value: zone.id_zona,
                }))}
                filterOption={(input, option) => {
                  const include = option?.label
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(input.toLowerCase());
                  return !!include;
                }}
                onChange={(value) => {
                  (async () => {
                    const hasAll = value.includes(-1);
                    if (hasAll && value.length > 1) {
                      value = value.filter((item) => item !== -1);
                    }

                    if (value.length === 0) {
                      value = [];
                    }
                    setSelectedRoutes(value);
                  })();
                }}
              />
            </div>
          </>
        )}
        <Button
          type="primary"
          onClick={() => handleApply()}
          style={{ width: '10rem' }}
        >
          Aplicar
        </Button>
      </Modalx>
    </>
  );
};

export default MapConfig;
