import './index.css';
import { FC, useEffect, useRef, useState } from 'react';
import { Avatar, Badge, Button, Col, Empty, Input, List, Row } from 'antd';
import { CloseOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Chat } from '../../../../domain/models/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { closeChat, toggleChat } from '../../../store/chat/chat.actions';
import useMessage from '../../../hooks/useMessage';
import { Message } from '../../../../domain/models/Message';
import { chatService } from '../../../../domain/services/Chat.service';
import { scroller } from 'react-scroll';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';
import {
  selectCollapsedChatRightPanel,
  selectCollapsedOnlineTrackingRightPanel,
} from '../../../store/ui/ui.selectors';
import ucfirst from '../../../libs/ucfirst';
import measurements from '../../../const/measurements';
import useUserOnline from '../../../hooks/useUserOnline';
import emojiRegex from 'emoji-regex';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../firebase/firebase';
import { instance } from '../../../http/axios';
import { INFO_APP } from '../../../const/info';

type ChatItemProps = {
  chat: Chat;
  index: number;
};

const EmojiPicker: FC<any> = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    new Picker({ ...props, data, ref });
  }, []);

  return <div ref={ref} />;
};

const BubbleChatItem: FC<ChatItemProps> = ({ chat, index }) => {
  const [loading, setLoading] = useState(true);
  const [distance, setDistance] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoadedMore, setIsLoadedMore] = useState(false);
  const refTxt = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [txtMessage, setTxtMessage] = useState('');
  const currentUser = useSelector(selectCurrentUser);
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setChatId] = useMessage();
  const isCollapsedRightChatPanel = useSelector(selectCollapsedChatRightPanel);
  const dispatch = useDispatch();
  const user = chat.users.find((user) => user.user_id === currentUser?.user_id);
  const toUser = chat.users.find(
    (user) => user.user_id !== currentUser?.user_id
  );
  const [isOnline] = useUserOnline(toUser?.user_id);
  const isCollapsedRightTrackingPanel = useSelector(
    selectCollapsedOnlineTrackingRightPanel
  );
  const [typingTimer, setTypingTimer] = useState<any>(null);

  const handleCloseChat = (chat: Chat) => {
    logEvent(analytics, 'close_bubble_chat', {
      chatId: chat.id,
      users: chat.id_users,
      exists: chat.exists,
      instance,
      version: INFO_APP.VERSION,
      userId: currentUser?.user_id,
      groupId: currentUser?.user_group_id,
    });
    dispatch(closeChat(chat.id));
  };

  const handleToggleChat = (chat: Chat) => {
    if (user?.window_collapsed) {
      logEvent(analytics, 'open_collapsed_bubble_chat', {
        chatId: chat.id,
        users: chat.id_users,
        exists: chat.exists,
        instance,
        version: INFO_APP.VERSION,
        userId: currentUser?.user_id,
        groupId: currentUser?.user_group_id,
      });
    } else {
      logEvent(analytics, 'close_collapsed_bubble_chat', {
        chatId: chat.id,
        users: chat.id_users,
        exists: chat.exists,
        instance,
        version: INFO_APP.VERSION,
        userId: currentUser?.user_id,
        groupId: currentUser?.user_group_id,
      });
    }
    if (currentUser) dispatch(toggleChat(chat.id, currentUser?.user_id));
  };

  const handleSendMessage = async (isSendedMessage: boolean) => {
    if (
      isSendedMessage &&
      (txtMessage.length >= 2 || txtMessage !== '\n') &&
      currentUser
    ) {
      const newMessage = txtMessage;
      setTxtMessage('');
      setShowEmojiPicker(false);
      try {
        if (!chat.exists) {
          const newId = await chatService.createChat(chat);
          chat.id = newId;
          chat.exists = true;
        }

        chat.last_message = newMessage;
        logEvent(analytics, 'send_message_bubble_chat', {
          chatId: chat.id,
          users: chat.id_users,
          exists: chat.exists,
          message: newMessage,
          instance,
          version: INFO_APP.VERSION,
          userId: currentUser?.user_id,
          groupId: currentUser?.user_group_id,
        });
        await chatService.sendMessage(chat, newMessage, currentUser?.user_id);
      } catch (e) {
        console.error(e);
      }
    } else if (isSendedMessage) {
      setTxtMessage('');
    }
  };

  const handleFocusTextarea = async () => {
    if (user && !user.is_view && user.window_collapsed) {
      await chatService.readMessage(chat, user.user_id);
    }
  };

  const handleWrite = async (isWrite: boolean) => {
    if (user && chat.exists) {
      await chatService.sendWriting(chat, user.user_id, isWrite);
    }
  };

  const handleLoadMoreMessages = async () => {
    if (!hasMoreMessages) return;
    setLoading(true);
    setIsLoadedMore(true);
    const copyMessages = [...messages].sort(
      (a, b) => a.timestamp - b.timestamp
    );

    const lastMessageId = messages[0].id;
    const newMessages = await chatService.loadMoreMessagesBetween(
      chat.id,
      messages[0].id,
      messages[0].timestamp
    );
    setMessages([...newMessages, ...messages]);
    /* setMessages((messages) =>
      messages.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.id === thing.id)
      )
    ); */
    setLoading(false);
    if (newMessages.length === 0) {
      setHasMoreMessages(false);
    }
    scroller.scrollTo(lastMessageId, {
      duration: 0,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'scrollChat-' + chat.id,
    });
    //setIsLoadedMore(false);
  };

  useEffect(() => {
    chatService.loadMessagesByChat(chat.id).then((messages) => {
      setMessages(messages);
      setLoading(false);
    });
    return () => {};
  }, []);

  useEffect(() => {
    setChatId(chat.id);
  }, [chat]);

  useEffect(() => {
    if (message.event === 'added') {
      const exists = messages.findIndex((m) => m.id === message.message.id);
      if (exists === -1) {
        setMessages([...messages, message.message]);
      }
    }

    if (message.event === 'modified') {
      setMessages([
        ...messages.map((m) => {
          if (m.id === message.message.id) {
            m = message.message;
          }
          return m;
        }),
      ]);
    }

    if (message.event === 'removed') {
      setMessages([...messages.filter((m) => m.id !== message.message.id)]);
    }
  }, [message, dispatch, messages]);

  useEffect(() => {
    if (messages.length > 0 && !isLoadedMore) {
      const lastMessage = messages[messages.length - 1];
      scroller.scrollTo(lastMessage.id, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scrollChat-' + chat.id,
      });
    }

    if (isLoadedMore) {
      setIsLoadedMore(false);
    }
  }, [messages, user?.window_collapsed]);

  useEffect(() => {
    if (isCollapsedRightChatPanel && isCollapsedRightTrackingPanel) {
      setDistance(index * 310 + 1);
    } else {
      if (!isCollapsedRightChatPanel) {
        setDistance(index * 310 + measurements.rightPanel.collapsible.width);
      }

      if (!isCollapsedRightTrackingPanel) {
        setDistance(
          index * 310 + measurements.rightPanel.collapsible.trackingWidth
        );
      }
    }
  }, [isCollapsedRightChatPanel, isCollapsedRightTrackingPanel, index]);

  if (distance === 0) return null;

  const lastMessage = () => {
    let list = messages.slice();
    list.sort((a, b) => b.timestamp - a.timestamp);
    return list[0]?.message || '';
  };

  return (
    <div
      className="window_chat_item"
      style={{
        height: user?.window_collapsed ? '20rem' : '50px',
        marginRight: distance,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Row
        style={{
          height: user?.window_collapsed ? '40px' : '50px',
          borderBottom: '1px solid #5d687b',
        }}
      >
        <Col span={5} style={{ color: 'white' }}>
          <Badge
            count={user?.is_view ? 0 : 1}
            dot
            status={'error'}
            size="default"
            offset={[-28, 5]}
          >
            {toUser?.picture ? (
              <Avatar
                shape="circle"
                src={toUser?.picture}
                style={{ color: '#fff !important' }}
              />
            ) : (
              <Avatar
                shape="circle"
                icon={<UserOutlined />}
                style={{ color: '#fff !important' }}
              />
            )}
          </Badge>
        </Col>
        <Col
          span={13}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isOnline ? 'center' : 'start',
          }}
          onClick={() => handleToggleChat(chat)}
        >
          <span
            style={{
              overflow: 'hidden',
              maxWidth: '30ch',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: '#fff',
            }}
          >
            {ucfirst(toUser?.firstname.toLocaleLowerCase() ?? '')}{' '}
            {ucfirst(toUser?.lastname.toLocaleLowerCase() ?? '')}
          </span>
          <small
            style={{
              fontSize: '9px',
              color: '#ddd',
              overflow: 'hidden',
              maxWidth: '30ch',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {toUser?.is_write ? <>Escribiendo...</> : <>{lastMessage()}</>}
          </small>
        </Col>
        <Col
          span={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'end',
          }}
        >
          <Badge dot status={isOnline ? 'success' : 'default'} size="default" />
        </Col>
        <Col
          span={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            type="text"
            size="small"
            shape="circle"
            style={{ color: '#fff' }}
            icon={<CloseOutlined />}
            onClick={() => handleCloseChat(chat)}
          />
        </Col>
      </Row>
      {user?.window_collapsed && (
        <>
          <div
            id={'scrollChat-' + chat.id}
            style={{
              height: '100%',
              overflowY: 'auto',
              flex: 1,
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={handleLoadMoreMessages}
              inverse={true}
              hasMore={hasMoreMessages}
              loader={<></>}
              endMessage={<></>}
              scrollableTarget={'scrollChat-' + chat.id}
            >
              <List
                dataSource={messages}
                itemLayout="horizontal"
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{
                        color: '#fff',
                      }}
                      description={<span>No hay mensajes</span>}
                    />
                  ),
                }}
                renderItem={(item, index) => {
                  let fontSize = 14;
                  if (
                    !item.message.match('[a-zA-Z0-9]+') &&
                    emojiRegex().test(item.message) &&
                    item.message.match(emojiRegex())
                  ) {
                    if (item.message.match(emojiRegex())!.length <= 3) {
                      fontSize = 24;
                    }
                  }
                  return (
                    <div
                      key={`${item.id}-${index}`}
                      style={{
                        width: '80%',
                        float: `${
                          item.user_from_id === currentUser?.user_id
                            ? 'right'
                            : 'left'
                        }`,
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          padding: '5px',
                          border: `1px solid ${
                            item.user_from_id === currentUser?.user_id
                              ? '#ccfe9a'
                              : '#ddd'
                          }`,
                          backgroundColor: `${
                            item.user_from_id === currentUser?.user_id
                              ? '#ccfe9a'
                              : '#fbfbfb'
                          }`,
                          marginBottom: '.5rem',
                          borderRadius: '.5rem',
                          color: '#000',
                          wordWrap: 'break-word',
                          boxShadow: '0px 2px 5px -2px rgba(0,0,0,0.34)',
                          display: 'flex',
                          flexDirection: 'column',
                          float: `${
                            item.user_from_id === currentUser?.user_id
                              ? 'right'
                              : 'left'
                          }`,
                          maxWidth: '100%',
                        }}
                      >
                        <span style={{ fontSize: fontSize }}>
                          {item.message}
                        </span>
                        <div
                          style={{
                            fontSize: '.6rem',
                            color: '#747d8c',
                            float: 'right',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'end',
                          }}
                        >
                          <small>
                            {moment((item.created_at as any).toDate()).format(
                              'HH:mm a'
                            )}
                          </small>
                        </div>
                      </div>
                      <div
                        style={{ position: 'absolute', bottom: 0 }}
                        id={item.id}
                      ></div>
                    </div>
                  );
                }}
              />
            </InfiniteScroll>
          </div>
          <div
            style={{
              borderTop: '1px solid #5d687b',
              padding: '.2rem',
              position: 'relative',
            }}
          >
            {showEmojiPicker && (
              <div
                style={{ position: 'absolute', top: '-25rem', left: '-5rem' }}
              >
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      zIndex: '100',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setShowEmojiPicker(false);
                      (
                        refTxt.current as any
                      ).resizableTextArea.textArea.focus();
                    }}
                  >
                    <CloseOutlined />
                  </div>
                  <EmojiPicker
                    onEmojiSelect={(ev: any) => {
                      setTxtMessage(
                        (refTxt.current as any).resizableTextArea.textArea
                          .value + ev.native
                      );
                      (
                        refTxt.current as any
                      ).resizableTextArea.textArea.focus();
                      setShowEmojiPicker(false);
                    }}
                  />
                </div>
              </div>
            )}
            <Input.TextArea
              rows={2}
              placeholder="Escribe..."
              style={{
                resize: 'none',
                backgroundColor: 'rgba(0,0,0,0.2)',
                border: '1px solid rgba(0,0,0,0.2)',
                color: '#fff',
              }}
              onKeyUp={(key) => handleSendMessage(key.key === 'Enter')}
              onChange={(val) => {
                setTxtMessage(val.currentTarget.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Escape') {
                  setTxtMessage('');
                  dispatch(closeChat(chat.id));
                  return;
                }

                if (txtMessage.length > 0 && !user.is_write) {
                  handleWrite(true);
                }

                if (typingTimer) {
                  clearTimeout(typingTimer);
                }
                setTypingTimer(
                  setTimeout(() => {
                    handleWrite(false);
                  }, 1000)
                );
              }}
              value={txtMessage}
              disabled={loading}
              ref={refTxt}
              onFocus={handleFocusTextarea}
            />
            <div
              style={{
                position: 'absolute',
                right: 7,
                bottom: 3,
                zIndex: '100',
                color: '#fff !important',
              }}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              <SmileOutlined style={{ color: '#fff !important' }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BubbleChatItem;
