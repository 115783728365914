import { Region } from '../../models/Region';
import * as mapActivityRepo from '../../../infrastructure/repositories/widgets/mapActivity.repository';
import { Zone } from '../../models/Zone';

export const getRegions = async (): Promise<Region[]> => {
  return mapActivityRepo.findAllRegion();
};

export const getCommunesByRegion = async (regionId: number): Promise<any> => {
  return mapActivityRepo.findCommunesByRegion(regionId);
};

export const getAllZones = async (): Promise<Zone[]> => {
  return mapActivityRepo.findAllZones();
};

export const getZonesByRegion = async (regionId: number): Promise<any> => {
  return mapActivityRepo.findZonesByRegion(regionId);
};

export const getZoneById = async (zoneId: number): Promise<any> => {
  return mapActivityRepo.findZoneById(zoneId);
};
