import React from 'react';
import { LogoAnimated2Icon } from '../icons';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import { Themes } from '../../store/ui';

const OnfieldLoading = () => {
  const theme = useSelector(selectUiTheme);
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2001,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          background:
            theme === Themes.LIGH_THEME
              ? 'rgba(216, 216, 216, 0.78)'
              : 'rgba(0, 0, 0, 0.68)',
          zIndex: 2002,
        }}
      ></div>
      <LogoAnimated2Icon width="90px" style={{ zIndex: 2003 }} theme={theme} />
    </div>
  );
};

export default OnfieldLoading;
