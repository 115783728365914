import { FC, useEffect, useState } from 'react';
import { DashboardWidget } from '../../../../domain/models/DashboardWidget';
import ActiveCampaignsWidget from './ActiveCampaignsWidget';
import ActivitiesMapWidget from './ActivitiesMapWidget';
import ActivitiesPerDayWidget from './ActivitiesPerDayWidget';
import ActivitiesWidget from './ActivitiesWidget';
import AverageTimeWidget from './AverageTimeWidget';
import OrdersWidget from './OrdersWidget';
import TasksWidget from './TasksWidget';
import Top10Widget from './Top10Widget';
import UsersOnlineWidget from './UsesOnlineWidget';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';

type Props = {
  widget: DashboardWidget;
  clientsId: number[];
};

const DashboardWidgets: FC<Props> = ({ widget, clientsId }) => {
  const [resourceClientsId, setResourceClientsId] = useState<number[]>([]);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (currentUser) {
      const sw_relacion_cta_contenedora_usuario =
        currentUser?.instance?.parameters.find(
          (p) => p.parameter_id === 'sw_relacion_cta_contenedora_usuario'
        );
      if (
        sw_relacion_cta_contenedora_usuario?.value1 === '1' &&
        !sw_relacion_cta_contenedora_usuario?.value2
          .split(',')
          .includes(`${currentUser?.user_group_id}`)
      ) {
        console.log('entra');
        setResourceClientsId(
          currentUser?.clients?.map((c) => c.id_cliente) || []
        );
      } else {
        setResourceClientsId(clientsId);
      }
    }
  }, [clientsId, currentUser]);

  if (widget.component === 'ActiveCampaignsWidget')
    return <ActiveCampaignsWidget clientsId={resourceClientsId} />;

  if (widget.component === 'ActivitiesMapWidget')
    return <ActivitiesMapWidget clientsId={resourceClientsId} />;

  if (widget.component === 'ActivitiesPerDayWidget')
    return <ActivitiesPerDayWidget clientsId={resourceClientsId} />;

  if (widget.component === 'ActivitiesWidget')
    return <ActivitiesWidget clientsId={resourceClientsId} />;

  if (widget.component === 'AverageTimeWidget')
    return <AverageTimeWidget clientsId={resourceClientsId} />;

  if (widget.component === 'OrdersWidget')
    return <OrdersWidget clientsId={resourceClientsId} />;

  if (widget.component === 'TasksWidget')
    return <TasksWidget clientsId={resourceClientsId} />;

  if (widget.component === 'Top10Widget')
    return <Top10Widget clientsId={resourceClientsId} />;

  if (widget.component === 'UsersOnlineWidget') return <UsersOnlineWidget />;

  return <h1>NOT FOUND {widget.component}</h1>;
};

export default DashboardWidgets;
