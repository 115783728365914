import { EventSourceInput } from '@fullcalendar/react';
import { Modal, notification } from 'antd';
import moment from 'moment-timezone';
import { FC, useEffect, useRef, useState } from 'react';
import { http } from '../../../http/axios';
import Marker from './Marker';
import { AxiosError } from 'axios';
import MarkerStart from './MarkerStart';
import { decode } from '@googlemaps/polyline-codec';
import RoutePlanMap, { IUnAssigned } from './RoutePlanMap';
import JsonRenderDrag from '../../../components/JsonRenderDrag';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { buildEnvironment } from '../../../firebase/firebase';
import JSONEditor from 'jsoneditor';
import { CalendarStatus } from '../../../enums/calendarStatus';
import useUserLocationMap from '../../../hooks/useUserLocationMap';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { VROOM } from '../../../components/JsonRenderDrag/vroom';
import { convertBase64ToFile } from '../../../libs/base64ToFile';
import { saveAs } from 'file-saver';
import { RoutePlanCampaignStatus } from '../../../enums/routePlanCampaignStatus';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { Themes } from '../../../store/ui';
import ChangePerson from './ChangePerson';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import OnfieldLoading from '../../../components/OnfieldLoading';
import ChangePersonColor from './ChangePersonColor';
import GroupedMark from './GroupedMark';
import GroupedMarkerStart from './GroupedMarkerStart';
import Config from './Config';

const waitingTimeToRecalculatePersons = 0;

type Props = {
  defaultCenter: google.maps.LatLng;
  startCalendarDate: string;
  endCalendarDate: string;
  idRoutePlanCampaign: number;
  idRoutePlan: number;
  campaign: any;
  onCalculate: () => void;
  moduloPlanRutas: any;
  skillsNotIncluded?: number[];
};

type GroupedMarkers = {
  position: google.maps.LatLng;
  color: string;
  calendars: any[];
  order: any;
  local: any;
  id: string;
  type: string;
  index: any;
  user?: any;
};

const RoutePlanMapCalendar: FC<Props> = ({
  defaultCenter,
  startCalendarDate,
  endCalendarDate,
  idRoutePlanCampaign,
  idRoutePlan,
  campaign,
  onCalculate,
  moduloPlanRutas,
  skillsNotIncluded,
}) => {
  const [resources, setResources] = useState<any[]>([]);
  const [isOnlyRead, setIsOnlyRead] = useState<boolean>(true);
  const [events, setEvents] = useState<EventSourceInput>([]);
  const [unAssignedEvents, setUnAssignedEvents] = useState<IUnAssigned[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [options, setOptions] = useState<JSONEditor | null>(null);
  const [dataResponse, setDataResponse] = useState<VROOM>({});
  const [polylines, setPolylines] = useState<
    { user: string; polyline: google.maps.Polyline }[]
  >([]);
  const [users, setUsers] = useState<any[]>([]);
  const [selectUser, setSelectUser] = useState<string[]>([]);
  const [usersToRefreshRoute, setUsersToRefreshRoute] = useState<string[]>([]);
  const [calendars, setCalendars] = useState<any[]>([]);
  const [unassigned, setUnassigned] = useState<any[]>([]);
  const [unCalculated, setUnCalculated] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastEventChanged, setLastEventChanged] = useState(null);
  const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
  const changePersonRef = useRef<ChangePerson>(null);
  const changePersonColorRef = useRef<ChangePersonColor>(null);
  const [showUserLocationButton, setShowUserLocationButton] =
    useState<boolean>(false);
  const [jsonResponse, setJsonResponse] = useState<any>({});
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [routePlan, setRoutePlan] = useState<any>(null);
  const calendar = useRef<any>(null);
  const currentUser = useSelector(selectCurrentUser);
  const { setModeChangeLocation, modeChangeLocation, locations } =
    useUserLocationMap(map);
  const [showUnAssigned, setShowUnAssigned] = useState<boolean>(false);
  const [useVroomDebug, setUseVroomDebug] = useState<boolean>(false);
  const [showPolygonDrawer, setShowPolygonDrawer] = useState<boolean>(false);
  const [campaignStatus, setCampaignStatus] = useState<number>(1);
  const [polygonDrawer, setPolygonDrawer] =
    useState<google.maps.Polyline | null>(null);
  const loadingRef = useRef<LoadingBarRef>(null);
  const [dataBoundsMap, setDataBoundsMap] =
    useState<google.maps.LatLngBounds | null>(null);
  const currentTheme = useSelector(selectUiTheme);
  const [waitingTimeRecalculate, setWaitingTimeRecalculate] = useState(
    waitingTimeToRecalculatePersons
  );
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const [shiftIsPressed, setShiftIsPressed] = useState(false);

  const toggleLoadingResource = (userId: string, showLoading: boolean) => {
    const element = document.getElementById(`load_indicator_${userId}`);
    const elementIcon = document.getElementById(`icon_indicator_${userId}`);
    if (element) {
      element.style.display = showLoading ? 'block' : 'none';
    }

    if (elementIcon) {
      elementIcon.style.display = showLoading ? 'none' : 'block';
    }
  };

  const downloadExcel = async () => {
    setLoading(true);
    try {
      const data: any = await http.get(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/excel/${campaign.fecha_agenda}`
      );

      let binary = '';
      let bytes = new Uint8Array(data.data);
      for (let i = 0; i < bytes.length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64string = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${window.btoa(
        binary
      )}`;
      const fileName = 'Plan de rutas.xlsx';
      const file = convertBase64ToFile(base64string, fileName);
      saveAs(file, fileName);
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al descargar excel',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadDataUnConfirmed = async () => {
    setLoading(true);
    try {
      const data: {
        calendars: any[];
        campaign: any;
        users: any[];
        unassigned: any[];
        blocks: any[];
      } = await http.get(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/persons/${CalendarStatus.SCHEDULED}`
      );
      const dataBounds = new google.maps.LatLngBounds();

      setUsers(data.users);
      setResources(
        data.users.map((item: any, index) => {
          dataBounds.extend(
            new google.maps.LatLng(
              parseFloat(item.origen_lat),
              parseFloat(item.origen_lng)
            )
          );
          item.selected = false;
          return {
            id: `${item.user.user_id}`,
            title: `${item.user.firstname} ${item.user.lastname} ${
              item.user.group?.abreviacion
                ? `(${item.user.group.abreviacion})`
                : ''
            }`,
            extendedProps: item,
            order: index,
            showRefreshLocation: skillsNotIncluded?.includes(item.user.id_grupo)
              ? false
              : true,
            showRefreshRoute: skillsNotIncluded?.includes(item.user.id_grupo)
              ? false
              : true,
            showRemove: false,
          };
        })
      );
      setEvents([
        ...data.calendars
          .filter(
            (item) =>
              (item.calendar ? item.calendar.order : item.order) !== null
          )
          .map((item: any) => {
            return {
              id: `${
                item.calendar ? item.calendar.id_agenda : item.order.id_pedido
              }`,
              start: moment(item.fechahora_ini).toDate(),
              end: moment(item.fechahora_fin).toDate(),
              title: `<strong>${
                (item.calendar ? item.calendar.order : item.order).id_pedido
              } - ${(item.calendar
                ? item.calendar.order
                : item.order
              ).local.commune.nombre.toUpperCase()}</strong>`,
              extraParams: {
                description: `
								${item.sku ? `${item.sku}<br />` : ''}
								<strong>${
                  (item.calendar ? item.calendar.order : item.order).local
                    .nombre
                }</strong><br />
								<small>${(item.calendar ? item.calendar.order : item.order).local.direccion}, ${
                  (item.calendar ? item.calendar.order : item.order).local
                    .direccion2
                }</small>
							`,
                distance: null,
                index: null,
                tipo: item.tipoPedido ?? null,
                block: false,
                modules: item?.calendar?.modulos || null,
              },
              resourceId: `${item.user.user_id}`,
            };
          }),
        ...data.blocks.map((item: any) => {
          return {
            id: `${item.id_agenda}`,
            start: moment(item.fechahora_ini).toDate(),
            end: moment(item.fechahora_fin).toDate(),
            title: item.motivo_bloqueo,
            extraParams: null,
            resourceId: `${item.user_id}`,
            backgroundColor: '#ddd',
            borderColor: '#ddd',
            textColor: '#000',
          };
        }),
      ]);

      const groupedCalendarMarkers: GroupedMarkers[] = [];
      const groupedUnAssignedMarkers: GroupedMarkers[] = [];
      const groupedUserMarkers: GroupedMarkers[] = [];

      for (const calendar of data.calendars.filter(
        (c) => !skillsNotIncluded?.includes(c.user.id_grupo)
      )) {
        const user = data.users.find(
          (u) => u.user_id === calendar.user.user_id
        );

        const latLng = new google.maps.LatLng(
          parseFloat(
            (calendar.calendar ? calendar.calendar.order : calendar.order).local
              .latitud
          ),
          parseFloat(
            (calendar.calendar ? calendar.calendar.order : calendar.order).local
              .longitud
          )
        );
        // eslint-disable-next-line no-loop-func
        const idxOtherNearMarker = groupedCalendarMarkers.find((g) => {
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              latLng,
              g.position
            );
          return distance < 10;
        });

        if (!idxOtherNearMarker) {
          groupedCalendarMarkers.push({
            id: `Point_${
              calendar.calendar
                ? calendar.calendar.id_agenda
                : calendar.order.id_pedido
            }`,
            position: latLng,
            color: user?.color || '#7f8c8d',
            order: calendar.order,
            type: 'Point',
            calendars: [],
            local: calendar.order.local,
            index: null,
          });
        } else {
          groupedCalendarMarkers[
            groupedCalendarMarkers.indexOf(idxOtherNearMarker)
          ].calendars.push({ ...calendar, color: user?.color || '#7f8c8d' });
        }
      }

      for (const unassign of data.unassigned) {
        const latLng = new google.maps.LatLng(
          parseFloat(unassign.order.local.latitud),
          parseFloat(unassign.order.local.longitud)
        );

        // eslint-disable-next-line no-loop-func
        const idxOtherNearMarker = groupedUnAssignedMarkers.find((g) => {
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              latLng,
              g.position
            );
          return distance < 10;
        });

        if (!idxOtherNearMarker) {
          groupedUnAssignedMarkers.push({
            id: `Unassigned_${unassign.order.local.id_local}`,
            position: latLng,
            color: '#7f8c8d',
            order: unassign.order,
            type: 'Point',
            calendars: [],
            local: unassign.order.local,
            index: null,
          });
        } else {
          groupedUnAssignedMarkers[
            groupedUnAssignedMarkers.indexOf(idxOtherNearMarker)
          ].calendars.push(unassign);
        }
      }

      for (const user of data.users.filter(
        (u) => !skillsNotIncluded?.includes(u.user.id_grupo)
      )) {
        const latLng = new google.maps.LatLng(
          parseFloat(user.origen_lat),
          parseFloat(user.origen_lng)
        );

        // eslint-disable-next-line no-loop-func
        const idxOtherNearUser = groupedUserMarkers.find((g) => {
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              latLng,
              g.position
            );
          return distance < 10;
        });

        if (!idxOtherNearUser) {
          groupedUserMarkers.push({
            position: latLng,
            id: `Point_User_${user.user_id}`,
            color: user.color,
            local: null,
            order: user,
            calendars: [],
            type: 'User',
            index: null,
          });
        } else {
          groupedUserMarkers[
            groupedUserMarkers.indexOf(idxOtherNearUser)
          ].calendars.push(user);
        }
      }

      setMarkers([
        ...groupedCalendarMarkers.map((item) => {
          dataBounds.extend(item.position);
          return (
            <Marker
              position={item.position}
              color={item.color}
              order={item.order}
              local={item.order.local}
              key={item.order.local.id_local}
              type="Point"
              id={`Point_${item.id}`}
            />
          );
        }),
        ...groupedUserMarkers.map((user, index) => {
          if (user.calendars.length === 0)
            return (
              <MarkerStart
                position={user.position}
                color={user.color}
                user={user.order}
                key={user.id}
              />
            );

          return (
            <GroupedMarkerStart
              position={user.position}
              color={'#7f8c8d'}
              user={user.order}
              key={user.id}
              groups={user.calendars}
              onClick={(e) => {
                console.log(e);
              }}
            />
          );
        }),
        ...data.users.map((user) => (
          <Marker
            position={
              new google.maps.LatLng(
                parseFloat(user.destino_lat),
                parseFloat(user.destino_lng)
              )
            }
            color={'black'}
            key={user.user_id}
          />
        )),
        ...groupedUnAssignedMarkers.map((item) => {
          if (item.calendars.length === 0) {
            return (
              <Marker
                position={item.position}
                color={'#7f8c8d'}
                order={item.order}
                local={item.order.local}
                key={item.id}
              />
            );
          }

          return (
            <GroupedMark
              position={item.position}
              color="#7f8c8d"
              key={item.id}
              id={item.id}
              order={{ ...item.order, color: '#7f8c8d' }}
              groups={item.calendars}
              idNumber={null}
            />
          );
        }),
      ]);

      setUnAssignedEvents(
        data.unassigned.map((i) => {
          dataBounds.extend(
            new google.maps.LatLng(
              parseFloat(
                (i.calendar ? i.calendar.order : i.order).local.latitud
              ),
              parseFloat(
                (i.calendar ? i.calendar.order : i.order).local.longitud
              )
            )
          );
          return {
            id: i.id_plan_ruta_no_asignado,
            title: `<strong>${
              i.calendar ? i.calendar.order.id_pedido : i.order.valor1
            } - ${i.order.local.commune.nombre.toUpperCase()}</strong>`,
            description: `
							${i.sku ? `${i.sku}<br />` : ''}
							${i.order.local.nombre}<br />
							<small>${i.order.local.direccion}, ${i.order.local.direccion2}</small><br />
							<span>${i.tipoPedido || ''}</span>
						`,
            modulos: i.calendar ? i.calendar.modulos : 1,
          } as any;
        })
      );
      setCalendars(data.calendars);
      setDataBoundsMap(dataBounds);
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al cargar la información',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadDataPreConfirmed = async (personId = -1) => {
    //setLoading(true);
    if (loadingRef.current) loadingRef.current.continuousStart(0, 1000);
    try {
      const data: {
        calendars: any[];
        campaign: any;
        users: any[];
        blocks: any[];
        unassigned: any[];
      } = await http.get(`/route-plans/${idRoutePlan}/persons`);
      setRoutePlan(data.campaign.routePlan);
      console.log(data);

      const dataBounds = new google.maps.LatLngBounds();
      const userWithGeometry = data.users.filter(
        (user) => user.vroom_response !== null
      );

      if (campaign.estado === RoutePlanCampaignStatus.CALCULATED) {
        const unCalculatedUsers = [];
        for (const user of data.users) {
          if (
            user.sync === 0 &&
            !skillsNotIncluded?.includes(user.user.id_grupo)
          ) {
            unCalculatedUsers.push(user);
          }
        }
        setUnCalculated(unCalculatedUsers);
      }

      setUsers(data.users);
      setCalendars(data.calendars);
      setUnassigned(data.unassigned);
      setResources(
        data.users.map((user, index) => {
          dataBounds.extend(
            new google.maps.LatLng(
              parseFloat(user.origen_lat),
              parseFloat(user.origen_lng)
            )
          );
          user.selected = false;
          if (skillsNotIncluded?.includes(user.user.id_grupo)) {
            const modules =
              data.calendars
                .filter((c) => c.user.user_id === user.user_id)
                .reduce((acc, c) => acc + (c?.calendar?.modulos || 0), 0) *
              3600;
            user.service = modules;
          }
          return {
            id: `${user.user_id}`,
            title: `${user.user.firstname} ${user.user.lastname} ${
              user.user.group?.abreviacion
                ? `(${user.user.group?.abreviacion})`
                : ''
            }`,
            extendedProps: user,
            order: index,
            showRefreshLocation: skillsNotIncluded?.includes(user.user.id_grupo)
              ? false
              : campaign.estado !== RoutePlanCampaignStatus.CONFIRMED,
            showRefreshRoute: skillsNotIncluded?.includes(user.user.id_grupo)
              ? false
              : campaign.estado !== RoutePlanCampaignStatus.CONFIRMED,
            showTooltipRefresh: user.sync === 0,
            showRemove:
              data.calendars.filter((c) => c.user.user_id === user.user_id)
                .length === 0,
          };
        })
      );

      setUnAssignedEvents(
        data.unassigned.map(
          (i) =>
            ({
              id: i.id_plan_ruta_no_asignado,
              title: `<strong>${
                i.calendar ? i.calendar.order.id_pedido : i.order.valor1
              } - ${i.order.local.commune.nombre.toUpperCase()}</strong>`,
              description: `
							${i.sku ? `${i.sku}<br />` : ''}
							${i.order.local.nombre}<br />
							<small>${i.order.local.direccion}, ${i.order.local.direccion2}</small><br />
							<span>${i.tipoPedido || ''}</span>
						`,
              modulos: i.calendar ? i.calendar.modulos : 1,
            } as any)
        )
      );

      if (personId === -1) {
        setEvents([
          ...data.calendars
            .sort((a, b) => a.user.user_id - b.user.user_id)
            .filter(
              (a) =>
                campaignStatus === RoutePlanCampaignStatus.CONFIRMED ||
                skillsNotIncluded?.includes(a.user.id_grupo) ||
                a.position !== null
            )
            .filter(
              (item) =>
                (item.calendar ? item.calendar.order : item.order) !== null
            )
            .map((calendar: any) => {
              const dataCalendar = {
                id: `${
                  calendar.calendar
                    ? calendar.calendar.id_agenda
                    : calendar.order.id_pedido
                }`,
                start: moment(new Date(calendar.fechahora_ini)).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                end: moment(new Date(calendar.fechahora_fin)).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                title: `${calendar.fieldTitle}`,
                extraParams: {
                  service:
                    (moment(new Date(calendar.fechahora_fin)).valueOf() -
                      moment(new Date(calendar.fechahora_ini)).valueOf()) /
                    1000,
                  duration: calendar.duration,
                  description: `${calendar.fieldName}${calendar.fieldAddress}`,
                  distance: calendar.distance,
                  weight:
                    typeof calendar.order !== 'undefined'
                      ? parseFloat(calendar.order.valor3)
                      : undefined,
                  index: skillsNotIncluded?.includes(calendar.user.id_grupo)
                    ? null
                    : calendar.position,
                  tipo: `${calendar.fieldFooter}`,
                  confirmation: calendar.calendar
                    ? calendar.calendar.confirmado
                    : undefined,
                  block: false,
                  modules: calendar?.calendar?.modulos || null,
                },
                resourceId: `${calendar.user.user_id}`,
              };
              return dataCalendar;
            }),
          ...data.blocks.map((item: any) => {
            return {
              id: `${item.id_agenda}`,
              start: moment(new Date(item.fechahora_ini)).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              end: moment(new Date(item.fechahora_fin)).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              title: item.motivo_bloqueo,
              extraParams: null,
              resourceId: `${item.user_id}`,
              backgroundColor: '#ddd',
              borderColor: '#ddd',
              textColor: '#000',
            };
          }),
        ]);

        const groupedCalendarMarkers: GroupedMarkers[] = [];
        const groupedUserMarkers: GroupedMarkers[] = [];

        for (const calendar of data.calendars.filter(
          (c) => !skillsNotIncluded?.includes(c.user.id_grupo)
        )) {
          const user = data.users.find(
            (u) => u.user_id === calendar.user.user_id
          );

          const latLng = new google.maps.LatLng(
            parseFloat(
              (calendar.calendar ? calendar.calendar.order : calendar.order)
                .local.latitud
            ),
            parseFloat(
              (calendar.calendar ? calendar.calendar.order : calendar.order)
                .local.longitud
            )
          );
          // eslint-disable-next-line no-loop-func
          const idxOtherNearMarker = groupedCalendarMarkers.find((g) => {
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                latLng,
                g.position
              );
            return distance < 10;
          });

          if (!idxOtherNearMarker) {
            groupedCalendarMarkers.push({
              id: `Point_${
                calendar.calendar
                  ? calendar.calendar.id_agenda
                  : calendar.order.id_pedido
              }`,
              position: latLng,
              color: user?.color || '#7f8c8d',
              order: calendar.order,
              type: 'Point',
              calendars: [],
              local: calendar.order.local,
              index: calendar.position,
              user: calendar.user,
            });
          } else {
            groupedCalendarMarkers[
              groupedCalendarMarkers.indexOf(idxOtherNearMarker)
            ].calendars.push({ ...calendar, color: user?.color || '#7f8c8d' });
          }
        }

        for (const user of data.users.filter(
          (u) => !skillsNotIncluded?.includes(u.user.id_grupo)
        )) {
          const latLng = new google.maps.LatLng(
            parseFloat(user.origen_lat),
            parseFloat(user.origen_lng)
          );
          // eslint-disable-next-line no-loop-func
          const idxOtherNearUser = groupedUserMarkers.find((g) => {
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                latLng,
                g.position
              );
            return distance < 10;
          });

          if (!idxOtherNearUser) {
            groupedUserMarkers.push({
              position: latLng,
              id: `Point_User_${user.user_id}`,
              color: user.color,
              local: null,
              order: user,
              calendars: [],
              type: 'User',
              index: null,
            });
          } else {
            groupedUserMarkers[
              groupedUserMarkers.indexOf(idxOtherNearUser)
            ].calendars.push(user);
          }
        }

        setMarkers([
          ...groupedCalendarMarkers
            .sort((a, b) => a.user.user_id - b.user.user_id)
            .filter((item) => item.order !== null)
            .map((item, idx: number) => {
              let dataCalendar = (
                <Marker
                  position={item.position}
                  color={item.color}
                  local={item.order.local}
                  order={item.order}
                  key={item.id}
                  idNumber={item.index}
                  type="Point"
                  id={item.id}
                />
              );

              if (item.calendars.length > 0) {
                dataCalendar = (
                  <GroupedMark
                    color={item.color || '#7f8c8d'}
                    groups={item.calendars}
                    id={item.id}
                    position={item.position}
                    order={{ ...item.order, color: item.color }}
                    key={item.id}
                    idNumber={item.index}
                  />
                );
              }

              return dataCalendar;
            }),
          ...groupedUserMarkers.map((user, idx) => {
            if (user.calendars.length === 0)
              return (
                <MarkerStart
                  position={user.position}
                  color={user.color}
                  user={user.order}
                  key={user.id}
                />
              );
            return (
              <GroupedMarkerStart
                color="#7f8c8d"
                groups={user.calendars}
                position={user.position}
                user={user.order}
                key={user.id}
              />
            );
          }),
          ...data.users
            .filter((u) => !skillsNotIncluded?.includes(u.user.id_grupo))
            .map((user) => (
              <Marker
                position={
                  new google.maps.LatLng(
                    parseFloat(user.destino_lat),
                    parseFloat(user.destino_lng)
                  )
                }
                color={'black'}
                key={user.user_id}
              />
            )),
          ...data.unassigned.map((item: any, idx) => (
            <Marker
              position={
                new google.maps.LatLng(
                  (item.calendar
                    ? item.calendar.order
                    : item.order
                  ).local.latitud,
                  (item.calendar
                    ? item.calendar.order
                    : item.order
                  ).local.longitud
                )
              }
              color={'#7f8c8d'}
              local={(item.calendar ? item.calendar.order : item.order).local}
              order={(item.calendar ? item.calendar.order : item.order).order}
              key={`${
                (item.calendar ? item.calendar.order : item.order).local
                  .id_local
              }-${idx}-unassigned`}
            />
          )),
        ]);

        setPolylines(
          userWithGeometry.map((user) => {
            const coords = decode(user.vroom_response).map((coord: any) => ({
              lat: coord[0],
              lng: coord[1],
            }));
            coords.forEach((coord) => {
              dataBounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
            });

            return {
              user: user.user_id,
              polyline: new google.maps.Polyline({
                path: coords,
                geodesic: true,
                strokeColor: user.color,
                strokeOpacity: 1.0,
                strokeWeight: 6,
              }),
            };
          })
        );
      } else {
        const user = data.users.find(
          (u) => u.id_plan_ruta_persona === personId
        );

        const elementScrollCalendar =
          document.getElementsByClassName('fc-scroller')[3];
        const currentScrollCalendar = elementScrollCalendar?.scrollTop || 0;

        if (user) {
          setEvents((oldEvents: any) => [
            ...oldEvents.filter(
              (ev: any) => ev.resourceId !== `${user.user_id}`
            ),
            ...data.calendars
              .filter((c) => c.user.user_id === user.user_id)
              .filter(
                (item) =>
                  (item.calendar ? item.calendar.order : item.order) !== null
              )
              .map((calendar: any) => {
                const dataCalendar = {
                  id: `${
                    calendar.calendar
                      ? calendar.calendar.id_agenda
                      : calendar.order.id_pedido
                  }`,
                  start: moment(new Date(calendar.fechahora_ini)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  end: moment(new Date(calendar.fechahora_fin)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  title: calendar.calendar
                    ? `<strong>${
                        (calendar.calendar
                          ? calendar.calendar.order
                          : calendar.order
                        ).id_pedido
                      } - ${(calendar.calendar
                        ? calendar.calendar.order
                        : calendar.order
                      ).local.commune.nombre.toUpperCase()}</strong>`
                    : `
								<strong>${
                  calendar.order.valor1
                } - ${calendar.order.local.commune.nombre.toUpperCase()}</strong>
								`,
                  extraParams: {
                    service:
                      (moment(new Date(calendar.fechahora_fin)).valueOf() -
                        moment(new Date(calendar.fechahora_ini)).valueOf()) /
                      1000,
                    duration: calendar.duration,
                    description: calendar.calendar
                      ? `
											${calendar.sku ? `${calendar.sku}<br />` : ''}
											<strong>${
                        (calendar.calendar
                          ? calendar.calendar.order
                          : calendar.order
                        ).local.nombre
                      }</strong><br />
											<small>${
                        (calendar.calendar
                          ? calendar.calendar.order
                          : calendar.order
                        ).local.direccion
                      }, ${
                          (calendar.calendar
                            ? calendar.calendar.order
                            : calendar.order
                          ).local.direccion2
                        }</small>
										`
                      : `
											<strong>${calendar.order.local.nombre}</strong> | ${
                          calendar.order.local.valor2
                        } <br />
											<small>${calendar.order.local.direccion}${
                          calendar.order.local.direccion2
                            ? `, ${calendar.order.local.direccion2}`
                            : ''
                        }</small>
										`,
                    distance: calendar.distance,
                    weight:
                      typeof calendar.order !== 'undefined'
                        ? parseFloat(calendar.order.valor3)
                        : undefined,
                    index: skillsNotIncluded?.includes(calendar.user.id_grupo)
                      ? null
                      : calendar.position,
                    tipo: calendar.calendar
                      ? calendar.tipoPedido ?? null
                      : calendar.order.local.valor4
                      ? `${calendar.order.local.valor4}`.toUpperCase()
                      : null,
                    confirmation: calendar.calendar
                      ? calendar.calendar.confirmado
                      : undefined,
                    block: false,
                    modules: calendar?.calendar?.modulos || null,
                  },
                  resourceId: `${calendar.user.user_id}`,
                };
                return dataCalendar;
              }),
            ...data.blocks
              .filter((b) => b.user_id === user.user_id)
              .map((item: any) => {
                return {
                  id: `${item.id_agenda}`,
                  start: moment(new Date(item.fechahora_ini)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  end: moment(new Date(item.fechahora_fin)).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  title: item.motivo_bloqueo,
                  extraParams: null,
                  resourceId: `${item.user_id}`,
                  backgroundColor: '#ddd',
                  borderColor: '#ddd',
                  textColor: '#000',
                };
              }),
          ]);
        }

        setMarkers([
          ...data.calendars
            .filter((c) => !skillsNotIncluded?.includes(c.user.id_grupo))
            .filter((c) => {
              if (selectUser) {
                return `${c.user.user_id}` === `${selectUser}`;
              }
              return true;
            })
            .sort((a, b) => a.user.user_id - b.user.user_id)
            .filter(
              (item) =>
                (item.calendar ? item.calendar.order : item.order) !== null
            )
            .map((item: any, idx: number) => {
              const user = data.users.find(
                (u) => u.user_id === item.user.user_id
              );
              const dataCalendar = (
                <Marker
                  position={
                    new google.maps.LatLng(
                      (item.calendar
                        ? item.calendar.order
                        : item.order
                      ).local.latitud,
                      (item.calendar
                        ? item.calendar.order
                        : item.order
                      ).local.longitud
                    )
                  }
                  color={user ? user.color : '#7f8c8d'}
                  local={
                    (item.calendar ? item.calendar.order : item.order).local
                  }
                  key={`${
                    (item.calendar ? item.calendar.order : item.order).local
                      .id_local
                  }-${idx}`}
                  order={item.calendar ? item.calendar.order : item.order}
                  idNumber={item.position}
                  type="Point"
                  id={`Point_${
                    item.calendar
                      ? item.calendar.id_agenda
                      : item.order.id_pedido
                  }`}
                />
              );
              return dataCalendar;
            }),
          ...data.users
            .filter((u) => !skillsNotIncluded?.includes(u.user.id_grupo))
            .filter((c) => {
              if (selectUser) {
                return `${c.user.user_id}` === `${selectUser}`;
              }
              return true;
            })
            .map((user, idx) => (
              <MarkerStart
                position={
                  new google.maps.LatLng(
                    parseFloat(user.origen_lat),
                    parseFloat(user.origen_lng)
                  )
                }
                color={user.color}
                user={user}
                key={`${user.user_id}-${idx}`}
              />
            )),
          ...data.users
            .filter((u) => !skillsNotIncluded?.includes(u.user.id_grupo))
            .filter((c) => {
              if (selectUser) {
                return `${c.user.user_id}` === `${selectUser}`;
              }
              return true;
            })
            .map((user) => (
              <Marker
                position={
                  new google.maps.LatLng(
                    parseFloat(user.destino_lat),
                    parseFloat(user.destino_lng)
                  )
                }
                color={'black'}
                key={user.user_id}
              />
            )),
          ...data.unassigned.map((item: any, idx) => (
            <Marker
              position={
                new google.maps.LatLng(
                  item.order.local.latitud,
                  item.order.local.longitud
                )
              }
              color={'#7f8c8d'}
              local={item.order.local}
              order={item.order}
              key={`${item.order.local.id_local}-${idx}-unassigned`}
            />
          )),
        ]);

        setPolylines((polyline) => [
          ...polyline.filter((p) => p.user !== user.user_id),
          ...userWithGeometry
            .filter((u) => u.user_id === user.user_id)
            .map((u) => {
              const coords = decode(user.vroom_response).map((coord: any) => ({
                lat: coord[0],
                lng: coord[1],
              }));
              return {
                user: user.user_id,
                polyline: new google.maps.Polyline({
                  path: coords,
                  geodesic: true,
                  strokeColor: user.color,
                  strokeOpacity: 1.0,
                  strokeWeight: 6,
                }),
              };
            }),
        ]);
        setTimeout(() => {
          elementScrollCalendar.scrollTop = currentScrollCalendar;
        }, 100);

        toggleLoadingResource(user.user_id, false);
      }

      if (personId === -1) {
        setDataBoundsMap(dataBounds);
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al cargar la información',
        description: message,
      });
    } finally {
      setLoading(false);
      if (loadingRef.current) loadingRef.current.complete();
    }
  };

  // Muestra marcadores, rutas y personas en el mapa
  const toggleMarkersAndRoutesByUser = (userId: string) => {
    if (campaignStatus > 1) {
      const userSelectedIdx = selectUser.find((u) => u === userId);
      if (!userSelectedIdx && (shiftIsPressed || selectUser.length === 0)) {
        const selectedUsers = [...selectUser, userId];
        setSelectUser(selectedUsers);
      } else {
        if (!userSelectedIdx) {
          setSelectUser([]);
        } else {
          const selectedUsers = selectUser.filter((u) => u !== userId);
          setSelectUser(selectedUsers);
        }
      }
    }
  };

  // Recalcula la ruta de un usuario
  const refreshRoutePerson = async (
    idRoutePerson: number,
    recalculate = true,
    fullRefresh = false,
    reload = true
  ) => {
    if (loadingRef.current && reload)
      loadingRef.current.continuousStart(0, 1000);
    //setLoading(true);
    const user = users.find((u) => u.id_plan_ruta_persona === idRoutePerson);
    try {
      if (
        campaignStatus === RoutePlanCampaignStatus.CALCULATED ||
        campaignStatus === RoutePlanCampaignStatus.SCHEDULED ||
        campaignStatus === RoutePlanCampaignStatus.CONFIRMED
      ) {
        // //Agenda pre confirmada
        if (user) {
          toggleLoadingResource(user.user_id, true);
        }
        if (recalculate) {
          const data: any = await http.post(
            `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/persons/${idRoutePerson}/calculate`,
            { fullRefresh }
          );
          setDataResponse(data.vroomSend);
          delete data.vroomSend;
          setJsonResponse(data);
          if (user && unCalculated.length > 0) {
            setResources(
              resources.map((resource) => {
                if (user.user_id === parseInt(resource.id)) {
                  resource.showTooltipRefresh = false;
                }
                return resource;
              })
            );
            setUnCalculated(
              unCalculated.filter((uc) => uc.user_id !== user.user_id)
            );
          }
        }

        if (reload && campaignStatus === RoutePlanCampaignStatus.SCHEDULED)
          await loadDataUnConfirmed();
        if (reload && campaignStatus !== RoutePlanCampaignStatus.SCHEDULED)
          await loadDataPreConfirmed(idRoutePerson);
      }
      //if (refresh) setRestartMap((oldValue) => !oldValue);
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al refrescar la ruta',
        description: message,
      });
      await loadDataPreConfirmed(idRoutePerson);
    } finally {
      //setLoading(false);
      if (loadingRef.current) loadingRef.current.complete();
      if (user) {
        toggleLoadingResource(user.user_id, false);
      }
    }
  };

  // Desasigna un pedido de un usuario
  const unAssignEventOrder = async (
    idCalendar: string,
    recalculate = false,
    reload = true
  ) => {
    if (loadingRef.current && reload)
      loadingRef.current.continuousStart(0, 1000);
    try {
      await http.delete(
        `/route-plans/${idRoutePlan}/campaign/${idRoutePlanCampaign}/calendar/${idCalendar}`
      );
      if (recalculate) {
        const userId = (events as any[]).find(
          (e) => e.id === idCalendar
        )?.resourceId;
        if (userId) {
          setCalendars(
            calendars.filter((c) => c.id_agenda !== parseInt(idCalendar))
          );

          const routePersonId = users.find(
            (u) => u.user_id === parseInt(userId)
          )?.id_plan_ruta_persona;
          if (routePersonId) {
            const userDetail = users.find(
              (u) => u.user_id === parseInt(userId)
            );
            await refreshRoutePerson(
              routePersonId,
              campaignStatus === 2 &&
                !skillsNotIncluded?.includes(userDetail.user.id_grupo)
            );
          }
        }
      }

      if (reload) {
        setEvents((oldEvents: any) => [
          ...oldEvents.filter((ev: any) => ev.id !== idCalendar),
        ]);
        await loadDataPreConfirmed();
      }
      //setRestartMap((oldValue) => !oldValue);
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al desasignar',
        description: message,
      });
    } finally {
      //setLoading(false);
      if (loadingRef.current && reload) loadingRef.current.complete();
    }
  };

  //Cambia confirmacion agenda
  const changeConfirmation = async (
    idCalendar: string,
    confirmation: number
  ) => {
    if (loadingRef.current) loadingRef.current.continuousStart(0, 1000);
    try {
      const userId = (events as any[]).find(
        (e) => e.id === idCalendar
      )?.resourceId;
      if (userId) {
        await http.put(
          `/route-plans/${idRoutePlan}/campaign/${idRoutePlanCampaign}/calendar/${idCalendar}/confirmation`,
          {
            confirmation,
            userId,
          }
        );

        setEvents(
          (events as any[]).map((ev) => {
            if (ev.id === idCalendar && ev.resourceId === userId) {
              ev.extraParams.confirmation = confirmation;
            }
            return ev;
          })
        );
        loadDataPreConfirmed();
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al cambiar confirmación',
        description: message,
      });
    } finally {
      //setLoading(false);
      if (loadingRef.current) loadingRef.current.complete();
    }
  };

  // Asigna evento no asignado a un usuario
  const assignUnassigned = async (unassigned: number[], personId: number) => {
    //setLoading(true);
    if (loadingRef.current) loadingRef.current.continuousStart(0, 1000);
    setShowUnAssigned(false);
    try {
      await http.post(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/persons/${personId}/assign`,
        {
          unassigned: unassigned,
        }
      );
      setMarkers([...markers.filter((m) => !m.key.includes('unassigned'))]);
      const user = users.find((u) => u.id_plan_ruta_persona === personId);
      if (campaignStatus === 2) {
        if (user && skillsNotIncluded?.includes(user.user.id_grupo)) {
          await refreshRoutePerson(personId, false, false);
        } else {
          await refreshRoutePerson(personId, true);
        }
      } else {
        await loadDataPreConfirmed();
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al asignar',
        description: message,
      });
    } finally {
      //setLoading(false);
      if (loadingRef.current) loadingRef.current.complete();
    }
  };

  // Actualiza la localizacion de un usuario
  const updateRoutePersonLocation = async (
    userId: string,
    startLatLng: google.maps.LatLng,
    endLatLng: google.maps.LatLng
  ) => {
    try {
      const userIndex = users.findIndex((u) => u.user_id === parseInt(userId));
      const resourceIndex = resources.findIndex((r) => r.id === `${userId}`);
      if (userIndex !== -1 && resourceIndex !== -1) {
        if (resources[resourceIndex].extendedProps.polyline) {
          resources[resourceIndex].extendedProps.polyline.setMap(null);
        }

        await http.put(
          `/route-plan-persons/${users[userIndex].id_plan_ruta_persona}/positions`,
          {
            origen_lat: startLatLng.lat(),
            origen_lng: startLatLng.lng(),
            destino_lat: endLatLng.lat(),
            destino_lng: endLatLng.lng(),
          }
        );
        await refreshRoutePerson(
          users[userIndex].id_plan_ruta_persona,
          campaignStatus === 2,
          true
        );
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al actualizar la ubicación del usuario',
        description: message,
      });
    }
  };

  // Drag and drop event
  const modifyEvent = async (arg: any, recalculate = true, reload = true) => {
    //setLoading(true);
    const userId =
      arg.event.getResources().length === 0
        ? arg.event.extendedProps.extraParams.user_id
        : arg.event.getResources()[0].id;
    try {
      if (campaignStatus === RoutePlanCampaignStatus.CALCULATED) {
        // //Agenda pre confirmada
        const calendarId = arg.event.id;
        await http.put('/route-plan-persons/' + calendarId, {
          start: moment(arg.event.start).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(arg.event.end).format('YYYY-MM-DD HH:mm:ss'),
          userId: userId,
          oldUserId: arg.oldResource?.id || null,
          routePlanId: idRoutePlan,
          routePlanCampaignId: idRoutePlanCampaign,
        });

        //const promises = [];
        if (arg.event.extendedProps.extraParams) {
          if (arg.newResource) {
            /* promises.push(
              refreshRoutePerson(
                arg.newResource.extendedProps.id_plan_ruta_persona,
                recalculate,
                false,
                reload
              )
            ); */
            const exists = usersToRefreshRoute.find(
              (personId) =>
                personId === arg.newResource.extendedProps.id_plan_ruta_persona
            );
            if (!exists)
              setUsersToRefreshRoute((oldVal) => [
                ...oldVal,
                arg.newResource.extendedProps.id_plan_ruta_persona,
              ]);
          }
          if (arg.oldResource) {
            /* promises.push(
              refreshRoutePerson(
                arg.oldResource.extendedProps.id_plan_ruta_persona,
                recalculate,
                false,
                reload
              )
            ); */
            const exists = usersToRefreshRoute.find(
              (personId) =>
                personId === arg.oldResource.extendedProps.id_plan_ruta_persona
            );
            if (!exists)
              setUsersToRefreshRoute((oldVal) => [
                ...oldVal,
                arg.oldResource.extendedProps.id_plan_ruta_persona,
              ]);
          }
        }

        //await Promise.all(promises);
        if (usersToRefreshRoute.length > 0) {
          setWaitingTimeRecalculate(waitingTimeToRecalculatePersons);
        }
        setLastEventChanged(arg.event.id);
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al modificar el evento',
        description: message,
      });
      if (arg.oldEvent) {
        arg.event.setStart(arg.oldEvent.start || new Date());
        arg.event.setEnd(arg.oldEvent.end);
      }
    }
  };

  const calculate = async (debug = false) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('¿Está seguro de calcular la ruta?')) {
      setLoading(true);
      try {
        const data: any = await http.post(
          `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/calculate`,
          {
            vroom: useVroomDebug ? options?.get() : null,
            debug,
            restart: campaignStatus === 2,
          }
        );
        setDataResponse(data.vroomSend);
        delete data.vroomSend;
        setJsonResponse(data);
        onCalculate();
        //setRestartMap((oldValue) => !oldValue);
      } catch (error: AxiosError | any) {
        setLoading(false);
        console.error(error);
        let message =
          error instanceof AxiosError
            ? error.response?.data.message
            : error.message;
        notification['error']({
          message: 'Error al calcular',
          description: message,
        });
      }
    }
  };

  const confirmRoute = async () => {
    if (unCalculated.length > 0) {
      notification['error']({
        message: 'No es posible confirmar con datos no calculados.',
        description:
          'Nuevas entregas en la agenda de algunos usuarios que no has sincronizado, sincroniza antes de confirmar.',
      });
      await loadDataPreConfirmed();
      return;
    }

    const hasUnassigneds = unassigned.length > 0;
    if (
      window.confirm(
        hasUnassigneds
          ? 'Hay puntos no asignados que saldrán de la agenda para asignarse nuevamente. ¿Estás seguro que deseas confirmar el plan de ruta?'
          : '¿Está seguro de confirmar el plan de ruta?'
      )
    ) {
      setLoading(true);
      try {
        const data: any = await http.post(
          `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/confirm`,
          {}
        );
        setJsonResponse(data);
        setCampaignStatus(3);
        notification['success']({
          message: 'Ruta confirmada',
          description: 'Se ha confirmado el plan de ruta',
        });
        setShowUserLocationButton(false);
        setShowRefreshButton(false);
        setResources((oldResources) => [
          ...oldResources.map((res) => {
            res.showRefreshLocation = false;
            res.showRefreshRoute = false;
            res.showTooltipRefresh = false;
            return res;
          }),
        ]);
      } catch (error: AxiosError | any) {
        console.error(error);
        let message =
          error instanceof AxiosError
            ? error.response?.data.message
            : error.message;
        notification['error']({
          message: 'Error al confirmar',
          description: message,
        });
        loadDataPreConfirmed();
      } finally {
        setLoading(false);
      }
    }
  };

  const notifyWhatsapp = async () => {
    setLoading(true);
    try {
      await http.post(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/notify/whatsapp`,
        {}
      );
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al notificar por whatsapp',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const notifyIVR = async () => {
    setLoading(true);
    try {
      await http.post(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/notify/ivr`,
        {}
      );
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al notificar por whatsapp',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const notifyMail = async () => {
    setLoading(true);
    try {
      await http.post(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/notify/email`,
        {}
      );
    } catch (error: AxiosError | any) {
      console.error(error);
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al notificar por whatsapp',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  //Verifica los cambios que se han hecho al hacer drag and drop y muestra notificacion para recalcular
  useEffect(() => {
    if (campaignStatus === RoutePlanCampaignStatus.CALCULATED) {
      if (usersToRefreshRoute.length === 0) {
        notification.close('usersToRefreshRoute');
      }

      if (usersToRefreshRoute.length > 0) {
        notification['info']({
          key: 'usersToRefreshRoute',
          duration: 0,
          message: `Se han modificado ${usersToRefreshRoute.length} personas y no se ha recalculado la ruta. El sistema lo hará en ${waitingTimeRecalculate} segundos automáticamente.`,
        });
      }
    }
  }, [campaignStatus, usersToRefreshRoute, waitingTimeRecalculate]);

  useEffect(() => {
    if (usersToRefreshRoute.length > 0) {
      if (waitingTimeRecalculate > 0) {
        const countdownInterval = setInterval(() => {
          setWaitingTimeRecalculate((oldValue) => oldValue - 1);
        }, 1000);
        return () => {
          clearInterval(countdownInterval);
        };
      } else {
        notification.close('usersToRefreshRoute');

        const usersWithSkillExcluded = users
          .filter((u: any) =>
            usersToRefreshRoute.includes(u.id_plan_ruta_persona)
          )
          .filter((c: any) => skillsNotIncluded?.includes(c.user.id_grupo))
          .map((u) => u.id_plan_ruta_persona);

        const usersAvailableToUpdate = usersToRefreshRoute.filter(
          (u: any) => !usersWithSkillExcluded.includes(u)
        );

        (async () => {
          const promises = [];
          for (const planRoutePersonId of usersAvailableToUpdate) {
            promises.push(
              refreshRoutePerson(
                parseInt(planRoutePersonId),
                true,
                false,
                false
              )
            );
          }
          setUsersToRefreshRoute([]);
          setWaitingTimeRecalculate(waitingTimeToRecalculatePersons);
          await Promise.all(promises);
          await loadDataPreConfirmed();
        })();
      }
    }
  }, [users, skillsNotIncluded, usersToRefreshRoute, waitingTimeRecalculate]);

  //Pendiente del evento que ha sido cambiado y quedó como no asignado
  useEffect(() => {
    if (unassigned.length > 0 && lastEventChanged !== null) {
      const lastEventUnass = unassigned.find(
        (unass) => unass.id_agenda === parseInt(lastEventChanged)
      );
      if (lastEventUnass) {
        notification['info']({
          message: `No fué posible asignar la OT #${lastEventUnass.calendar.id_pedido}`,
          description: `Por favor revisa el listado de puntos sin asignación para asignarlo nuevamente.`,
        });
        setLastEventChanged(null);
      }
    }
    return () => {
      setLastEventChanged(null);
    };
  }, [unassigned, lastEventChanged]);

  // Captura teclas rápidas en pantalla
  useEffect(() => {
    const handleOpenUnASsigned = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'u') {
        setShowUnAssigned((oldValue) => !oldValue);
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleOpenUnASsigned);
    return () => {
      window.removeEventListener('keydown', handleOpenUnASsigned);
    };
  }, []);

  useEffect(() => {
    if (campaignStatus === 3) {
      setShowUserLocationButton(false);
      setShowRefreshButton(false);
    }
  }, [campaignStatus]);

  // Core de carga de datos
  useEffect(() => {
    setCampaignStatus(campaign.estado);
    if (campaign.estado === 1) {
      //Agenda no confirmada
      loadDataUnConfirmed();
      setShowUserLocationButton(true);
    } else if (campaign.estado === 2) {
      //Agenda pre confirmada
      setLoading(true);
      loadDataPreConfirmed();
      setShowUserLocationButton(true);
      setShowRefreshButton(true);
    } else if (campaign.estado === 3) {
      //Agenda confirmada
      setLoading(true);
      loadDataPreConfirmed();
    }

    return () => {
      setResources([]);
      setEvents([]);
      setMarkers([]);
      setUnAssignedEvents([]);
      setShowRefreshButton(false);
      setShowUserLocationButton(false);
      setPolylines([]);
      polygonDrawer?.setMap(null);
      setPolygonDrawer(null);
      setSelectUser([]);
      setUnCalculated([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  //Detecta tecla shift presionada
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.shiftKey) {
        setShiftIsPressed(true);
      }
    };

    const handleKeyUp = (event: any) => {
      if (!event.shiftKey) {
        setShiftIsPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Encargado del trazo de las rutas
  useEffect(() => {
    const infoWindow = new google.maps.InfoWindow();
    const mouseOverListeners: any[] = [];
    const mouseOutListeners: any[] = [];

    const mouseOver = (args: any, polyline: any) => {
      polyline.polyline.setOptions({
        strokeOpacity: 0.8,
      });
      infoWindow.close();
      const user = users.find((u) => `${u.user_id}` === `${polyline.user}`);
      if (user) {
        infoWindow.setContent(`${user.user.firstname} ${user.user.lastname}`);
        infoWindow.setPosition(args.latLng);
        infoWindow.setZIndex(10000);
        infoWindow.open(map);
      }
    };

    const mouseOut = (args: any, polyline: any) => {
      polyline.polyline.setOptions({
        strokeOpacity: 1.0,
      });
      infoWindow.close();
    };

    if (map) {
      polylines.forEach((polyline) => {
        mouseOverListeners.push(
          google.maps.event.addListener(
            polyline.polyline,
            'mouseover',
            (args) => {
              mouseOver(args, polyline);
            }
          )
        );

        mouseOutListeners.push(
          google.maps.event.addListener(
            polyline.polyline,
            'mouseout',
            (args) => {
              mouseOut(args, polyline);
            }
          )
        );
        polyline.polyline.setMap(map);
      });
    }

    return () => {
      polylines.forEach((polyline) => polyline.polyline.setMap(null));
      mouseOverListeners.forEach((listener) =>
        google.maps.event.removeListener(listener)
      );
      mouseOutListeners.forEach((listener) =>
        google.maps.event.removeListener(listener)
      );
      infoWindow?.close();
    };
  }, [map, polylines, users, selectUser]);

  useEffect(() => {
    if (selectUser) {
      polylines.forEach((polyline) => {
        if (`${polyline.user}` !== `${selectUser}`) {
          polyline.polyline.setMap(null);
        }
      });
    }
  }, [polylines, selectUser]);

  useEffect(() => {
    if (calendar.current)
      calendar.current
        .getApi()
        .gotoDate(moment(campaign.fecha_agenda, 'YYYY-MM-DD').toDate());

    return () => {};
  }, [idRoutePlan, idRoutePlanCampaign, campaign, map]);

  useEffect(() => {
    if (map && dataBoundsMap) {
      map.fitBounds(dataBoundsMap);
      setDataBoundsMap(null);
    }
  }, [map, dataBoundsMap]);

  useEffect(() => {
    if (locations.start.lat() !== 0 && locations.userId) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('¿Desea actualizar la ubicación del usuario?')) {
        if (locations.userId)
          updateRoutePersonLocation(
            `${locations.userId}`,
            locations.start,
            locations.end
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  //Actualizar el mapa cuando cambia el centro
  useEffect(() => {
    if (map) {
      map.setCenter(defaultCenter);
      map.setZoom(5);
    }
  }, [map, defaultCenter]);

  useEffect(() => {
    if (campaignStatus === 2) {
      if (selectUser.length === 1) {
        setShowPolygonDrawer(true);
      } else {
        setShowPolygonDrawer(false);
        polygonDrawer?.setMap(null);
        setPolygonDrawer(null);
      }
    }
  }, [selectUser, polygonDrawer, campaignStatus]);

  useEffect(() => {
    if (unCalculated.length > 0) {
      const usersUnCalculated = unCalculated
        .map((uc) => `${uc.user.firstname} ${uc.user.lastname}`)
        .filter((value, index, self) => self.indexOf(value) === index)
        .join(',');
      notification['info']({
        message: 'Se han registrado entradas sin calcular',
        description: `Nuevas entradas para ${usersUnCalculated}.`,
        key: 'uncalculated',
      });
    }
    return () => {
      notification.close('uncalculated');
    };
  }, [unCalculated]);

  useEffect(() => {
    if (campaignStatus >= RoutePlanCampaignStatus.CALCULATED) {
      const bounds = new google.maps.LatLngBounds();
      const columns = document.querySelectorAll(`td.fc-day`);
      const columnsHeader = document.querySelectorAll(`th.fc-col-header-cell`);

      columns.forEach((element: any) => {
        element.style.backgroundColor = 'transparent';
      });

      columnsHeader.forEach((element: any) => {
        element.style.backgroundColor = 'transparent';
      });
      if (selectUser.length === 0) {
        //sin seleccion de usuario
        const groupedCalendarMarkers: GroupedMarkers[] = [];
        const groupedUserMarkers: GroupedMarkers[] = [];

        for (const calendar of calendars.filter(
          (c) => !skillsNotIncluded?.includes(c.user.id_grupo)
        )) {
          const user = users.find((u) => u.user_id === calendar.user.user_id);

          const latLng = new google.maps.LatLng(
            parseFloat(
              (calendar.calendar ? calendar.calendar.order : calendar.order)
                .local.latitud
            ),
            parseFloat(
              (calendar.calendar ? calendar.calendar.order : calendar.order)
                .local.longitud
            )
          );
          // eslint-disable-next-line no-loop-func
          const idxOtherNearMarker = groupedCalendarMarkers.find((g) => {
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                latLng,
                g.position
              );
            return distance < 10;
          });

          if (!idxOtherNearMarker) {
            groupedCalendarMarkers.push({
              id: `Point_${
                calendar.calendar
                  ? calendar.calendar.id_agenda
                  : calendar.order.id_pedido
              }`,
              position: latLng,
              color: user?.color || '#7f8c8d',
              order: calendar.order,
              type: 'Point',
              calendars: [],
              local: calendar.order.local,
              index: calendar.position,
              user: calendar.user,
            });
          } else {
            groupedCalendarMarkers[
              groupedCalendarMarkers.indexOf(idxOtherNearMarker)
            ].calendars.push({ ...calendar, color: user?.color || '#7f8c8d' });
          }
        }

        for (const user of users.filter(
          (u) => !skillsNotIncluded?.includes(u.user.id_grupo)
        )) {
          const latLng = new google.maps.LatLng(
            parseFloat(user.origen_lat),
            parseFloat(user.origen_lng)
          );
          // eslint-disable-next-line no-loop-func
          const idxOtherNearUser = groupedUserMarkers.find((g) => {
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                latLng,
                g.position
              );
            return distance < 10;
          });

          if (!idxOtherNearUser) {
            groupedUserMarkers.push({
              position: latLng,
              id: `Point_User_${user.user_id}`,
              color: user.color,
              local: null,
              order: user,
              calendars: [],
              type: 'User',
              index: null,
            });
          } else {
            groupedUserMarkers[
              groupedUserMarkers.indexOf(idxOtherNearUser)
            ].calendars.push(user);
          }
        }

        setMarkers([
          ...groupedCalendarMarkers
            .sort((a, b) => a.user.user_id - b.user.user_id)
            .filter((item) => item.order !== null)
            .map((item, idx: number) => {
              let dataCalendar = (
                <Marker
                  position={item.position}
                  color={item.color}
                  local={item.order.local}
                  order={item.order}
                  key={item.id}
                  idNumber={item.index}
                  type="Point"
                  id={item.id}
                />
              );

              if (item.calendars.length > 0) {
                dataCalendar = (
                  <GroupedMark
                    color={item.color ?? '#7f8c8d'}
                    groups={item.calendars}
                    id={item.id}
                    position={item.position}
                    order={{ ...item.order, color: item.color }}
                    key={item.id}
                    idNumber={item.index}
                  />
                );
              }

              return dataCalendar;
            }),
          ...groupedUserMarkers.map((user, idx) => {
            if (user.calendars.length === 0)
              return (
                <MarkerStart
                  position={user.position}
                  color={user.color}
                  user={user.order}
                  key={user.id}
                />
              );
            return (
              <GroupedMarkerStart
                color="#7f8c8d"
                groups={user.calendars}
                position={user.position}
                user={user.order}
                key={user.id}
                onClick={(e) => {
                  toggleMarkersAndRoutesByUser(`${e.user_id}`);
                }}
              />
            );
          }),
          ...users
            .filter((u) => !skillsNotIncluded?.includes(u.user.id_grupo))
            .map((user) => (
              <Marker
                position={
                  new google.maps.LatLng(
                    parseFloat(user.destino_lat),
                    parseFloat(user.destino_lng)
                  )
                }
                color={'black'}
                key={user.user_id}
              />
            )),
          ...unassigned.map((item: any, idx) => (
            <Marker
              position={
                new google.maps.LatLng(
                  (item.calendar
                    ? item.calendar.order
                    : item.order
                  ).local.latitud,
                  (item.calendar
                    ? item.calendar.order
                    : item.order
                  ).local.longitud
                )
              }
              color={'#7f8c8d'}
              local={(item.calendar ? item.calendar.order : item.order).local}
              order={item.calendar ? item.calendar.order : item.order}
              key={`${
                (item.calendar ? item.calendar.order : item.order).local
                  .id_local
              }-${idx}-unassigned`}
            />
          )),
        ]);
        polylines.forEach((polyuser) => {
          polyuser.polyline.getPath().forEach((latLng) => {
            bounds.extend(latLng);
          });
          polyuser.polyline.setMap(map);
        });
      } else {
        //Usuarios seleccionados
        const groupedCalendarMarkers: GroupedMarkers[] = [];
        const groupedUserMarkers: GroupedMarkers[] = [];

        for (const userId of selectUser) {
          const user = users.find((u) => `${u.user_id}` === `${userId}`);
          if (!user) break;
          const element: any = document.querySelector(
            `td.fc-day[data-resource-id="${user.user_id}"]`
          );
          const elementHeader: any = document.querySelector(
            `th.fc-col-header-cell[data-resource-id="${user.user_id}"]`
          );

          if (element) {
            element.style.setProperty(
              'background-color',
              currentTheme === Themes.LIGH_THEME
                ? 'rgba(0,0,0,0.05)'
                : 'rgba(0,0,0,0.5)',
              'important'
            );
          }

          if (elementHeader) {
            elementHeader.style.setProperty(
              'background-color',
              currentTheme === Themes.LIGH_THEME
                ? 'rgba(0,0,0,0.05)'
                : 'rgba(0,0,0,0.5)',
              'important'
            );
          }

          bounds.extend(
            new google.maps.LatLng(
              parseFloat(user.origen_lat),
              parseFloat(user.origen_lng)
            )
          );
          bounds.extend(
            new google.maps.LatLng(
              parseFloat(user.destino_lat),
              parseFloat(user.destino_lng)
            )
          );

          const latLng = new google.maps.LatLng(
            parseFloat(user.origen_lat),
            parseFloat(user.origen_lng)
          );
          // eslint-disable-next-line no-loop-func
          const idxOtherNearUser = groupedUserMarkers.find((g) => {
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                latLng,
                g.position
              );
            return distance < 10;
          });

          if (!idxOtherNearUser) {
            groupedUserMarkers.push({
              position: latLng,
              id: `Point_User_${user.user_id}`,
              color: user.color,
              local: null,
              order: user,
              calendars: [],
              type: 'User',
              index: null,
            });
          } else {
            groupedUserMarkers[
              groupedUserMarkers.indexOf(idxOtherNearUser)
            ].calendars.push(user);
          }

          for (const calendar of calendars
            .filter((c) => !skillsNotIncluded?.includes(c.user.id_grupo))
            .filter((item: any) => userId === `${item.user.user_id}`)
            .filter(
              (item) =>
                (item.calendar ? item.calendar.order : item.order) !== null
            )) {
            const latLng = new google.maps.LatLng(
              parseFloat(
                (calendar.calendar ? calendar.calendar.order : calendar.order)
                  .local.latitud
              ),
              parseFloat(
                (calendar.calendar ? calendar.calendar.order : calendar.order)
                  .local.longitud
              )
            );
            // eslint-disable-next-line no-loop-func
            const idxOtherNearMarker = groupedCalendarMarkers.find((g) => {
              const distance =
                google.maps.geometry.spherical.computeDistanceBetween(
                  latLng,
                  g.position
                );
              return distance < 10;
            });

            if (!idxOtherNearMarker) {
              groupedCalendarMarkers.push({
                id: `Point_${
                  calendar.calendar
                    ? calendar.calendar.id_agenda
                    : calendar.order.id_pedido
                }`,
                position: latLng,
                color: user?.color || '#7f8c8d',
                order: calendar.order,
                type: 'Point',
                calendars: [],
                local: calendar.order.local,
                index: calendar.position,
                user: calendar.user,
              });
            } else {
              groupedCalendarMarkers[
                groupedCalendarMarkers.indexOf(idxOtherNearMarker)
              ].calendars.push({
                ...calendar,
                color: user?.color || '#7f8c8d',
              });
            }
          }
        }

        const polylineUsers = polylines.filter((polyline) =>
          selectUser.includes(`${polyline.user}`)
        );

        polylines.forEach((polyuser) => {
          polyuser.polyline.setMap(null);
        });

        for (const polylineUser of polylineUsers) {
          polylineUser.polyline.setMap(map);
          polylineUser.polyline.getPath().forEach((latLng) => {
            bounds.extend(latLng);
          });
        }

        setMarkers([
          ...groupedCalendarMarkers
            .sort((a, b) => a.user.user_id - b.user.user_id)
            .filter((item) => item.order !== null)
            .map((item, idx: number) => {
              let dataCalendar = (
                <Marker
                  position={item.position}
                  color={item.color}
                  local={item.order.local}
                  order={item.order}
                  key={item.id}
                  idNumber={item.index}
                  type="Point"
                  id={item.id}
                />
              );

              if (item.calendars.length > 0) {
                dataCalendar = (
                  <GroupedMark
                    color={item.color ?? '#7f8c8d'}
                    groups={item.calendars}
                    id={item.id}
                    position={item.position}
                    order={{ ...item.order, color: item.color }}
                    key={item.id}
                    idNumber={item.index}
                  />
                );
              }

              return dataCalendar;
            }),
          ...groupedUserMarkers.map((user, idx) => {
            if (user.calendars.length === 0)
              return (
                <MarkerStart
                  position={user.position}
                  color={user.color}
                  user={user.order}
                  key={user.id}
                />
              );
            return (
              <GroupedMarkerStart
                color="#7f8c8d"
                groups={user.calendars}
                position={user.position}
                user={user.order}
                key={user.id}
                onClick={(e) => {
                  toggleMarkersAndRoutesByUser(`${e.user_id}`);
                }}
              />
            );
          }),
          // eslint-disable-next-line no-loop-func
          ...unassigned.map((item: any, idx) => (
            <Marker
              position={
                new google.maps.LatLng(
                  (item.calendar
                    ? item.calendar.order
                    : item.order
                  ).local.latitud,
                  (item.calendar
                    ? item.calendar.order
                    : item.order
                  ).local.longitud
                )
              }
              color={'#7f8c8d'}
              local={(item.calendar ? item.calendar.order : item.order).local}
              order={item.calendar ? item.calendar.order : item.order}
              key={`${
                (item.calendar ? item.calendar.order : item.order).local
                  .id_local
              }-${idx}-unassigned`}
            />
          )),
          /* ...calendars
            .filter((item: any) => selectUser.includes(`${item.user.user_id}`))
            .filter(
              (item) =>
                (item.calendar ? item.calendar.order : item.order) !== null
            )
            // eslint-disable-next-line no-loop-func
            .map((item: any, idx: number) => {
              const user = users.find((u) => u.user_id === item.user.user_id);

              const dataCalendar = (
                <Marker
                  position={
                    new google.maps.LatLng(
                      (item.calendar
                        ? item.calendar.order
                        : item.order
                      ).local.latitud,
                      (item.calendar
                        ? item.calendar.order
                        : item.order
                      ).local.longitud
                    )
                  }
                  color={user ? user.color : '#7f8c8d'}
                  local={
                    (item.calendar ? item.calendar.order : item.order).local
                  }
                  key={`${
                    item.calendar
                      ? item.calendar.order.local.id_local
                      : item.order.local.id_local
                  }-${idx}`}
                  order={item.calendar ? item.calendar.order : item.order}
                  idNumber={item.position}
                  id={`Point_${
                    item.calendar
                      ? item.calendar.id_agenda
                      : item.order.id_pedido
                  }`}
                />
              );
              return dataCalendar;
            }),
          ...users
            .filter((u) => !skillsNotIncluded?.includes(u.user.id_grupo))
            .filter((u) => selectUser.includes(`${u.user_id}`))
            // eslint-disable-next-line no-loop-func
            .map((user, idx) => (
              <MarkerStart
                position={
                  new google.maps.LatLng(
                    parseFloat(user.origen_lat),
                    parseFloat(user.origen_lng)
                  )
                }
                color={user.color}
                user={user}
                key={`${user.user_id}-${idx}`}
              />
            )), */
          ...users
            .filter((u) => !skillsNotIncluded?.includes(u.user.id_grupo))
            .filter((u) => selectUser.includes(`${u.user_id}`))
            // eslint-disable-next-line no-loop-func
            .map((user) => (
              <Marker
                position={
                  new google.maps.LatLng(
                    parseFloat(user.destino_lat),
                    parseFloat(user.destino_lng)
                  )
                }
                color={'black'}
                key={user.user_id}
              />
            )),
        ]);
      }

      if (!bounds.isEmpty()) setDataBoundsMap(bounds);
    }
  }, [
    selectUser,
    polylines,
    users,
    unassigned,
    calendars,
    map,
    skillsNotIncluded,
    currentTheme,
    campaignStatus,
  ]);

  useEffect(() => {
    if (currentUser) {
      const menu = currentUser.role.menus.find(
        (m: any) => m.menu.key === 'route-plans'
      );
      if (menu && menu.permission >= 3) {
        setIsOnlyRead(false);
      }
    }
  }, [currentUser]);

  return (
    <>
      <LoadingBar
        shadow={false}
        containerClassName="onfield-loading"
        ref={loadingRef}
        color="#1890ff"
      />
      {loading && <OnfieldLoading />}
      {currentUser &&
        currentUser.lastname.toLowerCase().indexOf('sanchirico') !== -1 &&
        buildEnvironment === 'production' && (
          <JsonRenderDrag
            data={dataResponse}
            json={jsonResponse}
            setEditor={setOptions}
            setEnabled={(enabled) => {
              setUseVroomDebug(enabled);
            }}
          />
        )}
      <RoutePlanMap
        routePlan={routePlan}
        onLoadMap={(mapx) => {
          setMap(mapx);
        }}
        loading={loading}
        calendarRef={calendar}
        campaignStatus={campaignStatus}
        mapProps={{
          center: {
            lat: defaultCenter.lat(),
            lng: defaultCenter.lng(),
          },
          markers: markers,
          showManagerDrawer: showPolygonDrawer,
          async onDrawingPolygon(polygon, type) {
            if (polygonDrawer) {
              polygonDrawer.setMap(null);
              setPolygonDrawer(null);
            }
            setPolygonDrawer(polygon);

            if (type === 1) {
              // Agrega puntos
              const unassignedSelected = [];
              for (const ua of unassigned) {
                const resultPath = google.maps.geometry.poly.containsLocation(
                  new google.maps.LatLng(
                    parseFloat(
                      ua.calendar
                        ? ua.calendar.order.local.latitud
                        : ua.order.local.latitud
                    ),
                    parseFloat(
                      ua.calendar
                        ? ua.calendar.order.local.longitud
                        : ua.order.local.longitud
                    )
                  ),
                  polygon
                );
                if (resultPath) unassignedSelected.push(ua);
              }
              const person = users.find((u) =>
                selectUser.includes(`${u.user_id}`)
              );

              if (person && unassignedSelected.length > 0) {
                await assignUnassigned(
                  unassignedSelected.map((ua) => ua.id_plan_ruta_no_asignado),
                  person.id_plan_ruta_persona
                );
              }
              polygon.setMap(null);
              setPolygonDrawer(null);
            } else if (type === 2) {
              loadingRef?.current?.continuousStart(0, 1000);
              // Desasigna puntos
              const calendarsSelected = [];
              const userCalendars = calendars.filter((c) =>
                selectUser.includes(`${c.user.user_id}`)
              );

              for (const uc of userCalendars) {
                const resultPath = google.maps.geometry.poly.containsLocation(
                  new google.maps.LatLng(
                    parseFloat(
                      uc.calendar
                        ? uc.calendar.order.local.latitud
                        : uc.order.local.latitud
                    ),
                    parseFloat(
                      uc.calendar
                        ? uc.calendar.order.local.longitud
                        : uc.order.local.longitud
                    )
                  ),
                  polygon
                );
                if (resultPath) calendarsSelected.push(uc);
              }

              if (calendarsSelected.length > 0) {
                const allUnassigns: Promise<any>[] = [];

                for (const c of calendarsSelected) {
                  if (c.calendar) {
                    allUnassigns.push(
                      unAssignEventOrder(
                        `${c.calendar.id_agenda}`,
                        false,
                        false
                      )
                    );
                  }
                  if (c.order && !c.calendar) {
                    allUnassigns.push(
                      unAssignEventOrder(`${c.order.id_pedido}`, false, false)
                    );
                  }
                }

                try {
                  await Promise.all(allUnassigns);
                  //await loadDataPreConfirmed();
                } catch (error) {
                  alert('Error al desasignar');
                  console.error(error);
                }

                let idx = 1;
                for (const userId of selectUser) {
                  const user = users.find(
                    (u) => `${u.user_id}` === `${userId}`
                  );

                  if (user) {
                    const routePersonId = user.id_plan_ruta_persona;
                    if (routePersonId)
                      await refreshRoutePerson(
                        routePersonId,
                        true,
                        false,
                        idx === selectUser.length
                      );
                  }
                  idx++;
                }
              }
              polygon.setMap(null);
              setPolygonDrawer(null);
              loadingRef?.current?.complete();
            }
          },
          onAdjustCalendar() {
            setTimeout(() => {
              const bounds = new google.maps.LatLngBounds();

              if (selectUser.length === 0) {
                for (const user of users) {
                  bounds.extend(
                    new google.maps.LatLng(
                      parseFloat(user.origen_lat),
                      parseFloat(user.origen_lng)
                    )
                  );
                  bounds.extend(
                    new google.maps.LatLng(
                      parseFloat(user.destino_lat),
                      parseFloat(user.destino_lng)
                    )
                  );
                }

                for (const calendar of calendars) {
                  bounds.extend(
                    new google.maps.LatLng(
                      parseFloat(
                        calendar.calendar
                          ? calendar.calendar.order.local.latitud
                          : calendar.order.local.latitud
                      ),
                      parseFloat(
                        calendar.calendar
                          ? calendar.calendar.order.local.longitud
                          : calendar.order.local.longitud
                      )
                    )
                  );
                }
              } else {
                for (const userId of selectUser) {
                  const user = users.find(
                    (u) => `${u.user_id}` === `${userId}`
                  );

                  if (user) {
                    bounds.extend(
                      new google.maps.LatLng(
                        parseFloat(user.origen_lat),
                        parseFloat(user.origen_lng)
                      )
                    );
                    bounds.extend(
                      new google.maps.LatLng(
                        parseFloat(user.destino_lat),
                        parseFloat(user.destino_lng)
                      )
                    );

                    for (const calendar of calendars.filter(
                      (c) => c.user.user_id === user.user_id
                    )) {
                      bounds.extend(
                        new google.maps.LatLng(
                          parseFloat(
                            calendar.calendar
                              ? calendar.calendar.order.local.latitud
                              : calendar.order.local.latitud
                          ),
                          parseFloat(
                            calendar.calendar
                              ? calendar.calendar.order.local.longitud
                              : calendar.order.local.longitud
                          )
                        )
                      );
                    }
                  }
                }
              }
              setDataBoundsMap(bounds);
            }, 500);
          },
        }}
        calendarProps={{
          skillsNotIncluded,
          moduloPlanRutas: moduloPlanRutas,
          events,
          resources,
          startDate: startCalendarDate,
          endDate: endCalendarDate,
          editable: campaignStatus === 2,
          dayMaxEvents: false,
          selectable: false,
          selectMirror: false,
          currentDate: moment(campaign.fecha_agenda, 'YYYY-MM-DD').toDate(),
          showRefreshButton: showRefreshButton,
          showUserLocationButton: showUserLocationButton,
          showUnassigned: showUnAssigned,
          eventUnassigned: unAssignedEvents,
          users: users,
          showEventClose: campaignStatus === 2 || campaignStatus === 3,
          hasUnassigned: unassigned.length > 0 && campaignStatus >= 2,
          onClickUnAssigned() {
            setShowUnAssigned((oldValue) => !oldValue);
          },
          onlyRead: isOnlyRead,
        }}
        onCalculate={() => calculate()}
        onEventReceive={(info) => {
          info.event.remove();
          setEvents((oldEvents: any) => [
            ...oldEvents.map((ev: any) => {
              if (ev.id === info.event!.id) {
                if (ev.extraParams) ev.extraParams.block = false;
                ev.start = info.event.start;
                ev.end = info.event.end;
              }
              return ev;
            }),
          ]);
        }}
        onConfirm={() => {
          confirmRoute();
        }}
        onEventDrop={(ev) => {
          if (!isOnlyRead) {
            // Se identifica cuando el evento está bloqueado y lo retorna a su posición inicial
            if (
              ev.event.extendedProps.extraParams &&
              ev.event.extendedProps.extraParams.block &&
              ev.oldEvent
            ) {
              setEvents((oldEvents: any) => [
                ...oldEvents.map((event: any) => {
                  if (event.id === ev.event!.id && event.extraParams) {
                    event.resourceId = `${ev.oldEvent.getResources()[0].id}`;
                    event.start = ev.oldEvent.start;
                    event.end = ev.oldEvent.end;
                  }
                  return event;
                }),
              ]);
              return;
            }

            // Si es un evento de tipo bloqueo y se está pasando a otra persona, se retorna a su posición inicial
            if (!ev.event.extendedProps.extraParams && ev.newResource) {
              ev.event.setResources(ev.oldEvent.getResources());
              ev.event.setStart(ev.oldEvent.start || new Date());
              ev.event.setEnd(ev.oldEvent.end);
              return;
            }

            // Cuando se mueve un evento dentro de la misma persona
            if (!ev.oldResource && !ev.newResource) {
              setEvents((oldEvents: any) => [
                ...oldEvents.map((event: any) => {
                  if (event.id === ev.event!.id && event.extraParams) {
                    event.start = ev.event.start;
                    event.end = ev.event.end;
                  }
                  return event;
                }),
              ]);
              modifyEvent(ev, true, false);
              return;
            }

            // Cuando se mueve el evento a otra persona
            if (ev.oldResource && ev.newResource) {
              const oldUser = users.find(
                (u) => u.user_id === parseInt(ev.oldResource!.id)
              );
              const newUser = users.find(
                (u) => u.user_id === parseInt(ev.newResource!.id)
              );

              if (
                oldUser &&
                newUser &&
                !skillsNotIncluded?.includes(oldUser.user.id_grupo) &&
                !skillsNotIncluded?.includes(newUser.user.id_grupo)
              ) {
                setEvents((oldEvents: any) => [
                  ...oldEvents.map((event: any) => {
                    if (
                      ev.oldResource &&
                      ev.oldResource!.id === event.resourceId &&
                      event.id === ev.event!.id
                    ) {
                      event.resourceId = `${ev.newResource!.id}`;
                      if (event.extraParams) event.extraParams.block = true;
                      event.start = ev.event.start;
                      event.end = ev.event.end;
                    }
                    return event;
                  }),
                ]);

                modifyEvent(ev, true, true);
                return;
              }

              // Cuando se mueve el evento entre personas que están excluidas del algoritmo
              if (
                oldUser &&
                newUser &&
                skillsNotIncluded?.includes(oldUser.user.id_grupo) &&
                skillsNotIncluded?.includes(newUser.user.id_grupo)
              ) {
                setEvents((oldEvents: any) => [
                  ...oldEvents.map((event: any) => {
                    if (
                      ev.oldResource &&
                      ev.oldResource!.id === event.resourceId &&
                      event.id === ev.event!.id
                    ) {
                      event.resourceId = `${ev.newResource!.id}`;
                      //if (event.extraParams) event.extraParams.block = true;
                      event.start = ev.event.start;
                      event.end = ev.event.end;
                    }
                    return event;
                  }),
                ]);

                modifyEvent(ev, false, false);
                return;
              }
            }
          }

          // si no cumple todas las condiciones anteriores, se retorna a su posición inicial
          if (ev.oldEvent) {
            ev.event.setResources(ev.oldEvent.getResources());
            ev.event.setStart(ev.oldEvent.start || new Date());
            ev.event.setEnd(ev.oldEvent.end);
          }
        }}
        onEventClick={(ev) => {
          const elementMarker = document.getElementById(`Point_${ev.event.id}`);

          if (elementMarker) {
            elementMarker.style.scale = '1.5';
            setTimeout(() => {
              elementMarker.style.scale = '1';
            }, 1000);
          }
        }}
        onClickResourceName={({ resource }) => {
          const user = users.find((u) => u.user_id === parseInt(resource.id));
          if (user) {
            if (skillsNotIncluded?.includes(user.user.id_grupo)) return;
          }
          toggleMarkersAndRoutesByUser(resource.id);
        }}
        onCloseEvent={(id: string) => {
          const ev = (events as any[]).find((ev) => ev.id === id);
          if (!ev.extraParams) {
            if (window.confirm('¿Desas retirar el bloqueo?')) {
              unAssignEventOrder(id);
            }
            return;
          }

          if (window.confirm('¿Desea desasignar el punto?')) {
            if (campaignStatus === 1) {
              // Agenda no confirmada
              unAssignEventOrder(id);
            } else if (campaignStatus === 2) {
              // pre confirmada
              unAssignEventOrder(id, true);
            } else if (campaignStatus === 3) {
              unAssignEventOrder(id, false);
            }
          }
        }}
        onChangeConfirmation={(id: string, confirmation: number) => {
          changeConfirmation(id, confirmation);
        }}
        onClickDownloadExcel={() => {
          downloadExcel();
        }}
        onClickNotifyWhatsapp={() => {
          notifyWhatsapp();
        }}
        onClickNotifyIVR={() => {
          notifyIVR();
        }}
        onClickNotifyEmail={() => {
          notifyMail();
        }}
        onClickResourceRefresh={({ resource }) => {
          const canRecalculate = unCalculated.find(
            (uc) => uc.user_id === parseInt(resource.id)
          );
          refreshRoutePerson(
            resource.extendedProps.id_plan_ruta_persona,
            campaignStatus === RoutePlanCampaignStatus.CALCULATED ||
              canRecalculate !== undefined
          );
        }}
        onClickResourceGps={({ resource }) => {
          if (
            modeChangeLocation.isModeChangeLocation &&
            modeChangeLocation.userId === resource.id
          ) {
            setModeChangeLocation({
              isModeChangeLocation: false,
              userId: null,
            });
          } else {
            setModeChangeLocation({
              isModeChangeLocation: true,
              userId: resource.id,
            });
          }
        }}
        onClickChangePerson={({ resource }) => {
          changePersonRef.current?.setRoutePlanId(idRoutePlan);
          changePersonRef.current?.setCurrentPerson(resource);
          changePersonRef.current?.setResources(resources);
          changePersonRef.current?.toggleModal(true);
        }}
        onClickChangePersonColor={({ resource }) => {
          changePersonColorRef.current?.setResource(resource);
          changePersonColorRef.current?.setShow(true);
        }}
        onAssignUnassigned={(unassigned, personId) => {
          assignUnassigned(unassigned, personId);
        }}
        onClickRemovePerson={async ({ resource }) => {
          try {
            await http.delete(
              `/route-plan-persons/${resource.extendedProps.id_plan_ruta_persona}`
            );
            setResources((oldValue) =>
              [...oldValue].filter((res) => res.id !== resource.id)
            );
          } catch (error: any) {
            alert(error.message);
          }
        }}
        onClickAddMore={() => {
          Modal.confirm({
            title: `Deseas añadir nuevos pedidos agendados en la fecha ${campaign.fecha_agenda}`,
            icon: <ExclamationCircleOutlined />,
            content:
              'Se agregaran los pedidos que no se encuentren en este plan de ruta y quedarán como no asignados.',
            async onOk() {
              try {
                setLoading(true);
                const response: any = await http.post(
                  `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/add-orders`,
                  {}
                );
                notification['info']({
                  message: `Se agregaron ${response.total} registro(s).`,
                });
                if (response.total > 0) await loadDataPreConfirmed();
              } catch (error: any) {
                alert(error.message);
              } finally {
                setLoading(false);
              }
            },
            onCancel() {},
          });
        }}
        onConfigure={() => {
          setShowConfig(true);
        }}
      />
      <ChangePerson
        ref={changePersonRef}
        onChangeSuccess={() => {
          setSelectUser([]);
          if (campaign.estado === 1) {
            //Agenda no confirmada
            loadDataUnConfirmed();
          } else if (campaign.estado === 2) {
            //Agenda pre confirmada
            loadDataPreConfirmed();
          }
        }}
      />
      <ChangePersonColor
        ref={changePersonColorRef}
        onChange={(color, routePlanPersonId) => {
          if (campaign.estado === RoutePlanCampaignStatus.CALCULATED) {
            loadDataPreConfirmed();
          }
        }}
      />
      <Config
        show={showConfig}
        onClose={() => {
          setShowConfig(false);
        }}
      />
    </>
  );
};

export default RoutePlanMapCalendar;
