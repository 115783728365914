import React, {
  FC,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  CheckOutlined,
  FileExcelOutlined,
  FolderAddOutlined,
  PhoneOutlined,
  PlusOutlined,
  RollbackOutlined,
  SendOutlined,
  SettingOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Drawer, Radio, Select, Tooltip } from 'antd';
import moment from 'moment';
import AnimateHeight, { Height } from 'react-animate-height';
import { useNavigate } from 'react-router-dom';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import externalKeys from '../../../const/externalKeys';
import FullCalendar, {
  EventChangeArg,
  EventClickArg,
  EventDropArg,
  EventSourceInput,
} from '@fullcalendar/react';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import Event from './Event';
import ResourceLabel from './ResourceLabel';
import generateColor from '../../../libs/generateColor';
import {
  DrawingManager,
  GoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { Themes } from '../../../store/ui';
import styled from 'styled-components';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Clock2Icon,
  PointsIcon,
  RouteIcon,
} from '../../../components/icons';
import { MinusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand } from '@fortawesome/free-regular-svg-icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import EventBlock from './EventBlock';
import { RoutePlanCampaignStatus } from '../../../enums/routePlanCampaignStatus';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { getCurrentUser } from '../../../store/auth/auth.actions';

export interface IUnAssigned {
  id: string;
  title: string;
  description: string;
  modulos: number;
}

type MapProps = {
  center: {
    lat: number;
    lng: number;
  };
  markers?: ReactElement[];
  showManagerDrawer?: boolean;
  onDrawingPolygon?: (polygon: google.maps.Polygon, type: number) => void;
  onAdjustCalendar?: () => void;
};

type CalendarProps = {
  events: EventSourceInput;
  resources: any[];
  startDate: string;
  endDate: string;
  editable: boolean;
  selectable: boolean;
  selectMirror: boolean;
  dayMaxEvents: boolean;
  currentDate: Date;
  moduloPlanRutas: any;
  showRefreshButton?: boolean;
  showUserLocationButton?: boolean;
  eventUnassigned?: IUnAssigned[];
  users?: any[];
  showUnassigned?: boolean;
  showEventClose?: boolean;
  hasUnassigned?: boolean;
  onClickUnAssigned?: () => void;
  onlyRead?: boolean;
  skillsNotIncluded?: any[];
};

const heightMinimized = 60;

type Props = {
  campaignStatus: number;
  loading?: boolean;
  onCalculate?: () => void;
  onConfigure?: () => void;
  onConfirm?: () => void;
  onEventChange?: (arg: EventChangeArg) => void;
  onEventClick?: (arg: EventClickArg) => void;
  onEventReceive?: (arg: EventReceiveArg) => void;
  onEventDrop?: (arg: EventDropArg) => void;
  onClickNotifyWhatsapp?: () => void;
  onClickNotifyIVR?: () => void;
  onClickNotifyEmail?: () => void;
  onClickDownloadExcel?: () => void;
  onClickAddMore?: () => void;
  onClickResourceGps?: (arg: any) => void;
  onClickResourceName?: (arg: any) => void;
  onClickResourceRefresh?: (arg: any) => void;
  onClickChangePerson?: (arg: any) => void;
  onClickChangePersonColor?: (arg: any) => void;
  onClickRemovePerson?: (arg: any) => void;
  onClickMap?: (arg: { lat: number; lng: number }) => void;
  onLoadMap?: (arg: google.maps.Map) => void;
  onCloseEvent?: (id: string) => void;
  onChangeConfirmation?: (id: string, confirmation: number) => void;
  onAssignUnassigned?: (unassigned: number[], personId: number) => void;
  calendarRef?: MutableRefObject<any>;
  mapProps: MapProps;
  calendarProps: CalendarProps;
  routePlan?: any;
};

const DivContainer = styled.div<any>`
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
    padding: 10px;
  }

  .gm-style .gm-style-iw {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#1d1d1d' : '#fff'};
  }

  .gm-style .gm-style-iw-c {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#1d1d1d' : '#fff'};
    padding: 0 !important;
  }

  .gm-style .gm-style-iw-c > button {
    display: none !important;
  }

  .gm-style .gm-style-iw-tc::after {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#1d1d1d' : '#fff'};
  }
`;

const DivFullCalendar = styled.div<any>`
  height: 100%;
  background-color: ${(props) =>
    props.theme === Themes.DARK_THEME ? '#1d1d1d' : '#fff'};
  overflow-x: auto;
  color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .fc-timegrid-slot-label-frame {
    text-align: center;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 0 !important;
    width: 100%;
  }

  .fc td,
  .fc th {
    border-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '#555 !important'
        : '#ddd !important'};
  }

  .fc-license-message {
    display: none;
  }

  .fc-timegrid-slot {
    height: ${(props) => props.heightTimegrid} !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '0.5px solid #000 !important'
        : '1px solid #ddd !important'};
  }

  .fc-day-today {
    background: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? '#1e1e1e !important'
        : '#fff !important'};
  }
  .fc-v-event {
    border-radius: 0.5rem;
    border: none;
  }

  .fc-timegrid-event {
    box-shadow: none !important;
  }

  .fc-v-event .fc-event-main-frame {
    padding: 0.3rem;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0;
  }
`;

const RoutePlanMap: FC<Props> = ({
  routePlan,
  campaignStatus,
  loading,
  onCalculate,
  onConfigure,
  onConfirm,
  onLoadMap,
  calendarRef,
  mapProps,
  calendarProps,
  onClickResourceGps,
  onClickResourceRefresh,
  onEventClick,
  onEventReceive,
  onEventDrop,
  onEventChange,
  onClickResourceName,
  onClickChangePerson,
  onClickChangePersonColor,
  onClickRemovePerson,
  onClickMap,
  onClickNotifyEmail,
  onClickNotifyIVR,
  onClickDownloadExcel,
  onClickAddMore,
  onClickNotifyWhatsapp,
  onCloseEvent,
  onChangeConfirmation,
  onAssignUnassigned,
}) => {
  const navigate = useNavigate();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [isClickMarker, setIsClickMarker] = useState(false);
  const [heightMap, setHeightMap] = useState<Height>('auto');
  const [heightCalendar, setHeightCalendar] = useState<Height>('auto');
  const [infoWindow, setInfoWindow] = useState<number>(-1);
  const [markers, setMarkers] = useState<ReactNode[]>([]);
  const [slotDuration, setSlotDuration] = useState('00:15:00');
  const [heightTimegrid, setHeightTimegrid] = useState('4.8rem');
  const currentTheme = useSelector(selectUiTheme);
  const currentUser = useSelector(selectCurrentUser);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [showConfig, setShowConfig] = useState(false);
  const [selectedUnassigned, setSelectedUnassigned] = React.useState<number[]>(
    []
  );
  const [btnConfig, setBtnConfig] = useState<{
    show_btn_whatsapp: boolean;
    show_btn_ivr: boolean;
    show_btn_import_order: boolean;
    show_btn_email: boolean;
  }>({
    show_btn_whatsapp: false,
    show_btn_ivr: false,
    show_btn_email: false,
    show_btn_import_order: false,
  });
  const [selectedPersonId, setSelectedPersonId] = React.useState<number>(-1);
  const [selectedTypeDrawing, setSelectedTypeDrawing] =
    React.useState<number>(0);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: externalKeys.google.API_KEY_MAP,
  });
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType | null>(null);
  const [glosaWeight, setGlosaWeight] = useState('Kg');
  const dispatch = useDispatch();

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
    if (onLoadMap) onLoadMap(map);
  };

  const onMapClick = React.useCallback(
    (ev: google.maps.MapMouseEvent) => {
      if (onClickMap)
        onClickMap({
          lat: ev.latLng!.lat(),
          lng: ev.latLng!.lng(),
        });
    },
    [onClickMap]
  );

  const calculeTimeAvg = () => {
    const modules =
      (calendarProps.events as any[])
        .filter((ev) => {
          const resource = calendarProps.resources.find(
            (res) => res.id === ev.resourceId
          );
          return calendarProps.skillsNotIncluded?.includes(
            resource?.extendedProps?.user?.id_grupo
          );
        })
        .filter((ev) => ev.extraParams !== null)
        .reduce((acc, el) => acc + (el.extraParams?.modules || 1), 0) * 3600;
    const secs = routePlan.duration + routePlan.service + modules;

    const secProm =
      secs /
      calendarProps.resources.filter(
        (resource) =>
          (calendarProps.events as any[]).find(
            (ev) => ev.resourceId === resource.id
          ) !== undefined
      ).length;

    const diffInMinutes = Math.floor(secProm / 60);
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  };

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_plan_ruta = currentUser.instance?.parameters.find(
        (p: any) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (sw_modulo_plan_ruta) {
        const parameters = JSON.parse(sw_modulo_plan_ruta?.value6);
        if (parameters?.config?.visible) {
          setShowConfig(true);
        }

        setBtnConfig({
          show_btn_email: parameters?.config?.btn_email || false,
          show_btn_import_order: parameters?.config?.btn_import_order || false,
          show_btn_ivr: parameters?.config?.btn_ivr || false,
          show_btn_whatsapp: parameters?.config?.btn_whatsapp || false,
        });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (calendarProps.moduloPlanRutas) {
      setSlotDuration(
        `${
          +calendarProps.moduloPlanRutas?.value2 >= 60
            ? +calendarProps.moduloPlanRutas?.value2 / 60
            : '00'
        }:${
          +calendarProps.moduloPlanRutas?.value2 < 60
            ? +calendarProps.moduloPlanRutas?.value2
            : '00'
        }:00`
      );

      if (calendarProps.moduloPlanRutas?.value3) {
        const hours: string[] =
          calendarProps.moduloPlanRutas?.value3.split(',');
        hours.forEach((h) => {
          const interval = h.split('-');
          interval.forEach((i) => {
            const element: any = document.querySelector(
              `td[data-time^='${i}']`
            );
            if (element) {
              element.style.fontWeight = 'bold';
            }
          });
        });
      }

      if (calendarProps.moduloPlanRutas?.value6) {
        const jsonValue6 = JSON.parse(calendarProps.moduloPlanRutas?.value6);
        setHeightTimegrid(`${jsonValue6.altura_celda}`);
      }
    }
  }, [calendarProps]);

  useEffect(() => {
    if (mapProps.markers) {
      setMarkers(
        mapProps.markers.map((marker, index) => {
          return React.cloneElement(marker, {
            key: index,
            onClickEvent: (local: any) => {
              setIsClickMarker(true);
              setTimeout(() => {
                setIsClickMarker(false);
              }, 50);
              if (infoWindow === -1) {
                setInfoWindow(local.id_local);
              } else {
                if (local === null) {
                  setInfoWindow(-1);
                } else if (local.id_local === infoWindow) {
                  setInfoWindow(-1);
                } else {
                  setInfoWindow(local.id_local);
                }
              }
            },
            showInfoWindow: infoWindow,
          });
        })
      );
    }
  }, [mapProps.markers, infoWindow]);

  useEffect(() => {
    if (map) {
      const listener = map.addListener('click', () => {
        if (infoWindow !== -1 && !isClickMarker) {
          setInfoWindow(-1);
        }
      });

      return () => {
        listener.remove();
      };
    }
  }, [map, infoWindow, isClickMarker]);

  useEffect(() => {
    if (map) {
      if (currentTheme === Themes.DARK_THEME) {
        map.setOptions({
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#242f3e',
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#746855',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#242f3e',
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'administrative.neighborhood',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#d59563',
                },
              ],
            },
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#d59563',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#263c3f',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#6b9a76',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#38414e',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#212a37',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#9ca5b3',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#746855',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#1f2835',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#f3d19c',
                },
              ],
            },
            {
              featureType: 'transit',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#2f3948',
                },
              ],
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#d59563',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#17263c',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#515c6d',
                },
              ],
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#17263c',
                },
              ],
            },
          ],
        });
      } else {
        map.setOptions({
          styles: [
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'administrative.neighborhood',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        });
      }
    }
  }, [currentTheme, map]);

  useEffect(() => {
    if (mapProps.showManagerDrawer) {
    } else {
      const controlButton = document.querySelector('.control-button');
      controlButton?.remove();
    }
  }, [mapProps.showManagerDrawer, map]);

  useEffect(() => {
    if (calendarProps.eventUnassigned) {
      setIndeterminate(
        !!selectedUnassigned.length &&
          selectedUnassigned.length < calendarProps.eventUnassigned?.length
      );
      setCheckAll(
        calendarProps.eventUnassigned?.length === selectedUnassigned.length
      );
    }
  }, [selectedUnassigned, calendarProps.eventUnassigned]);

  useEffect(() => {
    if (!mapProps.showManagerDrawer) {
      setDrawingMode(null);
      setSelectedTypeDrawing(0);
    }
  }, [mapProps.showManagerDrawer]);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_plan_ruta = currentUser.instance?.parameters.find(
        (p: any) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (sw_modulo_plan_ruta) {
        const parameters = JSON.parse(sw_modulo_plan_ruta?.value6);
        if (typeof parameters.config_peso !== 'undefined') {
          setGlosaWeight(parameters.config_peso.glosa);
        }
      }
    }
  }, [currentUser]);

  return (
    <>
      <Drawer
        visible={calendarProps.showUnassigned}
        closable={false}
        placement="right"
        getContainer={'body'}
        style={{ zIndex: 999 }}
        onClose={() => {
          setSelectedPersonId(-1);
          setSelectedUnassigned([]);
          if (calendarProps.onClickUnAssigned)
            calendarProps.onClickUnAssigned();
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={(e: CheckboxChangeEvent) => {
                const allData =
                  calendarProps.eventUnassigned?.map((e) => parseInt(e.id)) ||
                  [];
                setSelectedUnassigned(e.target.checked ? allData : []);
                setIndeterminate(false);
                setCheckAll(e.target.checked);
              }}
              checked={checkAll}
            />
            <h1 style={{ flex: 1, textAlign: 'center' }}>
              No asignados ({calendarProps.eventUnassigned?.length})
            </h1>
          </div>
          <div
            style={{
              flex: 1,
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {calendarProps.eventUnassigned?.length === 0 && (
              <small style={{ textAlign: 'center', width: '100%' }}>
                No hay datos
              </small>
            )}
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={(checkedValues: CheckboxValueType[]) => {
                setSelectedUnassigned(checkedValues as number[]);
              }}
              value={selectedUnassigned}
            >
              {calendarProps.eventUnassigned?.map((event) => {
                const cssUnits = ['rem', 'px', 'em'];
                let heightCalculated: any = '0.0';

                for (const cssUnit of cssUnits) {
                  if (heightTimegrid.includes(cssUnit)) {
                    heightCalculated = +heightTimegrid.replace(cssUnit, '');
                    heightCalculated = heightCalculated * 2;
                    heightCalculated = `${heightCalculated}${cssUnit}`;
                    break;
                  }
                }

                return (
                  <div
                    style={{
                      width: '100%',
                      marginBottom: '0.5rem',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '0.5rem',
                      gap: '1rem',
                      alignItems: 'center',
                    }}
                    key={event.id}
                  >
                    <Checkbox value={event.id} />
                    <div
                      style={{
                        flex: 1,
                        border: '1px solid #ddd',
                        padding: '0.5rem',
                        boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 15px -5px',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        flexDirection: 'column',
                        height: heightCalculated,
                        overflowY: 'auto',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: event.title }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event.description,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </Checkbox.Group>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <h3 style={{ textAlign: 'center', padding: 0, margin: 0 }}>
              Asignar a
            </h3>
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder="Selecciona una persona +"
              onChange={(value) => {
                setSelectedPersonId(value);
              }}
              value={selectedPersonId}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: -1,
                  label: 'Selecciona una persona +',
                },
                ...(calendarProps.users || []).map((user) => {
                  return {
                    value: user.id_plan_ruta_persona,
                    label: `${user.user.firstname} ${user.user.lastname} ${
                      user.user.group?.abreviacion
                        ? `(${user.user.group.abreviacion})`
                        : ''
                    }`,
                  };
                }),
              ]}
            />
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}
            >
              <Button
                danger
                type="primary"
                block
                onClick={() => {
                  setSelectedPersonId(-1);
                  setSelectedUnassigned([]);
                  if (calendarProps.onClickUnAssigned)
                    calendarProps.onClickUnAssigned();
                }}
              >
                Cerrar
              </Button>
              <Button
                type="primary"
                block
                onClick={() => {
                  if (selectedUnassigned.length === 0) return;
                  if (selectedPersonId === -1) return;
                  if (onAssignUnassigned)
                    onAssignUnassigned(selectedUnassigned, selectedPersonId);
                  setSelectedPersonId(-1);
                  setSelectedUnassigned([]);
                }}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <DivContainer
        theme={currentTheme}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '1.5rem',
          paddingLeft: '1.5rem',
          position: 'relative',
          userSelect: 'none',
          msUserSelect: 'none',
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <h1
            style={{
              fontSize: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '2rem',
              flex: 1,
              height: '100%',
            }}
          >
            Plan de ruta
            <small>
              {moment(calendarProps.currentDate).format('dddd')} de{' '}
              {moment(calendarProps.currentDate).format('DD MMMM')} de{' '}
              {moment(calendarProps.currentDate).format('YYYY')}
            </small>
            {campaignStatus === 3 && (
              <small>
                Confirmada{' '}
                <CheckOutlined style={{ color: 'green', fontSize: 12 }} />
              </small>
            )}
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
              gap: '0.5rem',
              paddingBottom: '0.6rem',
            }}
          >
            <Button
              style={{
                borderRadius: '5px',
                backgroundColor:
                  currentTheme === Themes.DARK_THEME ? '#333' : '#fff',
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
                border: `1px solid ${
                  currentTheme === Themes.DARK_THEME ? '#555' : '#ddd'
                }`,
              }}
              onClick={() => {
                navigate('/route-plans');
              }}
              icon={<RollbackOutlined />}
            />
            {!calendarProps.onlyRead && (
              <>
                {campaignStatus === 1 && (
                  <Button
                    type="primary"
                    icon={<SettingOutlined />}
                    onClick={onCalculate}
                    loading={loading}
                    className="onfield-button"
                  >
                    Calcular
                  </Button>
                )}
                {[
                  RoutePlanCampaignStatus.SCHEDULED,
                  RoutePlanCampaignStatus.CALCULATED,
                ].includes(campaignStatus) &&
                  showConfig &&
                  !calendarProps.onlyRead && (
                    <Tooltip title="Configurar">
                      <Button
                        style={{
                          borderRadius: '5px',
                          backgroundColor:
                            currentTheme === Themes.DARK_THEME
                              ? '#333'
                              : '#fff',
                          color:
                            currentTheme === Themes.DARK_THEME
                              ? '#fff'
                              : '#000',
                          border: `1px solid ${
                            currentTheme === Themes.DARK_THEME ? '#555' : '#ddd'
                          }`,
                        }}
                        type="default"
                        icon={<SettingOutlined />}
                        onClick={onConfigure}
                        loading={loading}
                        className="onfield-button"
                      />
                    </Tooltip>
                  )}
                {campaignStatus === 2 && (
                  <Button
                    type="primary"
                    icon={<SettingOutlined />}
                    onClick={onConfirm}
                    loading={loading}
                    className="onfield-button"
                  >
                    Confirmar
                  </Button>
                )}
                {campaignStatus >= 2 && (
                  <>
                    {btnConfig.show_btn_email && (
                      <Tooltip title="Notificar por email">
                        <Button
                          style={{
                            borderRadius: '5px',
                            backgroundColor:
                              currentTheme === Themes.DARK_THEME
                                ? '#333'
                                : '#fff',
                            color:
                              currentTheme === Themes.DARK_THEME
                                ? '#fff'
                                : '#000',
                            border: `1px solid ${
                              currentTheme === Themes.DARK_THEME
                                ? '#555'
                                : '#ddd'
                            }`,
                          }}
                          type="default"
                          icon={<SendOutlined />}
                          loading={loading}
                          onClick={() => {
                            if (onClickNotifyEmail) {
                              onClickNotifyEmail();
                            }
                          }}
                        ></Button>
                      </Tooltip>
                    )}
                    {btnConfig.show_btn_whatsapp && (
                      <Tooltip title="Notificar por Whatsapp">
                        <Button
                          type="ghost"
                          style={{
                            backgroundColor: '#25D366',
                            color: '#fff',
                            borderRadius: '5px',
                            borderColor: '#25D366',
                          }}
                          icon={<WhatsAppOutlined />}
                          loading={loading}
                          onClick={() => {
                            if (onClickNotifyWhatsapp) {
                              onClickNotifyWhatsapp();
                            }
                          }}
                        ></Button>
                      </Tooltip>
                    )}
                    {btnConfig.show_btn_ivr && (
                      <Tooltip title="Notificar por IVR">
                        <Button
                          type="primary"
                          icon={<PhoneOutlined />}
                          loading={loading}
                          className="onfield-button"
                          onClick={() => {
                            if (onClickNotifyIVR) {
                              onClickNotifyIVR();
                            }
                          }}
                        ></Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Descargar excel">
                      <Button
                        type="default"
                        icon={<FileExcelOutlined />}
                        loading={loading}
                        style={{
                          borderRadius: '5px',
                          backgroundColor: 'rgb(67, 95, 44)',
                          color: '#fff',
                          border: `1px solid rgb(67, 95, 44)`,
                        }}
                        onClick={() => {
                          if (onClickDownloadExcel) {
                            onClickDownloadExcel();
                          }
                        }}
                      />
                    </Tooltip>
                    {btnConfig.show_btn_import_order && (
                      <Tooltip title="Importar más pedidos">
                        <Button
                          type="default"
                          icon={<FolderAddOutlined />}
                          loading={loading}
                          style={{
                            borderRadius: '5px',
                            backgroundColor:
                              currentTheme === Themes.DARK_THEME
                                ? '#333'
                                : '#fff',
                            color:
                              currentTheme === Themes.DARK_THEME
                                ? '#fff'
                                : '#000',
                            border: `1px solid ${
                              currentTheme === Themes.DARK_THEME
                                ? '#555'
                                : '#ddd'
                            }`,
                          }}
                          onClick={() => {
                            onClickAddMore?.();
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <AnimateHeight
          height={'auto'}
          style={{
            width: '100%',
            border:
              currentTheme === Themes.DARK_THEME
                ? '1px solid #000'
                : '1px solid #ddd',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            position: 'relative',
          }}
        >
          {isLoaded ? (
            <GoogleMap
              onLoad={onLoad}
              options={{
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
              }}
              onClick={(ev) => onMapClick(ev)}
              mapContainerStyle={{
                width: '100%',
                height:
                  heightCalendar === heightMinimized
                    ? '90.4vh'
                    : heightCalendar === 'auto'
                    ? '50vh'
                    : '5vh',
                transition: 'height 0.5s',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
                position: 'relative',
              }}
            >
              {(calendarProps.events as any[]).length > 0 &&
                routePlan !== null && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '10px',
                      left: '0.5rem',
                      paddingTop: 7,
                      paddingBottom: 7,
                      background:
                        currentTheme === Themes.LIGH_THEME
                          ? 'rgba(255,255,255,0.8)'
                          : 'rgba(34, 34, 34, 0.8)',
                      color:
                        currentTheme === Themes.LIGH_THEME
                          ? 'rgba(0,0,0,0.9)'
                          : 'rgba(255,255,255,0.9)',
                      border: `1px solid ${
                        currentTheme === Themes.LIGH_THEME ? '#ddd' : '#222'
                      }`,
                      borderRadius: '10px',
                      boxShadow: '0px 3px 10px -5px rgba(0, 0, 0, 0.66)',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: '1px solid #ddd',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ flex: 1, textAlign: 'center' }}>
                          {
                            calendarProps.resources.filter(
                              (resource) =>
                                (calendarProps.events as any[])
                                  .filter((ev) => ev.extraParams !== null)
                                  .find(
                                    (ev) => ev.resourceId === resource.id
                                  ) !== undefined
                            ).length
                          }
                        </div>
                        <div>
                          <RouteIcon
                            style={{
                              fontSize: '18px',
                              color:
                                currentTheme === Themes.LIGH_THEME
                                  ? 'rgba(0, 0, 0, 0.6)'
                                  : 'rgba(255, 255, 255, 0.6)',
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontWeight: '600',
                          fontSize: '10px',
                          textAlign: 'center',
                        }}
                      >
                        Asignadas
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: '1px solid #ddd',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          {Math.round(
                            (routePlan.distance /
                              1000 /
                              calendarProps.resources.filter((resource) => {
                                return (
                                  (calendarProps.events as any[])
                                    .filter((ev) => ev.extraParams !== null)
                                    .find(
                                      (ev) => ev.resourceId === resource.id
                                    ) !== undefined &&
                                  !calendarProps.skillsNotIncluded?.includes(
                                    resource.extendedProps.user.id_grupo
                                  )
                                );
                              }).length) *
                              10
                          ) / 10}
                        </div>
                        <div style={{ fontSize: '14px', marginLeft: '2px' }}>
                          Km
                        </div>
                      </div>
                      <div
                        style={{
                          fontWeight: '600',
                          fontSize: '10px',
                          textAlign: 'center',
                        }}
                      >
                        Promedio
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ flex: 1 }}>{calculeTimeAvg()}</div>
                        <div style={{ marginLeft: '2px' }}>
                          <Clock2Icon
                            style={{
                              fontSize: '18px',
                              color:
                                currentTheme === Themes.LIGH_THEME
                                  ? 'rgba(0, 0, 0, 0.6)'
                                  : 'rgba(255, 255, 255, 0.6)',
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          fontWeight: '600',
                          fontSize: '10px',
                          textAlign: 'center',
                        }}
                      >
                        Promedio
                      </div>
                    </div>
                  </div>
                )}
              {calendarProps.hasUnassigned && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '2rem',
                    right: '1rem',
                    zIndex: 100,
                    padding: 5,
                    width: 100,
                    transition: 'ease 0.5s',
                    borderRadius: '0.5rem',
                    background:
                      currentTheme === Themes.LIGH_THEME
                        ? 'rgba(255,255,255,0.8)'
                        : 'rgba(34, 34, 34, 0.8)',
                    border: `1px solid ${
                      currentTheme === Themes.LIGH_THEME ? '#ddd' : '#222'
                    }`,
                    color:
                      currentTheme === Themes.LIGH_THEME
                        ? 'rgba(0,0,0,0.9)'
                        : 'rgba(255,255,255,0.9)',
                    boxShadow: '0px 0px 10px -5px rgba(0, 0, 0, 0.66)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (calendarProps.onClickUnAssigned)
                        calendarProps.onClickUnAssigned();
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '50%',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        {calendarProps.eventUnassigned?.length}
                      </div>
                      <div>
                        <PointsIcon style={{ fontSize: '18px' }} />
                      </div>
                    </div>
                    <div style={{ fontSize: '12px', fontWeight: '600' }}>
                      No asignados
                    </div>
                  </div>
                </div>
              )}
              {markers}
              {mapProps.showManagerDrawer && (
                <div
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '50%',
                    zIndex: 999,
                  }}
                >
                  <Radio.Group
                    size="small"
                    options={[
                      {
                        label: <FontAwesomeIcon icon={faHand} />,
                        value: 0,
                      },
                      {
                        label: <PlusOutlined />,
                        value: 1,
                      },
                      {
                        label: <MinusOutlined />,
                        value: 2,
                      },
                    ]}
                    onChange={(ev) => {
                      if (ev.target.value === 0) {
                        setDrawingMode(null);
                      } else if (
                        ev.target.value === 1 ||
                        ev.target.value === 2
                      ) {
                        setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
                      }
                      setSelectedTypeDrawing(ev.target.value);
                    }}
                    value={selectedTypeDrawing}
                    optionType="button"
                  />
                </div>
              )}
              {mapProps.showManagerDrawer && (
                <DrawingManager
                  options={{
                    drawingControlOptions: {
                      drawingModes: [],
                      position: google.maps.ControlPosition.TOP_CENTER,
                    },
                    polygonOptions: {
                      fillColor:
                        selectedTypeDrawing === 1 ? '#2ecc71' : '#ff0000',
                      strokeColor:
                        selectedTypeDrawing === 1 ? '#2ecc71' : '#ff0000',
                      fillOpacity: 0.2,
                      strokeWeight: 3.5,
                      clickable: false,
                      editable: false,
                      zIndex: 1,
                    },
                    drawingMode: drawingMode,
                    drawingControl: false,
                  }}
                  onPolygonComplete={(polygon) => {
                    if (mapProps.onDrawingPolygon) {
                      mapProps.onDrawingPolygon(polygon, selectedTypeDrawing);
                    }
                  }}
                />
              )}
            </GoogleMap>
          ) : (
            <>Loading...</>
          )}
        </AnimateHeight>
        <AnimateHeight
          height={heightCalendar}
          style={{
            zIndex: 100,
            marginTop: '-20px',
            position: 'relative',
            boxShadow: '0px -5px 10px -5px rgba(0, 0, 0, 0.66)',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: -0,
              left: '3px',
              zIndex: 100,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '42px',
              height: '45px',
            }}
          >
            {heightCalendar !== '100%' && (
              <Button
                type="text"
                size="small"
                style={{ width: 20, height: 20 }}
                onClick={() => {
                  if (heightCalendar === heightMinimized) {
                    setHeightCalendar('auto');
                    setHeightMap('auto');
                  } else {
                    setHeightMap('100%');
                    setHeightCalendar(heightMinimized);
                  }
                }}
                icon={
                  heightCalendar !== heightMinimized ? (
                    <ArrowDownIcon
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                      height={15}
                      width={15}
                    />
                  ) : (
                    <ArrowUpIcon
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                      height={15}
                      width={15}
                    />
                  )
                }
              />
            )}
            {heightCalendar !== heightMinimized && (
              <Button
                type="text"
                size="small"
                style={{ width: 20, height: 20 }}
                onClick={() => {
                  if (heightMap === 0 && mapProps.onAdjustCalendar)
                    mapProps.onAdjustCalendar();
                  setHeightMap(heightMap === 'auto' ? 0 : 'auto');
                  setHeightCalendar(
                    heightCalendar === 'auto' ? '100%' : 'auto'
                  );
                }}
                icon={
                  heightCalendar === 'auto' ||
                  heightCalendar === heightMinimized ? (
                    <ArrowUpIcon
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                      height={15}
                      width={15}
                    />
                  ) : (
                    <ArrowDownIcon
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                      height={15}
                      width={15}
                    />
                  )
                }
              />
            )}
          </div>
          <DivFullCalendar theme={currentTheme} heightTimegrid={heightTimegrid}>
            <FullCalendar
              themeSystem="standard"
              ref={calendarRef}
              rerenderDelay={10}
              schedulerLicenseKey="0891673153-fcs-1628715525"
              plugins={[
                interactionPlugin,
                resourceTimeGridPlugin,
                scrollGridPlugin,
              ]}
              initialView="timeGridRoutePlan"
              locale="es"
              viewHeight={'100%'}
              editable={calendarProps.editable}
              selectable={calendarProps.selectable}
              selectMirror={calendarProps.selectMirror}
              dayMaxEvents={calendarProps.dayMaxEvents}
              headerToolbar={false}
              initialEvents={calendarProps.events}
              events={calendarProps.events}
              height={heightCalendar === 'auto' ? '47vh' : '90vh'}
              resources={calendarProps.resources}
              resourceOrder={'order'}
              eventReceive={(info) => {
                if (onEventReceive) onEventReceive(info);
              }}
              eventClick={(ev) => {
                if (onEventClick) onEventClick(ev);
              }}
              eventDrop={(ev) => {
                if (onEventDrop) onEventDrop(ev);
              }}
              eventChange={(ev) => {
                if (onEventChange) onEventChange(ev);
              }}
              eventContent={(info) => {
                if (!info.event.extendedProps.extraParams)
                  return (
                    <EventBlock
                      arg={{ id: info.event.id, title: info.event.title }}
                      onClose={(id: string) => {
                        if (onCloseEvent) onCloseEvent(id);
                      }}
                    />
                  );
                if (info.event.id === '') return null;

                let distanceText = undefined;
                let orderText = undefined;

                if (info.event.extendedProps.extraParams.distance) {
                  const kms =
                    info.event.extendedProps.extraParams.distance / 1000;
                  if (kms > 1) {
                    distanceText = `${kms.toFixed(0)} km`;
                  } else {
                    distanceText = `${info.event.extendedProps.extraParams.distance} m`;
                  }
                }

                if (info.event.extendedProps.extraParams?.weight) {
                  const kgs = Math.trunc(
                    info.event.extendedProps.extraParams?.weight
                  );
                  distanceText = `${kgs}${glosaWeight} | ${
                    distanceText || '-'
                  }`;
                }

                if (info.event.extendedProps.extraParams.index) {
                  orderText = `${info.event.extendedProps.extraParams.index}`;
                }
                const color =
                  info.event.getResources().length > 0
                    ? info.event.getResources()[0].extendedProps.color
                    : 'gray';
                return (
                  <Event
                    key={info.event.id}
                    arg={{
                      //title: `${info.event.extendedProps.extraParams.order.id_pedido} - ${info.event.extendedProps.extraParams.order.local.nombre}`,
                      title: `${info.event.title}`,
                      distance: distanceText,
                      order: orderText,
                      color: color,
                      description: `${info.event.extendedProps.extraParams.description}`,
                      confirmation:
                        info.event.extendedProps.extraParams.confirmation ?? 0,
                      id: info.event.id,
                      showCloseButton: !calendarProps.onlyRead
                        ? calendarProps.showEventClose
                        : false,
                      showConfirmation: !calendarProps.onlyRead,
                      type: info.event.extendedProps.extraParams.tipo ?? '',
                    }}
                    onClose={(id: string) => {
                      if (onCloseEvent) onCloseEvent(id);
                    }}
                    onChangeConfirmation={(
                      id: string,
                      confirmation: number
                    ) => {
                      if (onChangeConfirmation)
                        onChangeConfirmation(id, confirmation);
                    }}
                  />
                );
              }}
              resourceLabelContent={(arg) => {
                return (
                  <ResourceLabel
                    key={arg.resource.id}
                    arg={{
                      color:
                        arg.resource.extendedProps.color || generateColor(),
                      title: arg.resource.title,
                      userImage: arg.resource.extendedProps.user.image,
                      selected: arg.resource.extendedProps.selected || false,
                      showRefresh: !calendarProps.onlyRead
                        ? arg.resource.extendedProps.showRefreshRoute
                        : false,
                      showUserLocation: !calendarProps.onlyRead
                        ? arg.resource.extendedProps.showRefreshLocation
                        : false,
                      id: arg.resource.id,
                      showChangePerson: !calendarProps.onlyRead
                        ? campaignStatus === RoutePlanCampaignStatus.CALCULATED
                        : false,
                      showChangePersonColor: !calendarProps.onlyRead
                        ? campaignStatus ===
                            RoutePlanCampaignStatus.CALCULATED &&
                          !arg.resource.extendedProps.showRemove
                        : false,
                      showTooltipRefresh: !calendarProps.onlyRead
                        ? arg.resource.extendedProps.showTooltipRefresh
                        : false,
                      showRemove: !calendarProps.onlyRead
                        ? arg.resource.extendedProps.showRemove
                        : false,
                      events: arg.resource.getEvents(),
                      routePerson: arg.resource.extendedProps,
                    }}
                    onClickGps={() => {
                      if (onClickResourceGps) onClickResourceGps(arg);
                    }}
                    onClickRefresh={() => {
                      if (onClickResourceRefresh) onClickResourceRefresh(arg);
                    }}
                    onClickName={() => {
                      if (onClickResourceName) onClickResourceName(arg);
                    }}
                    onClickChangePerson={() => {
                      if (onClickChangePerson) onClickChangePerson(arg);
                    }}
                    onClickChangePersonColor={() => {
                      if (onClickChangePersonColor)
                        onClickChangePersonColor(arg);
                    }}
                    onClickRemovePerson={() => {
                      onClickRemovePerson?.(arg);
                    }}
                  />
                );
              }}
              views={{
                timeGridRoutePlan: {
                  dayMinWidth: 300,
                  stickyFooterScrollbar: true,
                  duration: { days: 1 },
                  type: 'resourceTimeGridDay',
                  allDaySlot: false,
                  slotDuration: slotDuration,
                  slotLabelFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: false,
                    meridiem: 'short',
                    omitZeroMinute: false,
                  },
                  slotMinWidth: 300,
                  slotMinTime: calendarProps.startDate,
                  slotMaxTime: '23:59:59',
                  viewHeight: '100%',
                  contentHeight: '100%',
                },
              }}
            />
          </DivFullCalendar>
        </AnimateHeight>
      </DivContainer>
    </>
  );
};
export default RoutePlanMap;
