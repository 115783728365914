import React, { FC, useEffect, useRef, useState } from 'react';
import { OverlayViewF } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  id: string;
  color: string;
  position: google.maps.LatLng;
  order: any;
  groups: any[];
  idNumber: number | null;
};

const GroupedMark: FC<Props> = ({
  color,
  position,
  id,
  groups,
  order,
  idNumber,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuDescOpen, setMenuDescOpen] = useState(-1);
  const menuRef = useRef<HTMLDivElement>(null);
  const [langLocal, setLangLocal] = useState({
    ot: 'OT',
    comuna: 'Comuna',
  });
  const currentUser = useSelector(selectCurrentUser);

  const handleMenuToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_pedidos = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'sw_modulo_pedidos'
      );
      const sw_glosa_comuna = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'glosa_comuna'
      );

      if (sw_glosa_comuna && sw_modulo_pedidos) {
        const langs = sw_modulo_pedidos.value2.split(',');
        setLangLocal({
          ot: langs[2],
          comuna: sw_glosa_comuna?.value1,
        });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
				setMenuDescOpen(-1);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <OverlayViewF position={position} zIndex={50} mapPaneName={'floatPane'}>
      <div
        id={id}
        style={{
          width: '20px',
          height: '20px',
          backgroundColor: 'white',
          border: `2px solid ${color}`,
          borderRadius: '50%',
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          fontSize: 10,
          zIndex: 1,
        }}
        onClick={handleMenuToggle}
      >
        {idNumber || ''}
        <div
          style={{
            position: 'absolute',
            top: '-10px',
            right: '13px',
            fontSize: '0.8rem',
          }}
        >
          <PlusOutlined />
        </div>
      </div>

      {isMenuOpen && (
        <div
          ref={menuRef}
          style={{
            position: 'absolute',
            top: '-20px', // Ajusta la posición del menú arriba del marcador
            left: '-20px',
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '8px',
            borderRadius: '4px',
            overflowY: 'auto', // Activa el scroll si es necesario
            zIndex: 100,
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
					<div style={{position: 'absolute', top: '1px', left: '1px'}}><PlusOutlined /></div>
          <div
            id={id}
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: 'white',
              border: `2px solid ${order.color}`,
              borderRadius: '50%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: 10,
              zIndex: 200,
            }}
						onClick={() => {
							if(menuDescOpen === -1) {
								setMenuDescOpen(order.id_pedido);
							} else {
								setMenuDescOpen(-1);
							}
						}}
          >
            {idNumber || ''}
          </div>

          {groups.map((calendar) => {
            return (
              <div
                id={id}
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: 'white',
                  border: `2px solid ${calendar.color}`,
                  borderRadius: '50%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontSize: 10,
                  zIndex: 200,
                }}
								onClick={() => {
									if(menuDescOpen === -1) {
										setMenuDescOpen(calendar.id_pedido);
									} else {
										setMenuDescOpen(-1);
									}
								}}
              >
                {calendar.position || ''}
              </div>
            );
          })}

					{
						menuDescOpen !== -1 && (
							<div
							style={{
								position: 'absolute',
								top: '-20px', // Ajusta la posición del menú arriba del marcador
								left: '20px',
								backgroundColor: 'white',
								boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
								padding: '8px',
								borderRadius: '4px',
								overflowY: 'auto', // Activa el scroll si es necesario
								zIndex: 100,
								display: 'flex',
								flexDirection: 'column',
								gap: '5px',
							}}>
								asdasdasd
							</div>
						)
					}

          {/* <div style={{ border: `1px solid ${order.color}`, padding: '2px' }}>
            <strong>{langLocal.ot}:</strong> {order.id_pedido} -{' '}
            {order.local.nombre}
						<br />
						<strong>Dirección:</strong> {order.local.direccion} - {order.local.direccion2}
						<br />
						<strong>{langLocal.comuna}:</strong> {order.local.commune.nombre}
          </div>
          {groups.map((calendar) => {
            return (
              <div
                key={calendar.id_pedido}
                style={{ border: `1px solid ${calendar.color}`, padding: '2px' }}
              >
									<strong>{langLocal.ot}:</strong> {calendar.order.id_pedido} -{' '}
									{calendar.order.local.nombre}
									<br />
									<strong>Dirección:</strong> {calendar.order.local.direccion} - {calendar.order.local.direccion2}
									<br />
									<strong>{langLocal.comuna}:</strong> {calendar.order.local.commune.nombre}
              </div>
            );
          })} */}
        </div>
      )}
    </OverlayViewF>
  );
};

export default GroupedMark;
