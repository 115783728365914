import { FC, useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  notification,
  Input,
  Button,
  Checkbox,
  message,
} from 'antd';
import { userService } from '../../domain/services/User.service';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserSuccess } from '../store/auth/auth.actions';
import { setConfiguration, setMenuLeftPanel } from '../store/ui/ui.actions';
import { getDashboardSuccess } from '../store/dashboard/dashboard.actions';
import { AxiosError } from 'axios';
import LOGO_ONFIELD from '../../assets/logo_onfield_login.svg';
import { instance, S3_BUCKET } from '../http/axios';
import { selectUiTheme } from '../store/ui/ui.selectors';
import { Themes } from '../store/ui';
import colors from '../const/colors';
import styled from 'styled-components';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { INFO_APP } from '../const/info';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type SigninForm = {
  username: string;
  password: string;
};

const Container = styled(Row)`
  .ant-form-item-label > label {
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }
`;

export const SignIn: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const currentTheme = useSelector(selectUiTheme);
  const [refreshReCaptcha] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const appMode = process.env.REACT_APP_MODE || 'DEV';

  const onVerify = useCallback((token: string) => {
    setCaptchaToken(token);
  }, []);

  const handleFinish = async (values: SigninForm) => {
    setLoading(true);
    message.destroy('tokenErrorMessage');
    try {
      const { username, password } = values;
      const { user, menus, dashboards, instance, clients, homeScreen, role } =
        await userService.signIn(username, password, captchaToken);
      const configurations = await userService.getConfigurations();

      dispatch(setConfiguration(configurations));
      dispatch(getDashboardSuccess(dashboards));
      dispatch(setMenuLeftPanel(menus));
      dispatch(
        getCurrentUserSuccess({ ...user, instance, clients, homeScreen, role })
      );
      setLoading(false);
    } catch (error: any | AxiosError) {
      setLoading(false);
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message,
        });
      } else {
        notification.error({
          message: 'Error',
          description: error.message,
        });
      }
    }
  };

  const handleFinishFailure = (errorInfo: any) => {
    notification['error']({
      message: 'Se ha producido un error',
      description: 'Por favor complete el formulario e intente nuevamente',
      duration: 10,
    });
    setLoading(false);
  };

  useEffect(() => {
    const badges: any = document.getElementsByClassName('grecaptcha-badge');
    if (badges.length > 0) {
      badges[0].style.display = 'block';
    }
    return () => {
      if (badges.length > 0) {
        badges[0].parentNode.removeChild(badges[0]);
        //badges[0].style.display = 'none';
      }
    };
  }, []);

  useEffect(() => {
    const errorMessage = searchParams.get('exception');

    if (errorMessage) {
      message.error(
        {
          content: errorMessage,
          key: 'tokenErrorMessage',
        },
        30
      );
      searchParams.delete('exception');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const reCaptchaKey =
    process.env.REACT_APP_RECATCHA_KEY ||
    '6LdFAPMUAAAAAGWLX5hVpVceUSvSMSUJL3pED0lO';

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={appMode === 'DEV' ? '' : reCaptchaKey}
    >
      <Container
        theme={currentTheme}
        style={{
          height: '100vh',
          backgroundColor:
            currentTheme === Themes.DARK_THEME
              ? colors.DARK_THEME.backgroundColor
              : colors.LIGHT_THEME.backgroundColor,
        }}
      >
        <Col
          xs={{ span: 0 }}
          lg={{ span: 12 }}
          style={{
            position: 'relative',
            backgroundImage:
              'url(//source.unsplash.com/collection/145103/computers-phones-and-tech;)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor:
                currentTheme === Themes.DARK_THEME
                  ? 'rgba(216,216,216,.2)'
                  : 'rgba(216,216,216,.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={LOGO_ONFIELD}
              alt="Onfield"
              style={{
                width: '50%',
                opacity: currentTheme === Themes.DARK_THEME ? 0.7 : 0.2,
              }}
            />
          </div>
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 18, offset: 3 }}
          lg={{ span: 12, offset: 0 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '-.5rem',
            backgroundColor:
              currentTheme === Themes.DARK_THEME
                ? colors.DARK_THEME.backgroundColor
                : colors.LIGHT_THEME.backgroundColor,
            height: '100vh',
            position: 'relative',
          }}
        >
          <Row style={{ width: '90%' }}>
            <Col
              span={24}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <img
                src={`${S3_BUCKET}${instance}/logo.png`}
                alt="Logo"
                width="200"
              />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <h1
                style={{
                  fontSize: '2.5rem',
                  color:
                    currentTheme === Themes.DARK_THEME
                      ? colors.DARK_THEME.textColor
                      : colors.LIGHT_THEME.textColor,
                }}
              >
                Iniciar sesión
              </h1>
            </Col>
            <Col span={24}>
              <Form
                layout="vertical"
                name="signin"
                onFinish={handleFinish}
                onFinishFailed={handleFinishFailure}
                form={form}
                initialValues={{
                  username: '',
                  password: '',
                }}
              >
                <Form.Item
                  label="Usuario"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa tu nombre de usuario!',
                    },
                  ]}
                >
                  <Input
                    autoComplete="username"
                    autoCapitalize="off"
                    placeholder="Nombre de usuario"
                    size="large"
                    style={{
                      borderRadius: '0.5rem',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa la contraseña de tu cuenta!',
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete="current-password"
                    autoCapitalize="off"
                    placeholder="Contraseña de usuario"
                    size="large"
                    style={{ borderRadius: '0.5rem' }}
                  />
                </Form.Item>

                <Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Checkbox
                      style={{
                        flex: 1,
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? colors.DARK_THEME.textColor
                            : colors.LIGHT_THEME.textColor,
                      }}
                    >
                      Recordarme
                    </Checkbox>
                    <div
                      style={{ color: '#2596be', cursor: 'pointer' }}
                      onClick={() => {
                        navigate('/forgot-password');
                      }}
                    >
                      ¿Olvidaste tu contraseña?
                    </div>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                    size="large"
                    style={{ borderRadius: '0.5rem' }}
                  >
                    Iniciar Sesión
                  </Button>
                  <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onVerify}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              color:
                currentTheme === Themes.DARK_THEME
                  ? colors.DARK_THEME.textColor
                  : colors.LIGHT_THEME.textColor,
            }}
          >
            v{INFO_APP.VERSION}
          </div>
        </Col>
      </Container>
    </GoogleReCaptchaProvider>
  );
};
