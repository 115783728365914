import './index.css';
import {
  ConfigProvider,
  Col,
  Input,
  List,
  Row,
  Segmented,
  Button,
  Tooltip,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { SearchOutlined, SoundOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ChatItem from './ChatItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChats,
  selectOpenedChats,
} from '../../store/chat/chat.selectors';
import { Chat as IChat } from '../../../domain/models/Chat';
import {
  getChats,
  getChatsSuccess,
  openChat,
  massiveChats,
} from '../../store/chat/chat.actions';
import { selectCurrentUser } from '../../store/auth/auth.selectors';
import useChat from '../../hooks/useChat';
import * as chatActions from '../../store/chat/chat.actions';
import { analytics } from '../../firebase/firebase';
import { instance } from '../../http/axios';
import useNotification from '../../hooks/useNotification';
import { setCollapsedChatRightPanel } from '../../store/ui/ui.actions';
import { useMediaQuery } from 'react-responsive';
import { logEvent } from 'firebase/analytics';
import { INFO_APP } from '../../const/info';
import useUsersOnline from '../../hooks/useUsersOnline';
import { Moment } from 'moment';
import MassiveChat from './MassChat';
import { MassiveIcon } from '../icons';

const SearchStyled = styled(Input)`
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: #fff;
  input {
    background: transparent;
    color: #fff;
  }
  border-radius: 5px;
`;

enum Filtro {
  Todos = 'Todos',
  EnLinea = 'En Línea',
  Otro = 'Otro',
}

const Chat: FC = () => {
  const [ContainerHeight, setContainerHeight] = useState(500);
  const chats = useSelector(selectChats);
  const openedChats = useSelector(selectOpenedChats);
  const stateMassiveChats = useSelector(massiveChats);
  const currentUser = useSelector(selectCurrentUser);
  const [allChats, setAllChats] = useState<IChat[]>([]);
  const [allChatsAll, setAllChatsAll] = useState<IChat[]>([]);
  const [search, setSearch] = useState('');
  const [isPageFocused, setIsPageFocused] = useState(true);
  const [chat, setUser] = useChat();
  const dispatch = useDispatch();
  const { hasPermission, sendNotification } = useNotification();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [update, setUpdate] = useState(true);

  const [filter, setFilter] = useState<string | number>('Todos');
  const [showMassive, setShowMassive] = useState(false);

  const [userConnections] = useUsersOnline();

  const onFocusPage = (ev: any) => {
    setIsPageFocused(true);
  };

  const onBlurPage = (ev: any) => {
    setIsPageFocused(false);
  };

  const handlerNewChat = (chat: IChat) => {
    if (currentUser?.user_id) {
      if (isTabletOrMobile) dispatch(setCollapsedChatRightPanel(true));
      if (chat.exists) {
        logEvent(analytics, 'open_bubble_chat', {
          chatId: chat.id,
          users: chat.id_users,
          exists: true,
          instance,
          version: INFO_APP.VERSION,
          userId: currentUser?.user_id,
          groupId: currentUser?.user_group_id,
        });
        dispatch(openChat(chat.id, currentUser?.user_id));
      } else {
        logEvent(analytics, 'open_bubble_chat', {
          chatId: chat.id,
          users: chat.id_users,
          exists: false,
          instance,
          version: INFO_APP.VERSION,
          userId: currentUser?.user_id,
          groupId: currentUser?.user_group_id,
        });
        dispatch(chatActions.openInactiveChat(chat, currentUser?.user_id));
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const rightPanel =
        document.getElementById('rightPanel')?.offsetHeight || 0;
      const headerRightPanelChat =
        document.getElementById('headerRightPanelChat')?.offsetHeight || 0;
      const footerRightPanelChat =
        document.getElementById('footerRightPanelChat')?.offsetHeight || 0;
      setContainerHeight(
        rightPanel - (headerRightPanelChat + footerRightPanelChat) - 20
      );
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('focus', onFocusPage);
    window.addEventListener('blur', onBlurPage);

    handleResize();

    setTimeout(() => {
      handleResize();
    }, 1000);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('focus', onFocusPage);
      window.removeEventListener('blur', onBlurPage);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(getChats(currentUser?.user_id));
      //setUser(currentUser?.user_id);
    }
    return () => {
      dispatch(getChatsSuccess([]));
    };
  }, [currentUser]);

  useEffect(() => {
    if (chat.event === 'added') {
      const existingChat = chats.find((c) => c.id === chat.chat.id);

      const existingChatRealDB = chats.find(
        (c) =>
          c.id_users
            .sort((a, b) => a.toString().localeCompare(b.toString()))
            .join('') ===
          chat.chat.id_users
            .sort((a, b) => a.toString().localeCompare(b.toString()))
            .join('')
      );
      if (existingChatRealDB !== undefined && existingChat === undefined) {
        const openedChatChange = openedChats.find(
          (c) =>
            c.id_users
              .sort((a, b) => a.toString().localeCompare(b.toString()))
              .join('') ===
            chat.chat.id_users
              .sort((a, b) => a.toString().localeCompare(b.toString()))
              .join('')
        );
        if (openedChatChange !== undefined) {
          dispatch(chatActions.closeChat(openedChatChange.id));
        }
        dispatch(
          chatActions.addUserChatRealDB(existingChatRealDB.id, chat.chat)
        );
      } else if (existingChat === undefined) {
        dispatch(chatActions.addUserChat(chat.chat));
      }
    }

    if (chat.event === 'modified') {
      dispatch(chatActions.updateUserChat(chat.chat));
      const openedChatChange = openedChats.find((c) => c.id === chat.chat.id);
      if (openedChatChange !== undefined) {
        dispatch(
          chatActions.updateOpenedChat({
            ...chat.chat,
            users: openedChatChange.users.map((u) => {
              const user = chat.chat.users.find(
                (u2) => u2.user_id === u.user_id
              );
              if (user) {
                u.is_view = user.is_view;
                u.is_write = user.is_write;
                u.is_connected = user.is_connected;
              }
              return u;
            }),
          })
        );
      } else {
        if (currentUser) {
          const chatChange = chats.find((c) => c.id === chat.chat.id);
          if (chatChange && chatChange.last_message !== chat.chat.last_message)
            dispatch(chatActions.openChat(chat.chat.id, currentUser?.user_id));
        }
      }
    }

    if (chat.event === 'removed') {
      dispatch(chatActions.removeUserChat(chat.chat));
    }
  }, [chat, dispatch, showMassive]);

  useEffect(() => {
    //dispatch(closeAllOpenedChats());
    if (showMassive) {
      dispatch(massiveChats(true));
    } else {
      setTimeout(function () {
        dispatch(massiveChats(false));
      }, 2000);
    }
  }, [showMassive]);

  useEffect(() => {
    let findChats = allChats.sort((a, b) => {
      if (a.last_message_date == null) return 1;
      if (b.last_message_date == null) return -1;

      return (
        (b.last_message_date as any).seconds -
        (a.last_message_date as any).seconds
      );
    });

    setAllChatsAll(findChats);

    if (search.trim().length > 0) {
      const filteredChats = [...findChats].filter((chat) => {
        const exists = chat.users.find((user) => {
          return (
            `${user.firstname} ${user.lastname}`
              .trim()
              .toLocaleLowerCase()
              .indexOf(search.trim().toLocaleLowerCase()) > -1 &&
            user.user_id !== currentUser?.user_id
          );
        });
        return exists !== undefined;
      });
      setAllChats(filteredChats);
    } else {
      setAllChats(findChats);
    }
  }, [chats, search]);

  useEffect(() => {
    if (chats.length) setUser(currentUser?.user_id || 0);
  }, [chats, currentUser, setUser]);

  useEffect(() => {
    if (hasPermission && chat.event === 'modified') {
      const fromUser = chat.chat.users.find(
        (u) => u.user_id !== currentUser?.user_id
      );
      const chatChange = chats.find((c) => c.id === chat.chat.id);
      const openedChat = openedChats.find((c) => c.id === chat.chat.id);

      if (
        fromUser !== undefined &&
        chatChange !== undefined &&
        chatChange.last_message !== chat.chat.last_message
      ) {
        if (openedChat !== undefined) {
          const toUser = openedChat.users.find(
            (u) => u.user_id === currentUser?.user_id
          );

          if (toUser?.window_collapsed !== false && isPageFocused) return;
        }

        const notify = sendNotification(
          `Mensaje de ${fromUser?.firstname} ${fromUser?.lastname}`,
          {
            body: chat.chat.last_message || '',
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            icon: '/favicon.ico',
          }
        );

        notify?.addEventListener('click', (ev: any) => {
          window.focus();
          if (openedChat !== undefined) {
            const toUser = openedChat.users.find(
              (u) => u.user_id === currentUser?.user_id
            );

            if (!toUser?.window_collapsed) {
              dispatch(
                chatActions.openChat(chat.chat.id, currentUser?.user_id || 0)
              );
            }
          }
        });
      }
    }
    return () => {};
  }, [chat, hasPermission]);

  useEffect(() => {
    if (search.length === 0) {      
      // -- inicio ordernar chat
      let listChats = chats.sort((a, b) => {
        const dateA = a.last_message_date;
        const dateB = b.last_message_date;

        if (!dateA) return 1; // 'a' sin fecha se coloca al final
        if (!dateB) return -1; // 'b' sin fecha se coloca al final

        // Ordenar por fecha de último mensaje en orden descendente
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });
      // -- fin ordenar chat

      const currentUserID = currentUser?.user_id?.toString();
      const connections: string[] = userConnections.users_online.filter(
        (id) => id !== currentUserID
      );

      const usersOnline = listChats.filter((chat) =>
        chat.id_users.some((id) => connections.includes(id.toString()))
      );

      switch (filter) {
        case Filtro.Todos:
          setAllChats(listChats);
          break;

        case Filtro.EnLinea:
          setAllChats(usersOnline);
          break;

        default:
          setAllChats(listChats);
          break;
      }
    }
  }, [chats, filter, update, search, userConnections]); 

  useEffect(() => {
    setSearch('');
  }, [filter]);

  return (
    <ConfigProvider>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      >
        <Row
          style={{
            paddingTop: '.5rem',
            paddingLeft: '0.4rem',
          }}
          id="headerRightPanelChat"
        >
          <Col span={24}>
            <div className="container-title">
              <div>Chat</div>
            </div>
          </Col>

          <Col
            span={24}
            style={{
              paddingTop: '1rem',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Segmented
              style={{
                marginRight: '1rem',
                borderRadius: '5px',
                padding: '0.3rem',
              }}
              options={['Todos', 'En Línea']}
              value={filter}
              onChange={setFilter}
            />

            <Tooltip title="Mensajes masivos">
              <Button
                onClick={() => setShowMassive(true)}
                icon={<MassiveIcon style={{ color: 'white', fontSize: 18 }} />}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.15)',
                  borderRadius: '5px',
                  padding: '0',
                  border: '0',
                  borderColor: 'unset',
                  height: '100%',
                }}
                size="large"
              />
            </Tooltip>
          </Col>

          <Col span={22} offset={1} style={{ paddingTop: '1rem' }}>
            <SearchStyled
              value={search}
              onChange={(value) => {
                setSearch(value.target.value);
              }}
              allowClear
              size="middle"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <div
          style={{
            height: ContainerHeight,
            flex: 1,
            paddingTop: '.5rem',
            overflowY: 'auto',
          }}
        >
          <List
            dataSource={allChats}
            renderItem={(item: IChat) => (
              <ChatItem key={item.id} chat={item} onSelect={handlerNewChat} />
            )}
          />
        </div>

        <div
          style={{ textAlign: 'center', color: '#fff' }}
          id="footerRightPanelChat"
        >
          <small>by Assertsoft</small>
        </div>
      </div>
      <MassiveChat
        visible={showMassive}
        onCancel={(visible) => setShowMassive(visible)}
        update={{
          function: setUpdate,
          data: update,
        }}
        data={chats}
      />
    </ConfigProvider>
  );
};

export default Chat;
