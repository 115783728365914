const colors = {
  LIGHT_THEME: {
    backgroundColor: '#fafafa',
    textColor: '#000000',
    rightSidebarButtonColors: '#ffffff',
    common: {
      h1: '#000',
      select: '#fff',
      selectSelection: '#f5f5f5',
      segmentedSelected: '#fff',
    },
    leftPanel: {
      color: '#707070',
      colorHover: '#2b2b2b',
      backgroundColor: '#D7DFE8',
      buttonCollapsed: {
        backgroundColor: '#c8cfd7',
        color: '#707070',
      },
    },
    rightPanel: {
      backgroundColor: '#748196',
      rightPanelCollapsible: '#5D687B',
      buttonCollapsed: {
        backgroundColor: '#5D687B',
      },
    },
  },
  DARK_THEME: {
    backgroundColor: '#222222',
    textColor: '#d8d4cf',
    rightSidebarButtonColors: '#ffffff',
    common: {
      h1: '#d8d4cf',
      select: '#2A2D2F',
      selectSelection: 'rgb(24, 26, 27)',
      segmentedSelected: '#000',
    },
    leftPanel: {
      color: '#ddd',
      colorHover: '#fff',
      backgroundColor: '#2A2D2F',
      buttonCollapsed: {
        backgroundColor: 'rgb(51, 55, 57)',
        color: '#a1998d',
      },
    },
    rightPanel: {
      backgroundColor: 'rgba(70, 80, 97, 0.9)',
      rightPanelCollapsible: 'rgba(74, 83, 98, 1)',
      buttonCollapsed: {
        backgroundColor: 'rgba(74, 83, 98, 1)',
      },
    },
  },
};

export default colors;
