import { useState, useEffect, useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Table, ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';

import { http } from '../../http/axios';
import { Themes } from '../../store/ui';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import { selectCurrentUser } from '../../store/auth/auth.selectors';

import Box from '../../components/Settings/zoneFrequency/box';
import UserSelect from '../../components/Settings/zoneFrequency/userSelect';
import Download from '../../components/Settings/zoneFrequency/download';
import './settings.css';
import './dark-theme.css';

const dataWeek = [
  { id: 0, string: 'Domingo' },
  { id: 1, string: 'Lunes' },
  { id: 2, string: 'Martes' },
  { id: 3, string: 'Miercoles' },
  { id: 4, string: 'Jueves' },
  { id: 5, string: 'Viernes' },
  { id: 6, string: 'Sabado' },
];


const DivTable = styled.div`
  background-color: ${(props) =>
    props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  border-radius: 6px;

  .ant-table-thead > tr > th {
    background-color: ${(props) =>
    props.theme === Themes.DARK_THEME ? '#333333' : '#f0f2f5'};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    border-bottom: ${(props) =>
    props.theme === Themes.DARK_THEME
      ? '1px solid #2a2d2f'
      : '1px solid #f0f0f0'};
  }

  .ant-table {
    border-radius: 6px;
  }

  .ant-select-clear {
    background: unset;
  }

  .copyZones-button {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#333333' : '#fafafa'};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-tag {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#333333' : '#fafafa'};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .custom-tag {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#333333' : '#fafafa'};
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .anticon-line {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .anticon-close {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-table-cell-row-hover {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }

  .ant-table-cell-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? 'red' : '#fff'};
  }  

  .ant-table-title {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }

  .column-title-ZoneFrequency {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-table-cell {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#1e91cf' : '#1e91cf')};
  }

  .ant-select-dropdown {
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }

  .ant-select-dropdown-placement-bottomLeft {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background-color: ${(props) => props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }

  ant-select-item ant-select-item-option {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-table-placeholder {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background-color: ${(props) =>
    props.theme === Themes.DARK_THEME ? '#2a2d2f' : '#fff'};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'background-color: none;' : '')};
  }

  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(props) => (props.theme === Themes.DARK_THEME ? '#555555' : '#2a2d2f')};
    border-right: 1px solid ${(props) => (props.theme === Themes.DARK_THEME ? '#555555' : '#2a2d2f')};
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    border-right: 1px solid ${(props) => (props.theme === Themes.DARK_THEME ? '#555555' : '#2a2d2f')};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
  }

  .ant-table-row {
    border: 1px solid ${(props) => (props.theme === Themes.DARK_THEME ? '#555555' : '#2a2d2f')};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: 1px solid #555555' : '')};
  }
  
  .custom-option-ZoneFrequency-dark {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'color: unset' : '')};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'background-color: none;' : 'background-color: none;')};
  }

  .ant-select-item-option-active {
    background-color: none;
  }

  .ant-table.ant-table-bordered {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-color: #555555 !important;' : '')};
  }

  .ant-table-tbody {    
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: 1px solid #555555' : '')};
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-right: unset' : '')};
  }

  .khzHRs .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: 1px solid #555555;' : '')};
  }

  .ant-table-tbody > tr > td {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-bottom: 1px solid #555555;' : '')};
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border-right: 1px solid #555555;' : '')};
  }

  .ant-table.ant-table-bordered > .ant-table-title {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: unset;' : '')};
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: unset;' : '')};
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    ${(props) => (props.theme === Themes.DARK_THEME ? 'border: unset;' : 'border-left: 1px solid black; border-top: 1px solid black;')};
  }

  ::selection {

  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: none;
  }
`;

function ZoneFrequency() {
  const currentUser = useSelector(selectCurrentUser);
  const currentTheme = useSelector(selectUiTheme);

  const modulo_plan_de_ruta = currentUser?.instance?.parameters.find(
    (param) => param.parameter_id === 'modulo_plan_de_ruta'
  );
  // filtrar los dias de la semana segun parametro
  const dias_semana_ocultos = JSON.parse(modulo_plan_de_ruta.value6)?.dias_semana_ocultos || [];
  const week = dataWeek.filter((item) => !dias_semana_ocultos.includes(item.id));

  const glosa_usuario =
    JSON.parse(modulo_plan_de_ruta.value6)?.glosa_usuario || 'Usuario';

  const [zones, setZones] = useState([]); // lista de zonas organizada
  const [techniciansData, setTechniciansData] = useState([]); // lista de usuarios de la consulta
  const [laodTechniciansData, setLoadTechniciansData] = useState(true);
  const [usersRender, setUsersRender] = useState([]); // lista de usuarios que se muestran en la tabla

  // Objeto de referencias para los componentes hijos (Box)
  const childRefs = useRef({});

  const loadZonesData = async () => { // consulta la informacion de zonas
    try {
      const zones = await http.get('/zones');
      zonesRender(zones);
    } catch (error) {
      console.error(error);
    }
  };

  const loadTechniciansData = async () => { // consulta la informacion de los tecnicos
    try {
      const response = await http.get("/users/technicians");
      setTechniciansData(response);
      setLoadTechniciansData(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => { // inicializa la consulta de informacion de zonas y tecnicos
    loadZonesData();
    loadTechniciansData();
  }, []);

  const zonesRender = (zones) => { // organiza la informacion de las zonas 
    const zonesList = [];
    zones.forEach((zone) => {
      zonesList.push({ id: zone.id_zona, string: zone.label });
    });
    setZones(zonesList);
  };

  const closeUser = (id) => { // quita un usuario de la tabla
    setUsersRender(usersRender.filter((user) => user.id !== id.key));
  };

  const copyZones = (day, zonesAdd, user) => {
    const ref = childRefs.current[`${user.id}-${day.id}`];
    if (ref?.addZones) {
      ref.addZones(zonesAdd);
    }
  };

  const userGroup = (id) => {
    const dataUser = usersRender.find((user) => user.id === id.key);
    if (dataUser !== undefined  && dataUser?.group !== undefined) {
      return `
      ${dataUser?.group?.abreviacion ?? ''}
      ${dataUser?.group?.abreviacion !== undefined && dataUser?.group?.nombre !== undefined ? ' - ' : ''}
      ${dataUser?.group?.nombre ?? ''}`;
    }
    return ('')
  };

  // Función para agregar una referencia al componente hijo
  const addChildRef = (id, ref) => {
    childRefs.current[id] = ref;
  };

  const columns = [
    // contiene los nombres de las columnas de la tabla
    {
      title: `${glosa_usuario}`,
      dataIndex: 'name',
      key: 'name',
      width: `${100 / (week.length + 1)}%`,
      align: 'center',
      render: (text, record) => (
        <div className="userBox">
          <Button
            className="closeUser"
            type="text"
            shape="circle"
            size="small"
            onClick={() => closeUser(record)}
            icon={
              <CloseOutlined
                style={{
                  color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                }}
              />
            }
          />
          <span>
            <b>{`${text}`}</b>
            <p
              style={{
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              }}
            >
              {userGroup(record)}
            </p>
          </span>
        </div>
      ),
    },
  ];

  week.forEach((day) => { // agrega las columnas de los dias 
    columns.push({
      title: <div className="column-title-ZoneFrequency">{day.string}</div>,
      dataIndex: day.id,
      key: day.string,
      width: `${100 / (week.length + 1)}%`, // % dividido entre la cantidad dias mas 1 por la columna de usuarios
    });
  });

  const data = []; // contiene los datos de la tabla

  usersRender.forEach((user) => { // agrega los datos de cada usuario
    const row = {
      key: user.id,
      name: `${user.string}`,
    };

    week.forEach((day) => {
      row[day.id] = <Box
        key={`${user.id}-${day.id}`}
        ref={ref => addChildRef(`${user.id}-${day.id}`, ref)} // Agrega la referencia al componente hijo
        zonesData={zones}
        day={day}
        week={week}
        user={user}
        copyZones={copyZones}
      />
    });

    data.push(row);
  });

  const customNoDataText = {
    emptyText: `No hay ${glosa_usuario} seleccionados.`,
  };

  return (
    <div className={`settingsContainer`}>
      <h1>Frecuencia de zonas</h1>

      <div className="zoneFrequencyDownload">
        <Download
          week={week}
          techniciansData={techniciansData}
          zones={zones}
          load={laodTechniciansData}
        />
      </div>

      <DivTable theme={currentTheme}>
        <ConfigProvider
          locale={es_ES}
          renderEmpty={() => <div>{customNoDataText.emptyText}</div>}
        >
          <Table
            className="zoneFrequencyTable ant-table-bordered"
            title={() => (
              <UserSelect
                value={usersRender}
                placeholder={`Buscar ${glosa_usuario}...`}
                load={laodTechniciansData}
                users={usersRender}
                setUsers={setUsersRender}
                techniciansData={techniciansData}
              />
            )}
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: true }}
            bordered
            rowClassName={
              currentTheme === Themes.DARK_THEME
                ? 'table-row-dark'
                : 'table-row-light'
            }
          />
        </ConfigProvider>
      </DivTable>
    </div>
  );
}

export default ZoneFrequency;
