export const GET_CHATS = 'GET_CHATS';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_FAILURE = 'GET_CHATS_FAILURE';

export const ADD_CHAT = 'ADD_CHAT';
export const ADD_CHAT_RDB = 'ADD_CHAT_RDB';
export const MODIFY_CHAT = 'MODIFY_CHAT';
export const MODIFY_SPECIFIC_CHAT = 'MODIFY_SPECIFIC_CHAT';
export const REMOVE_CHAT = 'REMOVE_CHAT';

export const GET_OPENED_CHATS = 'GET_OPENED_CHATS';

export const OPEN_CHAT = 'OPEN_CHAT';
export const CLOSE_ALL_OPENED_CHATS = 'CLOSE_ALL_OPENED_CHATS';
export const OPEN_INACTIVE_CHAT = 'OPEN_INACTIVE_CHAT';
export const CLOSE_CHAT = 'CLOSE_CHAT';
export const UPDATE_OPENED_CHAT = 'UPDATE_OPENED_CHAT';
export const TOGGLE_CHAT = 'TOGGLE_CHAT';

export const SET_MASSIVE = 'SET_MASSIVE';
