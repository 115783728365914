import React, { useState, useEffect } from 'react'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Input, Button, Tooltip, Spin } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Themes } from '../../../store/ui';
import { selectUiTheme } from '../../../store/ui/ui.selectors';

import { http } from "../../../http/axios";

import * as XLSX from 'xlsx';

const DownloadButton = styled.div`
    .dark-button:hover {
        background-color: #75a74d;
        border-color: #5c843d;
    }
    
    .light-button:hover {
        background-color: #75a74d;
        border-color: #5c843d;
    }
`;

function Download({ week, techniciansData, zones }) {

    const currentTheme = useSelector(selectUiTheme);

    const [loadDownload, setLoadDownload] = useState(false)

    function organizeData(allFrequencies) {
        const sheetHeaders = week.map(objeto => objeto.string);
        sheetHeaders.unshift('Técnico');

        const data = [];
        techniciansData.forEach(technical => {
            const technicalZones = allFrequencies.filter(frequency => frequency.user_id === technical.user_id);
            const technicalInfo = (`${technical.firstname} ${technical.lastname}`);
            const zonesOrganized = [];

            zonesOrganized.unshift(technicalInfo);

            week.forEach(day => {
                const schedule = technicalZones.filter(zone => zone[day.id] === true);
                const daily = schedule.map(userZone => {
                    const dataZone = zones.find(zone => zone.id === userZone.id_zona);
                    return dataZone !== undefined ? (`${dataZone.string}, \n`) : [''];
                });

                zonesOrganized.push(daily.join(''));
            });

            data.push(zonesOrganized);
        });

        // Aquí se crea el archivo Excel a partir de los datos de la tabla
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet([sheetHeaders, ...data]);

        // exportToExcel
        XLSX.utils.book_append_sheet(wb, ws, 'zoneFrequencyTable');
        XLSX.writeFile(wb, 'Frecuencia_de_zonas.xlsx');

        setLoadDownload(false);
    }

    const download = async () => {
        setLoadDownload(true);
        try {
            const data = await http.get('/frequency-zones');
            organizeData(data);
        } catch (error) {
            console.error(error);
            setLoadDownload(false);
        }
    }

    return (
        <Tooltip placement="bottom" title={'Exportar a excel'}>
        <DownloadButton theme={currentTheme}>
            <Button icon={loadDownload && <LoadingOutlined />} onClick={download} disabled={loadDownload}
                style={{
                    borderRadius: '6px',
                    backgroundColor: currentTheme === Themes.DARK_THEME ? 'rgb(67, 95, 44)' : '#8fbb6c',
                    borderColor: currentTheme === Themes.DARK_THEME ? 'rgb(83, 118, 54)' : '#7aae50',
                    ':hover': {
                        backgroundColor: '#75a74d',
                        borderColor: '#5c843d',
                    }
                }}
                className={currentTheme === Themes.DARK_THEME ? 'dark-button' : 'light-button'}
            >
                <span className="fa fa-file-excel-o"
                style={{color: 'white'}}/>
            </Button>
        </DownloadButton>
        </Tooltip>
    )
}

export default Download
