import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { Themes } from '../../../store/ui';

type Resource = {
  title: string;
  id: string;
};

type Props = {
  arg: Resource;
  onClose?: (id: string) => void;
};

const EventBlock: FC<Props> = ({ arg, onClose }) => {
  const currentTheme = useSelector(selectUiTheme);

  return (
    <div
      style={{
        flex: 1,
        overflowY: 'auto',
        height: '100%',
        backgroundColor:
          currentTheme === Themes.LIGH_THEME ? 'rgb(221, 221, 221)' : '#444',
        borderColor:
          currentTheme === Themes.LIGH_THEME ? 'rgb(221, 221, 221)' : '#444',
        color: currentTheme === Themes.LIGH_THEME ? 'black' : 'white',
        borderRadius: '0.5rem',
        padding: '0.3rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.1rem',
          height: '100%',
          color: currentTheme === Themes.LIGH_THEME ? 'black' : 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.1rem',
            padding: '2px',
          }}
        >
          <Button
            type="text"
            size="small"
            onClick={() => {
              if (onClose) onClose(arg.id);
            }}
            icon={
              <FontAwesomeIcon
                icon={faXmark}
                color={currentTheme === Themes.LIGH_THEME ? 'black' : 'white'}
              />
            }
          />
          <div
            style={{
              flex: 1,
              color: currentTheme === Themes.LIGH_THEME ? 'black' : 'white',
            }}
            dangerouslySetInnerHTML={{ __html: arg.title }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default EventBlock;
