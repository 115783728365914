import { RollbackOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Segmented,
  Select,
  Spin,
  Steps,
  Table,
} from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../http/axios';
import FormStepThree from './FormStepThree';
import FormStepTwo from './FormStepTwo';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../store/ui/ui.selectors';
import { Themes } from '../../store/ui';
import styled from 'styled-components';

const Div = styled.div`
  .ant-picker-input > span.ant-picker-suffix,
  .ant-picker-input > span.ant-picker-clear,
  .ant-picker-input > input {
    color: ${(props) => (props.theme === Themes.DARK_THEME ? '#fff' : '#000')};
    background: transparent;
  }
`;

const Create: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentId, setCurrentId] = useState<number>(0);
  const [routePlan, setRoutePlan] = useState<any>({});
  const [routePlanCampaign, setRoutePlanCampaign] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [splashLoading, setSplashLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [formPlan] = Form.useForm();
  const [formTwoStep] = Form.useForm();
  const [formThreeStep] = Form.useForm();
  const currentTheme = useSelector(selectUiTheme);
  const { id } = useParams();

  const saveRoutePlan = async () => {
    try {
      await formPlan.validateFields();
    } catch (error) {
      throw new Error('Error al validar el formulario del plan');
    }

    try {
      const { nombre, fecha_plan, comentarios } = formPlan.getFieldsValue();
      const response: any = await http.post('/route-plans', {
        nombre,
        fecha_plan: fecha_plan.toDate(),
        comentarios,
        nro_puntos: 0,
        nro_personas: 0,
      });
      setCurrentId(response.id_plan_ruta);
    } catch (error: any | AxiosError) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message.toString());
      } else {
        throw new Error(error.message);
      }
    }
  };

  const updateRoutePlan = async () => {
    try {
      await formPlan.validateFields();
    } catch (error) {
      throw new Error('Error al validar el formulario del plan');
    }

    try {
      const { nombre, fecha_plan, comentarios } = formPlan.getFieldsValue();
      const response: any = await http.put('/route-plans/' + currentId, {
        nombre,
        fecha_plan: fecha_plan.toDate(),
        comentarios,
      });
      setCurrentId(response.id_plan_ruta);
    } catch (error: any | AxiosError) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message.toString());
      } else {
        throw new Error(error.message);
      }
    }
  };

  const next = async () => {
    setLoading(true);
    try {
      if (currentStep === 0 && !currentId) await saveRoutePlan();
      if (currentStep === 0 && currentId) await updateRoutePlan();
      setCurrentStep(currentStep + 1);
    } catch (error: any) {
      notification['error']({
        message: 'Error',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const start = async () => {
    try {
      await formThreeStep.validateFields();
    } catch (error) {
      notification['error']({
        message: 'Para iniciar debe finalizar el formulario del paso 3',
      });
      return;
    }

    try {
      setLoading(true);
      const type =
        formThreeStep.getFieldsValue().type === 'Todos'
          ? 'all'
          : formThreeStep.getFieldsValue().type === 'Habilidad'
          ? 'skill'
          : 'selection';
      const response: any = await http.post(
        '/route-plans/' + currentId + '/persons',
        {
          ...formThreeStep.getFieldsValue(),
          type,
          id_route_plan: currentId,
          id_route_plan_campana: routePlanCampaign.id_plan_ruta_campana,
					id_cliente: formTwoStep.getFieldValue('customerContainer') ?? -1
        }
      );

      if (response.usersNotIncluded) {
        notification['info']({
          message: `No se agregaron estos usuarios porque ya tienen un plan confirmado este día: ${response.usersNotIncluded}`,
          key: 'info-create',
          duration: 10000,
        });
      }
      navigate('/route-plans/' + currentId + '/map');
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (id) {
      setSplashLoading(true);
      setCurrentId(Number(id));
      const loadRoutePlan = async () => {
        try {
          const response: any = await http.get(`/route-plans/${id}`);
          setRoutePlan({
            nombre: response.nombre,
            comentarios: response.comentarios,
            fecha_plan: moment(response.fecha_plan),
          });
          if (response.routeCampaigns.length > 0) {
            if (response.routeCampaigns[0].persons.length > 0)
              navigate('/route-plans/' + id + '/map');
          }
        } catch (error) {
          console.log(error);
        } finally {
          setSplashLoading(false);
        }
      };

      loadRoutePlan();
    }
  }, [id]);

  useEffect(() => {
    if (Object.keys(routePlan).length > 0) {
      formPlan.setFieldsValue({
        nombre: routePlan.nombre,
        comentarios: routePlan.comentarios,
        fecha_plan: moment(routePlan.fecha_plan),
      });
    }
  }, [routePlan]);

  if (splashLoading)
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Spin size="large" />
        </div>
      </div>
    );

  return (
    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem', height: '100%' }}>
      <Row gutter={[20, 15]}>
        <Col xs={18} md={{ span: 18 }}>
          <h1
            style={{
              fontSize: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              height: '100%',
              gap: '2rem',
            }}
          >
            Plan de ruta
          </h1>
        </Col>
        <Col
          xs={6}
          md={{ span: 6 }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{ borderRadius: '5px' }}
            onClick={() => navigate(-1)}
            icon={<RollbackOutlined />}
          />
        </Col>

        <Col xs={24} md={{ span: 12, offset: 6 }}>
          <Steps current={currentStep}>
            <Steps.Step
              title={
                <span
                  style={{
                    color:
                      currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                  }}
                >
                  Plan
                </span>
              }
            />
            <Steps.Step
              title={
                <span
                  style={{
                    color:
                      currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                  }}
                >
                  Qué y dónde
                </span>
              }
            />
            <Steps.Step
              title={
                <span
                  style={{
                    color:
                      currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                  }}
                >
                  Personas
                </span>
              }
            />
          </Steps>
        </Col>
        <Col
          xs={24}
          md={{ span: 12, offset: 6 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '3rem',
          }}
        >
          {currentStep === 0 && (
            <Div
              className="steps-content"
              style={{ flex: 1 }}
              theme={currentTheme}
            >
              <Form
                form={formPlan}
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                <Form.Item
                  label={
                    <span
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                    >
                      Fecha
                    </span>
                  }
                  name="fecha_plan"
                  rules={[{ required: true, message: 'Ingrese la fecha' }]}
                >
                  <DatePicker
                    style={{
                      width: '100%',
                      backgroundColor:
                        currentTheme === Themes.DARK_THEME
                          ? '#2A2D2F'
                          : 'white',
                      color:
                        currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                      borderColor:
                        currentTheme === Themes.DARK_THEME
                          ? '#333333'
                          : '#d9d9d9',
                    }}
                    placeholder="Seleccione la fecha"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                    >
                      Nombre
                    </span>
                  }
                  name="nombre"
                  rules={[{ required: true, message: 'Ingrese el nombre' }]}
                >
                  <Input
                    style={{
                      backgroundColor:
                        currentTheme === Themes.DARK_THEME
                          ? '#2A2D2F'
                          : 'white',
                      color:
                        currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                      borderColor:
                        currentTheme === Themes.DARK_THEME
                          ? '#333333'
                          : '#d9d9d9',
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                      }}
                    >
                      Comentarios
                    </span>
                  }
                  name="comentarios"
                >
                  <Input.TextArea
                    style={{
                      backgroundColor:
                        currentTheme === Themes.DARK_THEME
                          ? '#2A2D2F'
                          : 'white',
                      color:
                        currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                      borderColor:
                        currentTheme === Themes.DARK_THEME
                          ? '#333333'
                          : '#d9d9d9',
                    }}
                  />
                </Form.Item>
              </Form>
            </Div>
          )}

          {currentStep === 1 && (
            <FormStepTwo
              form={formTwoStep}
              routePlanId={currentId}
              currentDate={formPlan.getFieldsValue().fecha_plan || null}
              onChangeCampaign={(campaign: any) => {
                setRoutePlanCampaign(campaign);
              }}
            />
          )}

          {currentStep === 2 && (
            <FormStepThree form={formThreeStep} routePlanId={currentId} />
          )}
          <div
            className="steps-action"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'flex-end',
            }}
          >
            {currentStep > 0 && (
              <Button
                style={{ margin: '0 8px', borderRadius: '5px' }}
                onClick={() => prev()}
              >
                Atrás
              </Button>
            )}
            {currentStep < 2 && (
              <Button
                type="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => next()}
                loading={loading}
                className="onfield-button"
              >
                Siguiente
              </Button>
            )}
            {currentStep === 2 && (
              <Button
                type="primary"
                style={{ borderRadius: '5px' }}
                onClick={() => start()}
                loading={loading}
                className="onfield-button"
              >
                Comenzar
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Create;
