import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18n from '../i18n';
import { selectCurrentUser } from '../store/auth/auth.selectors';

const useLanguage = () => {
  const test = useSelector(selectCurrentUser);
  i18n.language = 'es';
  const { t } = useTranslation('translation', { i18n });
  return t;
};

export default useLanguage;
