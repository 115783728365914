import { onValue, ref, get } from 'firebase/database';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { database } from '../firebase/firebase';
import { instance } from '../http/axios';

const useUserOnline = (userId: number | undefined) => {
  const [userOnline, setUserOnline] = useState<boolean>(false);

  useEffect(() => {
    /* const firstLoad = async () => {
        const userStatusDatabaseRef = ref(
          database,
          '/status/' + instance + '/' + userId
        );
        const snapshot = await get(userStatusDatabaseRef);
        if (snapshot.val()) {
          setUserOnline(snapshot.val().state === 'online');
        }
      };
      firstLoad(); */
    const userStatusDatabaseRef = ref(
      database,
      '/status/' + instance + '/' + userId
    );
    const suscription = onValue(userStatusDatabaseRef, (snapshot) => {
      if (snapshot.val()) {
        setUserOnline(snapshot.val().state === 'online');
      }
    });
    return () => {
      suscription();
    };
  }, []);

  return [userOnline] as const;
};

export default useUserOnline;
