import { Commune } from '../../../domain/models/Commune';
import { Region } from '../../../domain/models/Region';
import { Zone } from '../../../domain/models/Zone';
import { http } from '../../http/axios';

export const findAllRegion = async (): Promise<Region[]> => {
  return await http.get<Region[]>('/regions');
};

export const findCommunesByRegion = async (
  regionId: number
): Promise<Commune[]> => {
  return await http.get<Commune[]>(`/regions/${regionId}/communes`);
};

export const findAllZones = async (): Promise<Zone[]> => {
  return await http.get<Zone[]>('/zones?q=zones');
};

export const findZonesByRegion = async (
  regionId: number
): Promise<Commune[]> => {
  return await http.get<Commune[]>(`/regions/${regionId}/zones`);
};

export const findZoneById = async (zoneId: number): Promise<Zone> => {
  return await http.get<Zone>(`/zones/${zoneId}`);
};
