import axios, { AxiosRequestHeaders } from 'axios';
//const urlServer = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // Default
const urlServer =
  process.env.REACT_APP_API_URL ||
  'https://jv6kbejr4l.execute-api.us-east-1.amazonaws.com/dev'; // Default

export const instance =
  window.location.hostname.split('.').slice(0, -2).join('.') || 'grupokameidqa';

export const S3_BUCKET =
  process.env.REACT_APP_S3_URL ||
  'https://onfield-v3-storage.s3.amazonaws.com/';

const headers: AxiosRequestHeaders = {
  'Content-Type': 'application/json',
  instance: instance,
};

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

function onTokenRefreshed(token: string) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    const tokenLocalStorage = localStorage.getItem('onfieldToken');

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          refreshSubscribers.push((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
      }

      const lastStaySession = parseInt(
        localStorage.getItem('lastStaySession') || Date.now().toString()
      );

      const diffLastStaySession = Math.round(
        (Date.now() - lastStaySession) / 1000
      );
      let errorMessage = `${error?.response?.data?.message}`;

      if (
        error.response.status === 401 &&
        diffLastStaySession < 300 &&
        !errorMessage.includes('ha sido iniciada') &&
        !errorMessage.includes('Usuario o contraseña incorrectos')
      ) {
        //FIXME: debe ser 3600 para 1h de actividad minima
        originalRequest._retry = true;
        isRefreshing = true;

        try {
          if (!tokenLocalStorage) throw new Error('Unauthorized');
          const refreshToken = localStorage.getItem('onfieldRefreshToken');
          const response = await axios.post<any>(
            `${urlServer}/signin/refresh`,
            { refreshToken },
            { headers }
          );

          localStorage.setItem('onfieldToken', response.data.token);
          localStorage.setItem(
            'onfieldRefreshToken',
            response.data.refreshToken
          );
          localStorage.setItem('onfieldLegacyToken', response.data.cookie);
          onTokenRefreshed(response.data.token);
          return axios(originalRequest);
        } catch (refreshError) {
          console.error('Error refreshing token', refreshError);
          localStorage.removeItem('onfieldToken');
          localStorage.removeItem('onfieldRefreshToken');
          localStorage.removeItem('onfieldLegacyToken');
          localStorage.removeItem('lastStaySession');

          if (errorMessage === 'Unauthorized') {
            errorMessage = 'Tu sesión ha expirado. Ingresa nuevamente';
          }
          window.location.href = '/signin?exception=' + errorMessage;
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else if (
        errorMessage.includes('iniciada en otro dispositivo') &&
        error.response.status === 401
      ) {
        localStorage.removeItem('onfieldToken');
        localStorage.removeItem('onfieldRefreshToken');
        localStorage.removeItem('onfieldLegacyToken');
        localStorage.removeItem('lastStaySession');
        window.location.href = '/signin?exception=' + errorMessage;
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('onfieldToken');
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

const get = async <T>(url: string, signal?: AbortSignal) => {
  const { data } = await axios.get<T>(`${urlServer}${url}`, {
    headers,
    signal,
  });
  return data;
};

const post = async <T>(url: string, body: any) => {
  const { data } = await axios.post<T>(`${urlServer}${url}`, body, {
    headers,
  });
  return data;
};

const put = async <T>(url: string, body: any) => {
  const { data } = await axios.put<T>(`${urlServer}${url}`, body, {
    headers,
  });
  return data;
};

const _delete = async <T>(url: string) => {
  const { data } = await axios.delete<T>(`${urlServer}${url}`, {
    headers,
  });
  return data;
};

export const http = {
  get,
  post,
  put,
  delete: _delete,
};
