import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const selectChat = (state: AppState) => state.chat;
export const selectChats = createSelector([selectChat], (chat) => chat.chats);
export const selectChatLoading = createSelector(
  [selectChat],
  (chat) => chat.loading
);
export const selectChatError = createSelector(
  [selectChat],
  (chat) => chat.error
);
export const selectOpenedChats = createSelector(
  [selectChat],
  (chat) => chat.openedChats
);
export const massiveChats = createSelector(
  [selectChat],
  (chat) => chat.massive
);