import { DashboardWidget } from '../../../domain/models/DashboardWidget';
import * as dashboardTypes from './dashboard.types';

export interface DashboardState {
  widgets: DashboardWidget[];
  loading: boolean;
  error: string | null;
}

const initialState: DashboardState = {
  widgets: [],
  loading: false,
  error: null,
};

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case dashboardTypes.GET_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case dashboardTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        widgets: action.payload,
      };
    case dashboardTypes.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
