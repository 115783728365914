import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { http } from '../../http/axios';

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: 'ascend' | 'descend';
  filters?: Record<string, FilterValue>;
}

const AdminProfiles = () => {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const navigate = useNavigate();

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'role_id',
      key: 'role_id',
    },
    {
      title: 'Perfil',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (value, record) => (
        <>
          <Button
            type="primary"
            onClick={() => navigate(`/admin/profiles/${record.role_id}`)}
            className="onfield-button"
            icon={<EditOutlined />}
          />
        </>
      ),
    },
  ];

  const deleteProfiles = () => {};

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response: any[] = await http.get('/roles');
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  return (
    <div style={{ padding: '0 1.5rem 1.5rem 1.5rem' }}>
      <Row gutter={[20, 15]}>
        <Col xs={24} md={{ span: 18 }}>
          <h1
            style={{
              fontSize: '24px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
            }}
          >
            Administrar Perfiles
          </h1>
        </Col>
        <Col
          xs={6}
          md={{ span: 6 }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '.5rem',
          }}
        >
          <Button
            onClick={() => navigate('create')}
            type="primary"
            icon={<PlusOutlined />}
            className="onfield-button"
          />
          <Button
            onClick={() => deleteProfiles()}
            type="primary"
            style={{ backgroundColor: '#f56b6b', border: '1px solid #f24545' }}
            icon={<DeleteOutlined />}
            className="onfield-button"
            disabled={selectedRows.length === 0}
          />
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={tableParams.pagination}
            onChange={() => {}}
            rowKey={(item) => item.role_id}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminProfiles;
