import './style.css';
import { Button, Col, Row, Segmented, Select } from 'antd';
import Card from '../../../Card';
import GoogleMapReact from 'google-map-react';
import { MoreOutlined } from '@ant-design/icons';
import { WidgetMapActivity } from '../../../../../domain/models/widgets/MapActivity';
import { FC, LegacyRef, useEffect, useRef, useState, useCallback } from 'react';
import { http } from '../../../../http/axios';
import moment from 'moment';
import { SegmentedValue } from 'antd/lib/segmented';
import Marker from './Marker';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import hexToRgb from '../../../../libs/hexToRgb';
import useLanguage from '../../../../hooks/useLanguage';
import MapConfig, { ConfigCommune, ConfigRegion, ConfigZone } from './Config';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { Themes } from '../../../../store/ui';
import externalKeys from '../../../../../infrastructure/const/externalKeys';

type Props = {
  clientsId: number[];
};

const ActivitiesMapWidget: FC<Props> = ({ clientsId }) => {
  const map: LegacyRef<any> = useRef(null);
  const [data, setData] = useState<WidgetMapActivity[]>([]);
  const [status, setStatus] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([-1]);
  const [showConfig, setShowConfig] = useState(false);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({ startAt: '', endAt: '' });
  const loadingRef = useRef<LoadingBarRef>(null);
  const [centerMap, setCenterMap] = useState({
    lat: -16.0503176,
    lng: -75.6246669,
  });
  const [zoom, setZoom] = useState(1);
  const [heatmapData, setHeatmapData] = useState<any>({
    positions: [],
    options: {
      radius: 10,
      opacity: 1,
    },
  });
  const [isHeatmap] = useState(true);
  const [polygons, setPolygons] = useState<any[]>([]);
  const [polygonsRegion, setPolygonsRegion] = useState<google.maps.Polygon[]>(
    []
  );
  const [infoWindow] = useState(new google.maps.InfoWindow());
  const currentTheme = useSelector(selectUiTheme);
  const t = useLanguage();

  const handleApiLoaded = (map: any, maps: any) => {
    // use map and maps objects
    //console.log(map, maps);
  };

  const handleChangeStatus = (value: number[]) => {
    const hasAll = value.includes(-1);
    if (hasAll && value.length > 1) {
      value = value.filter((item) => item !== -1);
    }

    if (value.length === 0) {
      value = [-1];
    }
    setSelectedStatus(value);
  };

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === t('today', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === '7d') {
      setFilterDate({
        startAt: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === '30d') {
      setFilterDate({
        startAt: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    }
  };

  const handleChangeConfigZone = (config: ConfigZone) => {
    if (polygons.length > 0) {
      polygons.forEach((item) => {
        item.setMap(null);
      });
      setPolygons([]);
    }

    infoWindow.close();

    //if (config.showPolygons) {
    //TODO: Mostrar poligonos de rutas
    if (config.zone.length > 0) {
      const boundForZoom = new google.maps.LatLngBounds();
      for (const item of config.zone) {
        const bound = new google.maps.LatLngBounds();
        const boundZone = new google.maps.LatLngBounds();
        const polygonCoords: any[] = [];
        let coords = [];
        if (`${item.area}`.startsWith('MULTIPOLYGON(((')) {
          coords = `${item.area}`
            .replace('MULTIPOLYGON(((', '')
            .replace(')))', '')
            .split(',');
        } else {
          coords = `${item.area}`
            .replace('POLYGON((', '')
            .replace('))', '')
            .split(',');
        }

        for (const coord of coords) {
          const [lng, lat] = coord
            .replace('((', '')
            .replace('))', '')
            .split(' ');

          const parsedLng = parseFloat(lng);
          const parsedLat = parseFloat(lat);

          if (!isNaN(parsedLng) && !isNaN(parsedLat)) {
            bound.extend(new google.maps.LatLng(parsedLat, parsedLng));
            boundZone.extend(new google.maps.LatLng(parsedLat, parsedLng));
            boundForZoom.extend(new google.maps.LatLng(parsedLat, parsedLng));
            polygonCoords.push(new google.maps.LatLng(parsedLat, parsedLng));
          }

          /* polygonCoords.push({
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          }); */
        }

        const rgba = hexToRgb(item.color);

        const zonePolygon = new google.maps.Polygon({
          paths: polygonCoords,
          strokeColor: `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, 0.5)`,
          strokeOpacity: 0.6,
          strokeWeight: 2,
          fillColor: `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, 0.4)`,
          fillOpacity: 0.35,
        });
        zonePolygon.setMap(map.current.map_);
        setPolygons((prev) => [...prev, zonePolygon]);
        google.maps.event.addListener(zonePolygon, 'click', (evt) => {
          infoWindow.setContent(item.label);
          infoWindow.setPosition(boundZone.getCenter());
          infoWindow.open(map.current.map_);
        });
        // google.maps.event.addListener(mrpdPolygon, 'mousemove', mousefn);
        google.maps.event.addListener(zonePolygon, 'mouseout', (evt) => {
          infoWindow.close();
        });
      }

      if (config.withZoom) {
        map.current.map_.fitBounds(boundForZoom);
        setZoom(map.current.map_.getZoom());
        setCenterMap({
          lat: boundForZoom.getCenter().lat(),
          lng: boundForZoom.getCenter().lng(),
        });
      }
    }
    /* } else {
      if (polygons.length > 0) {
        polygons.forEach((item) => {
          item.setMap(null);
        });
        setPolygons([]);
      }
    } */
  };

  const handleChangeConfigRegion = (config: ConfigRegion) => {
    if (polygonsRegion.length > 0) {
      polygonsRegion.forEach((item) => item.setMap(null));
      setPolygonsRegion([]);
    }

    infoWindow.close();

    if (config.region.length > 0) {
      const bound = new google.maps.LatLngBounds();
      let polygonCoords: any[] = [];

      for (const zona of config.region) {
        const boundZone = new google.maps.LatLngBounds();
        let coords = [];
        if (`${zona.area}`.startsWith('MULTIPOLYGON(((')) {
          coords = `${zona.area}`
            .replace('MULTIPOLYGON(((', '')
            .replace(')))', '')
            .split(',');
        } else {
          coords = `${zona.area}`
            .replace('POLYGON((', '')
            .replace('))', '')
            .split(',');
        }

        for (const coord of coords) {
          const [lng, lat] = coord
            .replace('((', '')
            .replace('))', '')
            .split(' ');

          const parsedLng = parseFloat(lng);
          const parsedLat = parseFloat(lat);

          if (!isNaN(parsedLng) && !isNaN(parsedLat)) {
            bound.extend(new google.maps.LatLng(parsedLat, parsedLng));
            boundZone.extend(new google.maps.LatLng(parsedLat, parsedLng));
            polygonCoords.push(new google.maps.LatLng(parsedLat, parsedLng));
          }
        }

        if (config.show) {
          const rgba = hexToRgb(zona.color);

          const zonePolygon = new google.maps.Polygon({
            paths: polygonCoords,
            strokeColor: `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, 0.5)`,
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, 0.4)`,
            fillOpacity: 0.35,
          });

          if (config.commune) {
            const centroRegion = zonePolygon
              .getPath()
              .getArray()
              .reduce(
                function (accumulator, currentValue) {
                  return {
                    lat: accumulator.lat + currentValue.lat(),
                    lng: accumulator.lng + currentValue.lng(),
                  };
                },
                { lat: 0, lng: 0 }
              );
            const puntoCentroRegion = new google.maps.LatLng(
              centroRegion.lat / zonePolygon.getPath().getLength(),
              centroRegion.lng / zonePolygon.getPath().getLength()
            );

            for (const area of config.commune.area) {
              const polygonCoords: google.maps.LatLng[] = [];
              for (const a of area) {
                if (Array.isArray(a)) {
                  a.forEach((b: any) => {
                    polygonCoords.push(new google.maps.LatLng(b.y, b.x));
                  });
                } else {
                  polygonCoords.push(new google.maps.LatLng(a.y, a.x));
                }
              }

              const polygon = new google.maps.Polygon({ paths: polygonCoords });
              if (
                google.maps.geometry.poly.containsLocation(
                  puntoCentroRegion,
                  polygon
                )
              ) {
                zonePolygon.setMap(map.current.map_);
              }
            }
          } else {
            zonePolygon.setMap(map.current.map_);
          }
          setPolygonsRegion((oldValue) => [...oldValue, zonePolygon]);
          google.maps.event.addListener(zonePolygon, 'click', (evt) => {
            infoWindow.setContent(zona.label);
            infoWindow.setPosition(boundZone.getCenter());
            infoWindow.open(map.current.map_);
          });
          // google.maps.event.addListener(mrpdPolygon, 'mousemove', mousefn);
          google.maps.event.addListener(zonePolygon, 'mouseout', (evt) => {
            infoWindow.close();
          });
        }

        polygonCoords = [];
      }

      map.current.map_.fitBounds(bound);
      setZoom(map.current.map_.getZoom());
      setCenterMap({
        lat: bound.getCenter().lat(),
        lng: bound.getCenter().lng(),
      });
    }
  };

  const handleChangeConfigCommune = (value: ConfigCommune) => {
    if (value.commune && !Array.isArray(value.commune)) {
      const bound = new google.maps.LatLngBounds();

      value.commune.area.forEach((area: any) => {
        area.forEach((a: any) => {
          if (Array.isArray(a)) {
            a.forEach((b: any) => {
              bound.extend(new google.maps.LatLng(b.y, b.x));
            });
          } else {
            bound.extend(new google.maps.LatLng(a.y, a.x));
          }
        });
      });

      if (value.show) {
        for (const region of polygonsRegion) {
          region.setMap(null);
          const centroRegion = region
            .getPath()
            .getArray()
            .reduce(
              function (accumulator, currentValue) {
                return {
                  lat: accumulator.lat + currentValue.lat(),
                  lng: accumulator.lng + currentValue.lng(),
                };
              },
              { lat: 0, lng: 0 }
            );
          const puntoCentroRegion = new google.maps.LatLng(
            centroRegion.lat / region.getPath().getLength(),
            centroRegion.lng / region.getPath().getLength()
          );

          for (const area of value.commune.area) {
            const polygonCoords: google.maps.LatLng[] = [];
            for (const a of area) {
              if (Array.isArray(a)) {
                a.forEach((b: any) => {
                  polygonCoords.push(new google.maps.LatLng(b.y, b.x));
                });
              } else {
                polygonCoords.push(new google.maps.LatLng(a.y, a.x));
              }
            }

            const polygon = new google.maps.Polygon({ paths: polygonCoords });
            if (
              google.maps.geometry.poly.containsLocation(
                puntoCentroRegion,
                polygon
              )
            ) {
              region.setMap(map.current.map_);
            }
          }
        }
      }

      map.current.map_.fitBounds(bound);
      setZoom(map.current.map_.getZoom());
      setCenterMap({
        lat: bound.getCenter().lat(),
        lng: bound.getCenter().lng(),
      });
    }
  };

  const themeControlsGoogleMaps = useCallback(() => {
    if (currentTheme === Themes.DARK_THEME) {
      document
        .querySelectorAll('button.gm-control-active')
        .forEach((item: any) => {
          item.style.background = 'none rgb(0, 0, 0)';
          item.style.border = '1px solid rgb(0, 0, 0)';
          item.style.color = '#fff';
        });
    } else {
      document
        .querySelectorAll('button.gm-control-active')
        .forEach((item: any) => {
          item.style.background = 'none rgb(255, 255, 255)';
          item.style.border = '1px solid rgb(255, 255, 255)';
          item.style.color = '#000';
        });
    }

    const borders: any = document.querySelectorAll('div.gmnoprint>div');

    if (borders[3]) {
      borders[3].style.backgroundColor = 'transparent';
      if (borders[3].childNodes[1]) {
        borders[3].childNodes[1].style.backgroundColor = 'transparent';
      }
    }
  }, [currentTheme]);

  useEffect(() => {
    setFilterDate({
      startAt: moment().format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (filterDate.startAt !== '' && filterDate.endAt !== '') {
      setData([]);
      setHeatmapData({
        ...heatmapData,
        positions: [],
      });
      if (loadingRef.current) loadingRef.current.continuousStart(0, 1000);
      let query = '';
      if (selectedStatus.length > 0) {
        query += `status=${selectedStatus.join(',')}`;
      }

      const clientsQuery = clientsId
        .map((client) => {
          return `client[]=${client}`;
        })
        .join('&');

      http
        .get<{ activities: WidgetMapActivity[]; availableStatus: any[] }>(
          `/widgets/map-activities/${filterDate.startAt}/${
            filterDate.endAt
          }/query?${clientsQuery}${query.length > 0 ? `&${query}` : ''}`,
          controller.signal
        )
        .then(({ activities, availableStatus }) => {
          let gradient = [
            'rgba(0, 255, 255, 0)',
            'rgba(0, 63, 255, 0.7)',
            'rgba(0, 63, 255, 0.7)',
            'rgba(0, 63, 255, 0.5)',
            'rgba(0, 63, 255, 0.5)',
            'rgba(0, 63, 255, 0.5)',
            'rgba(0, 63, 255, 0.5)',
            'rgba(0, 63, 255, 0.5)',
            'rgba(0, 0, 159, 1)',
            'rgba(0, 0, 159, 1)',
            'rgba(0, 0, 127, 1)',
            'rgba(0, 0, 127, 1)',
            'rgba(0, 0, 127, 1)',
            'rgba(0, 0, 127, 1)',
          ];

          if (activities.length > 0) {
            const bound = new google.maps.LatLngBounds();

            activities.forEach((item) => {
              bound.extend(new google.maps.LatLng(item.lat, item.lng));
            });

            if (!selectedStatus.includes(-1) && selectedStatus.length === 1) {
              const color = hexToRgb('#' + activities[0].color);
              gradient = [
                'rgba(0, 255, 255, 0)',
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.7)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.7)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.5)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.5)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.5)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.5)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 0.5)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 1)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 1)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 1)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 1)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 1)`,
                `rgba(${color?.r}, ${color?.g}, ${color?.b}, 1)`,
              ];
            }
            map.current.map_.fitBounds(bound);
            setZoom(map.current.map_.getZoom());
            map.current.map_.setZoom(map.current.map_.getZoom());
            setCenterMap({
              lat: bound.getCenter().lat(),
              lng: bound.getCenter().lng(),
            });
          }
          setData(activities);
          setStatus(availableStatus);
          setHeatmapData({
            ...heatmapData,
            positions: activities.map((item) => ({
              lat: item.lat,
              lng: item.lng,
            })),
            options: {
              ...heatmapData.options,
              gradient,
            },
          });
          if (loadingRef.current) loadingRef.current.complete();
        });
    }
    return () => {
      controller.abort();
    };
  }, [filterDate, selectedStatus, clientsId]);

  return (
    <>
      <Card>
        <LoadingBar
          shadow={false}
          containerClassName="onfield-loading"
          ref={loadingRef}
          color="#1890ff"
        />
        <div style={{ padding: '.5rem 1rem', width: '100%', height: '100%' }}>
          <Row>
            <Col
              span={11}
              style={{
                fontSize: '16px',
                fontWeight: '500',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              className="text"
            >
              {t('map_activities.title', { ns: 'widgets' })}
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Select
                className="filter-map-activities text"
                size="small"
                value={selectedStatus}
                mode="multiple"
                maxTagCount="responsive"
                placeholder="Seleccione"
                style={{ width: '100%' }}
                onChange={handleChangeStatus}
                onSelect={(value: number) => {
                  if (value === -1) {
                    setSelectedStatus([-1]);
                  }
                }}
              >
                <Select.Option value={-1}>
                  {t('all', { ns: 'common' })}
                </Select.Option>
                {status.map((item) => (
                  <Select.Option key={item.id_estado} value={item.id_estado}>
                    {item.nombre}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Segmented
                className="onfield-segmented text"
                size="small"
                options={[t('today', { ns: 'common' }), '7d', '30d']}
                defaultValue={t('today', { ns: 'common' })}
                onChange={handleChangeSegmented}
              />
            </Col>
            <Col
              span={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={() => setShowConfig(true)}
                icon={<MoreOutlined />}
                size="small"
                type="text"
                className="text"
              />
            </Col>
          </Row>
          <Row
            style={{ paddingTop: '.5rem', height: '92%' }}
            className="map-widget-container"
          >
            <Col span={24}>
              <GoogleMapReact
                ref={map}
                bootstrapURLKeys={{
                  key: externalKeys.google.API_KEY_MAP,
                  libraries: ['visualization', 'drawing'],
                }}
                defaultCenter={{ lat: -16.0503176, lng: -75.6246669 }}
                defaultZoom={1}
                zoom={zoom}
                center={centerMap}
                yesIWantToUseGoogleMapApiInternals
                onTilesLoaded={() => {
                  setTimeout(() => {
                    themeControlsGoogleMaps();
                    const footerGoogleMap: any = document.querySelector(
                      "div[style='display: inline-flex; position: absolute; right: 0px; bottom: 0px;']"
                    );
                    if (footerGoogleMap) {
                      footerGoogleMap.style.display = 'none';
                    }
                  }, 50);
                }}
                onGoogleApiLoaded={({ map, maps }) => {
                  handleApiLoaded(map, maps);
                }}
                style={{ width: '100%', height: '21rem' }}
                heatmap={heatmapData}
                options={{
                  styles:
                    currentTheme === Themes.DARK_THEME
                      ? [
                          {
                            elementType: 'geometry',
                            stylers: [{ color: '#242f3e' }],
                          },
                          {
                            elementType: 'labels.text.stroke',
                            stylers: [{ color: '#242f3e' }],
                          },
                          {
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#746855' }],
                          },
                          {
                            featureType: 'administrative.locality',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#d59563' }],
                          },
                          {
                            featureType: 'poi',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#d59563' }],
                          },
                          {
                            featureType: 'poi.park',
                            elementType: 'geometry',
                            stylers: [{ color: '#263c3f' }],
                          },
                          {
                            featureType: 'poi.park',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#6b9a76' }],
                          },
                          {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#38414e' }],
                          },
                          {
                            featureType: 'road',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#212a37' }],
                          },
                          {
                            featureType: 'road',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9ca5b3' }],
                          },
                          {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [{ color: '#746855' }],
                          },
                          {
                            featureType: 'road.highway',
                            elementType: 'geometry.stroke',
                            stylers: [{ color: '#1f2835' }],
                          },
                          {
                            featureType: 'road.highway',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#f3d19c' }],
                          },
                          {
                            featureType: 'transit',
                            elementType: 'geometry',
                            stylers: [{ color: '#2f3948' }],
                          },
                          {
                            featureType: 'transit.station',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#d59563' }],
                          },
                          {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#17263c' }],
                          },
                          {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#515c6d' }],
                          },
                          {
                            featureType: 'water',
                            elementType: 'labels.text.stroke',
                            stylers: [{ color: '#17263c' }],
                          },
                          {
                            featureType: 'poi',
                            stylers: [{ visibility: 'off' }],
                          },
                        ]
                      : [],
                }}
              >
                {/* Marcadores */}
                {!isHeatmap && (
                  <>
                    {data.map((item, index) => (
                      <Marker
                        key={index}
                        title={''}
                        lat={item.lat}
                        lng={item.lng}
                      />
                    ))}
                  </>
                )}
              </GoogleMapReact>
            </Col>
          </Row>
        </div>
      </Card>
      <MapConfig
        visible={showConfig}
        onCancel={(visible) => setShowConfig(visible)}
        onChangeZone={(value) => handleChangeConfigZone(value)}
        onChangeCommune={(value) => handleChangeConfigCommune(value)}
        onChangeRegion={(value) => handleChangeConfigRegion(value)}
        onZoomGeneral={() => {
          if (data.length > 0) {
            const bound = new google.maps.LatLngBounds();
            data.forEach((item) => {
              bound.extend(new google.maps.LatLng(item.lat, item.lng));
            });

            map.current?.map_?.fitBounds(bound);
          } else {
            setZoom(1);
            setCenterMap({ lat: -16.0503176, lng: -75.6246669 });
          }
        }}
      />
    </>
  );
};

export default ActivitiesMapWidget;
