import React from 'react';
import android from './images/android.png';
import logo_onfield_negro from './images/logo_onfield_negro.png';
import ios from './images/ios.png';
import { instance, S3_BUCKET } from '../../http/axios';
import './index.css';

// Titulo de la Web
const APP_TITLE = 'Onfield';
const logo = S3_BUCKET + instance + '/logo.png';

function Page() {
  return (
    <body
      className="backgroundPage"
      style={{
        backgroundImage: `url(https://source.unsplash.com/collection/145103/computers-phones-and-tech)`,
      }}
    >
      <div className="sp"></div>
      <div className="box-page login-box-page ">
        <div className="box">
          <div style={{ textAlign: 'center' }}>
            <img
              style={{
                position: `relative`,
                top: `-Xpx`,
                width: `150px`,
                margin: `0px 0px 10px 0px`,
              }}
              className="nav_logo"
              src={logo_onfield_negro}
              alt=""
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <img
              style={{
                position: `relative`,
                top: `-Xpx`,
                width: `150px`,
              }}
              className="nav_logo"
              src={logo}
              alt=""
            />
          </div>
          <div className="form-container" style={{ textAlign: 'center' }}>
            {/*<h1>App {instance}</h1>*/}
            <h2>App {APP_TITLE}</h2>
            <h1>Selecciona tu plataforma</h1>
            <a href="android">
              <img
                className="appicon"
                src={android}
                width="130"
                height="130"
                alt="Android"
              />
            </a>
            <br />
            <br />
            <a href="ios">
              <img
                className="appicon"
                src={ios}
                width="130"
                height="130"
                alt="iOS"
              />
            </a>
            <form id="loginForm" method="post" action="">
              <div>
                <br />
                <p>
                  Powered by{' '}
                  <a
                    href="https://assertsoft.com"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Assertsoft
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sp"></div>
    </body>
  );
}

export default Page;
