import { Menu } from '../../../domain/models/Menu';
import * as uiTypes from './ui.types';

export enum Themes {
  LIGH_THEME = 'LIGH_THEME',
  DARK_THEME = 'DARK_THEME',
}

export interface UiState {
  collapsedLeftPanel: boolean;
  collapsedChatRightPanel: boolean;
  collapsedOnlineTrackingRightPanel: boolean;
  firstLoadOnlineTracking: boolean;
  lastMenuKeyAfterOnlineTracking: string[];
  isOnlineTracking: boolean;
  loading: boolean;
  theme: Themes;
  leftMenu: Menu[];
  configuration: any[];
}

const initialState: UiState = {
  collapsedLeftPanel: false,
  collapsedChatRightPanel: true,
  collapsedOnlineTrackingRightPanel: true,
  firstLoadOnlineTracking: true,
  lastMenuKeyAfterOnlineTracking: ['dashboard'],
  isOnlineTracking: false,
  loading: false,
  theme: (localStorage.getItem('onfield-theme') as any) ?? Themes.LIGH_THEME,
  leftMenu: [],
  configuration: [],
};

const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case uiTypes.SET_COLLAPSED_LEFT_PANEL:
      return {
        ...state,
        collapsedLeftPanel: action.payload,
      };

    case uiTypes.SET_COLLAPSED_CHAT_RIGHT:
      return {
        ...state,
        collapsedChatRightPanel: action.payload,
      };

    case uiTypes.SET_IS_ONLINE_TRACKING:
      return {
        ...state,
        isOnlineTracking: action.payload,
      };

    case uiTypes.SET_IS_FIRST_LOAD_ONLINE_TRACKING:
      return {
        ...state,
        firstLoadOnlineTracking: action.payload,
      };

    case uiTypes.SET_COLLAPSED_ONLINE_TRACKING_RIGHT:
      return {
        ...state,
        collapsedOnlineTrackingRightPanel: action.payload,
      };

    case uiTypes.SET_KEY_MENU_AFTER_ONLINE_TRACKING:
      return {
        ...state,
        lastMenuKeyAfterOnlineTracking: action.payload,
      };

    case uiTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case uiTypes.SET_THEME:
      localStorage.setItem('onfield-theme', action.payload);
      return {
        ...state,
        theme: action.payload,
      };

    case uiTypes.SET_MENU_LEFT_PANEL:
      return {
        ...state,
        leftMenu: action.payload,
      };

    case uiTypes.SET_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload,
      };

    case uiTypes.RESET_UI:
      return {
        ...state,
        leftMenu: [],
        configuration: [],
      };

    default:
      return state;
  }
};

export default uiReducer;
