import { Avatar, Badge, Dropdown, Menu, Spin, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Clock2Icon, PointsIcon, RefreshIcon } from '../../../components/icons';
import { instance } from '../../../http/axios';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { Themes } from '../../../store/ui';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../const/colors';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';

const calculeTimeAvg = (events: any[]) => {
  const resource = events.at(0).getResources().at(0);
  if (resource) {
    const secProm = resource.extendedProps.service;
    const diffInMinutes = Math.floor(secProm / 60);
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  return '-';
};

type Resource = {
  color: string;
  userImage: string;
  title: string;
  selected?: boolean;
  showUserLocation?: boolean;
  showRefresh?: boolean;
  showTooltipRefresh?: boolean;
  showChangePerson?: boolean;
  showRemove?: boolean;
  showChangePersonColor?: boolean;
  id: string;
  events: any[];
  routePerson?: any;
};

type Props = {
  arg: Resource;
  onClickGps?: () => void;
  onClickRefresh?: () => void;
  onClickName?: () => void;
  onClickChangePerson?: () => void;
  onClickRemovePerson?: () => void;
  onClickChangePersonColor?: () => void;
};

const ResourceLabel: FC<Props> = ({
  arg,
  onClickGps,
  onClickRefresh,
  onClickName,
  onClickChangePerson,
  onClickRemovePerson,
  onClickChangePersonColor,
}) => {
  const currentTheme = useSelector(selectUiTheme);
  const currentUser = useSelector(selectCurrentUser);
  const [configWeight, setConfigWeight] = useState<any>(null);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_plan_ruta = currentUser.instance?.parameters.find(
        (p: any) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (sw_modulo_plan_ruta) {
        const parameters = JSON.parse(sw_modulo_plan_ruta?.value6);
        if (typeof parameters.config_peso !== 'undefined') {
          setConfigWeight(parameters.config_peso);
        }
      }
    }
  }, [currentUser]);

  const menuMore = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => {
          if (onClickGps) onClickGps();
        }}
      >
        Cambiar posición GPS
      </Menu.Item>
      {arg.showChangePerson && (
        <Menu.Item
          key={2}
          onClick={() => {
            if (onClickChangePerson) onClickChangePerson();
          }}
        >
          Cambiar persona
        </Menu.Item>
      )}
      {arg.showChangePersonColor && (
        <Menu.Item
          key={3}
          onClick={() => {
            onClickChangePersonColor?.();
          }}
        >
          Cambiar color
        </Menu.Item>
      )}
      {arg.showRemove && (
        <Menu.Item
          key={4}
          onClick={() => {
            onClickRemovePerson?.();
          }}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: arg.selected ? 'rgba(0,0,0,0.2)' : 'transparent',
      }}
    >
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          marginTop: '5px',
          border: '2px solid ' + arg.color,
        }}
      ></div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 5,
          paddingTop: '0.5rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          paddingBottom: '2px',
        }}
      >
        {arg.showRefresh && (
          <div
            onClick={() => {
              if (onClickRefresh) onClickRefresh();
            }}
          >
            <div
              id={`load_indicator_${arg.id}`}
              style={{
                display: 'none',
                transition: '0.5s ease',
              }}
            >
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
              />
            </div>
            <div id={`icon_indicator_${arg.id}`}>
              {arg.showTooltipRefresh && (
                <Tooltip title="El usuario tiene nuevas entregadas en la agenda, presiona sincronizar para cargalas.">
                  <Badge
                    dot={arg.showTooltipRefresh || false}
                    offset={[-13, 0]}
                  >
                    <RefreshIcon
                      style={{
                        color:
                          currentTheme === Themes.DARK_THEME
                            ? 'white'
                            : 'black',
                        cursor: 'pointer',
                      }}
                    />
                  </Badge>
                </Tooltip>
              )}
              {!arg.showTooltipRefresh && (
                <RefreshIcon
                  style={{
                    color:
                      currentTheme === Themes.DARK_THEME ? 'white' : 'black',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem',
            flex: 1,
            width: '100%',
            cursor: 'pointer',
            overflow: 'hidden',
          }}
          onClick={() => {
            if (onClickName) onClickName();
          }}
        >
          {arg.userImage ? (
            <Avatar
              src={`https://${instance}.onfield.cl/efs/${instance}/image/?tipo=user&img=${arg.userImage}`}
              size={24}
              style={{ backgroundColor: arg.color }}
            />
          ) : (
            <Avatar
              style={{
                backgroundColor: arg.color,
              }}
              size={24}
            >
              {`${arg.title[0]}`.toUpperCase()}
            </Avatar>
          )}
          <div
            style={{
              width: '90%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            title={arg.title}
          >
            {arg.title}
          </div>
        </div>
        {arg.showUserLocation && (
          <Dropdown
            overlay={menuMore}
            trigger={['click']}
            placement="top"
            arrow
          >
            <FontAwesomeIcon
              icon={faEllipsis}
              style={{
                color:
                  currentTheme === Themes.DARK_THEME
                    ? colors.DARK_THEME.textColor
                    : colors.LIGHT_THEME.textColor,
                cursor: 'pointer',
                fontSize: '1.2rem',
              }}
            />
          </Dropdown>
        )}
      </div>
      <div
        style={{
          fontWeight: 'normal',
          backgroundColor:
            currentTheme === Themes.LIGH_THEME
              ? 'rgba(0, 0, 0, 0.07)'
              : 'rgba(255, 255, 255, 0.09)',
          color:
            currentTheme === Themes.LIGH_THEME ? 'rgba(0, 0, 0, 0.9)' : 'white',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center',
          paddingBottom: '5px',
          fontSize: '12px',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      >
        {arg.events.length === 0 && 'No asignado'}
        {arg.events.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '12px',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                gap: '2px',
              }}
            >
              <div>
                {
                  arg.events.filter(
                    (ev) => ev.extendedProps.extraParams !== null
                  ).length
                }
              </div>
              <div>
                <PointsIcon style={{ fontSize: '10px' }} />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              {Math.round(
                ((!arg.routePerson
                  ? arg.events
                      .filter((ev) => ev.extendedProps.extraParams !== null)
                      .reduce(
                        (a, b) => a + b.extendedProps.extraParams.distance,
                        1
                      )
                  : arg.routePerson.distance) /
                  1000) *
                  10
              ) / 10}
              Km
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                gap: '2px',
              }}
            >
              <div>{calculeTimeAvg(arg.events)}</div>
              <div>
                <Clock2Icon
                  style={{
                    fontSize: '10px',
                    color:
                      currentTheme === Themes.LIGH_THEME
                        ? 'rgba(0, 0, 0, 0.6)'
                        : 'rgba(255, 255, 255, 0.6)',
                  }}
                />
              </div>
            </div>
            {configWeight && configWeight.mostrar_indicador_usuario && (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
              >
                {Math.round(
                  arg.events
                    .filter((ev) => ev.extendedProps.extraParams !== null)
                    .reduce(
                      (a, b) => a + (b.extendedProps.extraParams?.weight || 0),
                      0
                    ) * 10
                ) / 10}
                {configWeight.glosa}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourceLabel;
