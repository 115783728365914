import { User } from '../../../domain/models/User';
import * as authTypes from './auth.types';

export interface AuthState {
  currentUser: User | null;
  loading: boolean;
}

const initialState: AuthState = {
  currentUser: null,
  loading: true,
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case authTypes.REMOVE_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
      };

    case authTypes.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
      };

    case authTypes.GET_CURRENT_USER:
      return {
        ...state,
        loading: true,
      };

    case authTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
