import './style.css';
import { Col, Empty, Row, Segmented, Skeleton } from 'antd';
import Card from '../../../Card';
import AverageTime from './AverageTime';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { WidgetAverageTime } from '../../../../../domain/models/widgets/AverageTime';
import moment from 'moment';
import { http } from '../../../../http/axios';
import { SegmentedValue } from 'antd/lib/segmented';
import useLanguage from '../../../../hooks/useLanguage';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { Themes } from '../../../../store/ui';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

type Props = {
  clientsId: number[];
};

const AverageTimeWidget: FC<Props> = ({ clientsId }) => {
  const [loading, setLoading] = useState(true);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({ startAt: '', endAt: '' });
  const [data, setData] = useState<WidgetAverageTime[]>([]);
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === t('today', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('week_short', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('week').format('YYYY-MM-DD'),
        endAt: moment().endOf('week').format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('month', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('month').format('YYYY-MM-DD'),
        endAt: moment().endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  const secondsToTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (seconds < 60) {
      return `${
        seconds < 10
          ? '0' + Math.round(seconds * 10) / 10
          : Math.round(seconds * 10) / 10
      } s`;
    }

    if (seconds < 3600) {
      return `${
        minutes < 10
          ? '0' + Math.round(minutes * 10) / 10
          : Math.round(minutes * 10) / 10
      } min`;
    }

    return `${
      hours < 10
        ? '0' + Math.round(hours * 10) / 10
        : Math.round(hours * 10) / 10
    }h ${
      minutes < 10
        ? '0' + Math.round(minutes * 10) / 10
        : Math.round(minutes * 10) / 10
    }m`;
  };

  const totalAverageTime = (data: WidgetAverageTime[]) => {
    return (
      data.reduce(
        (acc, val) =>
          acc + (val.end_time < 0 ? val.end_time * -1 : val.end_time),
        0
      ) / data.length
    );
  };

  const totalAverageEndTime = (data: WidgetAverageTime[]) => {
    return (
      data.reduce(
        (acc, val) =>
          acc +
          ((val.start_time < 0 ? val.start_time * -1 : val.start_time) +
            (val.end_time < 0 ? val.end_time * -1 : val.end_time)),
        0
      ) / data.length
    );
  };

  const totalAverageStartTime = (data: WidgetAverageTime[]) => {
    return (
      data.reduce(
        (acc, val) =>
          acc + (val.start_time < 0 ? val.start_time * -1 : val.start_time),
        0
      ) / data.length
    );
  };

  const totalAveragePercent = (data: WidgetAverageTime[]) => {
    return parseFloat(
      (
        data.reduce(
          (acc, val) =>
            acc +
            (val.percentDiff < 0 ? val.percentDiff * -1 : val.percentDiff),
          0
        ) / data.length
      ).toFixed(2)
    );
  };

  useEffect(() => {
    setFilterDate({
      startAt: moment().format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (filterDate.startAt && filterDate.endAt) {
      setLoading(true);
      setData([]);

      const clientsQuery = clientsId
        .map((client) => {
          return `client[]=${client}`;
        })
        .join('&');

      http
        .get<WidgetAverageTime[]>(
          `/widgets/average-time/${filterDate.startAt}/${filterDate.endAt}/query?${clientsQuery}`,
          controller.signal
        )
        .then((res) => {
          setData(
            res.map((item) => ({
              ...item,
              percentDiff:
                item.percentDiff < 0
                  ? item.percentDiff * -1
                  : item.percentDiff ?? 0,
            }))
          );
          setLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [filterDate, clientsId]);

  return (
    <Card>
      <div
        style={{
          padding: '.5rem 1rem',
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Row>
          <Col
            span={14}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            {t('average_time.title', { ns: 'widgets' })}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              defaultValue={t('today', { ns: 'common' })}
              onChange={handleChangeSegmented}
              size="small"
              options={[
                t('today', { ns: 'common' }),
                t('week_short', { ns: 'common' }),
                t('month', { ns: 'common' }),
              ]}
            />
          </Col>
        </Row>
        <Skeleton loading={loading} active>
          {data.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
              description={'Sin datos'}
            />
          ) : (
            <div
              style={{
                paddingTop: '.5rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ padding: '1rem 0' }}>
                <AverageTime
                  averageTime={() => (
                    <>
                      <span style={{ fontWeight: '500' }} className="text">
                        {secondsToTime(totalAverageTime(data))}
                      </span>{' '}
                      <span style={{ fontSize: '10px' }} className="text">
                        {totalAveragePercent(data) >= 0 ? (
                          <CaretUpOutlined style={{ color: 'green' }} />
                        ) : (
                          <CaretDownOutlined style={{ color: 'red' }} />
                        )}{' '}
                        {totalAveragePercent(data)}%
                      </span>
                    </>
                  )}
                  endAt={secondsToTime(totalAverageEndTime(data))}
                  startAt={secondsToTime(totalAverageStartTime(data))}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '.2rem',
                  paddingTop: '.2rem',
                }}
              >
                {data.map((item, index) => (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4, 1fr)',
                    }}
                    key={item.name}
                  >
                    <span
                      style={{
                        color: item.color,
                        gridColumnStart: '1',
                        gridColumnEnd: '3',
                      }}
                    >
                      {item.name}
                    </span>
                    <span
                      style={{
                        flex: 1,
                        textAlign: 'center',
                        color: item.color,
                      }}
                    >
                      {secondsToTime(item.end_time)}
                    </span>
                    <span style={{ textAlign: 'center', color: item.color }}>
                      {item.percentDiff >= 0 ? (
                        <CaretUpOutlined style={{ color: 'green' }} />
                      ) : (
                        <CaretDownOutlined style={{ color: 'red' }} />
                      )}{' '}
                      {item.percentDiff}%
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Skeleton>
      </div>
    </Card>
  );
};

export default AverageTimeWidget;
