import { DragOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Switch } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import ReactJson from 'react-json-view';
import { VROOM, Vehicle, Job } from './vroom';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

type Props = {
  json: any;
  data: VROOM;
  setEditor: (editor: JSONEditor) => void;
  setEnabled?: (enabled: boolean) => void;
};

const JsonRenderDrag: FC<Props> = ({ json, setEditor, data, setEnabled }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [enableVroom, setEnableVroom] = useState(false);
  const [vroom, setVroom] = useState<VROOM>({
    vehicles: [],
    shipments: [],
    jobs: [],
  });
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!data) return;
    /* const vehicles: Vehicle[] = data.users.map((user: any) => {
      return {
        id: user.user_id,
        description: `${user.user.firstname} ${user.user.lastname}`,
        start: [parseFloat(user.origen_lng), parseFloat(user.origen_lat)],
        end: [parseFloat(user.destino_lng), parseFloat(user.destino_lat)],
        capacity: [user.capacidad],
      } as Vehicle;
    });
    const jobs: Job[] = [];

    for (const user of data.users) {
      for (const visit of user.visits) {
        jobs.push({
          id: visit.local.id_local,
          description: visit.local.nombre,
          location: [
            parseFloat(visit.local.longitud),
            parseFloat(visit.local.latitud),
          ],
          service: 0,
          delivery: [1],
        } as Job);
      }
    } */

    setVroom(data);
  }, [data]);

  useEffect(() => {
    if (editorRef.current) {
      const editorJS = new JSONEditor(editorRef.current, {});
      editorJS.set(vroom);

      editorJS.getText();

      if (setEditor) setEditor(editorJS);

      return () => {
        editorJS.destroy();
      };
    }
  }, [vroom, editorRef, setEditor]);

  return (
    <Draggable handle="button" defaultPosition={{ x: 0, y: 0 }} bounds="parent">
      <div
        style={{
          width: '400px',
          height: showDetail ? '500px' : '25px',
          border: '1px solid #ddd',
          zIndex: 1000,
          position: 'absolute',
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0,0,0,0.2)',
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '24px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Button size="small" type="text" icon={<DragOutlined />} />
          <div
            style={{
              flex: 1,
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ flex: 1, fontWeight: 500, textAlign: 'center' }}>
              ML Response
            </span>
            <Switch
              size="small"
              checked={enableVroom}
              onChange={(checked) => {
                if (setEnabled) setEnabled(checked);
                setEnableVroom(checked);
              }}
            />
          </div>
          <Button
            size="small"
            type="text"
            onClick={() => {
              setShowDetail(!showDetail);
            }}
            icon={showDetail ? <MinusOutlined /> : <PlusOutlined />}
          />
        </div>
        <div
          style={{
            flex: 1,
            border: '1px solid #ddd',
            overflowY: 'auto',
            display: showDetail ? 'block' : 'none',
          }}
        >
          <ReactJson src={json} />
        </div>
        <div style={{ display: showDetail ? 'block' : 'none' }}>
          <div ref={editorRef} style={{ width: '100%', height: 200 }}></div>
        </div>
      </div>
    </Draggable>
  );
};

export default JsonRenderDrag;
