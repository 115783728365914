import React, { useState, useEffect } from 'react'
import { Form, Input, Select, InputNumber, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { http } from '../../../../http/axios';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { Themes } from '../../../../store/ui';

import { useSelector } from 'react-redux';

function WarrantyAdd({ action, load }) {

    const currentTheme = useSelector(selectUiTheme);

    const [form] = Form.useForm();

    const [dataMarca, setDataMarca] = useState([]);
    const [dataSubFamilia, setDataSubFamilia] = useState([]);

    const [subFamilia, setSubFamilia] = useState([]);
    const [marca, setMarca] = useState([]);

    useEffect(() => {
        if (dataMarca.length > 0 && dataSubFamilia.length > 0) {
            setMarca(toArray(dataMarca[0]));
            setSubFamilia(toArray(dataSubFamilia[0]));
        }
    }, [dataMarca, dataSubFamilia])

    const toArray = (response) => {
        try {
            const inputString = response.alternativas;
            const separatedValues = inputString.split("|");

            const resultArray = separatedValues.map(value => ({
                value,
                label: value
            }));

            return resultArray;
        } catch (error) {
            console.error(error);
            return response;
        }
    };

    const loadMarcaData = async () => {
        try {
            const data = await http.get('/product-field-type/marca');
            setDataMarca(data);
        } catch (error) {
            console.error(error);
        }
    };

    const loadSubFamiliaData = async () => {
        try {
            const data = await http.get('/product-field-type/subfamilia');
            setDataSubFamilia(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        loadMarcaData();
        loadSubFamiliaData();
    }, [])

    const onFinish = (values) => {
        action(values);
        form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dropdownStyle = () => {
        
        if (currentTheme === Themes.DARK_THEME) {
            return {
                backgroundColor: "#a1a1a1",
                color: "white"
            }
        }
        return {}
    }

    return (
        <Form
            form={form}
            name="WarrantyAdd"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"

            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}

            disabled={load}
        >

            <Form.Item
                label="Marca"
                name="marca"
            >
                <Select
                    showSearch
                    placeholder="Marca"
                    optionFilterProp="children"
                    dropdownStyle={dropdownStyle()}
                    // onChange={onChange}
                    // onSearch={onSearch}
                    // bordered={false}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={marca}
                />
            </Form.Item>

            <Form.Item
                label="Subfamilia"
                name="subfamilia"
            >
                <Select
                    showSearch
                    placeholder="Subfamilia"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    // bordered={false}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={subFamilia}
                />
            </Form.Item>

            <Form.Item
                label="meses"
                name="meses"
            >
                <InputNumber
                    // bordered={false}
                    min={0}
                    placeholder="meses"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{backgroundColor: '#1e91cf', borderColor: '#1978ab', borderRadius: '10px', color: 'white'}}>
                    Agregar
                </Button>
            </Form.Item>
        </Form>
    )
}

export default WarrantyAdd
