import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Layout from '../components/layout';
import { getCurrentUser } from '../store/auth/auth.actions';
import {
  selectCurrentUser,
  selectLoadingSession,
} from '../store/auth/auth.selectors';
import { SignIn } from './SignIn';
import Dashboard from './Dashboard';
import { Menu } from '../../domain/models/Menu';
import OnlineTracking from './OnlineTracking';
import Page from '../containers/Page';
import Android from '../containers/Page/android';
import AndroidCertificado from '../containers/Page/android/instalar_certificado';
import AndroidCodigoBarras from '../containers/Page/android/codigodebarras';
import Ios from '../containers/Page/ios';
import IosCertificado from '../containers/Page/ios/instalar_certificado';
import RoutePlan from './RoutePlan';
import RoutePlanCreate from './RoutePlan/Create';
import RoutePlanMap from './RoutePlan/indexMap';
import TipoCampoValor from './tipoCampoValor';
import { http } from '../http/axios';
import AdminProfiles from './Admin/Profiles';
import AdminProfilesForm from './Admin/Form';
import PasswordRecovery from './PasswordRecovery';
import { Button, Modal } from 'antd';
import { INFO_APP } from '../const/info';
import { selectUiTheme } from '../store/ui/ui.selectors';
import { Themes } from '../store/ui';
import ZoneFrequency from './Settings/zoneFrequency';
import SettingsAA from './Settings/settingsAA';

const Test = React.lazy(() => import('./Test'));

export type RouteStateProps = {
  selectedMenu: Menu;
};

const Router: FC = () => {
  const currentUser = useSelector(selectCurrentUser);
  const currentTheme = useSelector(selectUiTheme);
  const selectLoading = useSelector(selectLoadingSession);
  const [needUpgrade, setNeedUpgrade] = useState(false);
  const [currentVersion, setCurrentVersion] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      const autoLoginRegisterActivity = async () => {
        await http.post('/users/activity', {});
      };
      autoLoginRegisterActivity();
      const timerVerifySession = setInterval(async () => {
        const legacyToken = localStorage.getItem('onfieldLegacyToken');

        if (!legacyToken) {
          navigate('/signin', { replace: true });
        } else {
          await http.get('/users/current?legacyToken=' + legacyToken);
        }
      }, 10000);

      const path = location.pathname;

      if ((path === '/' || path === '/signin') && currentUser.homeScreen?.url) {
        if (currentUser.homeScreen.is_legacy) {
          navigate(
            `${currentUser.homeScreen.url}?key=${currentUser.homeScreen.key}`
          );
        } else {
          navigate(currentUser.homeScreen?.url);
        }
      }

      return () => {
        clearInterval(timerVerifySession);
      };
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectLoading && !currentUser) {
      navigate('/signin');
    }
  }, [selectLoading, currentUser, navigate]);

  useEffect(() => {
    if (!currentUser) {
      const onfieldToken = localStorage.getItem('onfieldToken');
      const path = location.pathname;
      const excludes = [
        '/app',
        '/android',
        '/android/instalar_certificado',
        '/android/codigodebarras',
        '/ios',
        '/ios/instalar_certificado',
        '/signin',
      ];
      if (excludes.includes(path)) return;
      if (onfieldToken) {
        dispatch(getCurrentUser());
      } else {
        navigate('/signin');
      }
    }
  }, [dispatch, currentUser, navigate, location.pathname]);

  useEffect(() => {
    const loadVersion = async () => {
      const currentVersion = INFO_APP.VERSION;
      const serverVersion: { version: string; description: string } =
        await http.get('/version');

      setCurrentVersion(serverVersion.version);
      setCurrentDescription(serverVersion.description);
      const serverVersionInt = parseInt(
        serverVersion.version.replaceAll('.', '')
      );

      /* if (currentVersion !== serverVersion.version) {
        setNeedUpgrade(true);
      } */

      if (serverVersionInt > parseInt(currentVersion.replaceAll('.', ''))) {
        setNeedUpgrade(true);
      }
    };
    loadVersion();
  }, []);

  return (
    <>
      <Modal
        visible={needUpgrade}
        footer={null}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{
          backgroundColor:
            currentTheme === Themes.DARK_THEME ? '#2b2b2b' : '#fff',
          color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
        }}
      >
        <h1
          style={{
            textAlign: 'center',
            color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
          }}
        >
          Hola!
        </h1>
        <p style={{ textAlign: 'justify' }}>
          Hay una nueva versión disponible de OnField, por lo que debes
          actualizar tu página.
          <br />
          Presiona en el botón de actualizar para obtener la última versión.
          <br />
          <br />
          <span dangerouslySetInnerHTML={{ __html: currentDescription }}></span>
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Actualizar a la versión {currentVersion}
          </Button>
        </div>
      </Modal>
      <Routes>
        <Route path="/forgot-password" element={<PasswordRecovery />} />
        <Route path="/app" element={<Page />} />
        <Route path="/android" element={<Android />} />
        <Route
          path="/android/instalar_certificado"
          element={<AndroidCertificado />}
        />
        <Route
          path="/android/codigodebarras"
          element={<AndroidCodigoBarras />}
        />
        <Route path="/ios" element={<Ios />} />
        <Route path="/ios/instalar_certificado" element={<IosCertificado />} />
        <Route path="/" element={<Layout />}>
          <Route path="*" element={<Navigate to="/" />} />
          {/**Aqui van las rutas de usuarios autenticados */}
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/orders"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/routes"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/accounts"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/reports"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/contact-center"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/admin"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/products"
            element={
              <React.Suspense fallback={<>...</>}>
                <Test />
              </React.Suspense>
            }
          />
          <Route
            path="/onlineTracking"
            element={
              <React.Suspense fallback={<>...</>}>
                <OnlineTracking />
              </React.Suspense>
            }
          />
          <Route
            path="/settings/zona-frecuente"
            element={
              <React.Suspense fallback={<>...</>}>
                <ZoneFrequency />
              </React.Suspense>
            }
          />
          <Route
            path="/settings/autoagendamiento"
            element={
              <React.Suspense fallback={<>...</>}>
                <SettingsAA />
              </React.Suspense>
            }
          />
          <Route path="/route-plans" element={<Outlet />}>
            <Route path="" element={<RoutePlan />} />
            <Route path="create" element={<RoutePlanCreate />} />
            <Route path=":id" element={<RoutePlanCreate />} />
            <Route path=":id/map" element={<RoutePlanMap />} />
          </Route>
          <Route path="/admin" element={<Outlet />}>
            <Route path="profiles" element={<AdminProfiles />} />
            <Route path="profiles/:id" element={<AdminProfilesForm />} />
          </Route>
          <Route path="/tipo_campo_valores" element={<TipoCampoValor />} />
        </Route>
        <Route
          path="/signin"
          element={
            currentUser != null ? (
              <Navigate to="/" replace={true} />
            ) : (
              <SignIn />
            )
          }
        />
      </Routes>
    </>
  );
};

export default Router;
