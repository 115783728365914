import { User } from '../../../domain/models/User';
import * as authTypes from './auth.types';

export const removeCurrentUser = () => ({
  type: authTypes.REMOVE_CURRENT_USER,
});

export const getCurrentUser = () => ({
  type: authTypes.GET_CURRENT_USER,
});

export const getCurrentUserSuccess = (user: User | null) => ({
  type: authTypes.GET_CURRENT_USER_SUCCESS,
  payload: user,
});

export const setLoading = (loading: boolean) => ({
  type: authTypes.SET_LOADING,
  payload: loading,
});
