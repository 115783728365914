import React, { useState, useEffect } from 'react'
import { Card, message } from 'antd';
import { http } from '../../../http/axios';

import AlternativeAdd from './alternativeSSTT/alternativeAdd'
import AlternativeItem from './alternativeSSTT/alternativeItem'

function AlternativeSSTT() {

  const [alternativeBrand, setAlternativeBrand] = useState([]);
  const [load, setLoad] = useState(true);
  const [adding, setAdding] = useState(false);

  let feedback = () => { };

  const loadAlternativeBrandData = async () => {
    try {
      const alternativeBrand = await http.get('/alternative-brand');
      setAlternativeBrand(alternativeBrand);
    } catch (error) {
      console.error(error);
    } finally {
      setLoad(false);
    }
  };

  const addAlternativeBrand = async (add) => {
    feedback = message.loading("Guardando...", 0);
    setAdding(true);
    try {
      const response = await http.post("/alternative-brand", { add })
      successAdd(response)
    } catch (error) {
      feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
      console.error(error);
    } finally {
      setAdding(false);
      feedback();
    }
  };

  useEffect(() => {
    loadAlternativeBrandData();
  }, []);

  const addBrand = (values) => {
    addAlternativeBrand(values);
  }

  const successAdd = (value) => {
    const update = [...alternativeBrand, value]
    setAlternativeBrand(update);
  }

  const successDelete = (brandID) => {
    const brands = [...alternativeBrand]
    const update = brands.filter((item) => item.id_marca_alternativa !== brandID)
    setAlternativeBrand(update);
    loadAlternativeBrandData();
  }

  return (
    <Card size="small" loading={load}>
      {/*<h2>SSTT Alternativos</h2>*/}
      <AlternativeAdd action={addBrand} load={adding} />

      <div className='settingsTableBorder'>
        <table className='settingsTable'>
          <tr>
            <th>Nombre</th>
            <th>Teléfono</th>
            <th>Dirección</th>
            <th>Email</th>
            {/*<th>URL Logo</th>*/}
            <th></th>
          </tr>
          {alternativeBrand.map((item) =>
            <AlternativeItem key={item.id_marca_alternativa} item={item} onDelete={successDelete} />
          )}
        </table>
      </div>
    </Card>
  )
}

export default AlternativeSSTT