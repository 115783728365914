import { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Col, Row } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Chat } from '../../../../domain/models/Chat';
import useUserOnline from '../../../hooks/useUserOnline';
import ucfirst from '../../../libs/ucfirst';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';

type ChatItemProps = {
  onSelect: Function;
  chat: Chat;
  list?: boolean;
};

const ChatItem: FC<ChatItemProps> = ({ onSelect, chat, list = false }) => {
  const currentUser = useSelector(selectCurrentUser);
  const user = chat.users.find((user) => user.user_id !== currentUser?.user_id);
  const fromUser = chat.users.find(
    (user) => user.user_id === currentUser?.user_id
  );
  const [isOnline] = useUserOnline(user?.user_id);

  return (
    <Row
      style={{ marginTop: '1rem', marginLeft: '.5rem', cursor: 'pointer' }}
      onClick={() => onSelect(chat)}
    >
      <Col span={5}>
        <span>
          <Badge
            size="small"
            offset={[-38, 8]}
            dot
            count={fromUser?.is_view ? 0 : 1}
          >
            {user?.picture ? (
              <>
                <Avatar size={44} shape="circle" src={user?.picture} />
              </>
            ) : (
              <>
                <Avatar size={44} shape="circle" icon={<UserOutlined />} />
              </>
            )}
          </Badge>
        </span>
      </Col>
      <Col
        span={15}
        style={{
          paddingLeft: '.5rem',
          color: list ? 'black' : 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          {ucfirst(user?.firstname.toLocaleLowerCase() ?? '')}{' '}
          {ucfirst(user?.lastname.toLocaleLowerCase() ?? '')}
        </div>
        <small
          style={{
            overflow: 'hidden',
            maxWidth: '75ch',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {user?.is_write ? <>Escribiendo...</> : <>{chat?.last_message}</>}
        </small>
      </Col>
      <Col
        span={4}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'end',
        }}
      >
        <Badge status={isOnline ? 'success' : 'default'} />
      </Col>
    </Row>
  );
};

export default ChatItem;
