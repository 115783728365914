import './style.css';
import { Col, Progress, Row, Segmented, Skeleton } from 'antd';
import Card from '../../../Card';
import { WidgetActivityTask } from '../../../../../domain/models/widgets/ActivityTask';
import { FC, Fragment, useEffect, useState } from 'react';
import { SegmentedValue } from 'antd/lib/segmented';
import { http } from '../../../../http/axios';
import moment from 'moment';
import { OkIcon } from '../../../icons';
import useLanguage from '../../../../hooks/useLanguage';
import styled from 'styled-components';
import { Themes } from '../../../../store/ui';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

type Props = {
  clientsId: number[];
};

const Divx = styled.div`
  .ant-progress-circle-trail {
    stroke: ${(prop) =>
      prop.theme === Themes.DARK_THEME ? 'rgba(0,0,0,0.5)' : '#f5f5f5'};
  }
`;

const TasksWidget: FC<Props> = ({ clientsId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<WidgetActivityTask[]>([]);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({ startAt: '', endAt: '' });
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === t('today', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('week_short', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('week').format('YYYY-MM-DD'),
        endAt: moment().endOf('week').format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('month', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('month').format('YYYY-MM-DD'),
        endAt: moment().endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  useEffect(() => {
    setFilterDate({
      startAt: moment().format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (filterDate.startAt && filterDate.endAt) {
      setLoading(true);
      setData([]);

      const clientsQuery = clientsId
        .map((client) => {
          return `client[]=${client}`;
        })
        .join('&');

      http
        .get<WidgetActivityTask[]>(
          `/widgets/activity-tasks/${filterDate.startAt}/${filterDate.endAt}/query?${clientsQuery}`,
          controller.signal
        )
        .then((res) => {
          setData(res);
          setLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [filterDate, clientsId]);

  return (
    <Card>
      <Divx
        theme={currentTheme}
        style={{ padding: '.5rem 1rem', width: '100%', height: '100%' }}
      >
        <Row>
          <Col
            span={14}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            {t('tasks')}
          </Col>
          <Col
            span={10}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              size="small"
              onChange={handleChangeSegmented}
              options={[
                t('today', { ns: 'common' }),
                t('week_short', { ns: 'common' }),
                t('month', { ns: 'common' }),
              ]}
              defaultValue={t('today', { ns: 'common' })}
            />
          </Col>
        </Row>
        <Skeleton loading={loading} active>
          <div
            style={{
              height: '80%',
              paddingTop: '.5rem',
              display: 'flex',
              flexDirection: 'row',
              gap: '.5rem',
            }}
          >
            <div
              style={{ width: 150, display: 'flex', alignContent: 'center' }}
            >
              <Progress
                strokeWidth={20}
                strokeColor={'rgba(95, 178, 104, 0.5)'}
                type="circle"
                strokeLinecap="butt"
                percent={
                  data.length > 0
                    ? Math.round(
                        (data[0].total /
                          data.reduce(
                            (prev, current) => prev + current.total,
                            0
                          )) *
                          100
                      )
                    : 0
                }
                format={(percent) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: '18px' }} className="text">
                      {`${percent}`}%
                    </span>
                    <OkIcon style={{ fontSize: '14px' }} />
                  </div>
                )}
              />
            </div>
            <Row
              gutter={[0, 0]}
              style={{
                overflowX: 'auto',
              }}
            >
              {data.map((item, index) => (
                <Fragment key={index}>
                  <Col
                    key={index}
                    span={24}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: item.color + ' !important',
                    }}
                  >
                    <div style={{ flex: 1, color: item.color }}>
                      {item.name}
                    </div>
                    <div style={{ color: item.color }}>{item.total}</div>
                  </Col>
                </Fragment>
              ))}
              <Col
                span={24}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ flex: 1 }} className="text">
                  {t('total', { ns: 'common' })}
                </div>
                <div className="text">
                  {data.reduce((prev, current) => prev + current.total, 0)}
                </div>
              </Col>
            </Row>
          </div>
        </Skeleton>
      </Divx>
    </Card>
  );
};

export default TasksWidget;
