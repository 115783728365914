import { message, notification } from 'antd';
import { useEffect, useState } from 'react';

interface IChangeLocation {
  isModeChangeLocation: boolean;
  userId: number | null;
}

interface IChangeLocationSuccess {
  start: google.maps.LatLng;
  end: google.maps.LatLng;
  userId: number | null;
}

enum StateChangeLocation {
  CHANGE_LOCATION = 'CHANGE_LOCATION',
  CHANGE_LOCATION_START = 'CHANGE_LOCATION_START',
  CHANGE_LOCATION_END = 'CHANGE_LOCATION_END',
  CHANGE_LOCATION_SUCCESS = 'CHANGE_LOCATION_SUCCESS',
}

const useUserLocationMap = (map: google.maps.Map | null) => {
  const [modeChangeLocation, setModeChangeLocation] = useState<IChangeLocation>(
    {
      isModeChangeLocation: false,
      userId: null,
    }
  );
  const [stateChangeLocation, setStateChangeLocation] =
    useState<StateChangeLocation>(StateChangeLocation.CHANGE_LOCATION);
  const [tempLocations, setTempLocations] = useState<IChangeLocationSuccess>({
    start: new google.maps.LatLng(0, 0),
    end: new google.maps.LatLng(0, 0),
    userId: null,
  });
  const [locations, setLocations] = useState<IChangeLocationSuccess>({
    start: new google.maps.LatLng(0, 0),
    end: new google.maps.LatLng(0, 0),
    userId: null,
  });

  // Inicia el modo de cambio de ubicación
  useEffect(() => {
    if (modeChangeLocation.isModeChangeLocation) {
      message.info('Por favor selecciona el punto de inicio', 0);
      setStateChangeLocation(StateChangeLocation.CHANGE_LOCATION_START);
      setLocations({
        start: new google.maps.LatLng(0, 0),
        end: new google.maps.LatLng(0, 0),
        userId: null,
      });
    }
    return () => {
      message.destroy();
      notification.destroy();
      setStateChangeLocation(StateChangeLocation.CHANGE_LOCATION);
      setLocations({
        start: new google.maps.LatLng(0, 0),
        end: new google.maps.LatLng(0, 0),
        userId: null,
      });
      setTempLocations({
        start: new google.maps.LatLng(0, 0),
        end: new google.maps.LatLng(0, 0),
        userId: null,
      });
    };
  }, [modeChangeLocation]);

  useEffect(() => {
    if (modeChangeLocation.isModeChangeLocation) {
      notification.info({
        message: 'Estás en modo de cambio de ubicación',
        description:
          'Haz click en el mapa para cambiar la ubicación del usuario',
        duration: 0,
        placement: 'bottomRight',
        onClose() {
          setModeChangeLocation({
            isModeChangeLocation: false,
            userId: null,
          });
        },
      });
    } else {
      notification.destroy();
      message.destroy();
    }
  }, [modeChangeLocation]);

  //Envia la localización al cliente
  useEffect(() => {
    if (stateChangeLocation === StateChangeLocation.CHANGE_LOCATION_SUCCESS) {
      setStateChangeLocation(StateChangeLocation.CHANGE_LOCATION);
      setLocations(tempLocations);
      notification.destroy();
      message.destroy();
    }
  }, [tempLocations, stateChangeLocation]);

  useEffect(() => {
    if (map) {
      map.addListener('click', (e: any) => {
        if (stateChangeLocation === StateChangeLocation.CHANGE_LOCATION_START) {
          setTempLocations({
            start: e.latLng,
            end: new google.maps.LatLng(0, 0),
            userId: modeChangeLocation.userId,
          });
          setStateChangeLocation(StateChangeLocation.CHANGE_LOCATION_END);
          message.destroy();
          message.info('Por favor selecciona el punto de llegada', 0);
        }

        if (stateChangeLocation === StateChangeLocation.CHANGE_LOCATION_END) {
          setTempLocations((old) => ({
            start: old.start,
            end: e.latLng,
            userId: modeChangeLocation.userId,
          }));
          setStateChangeLocation(StateChangeLocation.CHANGE_LOCATION_SUCCESS);
        }
      });

      return () => {
        google.maps.event.clearListeners(map, 'click');
      };
    }
  }, [map, modeChangeLocation.userId, stateChangeLocation]);

  return { modeChangeLocation, setModeChangeLocation, locations };
};

export default useUserLocationMap;
