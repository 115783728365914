import './style.css';
import { Col, Empty, Row, Segmented, Skeleton } from 'antd';
import Card from '../../../Card';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { FC, Fragment, useEffect, useState } from 'react';
import { http } from '../../../../http/axios';
import moment from 'moment';
import { SegmentedValue } from 'antd/lib/segmented';
import { WidgetActivity } from '../../../../../domain/models/widgets/Activity';
import useLanguage from '../../../../hooks/useLanguage';
import { Themes } from '../../../../store/ui';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

type Props = {
  clientsId: number[];
};

const ActivitiesWidget: FC<Props> = ({ clientsId }) => {
  const [loading, setLoading] = useState(true);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({ startAt: '', endAt: '' });
  const [data, setData] = useState<WidgetActivity[]>([]);
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === t('today', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('week_short', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('week').format('YYYY-MM-DD'),
        endAt: moment().endOf('week').format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('month', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('month').format('YYYY-MM-DD'),
        endAt: moment().endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  useEffect(() => {
    setFilterDate({
      startAt: moment().format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (filterDate.startAt && filterDate.endAt) {
      setLoading(true);
      setData([]);

      const clientsQuery = clientsId
        .map((client) => {
          return `client[]=${client}`;
        })
        .join('&');

      http
        .get<WidgetActivity[]>(
          `/widgets/activities/${filterDate.startAt}/${filterDate.endAt}/query?${clientsQuery}`
        )
        .then((res) => {
          setData(res);
          setLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [filterDate, clientsId]);

  return (
    <Card>
      <div
        style={{
          padding: '.5rem 1rem',
          width: '100%',
          height: '100%',
        }}
      >
        <Row>
          <Col
            span={10}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            {t('activities')}
          </Col>
          <Col
            span={14}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              onChange={handleChangeSegmented}
              size="small"
              options={[
                t('today', { ns: 'common' }),
                t('week_short', { ns: 'common' }),
                t('month', { ns: 'common' }),
              ]}
              defaultValue={t('today', { ns: 'common' })}
            />
          </Col>
        </Row>
        <div style={{ padding: '.5rem' }} />
        <Skeleton loading={loading} active>
          {data.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
              description={'Sin datos'}
            />
          ) : (
            <Row gutter={[0, 10]}>
              {data.map((item, index) => (
                <Fragment key={index}>
                  <Col span={13} key={item.name} style={{ color: item.color }}>
                    {item.name}
                  </Col>
                  <Col
                    span={6}
                    key={item.name + item.total + index}
                    style={{
                      textAlign: 'right',
                      fontSize: '18px',
                      color: item.color,
                    }}
                  >
                    {item.total}
                  </Col>
                  <Col
                    span={5}
                    key={item.name + item.total + item.diff + index}
                    style={{ textAlign: 'right', fontSize: '12px' }}
                    className="text"
                  >
                    {item.diff > 0 ? (
                      <CaretUpOutlined style={{ color: 'green' }} />
                    ) : (
                      <CaretDownOutlined style={{ color: 'red' }} />
                    )}{' '}
                    {item.diff}%
                  </Col>
                </Fragment>
              ))}
            </Row>
          )}
        </Skeleton>
      </div>
    </Card>
  );
};

export default ActivitiesWidget;
