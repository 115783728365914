import { firestoreAutoId } from '../../infrastructure/firebase/firebase';
import { instance } from '../../infrastructure/http/axios';
import { userRepository } from '../../infrastructure/repositories/user.repository';
import { Chat } from '../models/Chat';
import { Client } from '../models/Client';
import { DashboardWidget } from '../models/DashboardWidget';
import { Instance } from '../models/Instance';
import { Menu } from '../models/Menu';
import { User } from '../models/User';

export const userService = {
  signIn: (
    username: string,
    password: string,
    token: string
  ): Promise<{
    user: User;
    menus: Menu[];
    dashboards: DashboardWidget[];
    instance: Instance;
    clients: Client[];
    homeScreen: Menu;
		role: any;
  }> => userRepository.signIn(username, password, token),
  getCurrentSession: async (): Promise<{
    user: User;
    menus: Menu[];
    dashboards: DashboardWidget[];
    clients: Client[];
    instance: Instance;
  } | null> => {
    const currentUser = await userRepository.getCurrentUser();
    if (!currentUser) return null;
    return currentUser;
  },
	getConfigurations: async (): Promise<any[]> => {
		return (await userRepository.findConfiguration()).map((c: any) => ({ ...c, value: JSON.parse(c.value)}));
	},
  removeCurrentSession: () => {
    userRepository.removeCurrentUser();
  },
  findInactiveChats: async (
    currentUser: User,
    currentChats: Chat[]
  ): Promise<Chat[]> => {
    const users = (await userRepository.findAllUsers()).filter((user) => {
      return !currentChats.find((chat) => chat.id_users.includes(user.user_id));
    });
    return users.map(
      (user) =>
        ({
          id: firestoreAutoId(),
          id_users: [user.user_id, currentUser.user_id],
          instance: instance,
          exists: false,
          messages: [],
          users: [
            {
              user_id: user.user_id,
              username: user.username,
              window_collapsed: false,
              window: false,
              firstname: user.firstname,
              lastname: user.lastname,
              battery: 0,
              clients: [],
              cookie: '',
              is_connected: false,
              is_view: true,
              is_write: false,
              position: [],
              rut: user.rut,
              token: '',
              url_foto: '',
              uuid: user.uuid,
              picture: user.picture,
              role_id: user.role_id,
              user_group_id: user.user_group_id,
            },
            {
              user_id: currentUser.user_id,
              username: currentUser.username,
              window_collapsed: false,
              window: false,
              firstname: currentUser.firstname,
              lastname: currentUser.lastname,
              battery: 0,
              clients: [],
              cookie: '',
              is_connected: true,
              is_view: true,
              is_write: false,
              position: [],
              rut: currentUser.rut,
              token: '',
              url_foto: '',
              uuid: currentUser.uuid,
              picture: currentUser.picture,
              role_id: currentUser.role_id,
              user_group_id: currentUser.user_group_id,
            },
          ],
          last_message: null,
          last_message_date: null,
        } as Chat)
    );
  },
};
