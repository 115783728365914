import { FC, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Col,
  Dropdown,
  Image,
  Layout as Layoutx,
  Menu,
  Modal,
  Row,
  Skeleton,
} from 'antd';
import './index.css';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfiguration,
  selectIsFirstLoadOnlineTracking,
  selectUiTheme,
} from '../../store/ui/ui.selectors';
import { Themes } from '../../store/ui';
import colors from '../../const/colors';
import { selectOpenedChats } from '../../store/chat/chat.selectors';
import BubbleChatItem from '../chat/BubbleChatItem';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { MenuOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import {
  resetUi,
  setCollapsedChatRightPanel,
  setCollapsedLeftPanel,
  setCollapsedOnlineTrackingRightPanel,
  setIsFirstLoadOnlineTracking,
  setTheme,
} from '../../store/ui/ui.actions';
import { faMoon, faSun, faUser } from '@fortawesome/free-regular-svg-icons';
import { ChatIcon, TracingIcon } from '../icons';
import {
  selectCurrentUser,
  selectLoadingSession,
} from '../../store/auth/auth.selectors';
import LOGO_MINI_LIGHT from '../../../assets/logo_onfield_mini.svg';
import LOGO_MINI_DARK from '../../../assets/logo_onfield_mini_dark.svg';
import { logEvent } from 'firebase/analytics';
import { analytics, database, firestore } from '../../firebase/firebase';
import { instance } from '../../http/axios';
import { INFO_APP } from '../../const/info';
import { useIdleTimer } from 'react-idle-timer';
import { userService } from '../../../domain/services/User.service';
import { removeCurrentUser } from '../../store/auth/auth.actions';
import { closeAllOpenedChats } from '../../store/chat/chat.actions';
import { onDisconnect, onValue, ref, set } from 'firebase/database';
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';

const idleTime = 60; //Seconds
const waitingResponseTimeout = 20; //Seconds

const { Content } = Layoutx;

const Contentx = styled(Content)`
  background-color: ${(props) =>
    props.theme === Themes.DARK_THEME
      ? colors.DARK_THEME.backgroundColor
      : colors.LIGHT_THEME.backgroundColor};
  position: relative;
  z-index: 1;
  overflow-y: auto;
  h1 {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.h1
        : colors.DARK_THEME.common.h1};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
  }

  .text {
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }

  .ant-card {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select};
    border: 1px solid
      ${(props) =>
        props.theme === Themes.DARK_THEME
          ? colors.DARK_THEME.backgroundColor
          : '#f0f0f0'};
  }

  .ant-select-dropdown {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
  }

  .ant-segmented-item-selected {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.common.select
        : colors.LIGHT_THEME.common.select} !important;
    color: ${(props) =>
      props.theme === Themes.DARK_THEME
        ? colors.DARK_THEME.textColor
        : colors.LIGHT_THEME.textColor};
  }

  .ant-segmented-thumb {
    background-color: transparent;
  }
`;

const GlobalStyle = styled(Layoutx)`
  .ant-menu-submenu-popup > .ant-menu {
    background-color: green;
  }
`;

const DropdownStyled = styled(Dropdown)`
  .ant-dropdown-menu {
    background-color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.select
        : colors.DARK_THEME.common.select} !important;
  }
`;

const Layout: FC = () => {
  const currentTheme = useSelector(selectUiTheme);
  const openedChats = useSelector(selectOpenedChats);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();
  const isFirstLoadOnlineTracking = useSelector(
    selectIsFirstLoadOnlineTracking
  );
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const [enableOnlineTracking, setEnableOnlineTracking] = useState(false);
  const [enableChat, setEnableChat] = useState(false);
  const uiConfigurations = useSelector(selectConfiguration);
  const [countdownWaitingSession, setCountdownWaitingSession] = useState(
    uiConfigurations[0]?.value.waitingResponseTimeout || waitingResponseTimeout
  );
  const [visibleStaySession, setVisibleStaySession] = useState(false);
  const isLoadingSession = useSelector(selectLoadingSession);
  const idleTimer = useIdleTimer({
    timeout: 1000 * (uiConfigurations[0]?.value.timeout || idleTime),
    promptTimeout:
      (1000 * (uiConfigurations[0]?.value.timeout || idleTime)) / 2,
    onPrompt: () => {
      setVisibleStaySession(true);
    },
    onIdle: () => {},
    onAction: () => {
      if (!visibleStaySession)
        localStorage.setItem('lastStaySession', Date.now().toString());
    },
    onActive: () => {
      localStorage.setItem('lastStaySession', Date.now().toString());
    },
    debounce: 500,
  });

  const staySession = () => {
    localStorage.setItem('lastStaySession', Date.now().toString());
    setVisibleStaySession(false);
    setCountdownWaitingSession(
      uiConfigurations[0]?.value.waitingResponseTimeout ||
        waitingResponseTimeout
    );
    idleTimer.reset();
  };

  const automaticLogout = () => {
    const lastStaySession = parseInt(
      localStorage.getItem('lastStaySession') || Date.now().toString()
    );

    const diffLastStaySession = Math.round(
      (Date.now() - lastStaySession) / 1000
    );

    if (diffLastStaySession >= uiConfigurations[0]?.value.timeout) {
      userService.removeCurrentSession();
      dispatch(removeCurrentUser());
      dispatch(closeAllOpenedChats());
      dispatch(resetUi());
      navigate('/signin', { replace: true });
    } else {
      staySession();
    }
  };

  const handleProfileClick = () => {
    const selectedMenu = {
      component: `${currentUser?.instance?.legacy_url}/index.php?route=user/user/edit`,
    };
    navigate('routes?key=userProfile', {
      state: {
        selectedMenu,
      },
    });
  };

  const mobileRightMenu = (
    <Menu
      style={{
        backgroundColor:
          currentTheme === Themes.LIGH_THEME
            ? colors.LIGHT_THEME.common.select
            : colors.DARK_THEME.common.select,
        color: 'red',
      }}
      theme={currentTheme === Themes.LIGH_THEME ? 'light' : 'dark'}
      items={[
        {
          key: '1',
          label: <>Perfil</>,
          disabled: false,
          icon: (
            <FontAwesomeIcon
              icon={faUser}
              style={{ color: '#ddd', fontSize: '18px' }}
            />
          ),
          onClick: () => handleProfileClick(),
        },
        {
          key: '2',
          label: <>Chat</>,
          disabled: !enableChat,
          icon: <ChatIcon style={{ color: '#ddd', fontSize: '18px' }} />,
          onClick: () => {
            logEvent(analytics, 'right_panel_chat_open', {
              instance,
              version: INFO_APP.VERSION,
              userId: currentUser?.user_id,
              groupId: currentUser?.user_group_id,
            });
            dispatch(setCollapsedChatRightPanel(false));
          },
        },
        {
          key: '3',
          label: <>Seguimiento en línea</>,
          disabled: !enableOnlineTracking,
          icon: <TracingIcon style={{ color: '#ddd', fontSize: '18px' }} />,
          onClick: () => {
            if (isFirstLoadOnlineTracking) {
              dispatch(setIsFirstLoadOnlineTracking(false));
            }
            logEvent(analytics, 'right_panel_tracking_open', {
              instance,
              version: INFO_APP.VERSION,
              userId: currentUser?.user_id,
              groupId: currentUser?.user_group_id,
            });
            dispatch(setCollapsedOnlineTrackingRightPanel(false));
          },
        },
      ].filter((item) => !item.disabled)}
    />
  );

  useEffect(() => {
    if (currentUser) {
      const userStatusDatabaseRef = ref(
        database,
        '/status/' + instance + '/' + currentUser.user_id
      );
      const userFirestoreRef = query(
        collection(firestore, 'Chat'),
        where('id_users', 'array-contains', currentUser.user_id),
        where('instance', '==', instance)
      );
      const infoConnected = ref(database, '.info/connected');
      const isOfflineForDatabase = {
        state: 'offline',
        instance: instance,
        last_change: Timestamp.now(),
        type: 'web',
      };

      const isOnlineForDatabase = {
        state: 'online',
        instance: instance,
        last_change: Timestamp.now(),
        type: 'web',
      };

      const unsubUserStatus = onValue(infoConnected, async (snapshot) => {
        if (snapshot.val() === false) {
          const chats = await getDocs(userFirestoreRef);
          chats.forEach((chat) => {
            const newUsers = chat.data().users.map((user: any) => {
              if (user.user_id === currentUser.user_id) {
                user.is_connected = false;
                user.is_write = false;
              }
              return user;
            });
            updateDoc(chat.ref, { users: newUsers });
          });
          return;
        }

        onDisconnect(userStatusDatabaseRef)
          .set(isOfflineForDatabase)
          .then(async () => {
            await set(userStatusDatabaseRef, isOnlineForDatabase);

            const chats = await getDocs(userFirestoreRef);
            chats.forEach((chat) => {
              const newUsers = chat.data().users.map((user: any) => {
                if (user.user_id === currentUser.user_id) {
                  user.is_connected = true;
                }
                return user;
              });
              updateDoc(chat.ref, { users: newUsers });
            });
          });
      });
      //dispatch(chatActions.loadUserChats(currentUser.id));
      return () => {
        set(userStatusDatabaseRef, isOfflineForDatabase);
        unsubUserStatus();
        getDocs(userFirestoreRef).then((chats) => {
          chats.forEach((chat) => {
            const newUsers = chat.data().users.map((user: any) => {
              if (user.user_id === currentUser.user_id) {
                user.is_connected = true;
              }
              return user;
            });
            updateDoc(chat.ref, { users: newUsers });
          });
        });
      };
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (visibleStaySession) {
      const intervalWaitingSession = setInterval(() => {
        setCountdownWaitingSession(countdownWaitingSession - 1);
        if (countdownWaitingSession <= 0) {
          automaticLogout();
          clearInterval(intervalWaitingSession);
        }
      }, 1000);
      return () => {
        clearInterval(intervalWaitingSession);
      };
    }
  }, [countdownWaitingSession, visibleStaySession]);

  useEffect(() => {
    const sw_seguimiento_en_linea = currentUser?.instance?.parameters.find(
      (p) => p.parameter_id === 'sw_seguimiento_en_linea'
    );
    if (
      currentUser &&
      sw_seguimiento_en_linea !== undefined &&
      sw_seguimiento_en_linea.value1 === '1'
    ) {
      if (currentUser.role?.has_tracking) setEnableOnlineTracking(true);
    }

    const sw_modulo_chat = currentUser?.instance?.parameters.find(
      (p) => p.parameter_id === 'sw_modulo_chat'
    );
    if (
      currentUser &&
      sw_modulo_chat !== undefined &&
      sw_modulo_chat.value1 === '1'
    ) {
      if (currentUser.role?.has_chat) setEnableChat(true);
    }
  }, [currentUser]);

  return (
    <GlobalStyle className="layout_container">
      <LeftPanel theme={currentTheme} />
      <Layoutx>
        <Contentx theme={currentTheme}>
          {openedChats.map((chat, index) => (
            <BubbleChatItem chat={chat} key={chat.id} index={index} />
          ))}
          {isTabletOrMobile && (
            <Row>
              <Col span={24}>
                <div
                  style={{
                    width: '100%',
                    height: '45px',
                    position: 'sticky',
                    display: 'flex',
                    flexDirection: 'row',
                    boxShadow:
                      '0 0px 20px -6px rgb(0 0 0 / 10%), 0 0px 20px -6px rgb(0 0 0 / 10%)',
                    alignItems: 'center',
                    padding: '20px',
                    gap: '10px',
                    backgroundColor:
                      currentTheme === Themes.DARK_THEME
                        ? colors.DARK_THEME.leftPanel.backgroundColor
                        : colors.LIGHT_THEME.backgroundColor,
                    color:
                      currentTheme === Themes.DARK_THEME
                        ? colors.DARK_THEME.textColor
                        : colors.LIGHT_THEME.textColor,
                  }}
                >
                  <div>
                    <MenuOutlined
                      onClick={() => {
                        dispatch(setCollapsedLeftPanel(false));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Image
                      src={
                        currentTheme === Themes.DARK_THEME
                          ? LOGO_MINI_DARK
                          : LOGO_MINI_LIGHT
                      }
                      alt="LOGO"
                      preview={false}
                      width={20}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      dispatch(
                        setTheme(
                          currentTheme === Themes.DARK_THEME
                            ? Themes.LIGH_THEME
                            : Themes.DARK_THEME
                        )
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      color={
                        currentTheme === Themes.DARK_THEME
                          ? colors.DARK_THEME.textColor
                          : colors.LIGHT_THEME.textColor
                      }
                      icon={currentTheme === Themes.DARK_THEME ? faSun : faMoon}
                    />
                  </div>
                  <DropdownStyled
                    theme={currentTheme}
                    overlay={mobileRightMenu}
                  >
                    <div
                      style={{
                        width: 20,
                        height: '45px',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ width: '100%' }}
                        icon={faEllipsisVertical}
                      />
                    </div>
                  </DropdownStyled>
                </div>
              </Col>
            </Row>
          )}
          <Row style={{ height: '100%' }}>
            <Col span={24}>
              <Outlet />
              {isLoadingSession && !currentUser && (
                <div
                  style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
                >
                  <Skeleton active />
                </div>
              )}
            </Col>
          </Row>
          <Modal
            title="¡ Hemos detectado inactividad en tu sesión !"
            visible={visibleStaySession}
            onOk={staySession}
            onCancel={automaticLogout}
            okText={`Continuar (${countdownWaitingSession})`}
            cancelText="Cerrar"
          >
            <p>
              Tu sesión está apunto de expirar. Por seguridad serás
              automaticamente desconectado.
            </p>
            <p>¿Quieres permanecer con la sesión activa?</p>
          </Modal>
        </Contentx>
      </Layoutx>
      <RightPanel theme={currentTheme} />
    </GlobalStyle>
  );
};

export default Layout;
