import React from "react"
import logo_onfield_negro from '../images/logo_onfield_negro.png'
import paso1 from './images/paso1.png'
import paso2 from './images/paso2.png'
import paso3 from './images/paso3.png'
import paso4 from './images/paso4.png'
import paso1_1 from './images/paso1_2.png'
import paso2_2 from './images/paso2_2.png'
import './instalar_certificado.css';

function Instalar_certificado() {
    return (

          <body className="backgroundPage fullScroll" style={{
                backgroundImage: `url(https://source.unsplash.com/collection/145103/computers-phones-and-tech)`
                }} >
          <div className="sp"></div>
          <div className="box-page login-box-page ">
            <div className="box">
              <div style={{textAlign:"center"}}>
                <img style={{
                  position: `relative`,
                  top:`-Xpx`,
                  width: `200px`
                }}
                  className="nav_logo" src={logo_onfield_negro}
                  alt=""
                  title=""
                />
              </div>
              <div className="form-container document"  style={{textAlign:"center"}}>
                <br/>
                <h2>Pasos para Habilitar la App luego de Instalada</h2>
                <br/>
                <h1>Versiones de Android Oreo (8.0) o superior</h1>

                <form id="loginForm" method="post" action="">
                  <fieldset>
                    <div className="form-group loginfield">
                      <div>
                        <div className="section" id="trust-enterprise-certificates">
                          <ol className="arabic simple" start={1}>
                            <li style={{textAlign:"left"}}>Dirígete a los Ajustes de tu teléfono móvil y entra en Aplicaciones y notificaciones.</li>
                          </ol>
                          <a className="reference internal image-reference" href={paso1}> <img alt="images/paso1.png" src={paso1} style={{width:"300px"}} /></a>
                          <p/>
                          <ol className="arabic simple" start={2}>
                            <li style={{textAlign:"left"}}>En el nuevo menú, deberás extender las opciones avanzadas y entrar en la categoría de Acceso especial de aplicaciones.</li>
                          </ol>
                          <a className="reference internal image-reference" href={paso2}><img alt="Configuración" src={paso2} style={{width:"300px"}} /></a>
                          <br/>
                          <ol className="arabic simple" start={3}>
                            <li style={{textAlign:"left"}}>En este menú deberás entrar en Instalar aplicaciones desconocidas</li>
                          </ol>
                          <br/>
                          <p>
                            <a className="reference internal image-reference" href={paso3}><img alt="images/paso3.png" src={paso3} style={{width:"300px"}} /></a>
                          </p>
                          <ol className="arabic simple" start={4}>
                            <li style={{textAlign:"left"}}>En este menú verás una lista de aplicaciones que tienes instaladas en tu dispositivo y se pueden utilizar para instalar apks de terceros. Debes seleccionar la aplicación que estás usando para descargar la app. En este caso estamos usando Chrome.</li>
                          </ol>
                          <p>
                            <a className="reference internal image-reference" href={paso4}><img alt="images/paso4.png" src={paso4} style={{width:"300px"}} /></a>
                          </p>
                          <ol className="arabic simple" start={5}>
                            <li style={{textAlign:"left"}}>Ahora ya puedes abrir el archivo APK sin problemas.</li>
                          </ol>
                        </div>
                      </div>
                      <br/>
     		              <h1>Versiones Nougat (7.0) o inferior </h1>
     		              <br/>
     		              <ol className="arabic simple" start={1}>
                        <li style={{textAlign:"left"}}>Dirígete a los Ajustes de tu teléfono móvil y entra en Seguridad.</li>
                      </ol>
                      <br/>
                        <a className="reference internal image-reference" href={paso1_1}><img alt="images/paso1_1.png" src={paso1_1} style={{width:"300px"}} /></a>
                        <br/>
                      <ol className="arabic simple" start={2}>
             	          <br/>
                        <li style={{textAlign:"left"}}>Ahora debes activar el switch que aparece en Fuentes desconocidas. Te aparecerá una ventana de alerta, debes confirmar el cambio</li>
                      </ol>
                      <br/>
                      <a className="reference internal image-reference" href={paso2_2}><img alt="images/paso2_2.png" src={paso2_2} style={{width:"300px"}} /></a>
                      <ol className="arabic simple" start={3}>
                	      <br/>
                        <li style={{textAlign:"left"}}>Ahora ya puedes abrir el archivo APK sin problemas.</li>
                      </ol>
                  </div>
                  </fieldset>
                      <div style={{width:"100%"}}>
                        <a className="btn blue-btn" href="../android" style={{textDecoration: "none"}} >VOLVER</a>
                      </div>
                      <br/>
                      <div>
                        <p>Powered by <a href="https://assertsoft.com"  style={{textDecoration: "none"}} target="_blank">Assertsoft</a></p>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
          </body>
    );
}

export default Instalar_certificado;