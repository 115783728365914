import { DashboardWidget } from '../../../domain/models/DashboardWidget';
import * as dashboardTypes from './dashboard.types';

export const getDashboard = () => ({
  type: dashboardTypes.GET_DASHBOARD,
});

export const getDashboardSuccess = (dashboard: DashboardWidget[]) => ({
  type: dashboardTypes.GET_DASHBOARD_SUCCESS,
  payload: dashboard,
});

export const getDashboardFailure = (error: string) => ({
  type: dashboardTypes.GET_DASHBOARD_FAILURE,
  payload: error,
});
