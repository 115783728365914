import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as authTypes from './auth.types';
import * as authActions from './auth.actions';
import * as uiActions from '../ui/ui.actions';
import * as dashboardActions from '../dashboard/dashboard.actions';
import { userService } from '../../../domain/services/User.service';
import { User } from '../../../domain/models/User';
import { Menu } from '../../../domain/models/Menu';
import { DashboardWidget } from '../../../domain/models/DashboardWidget';
import { Client } from '../../../domain/models/Client';
import { Instance } from '../../../domain/models/Instance';

export function* getCurrentUser() {
  try {
    const data: {
      user: User;
      menus: Menu[];
      dashboards: DashboardWidget[];
      clients: Client[];
      instance: Instance;
      homeScreen: Menu;
      role: any;
    } | null = yield call(userService.getCurrentSession);

    if (data) {
      yield put(
        authActions.getCurrentUserSuccess({
          ...data.user,
          instance: data.instance,
          clients: data.clients,
          homeScreen: data.homeScreen,
          role: data.role,
        })
      );

			const configurations: unknown = yield call(userService.getConfigurations);
			yield put(uiActions.setConfiguration(configurations as any));

      yield put(uiActions.setMenuLeftPanel(data.menus));

      const savedDashboard = localStorage.getItem('dashboardWidgets');

      if (savedDashboard) {
        const widgets: DashboardWidget[] = JSON.parse(savedDashboard);
        data.dashboards = data.dashboards
          .map((dashboard) => {
            const findWidget = widgets.find(
              (widget) => widget.dash_panel_id === dashboard.dash_panel_id
            );
            if (findWidget) {
              dashboard.position = findWidget.position;
            }
            return dashboard;
          })
          .sort((a, b) => a.position - b.position);
      }

      yield put(dashboardActions.getDashboardSuccess(data.dashboards));
    }
    yield put(authActions.setLoading(false));
  } catch (error) {
    console.error(error);
  }
}

// Triggers saga
export function* onGetCurrentUser() {
  yield takeLatest(authTypes.GET_CURRENT_USER, getCurrentUser);
}

export function* authSaga() {
  yield all([call(onGetCurrentUser)]);
}
