import { onValue, ref, get } from 'firebase/database';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { database } from '../firebase/firebase';
import { instance } from '../http/axios';

type UserOnline = {
  time: Moment;
  app: number;
  web: number;
  users_online: string[];
};

const userStatusDatabaseRef = ref(database, '/users_online/' + instance);

const useUsersOnline = () => {
  const [usersOnline, setUsersOnline] = useState<UserOnline>({
    app: 0,
    time: moment(),
    web: 0,
    users_online: [],
  });

  useEffect(() => {
    return onValue(userStatusDatabaseRef, (snapshot) => {
      if (snapshot.exists()) {
        const data: {
          online_app: number;
          online_web: number;
          online_data: string[];
        } = snapshot.val();
        setUsersOnline({
          app: data.online_app,
          web: data.online_web,
          users_online: data.online_data || [],
          time: moment(),
        });
      }
    });
  }, []);

  return [usersOnline] as const;
};

export default useUsersOnline;
