import { RollbackOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OnfieldFrame from '../components/OnfieldFrame';
import { instance } from '../http/axios';
import { selectUiTheme } from '../store/ui/ui.selectors';

const PasswordRecovery: FC = () => {
  const currentTheme = useSelector(selectUiTheme);
  const navigation = useNavigate();

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: 0, right: 0, margin: '0.5rem' }}>
        <Button
          icon={<RollbackOutlined />}
          onClick={() => {
            navigation(-1);
          }}
        />
      </div>
      <OnfieldFrame
        url={`https://${instance}.onfield.cl/admin/index.php?route=common/forgotten`}
        theme={currentTheme}
        onlyUrl
      />
    </div>
  );
};

export default PasswordRecovery;
