import { FC, useEffect, useState } from 'react';
import './rightPanel.css';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Drawer,
  Layout,
  Progress,
  Row,
} from 'antd';
import styled from 'styled-components';
import { Themes } from '../../../store/ui';
import colors from '../../../const/colors';
import { UserOutlined } from '@ant-design/icons';
import { ChatIcon, TracingIcon } from '../../icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCollapsedChatRightPanel,
  setCollapsedOnlineTrackingRightPanel,
  setIsFirstLoadOnlineTracking,
  setIsOnlineTracking,
  setTheme,
} from '../../../store/ui/ui.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-regular-svg-icons';
import measurements from '../../../const/measurements';
import {
  selectCollapsedChatRightPanel,
  selectCollapsedLeftPanel,
  selectCollapsedOnlineTrackingRightPanel,
  selectIsFirstLoadOnlineTracking,
  selectIsOnlineTracking,
} from '../../../store/ui/ui.selectors';
import Chat from '../../chat';
import ButtonCollapse from '../../ButtonCollapse';
import { selectChats } from '../../../store/chat/chat.selectors';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { useNavigate } from 'react-router-dom';
import { Menu } from '../../../../domain/models/Menu';
import OnlineTracking from '../../OnlineTracking';
import { useMediaQuery } from 'react-responsive';
import { analytics } from '../../../firebase/firebase';
import { logEvent } from 'firebase/analytics';
import { instance } from '../../../http/axios';
import { INFO_APP } from '../../../const/info';

const { Sider } = Layout;

const RightPanelStyled = styled(Sider)`
  flex: 0 0 ${measurements.rightPanel.width} !important;
  max-width: ${measurements.rightPanel.width} !important;
  min-width: ${measurements.rightPanel.width} !important;
  width: ${measurements.rightPanel.width} !important;
  background: ${(props) =>
    props.theme === Themes.LIGH_THEME
      ? colors.LIGHT_THEME.rightPanel.rightPanelCollapsible
      : colors.DARK_THEME.rightPanel.rightPanelCollapsible};
`;

const RightPanelCollapsibleStyled = styled(Sider)`
  background: ${(props) =>
    props.theme === Themes.LIGH_THEME
      ? colors.LIGHT_THEME.rightPanel.backgroundColor
      : colors.DARK_THEME.rightPanel.backgroundColor};
`;

const DrawerStyled = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    background: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.rightPanel.backgroundColor
        : colors.DARK_THEME.rightPanel.backgroundColor};
  }

  .ant-drawer-header {
    border-bottom: 1px solid
      ${(props) =>
        props.theme === Themes.LIGH_THEME
          ? colors.LIGHT_THEME.rightPanel.backgroundColor
          : colors.DARK_THEME.rightPanel.backgroundColor};
    border-radius: 0;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-arrow {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }

  .ant-menu-submenu-active .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.colorHover
        : colors.DARK_THEME.leftPanel.colorHover};
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.select
        : colors.DARK_THEME.common.select} !important;
  }

  .ant-menu-item {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }
`;

type Props = {
  theme: Themes;
};

const RightPanel: FC<Props> = ({ theme }) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const isCollapsedLeftPanel = useSelector(selectCollapsedLeftPanel);
  const isCollapsedRightChatPanel = useSelector(selectCollapsedChatRightPanel);
  const isCollapsedRightOnlineTrackingPanel = useSelector(
    selectCollapsedOnlineTrackingRightPanel
  );
  const currentChats = useSelector(selectChats);
  const currentUser = useSelector(selectCurrentUser);
  const isOnlineTracking = useSelector(selectIsOnlineTracking);
  const isFirstLoadOnlineTracking = useSelector(
    selectIsFirstLoadOnlineTracking
  );
  const [enableOnlineTracking, setEnableOnlineTracking] = useState(false);
  const [enableChat, setEnableChat] = useState(false);
  const [lastTheme, setLastTheme] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleProfileClick = () => {
    const selectedMenu = {
      component: `${currentUser?.instance?.legacy_url}/index.php?route=user/user/edit`,
    };
    navigate('routes?key=userProfile', {
      state: {
        selectedMenu,
      },
    });
  };

  useEffect(() => {
    if (isCollapsedRightOnlineTrackingPanel && lastTheme !== theme) {
      dispatch(setIsFirstLoadOnlineTracking(true));
    }
    setLastTheme(theme);
  }, [isCollapsedRightOnlineTrackingPanel, theme]);

  useEffect(() => {
    const unreadMessages = currentChats.filter((c) => {
      return c.users.some(
        (u) => u.user_id === currentUser?.user_id && u.is_view === false
      );
    });
    setUnreadMessages(unreadMessages.length);
  }, [currentChats]);

  useEffect(() => {
    const sw_seguimiento_en_linea = currentUser?.instance?.parameters.find(
      (p) => p.parameter_id === 'sw_seguimiento_en_linea'
    );
    if (
      currentUser &&
      sw_seguimiento_en_linea !== undefined &&
      sw_seguimiento_en_linea.value1 === '1'
    ) {
      if (currentUser.role?.has_tracking) setEnableOnlineTracking(true);
    }

    const sw_modulo_chat = currentUser?.instance?.parameters.find(
      (p) => p.parameter_id === 'sw_modulo_chat'
    );
    if (
      currentUser &&
      sw_modulo_chat !== undefined &&
      sw_modulo_chat.value1 === '1'
    ) {
      if (currentUser.role?.has_chat) setEnableChat(true);
    }
  }, [currentUser]);

  const toggleTheme = () => {
    dispatch(
      setTheme(
        theme === Themes.DARK_THEME ? Themes.LIGH_THEME : Themes.DARK_THEME
      )
    );
  };

  useEffect(() => {
    if (!isCollapsedLeftPanel) {
      dispatch(setCollapsedChatRightPanel(true));
      dispatch(setCollapsedOnlineTrackingRightPanel(true));
    }
  }, [isCollapsedLeftPanel, dispatch]);

  useEffect(() => {
    if (!isCollapsedRightOnlineTrackingPanel) {
      dispatch(setCollapsedChatRightPanel(true));
    }
  }, [isCollapsedRightOnlineTrackingPanel, dispatch]);

  useEffect(() => {
    if (!isCollapsedRightChatPanel) {
      dispatch(setCollapsedOnlineTrackingRightPanel(true));
    }
  }, [isCollapsedRightChatPanel, dispatch]);

  return isTabletOrMobile ? (
    <>
      <DrawerStyled
        placement="right"
        theme={theme}
        closable={false}
        visible={!isCollapsedRightChatPanel}
        destroyOnClose={false}
        forceRender={true}
        width={measurements.rightPanel.collapsible.width}
        onClose={() => dispatch(setCollapsedChatRightPanel(true))}
        bodyStyle={{ padding: 0 }}
      >
        {enableChat && <Chat />}
      </DrawerStyled>

      <DrawerStyled
        placement="right"
        theme={theme}
        closable={false}
        visible={!isCollapsedRightOnlineTrackingPanel}
        width={measurements.rightPanel.collapsible.trackingWidth}
        onClose={() => {
          dispatch(setCollapsedOnlineTrackingRightPanel(true));
        }}
        bodyStyle={{ padding: 0 }}
      >
        {!isFirstLoadOnlineTracking && <OnlineTracking />}
      </DrawerStyled>
    </>
  ) : (
    <>
      {/** Panel Chat */}
      <RightPanelCollapsibleStyled
        theme={theme}
        collapsed={isCollapsedRightChatPanel}
        collapsedWidth={measurements.rightPanel.collapsible.collapsedWidth}
        width={measurements.rightPanel.collapsible.width}
        id="rightPanel"
      >
        {!isCollapsedRightChatPanel && (
          <>
            <div className="button_right_collapsible">
              <ButtonCollapse
                onClick={(isCollapsed) => {
                  dispatch(setCollapsedChatRightPanel(isCollapsed));
                }}
                theme={theme}
                position="right"
                isCollapsed={isCollapsedRightChatPanel}
              />
            </div>
          </>
        )}
        {enableChat && <Chat />}
      </RightPanelCollapsibleStyled>
      <RightPanelCollapsibleStyled
        theme={theme}
        collapsed={isCollapsedRightOnlineTrackingPanel}
        collapsedWidth={measurements.rightPanel.collapsible.collapsedWidth}
        width={measurements.rightPanel.collapsible.trackingWidth}
        id="rightPanel"
      >
        {!isCollapsedRightOnlineTrackingPanel && (
          <>
            <div className="button_right_collapsible">
              <ButtonCollapse
                onClick={(isCollapsed) => {
                  logEvent(analytics, 'right_panel_tracking_close', {
                    instance,
                    version: INFO_APP.VERSION,
                    userId: currentUser?.user_id,
                    groupId: currentUser?.user_group_id,
                  });
                  dispatch(setCollapsedOnlineTrackingRightPanel(isCollapsed));
                }}
                theme={theme}
                position="right"
                isCollapsed={isCollapsedRightOnlineTrackingPanel}
              />
            </div>
          </>
        )}
        {!isFirstLoadOnlineTracking && <OnlineTracking />}
      </RightPanelCollapsibleStyled>
      <RightPanelStyled theme={theme}>
        <div className="wrapper_right_panel">
          <Row className="container_right_panel" gutter={[0, 10]}>
            <Col
              span={24}
              className="center_flex"
              style={{ cursor: 'pointer' }}
              onClick={handleProfileClick}
            >
              {currentUser?.picture ? (
                <>
                  <Avatar size={36} src={currentUser.picture} />
                </>
              ) : (
                <>
                  <Avatar size={36} icon={<UserOutlined />} />
                </>
              )}
            </Col>
            <Col span={24}></Col>
            {enableChat && (
              <Col span={24} className="center_flex item_right_panel">
                <Badge count={unreadMessages} size="small" offset={[-32, 8]}>
                  <Button
                    size="large"
                    shape="circle"
                    type="text"
                    icon={
                      <ChatIcon style={{ color: '#ddd', fontSize: '22px' }} />
                    }
                    className={
                      !isCollapsedRightChatPanel ? 'button_selected' : ''
                    }
                    onClick={() => {
                      if (isCollapsedRightChatPanel) {
                        logEvent(analytics, 'right_panel_chat_open', {
                          instance,
                          version: INFO_APP.VERSION,
                          userId: currentUser?.user_id,
                          groupId: currentUser?.user_group_id,
                        });
                      } else {
                        logEvent(analytics, 'right_panel_chat_close', {
                          instance,
                          version: INFO_APP.VERSION,
                          userId: currentUser?.user_id,
                          groupId: currentUser?.user_group_id,
                        });
                      }
                      dispatch(
                        setCollapsedChatRightPanel(!isCollapsedRightChatPanel)
                      );
                    }}
                  />
                </Badge>
              </Col>
            )}
            {enableOnlineTracking && (
              <Col span={24} className="center_flex item_right_panel">
                <Button
                  size="large"
                  shape="circle"
                  type="text"
                  icon={
                    <TracingIcon style={{ color: '#ddd', fontSize: '22px' }} />
                  }
                  className={
                    !isCollapsedRightOnlineTrackingPanel || isOnlineTracking
                      ? 'button_selected'
                      : ''
                  }
                  onClick={() => {
                    if (isCollapsedRightOnlineTrackingPanel) {
                      logEvent(analytics, 'right_panel_tracking_open', {
                        instance,
                        version: INFO_APP.VERSION,
                        userId: currentUser?.user_id,
                        groupId: currentUser?.user_group_id,
                      });
                    } else {
                      logEvent(analytics, 'right_panel_tracking_close', {
                        instance,
                        version: INFO_APP.VERSION,
                        userId: currentUser?.user_id,
                        groupId: currentUser?.user_group_id,
                      });
                    }
                    dispatch(
                      setCollapsedOnlineTrackingRightPanel(
                        !isCollapsedRightOnlineTrackingPanel
                      )
                    );

                    if (isFirstLoadOnlineTracking) {
                      dispatch(setIsFirstLoadOnlineTracking(false));
                    }

                    if (isOnlineTracking) {
                      navigate(-1);
                      dispatch(setIsOnlineTracking(false));
                    }
                  }}
                />
              </Col>
            )}
            {/* <Col span={24} className="center_flex item_right_panel">
              <Button type="text">
                <Progress
                  type="circle"
                  percent={75}
                  width={26}
                  strokeColor="#2ecc71"
                  strokeWidth={10}
                  format={(percent) => (
                    <small style={{ color: 'white' }}>{percent}%</small>
                  )}
                />
              </Button>
            </Col> */}
          </Row>
          <div style={{ flex: 1 }}></div>
          <Row>
            <Col span={24} className="center_flex">
              <Button
                size="large"
                shape="circle"
                type="text"
                icon={
                  <FontAwesomeIcon
                    color={
                      theme === Themes.DARK_THEME
                        ? colors.DARK_THEME.rightSidebarButtonColors
                        : colors.LIGHT_THEME.rightSidebarButtonColors
                    }
                    icon={theme === Themes.DARK_THEME ? faSun : faMoon}
                  />
                }
                onClick={toggleTheme}
              />
            </Col>
            <Col span={24} className="center_flex">
              <small
                style={{
                  color:
                    theme === Themes.DARK_THEME
                      ? colors.DARK_THEME.rightSidebarButtonColors
                      : colors.LIGHT_THEME.rightSidebarButtonColors,
                }}
              >
                v{INFO_APP.VERSION}
              </small>
            </Col>
          </Row>
        </div>
      </RightPanelStyled>
    </>
  );
};

export default RightPanel;
