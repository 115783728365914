const ucfirst = (string: string) => {
  let str = '';
  let arrStr = string.trim().split(' ');
  arrStr.forEach((item, index) => {
    if (index === 0) {
      str += item.charAt(0).toUpperCase() + item.slice(1);
    } else {
      str += ' ' + item.charAt(0).toUpperCase() + item.slice(1);
    }
  });
  return str;
};

export default ucfirst;
