import React, { useState, useEffect } from 'react'
import { Form, Card, InputNumber, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { http } from '../../../http/axios';

import WarrantyAdd from './warranties/warrantyAdd'
import WarrantyItem from './warranties/warrantyItem'

function Warranties({ setUnsavedChanges, loadingSave, setLoadingSave, unsavedChanges }) {

    const [warranties, setWarranties] = useState([]);
    const [defaultWarranty, setDefaultWarranty] = useState({});

    const [defaultWarrantyLoad, setDefaultWarrantyLoad] = useState(true);
    const [adding, setAdding] = useState(false);

    const [changes, setChanges] = useState({});

    let feedback = () => { };

    const loadWarrantiesData = async () => {
        try {
            const warrantyData = await http.get('/warranty/exception');
            setWarranties(warrantyData);
        } catch (error) {
            console.error(error);
        }
    };

    const loadDefaultWarrantyData = async () => {
        try {
            const warrantyDefaultData = await http.get('/warranty/default');
            setDefaultWarranty(warrantyDefaultData);
            setDefaultWarrantyLoad(false);
        } catch (error) {
            console.error(error);
        }
    };

    const addWarrantiesBrand = async (add) => {
        feedback = message.loading("Guardando...", 0);
        setAdding(true);
        try {
            const response = await http.post("/warranty", { add })
            successAdd(response)
        } catch (error) {
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
            console.error(error);
        } finally {
            setAdding(false);
            feedback();
        }
    };

    const addWarranty = (values) => {
        addWarrantiesBrand(values);
    }

    const successAdd = (value) => {
        const update = [...warranties, value]
        setWarranties(update);
    }

    const successDelete = (warrantyID) => {
        const warrantiesVar = [...warranties]
        const update = warrantiesVar.filter((item) => item.id_garantia !== warrantyID)
        setWarranties(update);
        loadWarrantiesData();
    }

    const editDefault = async (query) => {
        try {
            const response = await http.put('/warranty/default', { query });
            setDefaultWarranty(response);
            setUnsavedChanges(false);
        } catch (error) {
            console.error(error);
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
        } finally {
            loadWarrantiesData(); // si la garantia por defecto se actualiza, cambian las excepciones
            setLoadingSave(false);
        }
    };

    useEffect(() => {
        if (!unsavedChanges) {
            setChanges([]);
        }
    }, [unsavedChanges])

    useEffect(() => {
        if (Object.entries(changes).length != 0) {
            editDefault(changes);
        }
    }, [loadingSave])

    const editParameters = (value, name) => {
        setChanges(value);
        setUnsavedChanges(true);
    };

    useEffect(() => {
        loadWarrantiesData();
        loadDefaultWarrantyData();
    }, [])

    return (
        <Card size="small" loading={defaultWarrantyLoad}>
            {/*<h2>Garantías</h2>*/}

            {defaultWarrantyLoad
                ? (
                    <Spin size="small"
                        indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                    />
                )
                : (
                    <Form
                        name="generals"
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Meses garantía por defecto"
                        >
                            <InputNumber
                                min={0}
                                defaultValue={defaultWarranty.meses}
                                onChange={(e) => editParameters(e, 'warrantyDefault')}
                            />
                        </Form.Item>
                    </Form>
                )
            }

            <WarrantyAdd action={addWarranty} load={adding} />

            <Form
                name="generals"
                autoComplete="off"
                layout={'vertical'}
            >
                <Form.Item
                    label="Excepciones:"
                >
                    <div className='settingsTableBorder'>
                        <table className='settingsTable'>
                            <tr>
                                <th>Marca</th>
                                <th>Subfamilia</th>
                                <th>Meses</th>
                                <th></th>
                            </tr>
                            {warranties.map((item) =>
                                <WarrantyItem key={item.id_garantia} item={item} onDelete={successDelete} />
                            )}
                        </table>
                    </div>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Warranties