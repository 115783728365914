import './style.css';
import { Card as CardAnt } from 'antd';
import { FC } from 'react';

type Props = {
  children: React.ReactNode;
};

const Card: FC<Props> = ({ children }) => {
  return (
    <CardAnt
      bordered={false}
      style={{ padding: '0 !important', height: '100%', position: 'relative' }}
      className="card-onfield"
    >
      {children}
    </CardAnt>
  );
};

export default Card;
