import { FC, useEffect, useState } from 'react';
import './leftPanel.css';
import LOGO_LIGHT from '../../../../assets/logo_dm.png';
import LOGO_DARK from '../../../../assets/logo_dm_dark.png';
import LOGO_MINI_LIGHT from '../../../../assets/logo_onfield_mini.svg';
import LOGO_MINI_DARK from '../../../../assets/logo_onfield_mini_dark.svg';
import {
  Col,
  Drawer,
  Image,
  Layout,
  Menu,
  MenuProps,
  Row,
  Modal,
  Skeleton,
} from 'antd';
import styled from 'styled-components';
import { Themes } from '../../../store/ui';
import colors from '../../../const/colors';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCollapse from '../../ButtonCollapse';
import ReactPlayer from 'react-player';
import {
  selectCollapsedChatRightPanel,
  selectCollapsedLeftPanel,
  selectCollapsedOnlineTrackingRightPanel,
  selectIsOnlineTracking,
  selectLastMenuKeyAfterOnlineTracking,
  selectLeftMenu,
} from '../../../store/ui/ui.selectors';
import {
  resetUi,
  setCollapsedLeftPanel,
  setIsOnlineTracking,
  setLastMenuKeyOnlineTracking,
} from '../../../store/ui/ui.actions';
import {
  AccountsIcon,
  AdminIcon,
  DashIcon,
  HelpIcon,
  OrdersIcon,
  ProductsIcon,
  ReportsIcon,
  RoutesIcon,
  SignoutIcon,
  ContactCenterIcon,
} from '../../icons';
import measurements from '../../../const/measurements';
import { removeCurrentUser } from '../../../store/auth/auth.actions';
import { userService } from '../../../../domain/services/User.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { instance } from '../../../http/axios';
import { closeAllOpenedChats } from '../../../store/chat/chat.actions';
import { selectLoadingSession } from '../../../store/auth/auth.selectors';
const urlDataVideo = `https://vkfuot5qhl.execute-api.us-east-1.amazonaws.com/dev/videos/`;
const urlDataVideoByInstanciaUserGroup = `https://vkfuot5qhl.execute-api.us-east-1.amazonaws.com/dev/video-user-group-instancia/`;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group' | 'divider'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const menuIcons: any = {
  DashIcon: <DashIcon style={{ fontSize: '20px' }} />,
  OrdersIcon: <OrdersIcon style={{ fontSize: '20px' }} />,
  RoutesIcon: <RoutesIcon style={{ fontSize: '22px' }} />,
  ProductsIcon: <ProductsIcon style={{ fontSize: '24px' }} />,
  AccountsIcon: <AccountsIcon style={{ fontSize: '20px' }} />,
  ReportsIcon: <ReportsIcon style={{ fontSize: '20px' }} />,
  AdminIcon: <AdminIcon style={{ fontSize: '20px' }} />,
  ContactCenterIcon: <ContactCenterIcon style={{ fontSize: '20px' }} />,
};

const items: MenuProps['items'] = [];
const itemsHelp: MenuProps['items'] = [
  getItem('Ayuda', 'help', <HelpIcon style={{ fontSize: '20px' }} />, [
    getItem('Video tutoriales', 'video-tutoriales', null, [], 'group'),
  ]),
  getItem('Salir', 'signout', <SignoutIcon style={{ fontSize: '20px' }} />),
];

const LeftPanelStyled = styled(Layout.Sider)`
  background: ${(props) =>
    props.theme === Themes.LIGH_THEME
      ? colors.LIGHT_THEME.leftPanel.backgroundColor
      : colors.DARK_THEME.leftPanel.backgroundColor};

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-arrow {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }

  .ant-menu-submenu-active .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.colorHover
        : colors.DARK_THEME.leftPanel.colorHover};
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.select
        : colors.DARK_THEME.common.select} !important;
  }

  .ant-menu-item a {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }

  .ant-menu-item > span > a:hover {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }

  .ant-menu-item > span > a {
    text-decoration: none;
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }
`;
const DrawerStyled = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    background: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.backgroundColor
        : colors.DARK_THEME.leftPanel.backgroundColor};
  }

  .ant-drawer-header {
    border-bottom: 1px solid
      ${(props) =>
        props.theme === Themes.LIGH_THEME
          ? colors.LIGHT_THEME.leftPanel.backgroundColor
          : colors.DARK_THEME.leftPanel.backgroundColor};
    border-radius: 0;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-arrow {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }

  .ant-menu-submenu-active .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.colorHover
        : colors.DARK_THEME.leftPanel.colorHover};
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.common.select
        : colors.DARK_THEME.common.select} !important;
  }

  .ant-menu-item {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }

  .ant-menu-item > span > a {
    color: ${(props) =>
      props.theme === Themes.LIGH_THEME
        ? colors.LIGHT_THEME.leftPanel.color
        : colors.DARK_THEME.leftPanel.color} !important;
  }
`;

type Props = {
  theme: Themes;
};

const LeftPanel: FC<Props> = ({ theme }) => {
  const isCollapsedLeftPanel = useSelector(selectCollapsedLeftPanel);
  const isCollapsedRightChatPanel = useSelector(selectCollapsedChatRightPanel);
  const isCollapsedRightOnlineTrackingPanel = useSelector(
    selectCollapsedOnlineTrackingRightPanel
  );
  const selectMenus = useSelector(selectLeftMenu);
  const isOnlineTracking = useSelector(selectIsOnlineTracking);
  const isLoadingSession = useSelector(selectLoadingSession);
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState<string[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState(['']);
  const [menus, setMenus] = useState(items);
  //Menu help-videos
  const [helpMenus, setHelpMenus] = useState(itemsHelp);
  const [currentHelpMenus] = useState<MenuItem[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const lastMenuKeyAfterOnlineTracking = useSelector(
    selectLastMenuKeyAfterOnlineTracking
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenHelpMenu, setIsOpenHelpMenu] = useState(false);
  const [urlVideo, setUrlVideo] = useState('');
  const [titleVideo, setTitleVideo] = useState('Video Tutoriales');
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const fetchVideosData = async () => {
    const response = await fetch(urlDataVideoByInstanciaUserGroup, {
      method: 'get',
      headers: new Headers({
        authorization:
          'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY2NDkwMzg1NiwiaWF0IjoxNjY0OTAzODU2fQ.ZFR_0bKVL7Y6PaW7p36twnHvq5DJDgcfvAb1zhPw6Ug',
      }),
    });
    const res = await response.json();
    let sortVideos = res.Items.filter(
      (item: any) => item.id_instancia.nombre === instance
    ).sort((a: any, b: any) => {
      return a.id_video.position - b.id_video.position;
    });

    const menus: any[] = [];
    for (const entry of Object.entries<any>(sortVideos)) {
      if (entry[1].id_instancia.nombre === instance) {
        if (Number(entry[1].id_user_group) === currentUser?.user_group_id) {
          const responseVideo = await fetch(
            urlDataVideo + entry[1].id_video.id,
            {
              method: 'get',
              headers: new Headers({
                authorization:
                  'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY2NDkwMzg1NiwiaWF0IjoxNjY0OTAzODU2fQ.ZFR_0bKVL7Y6PaW7p36twnHvq5DJDgcfvAb1zhPw6Ug',
              }),
            }
          );
          const resVideo = await responseVideo.json();
          if (Object.keys(resVideo).length > 0) {
            if (resVideo.Item.modulos === '-') {
              menus.push({
                key: resVideo.Item.modulos,
                title: resVideo.Item.nombre,
                url: resVideo.Item.url,
                icon: null,
                items: [],
                type: 'group',
              });
            } else {
              const existCategory = menus.findIndex(
                (menu) => menu.key === resVideo.Item.modulos
              );
              if (existCategory === -1) {
                menus.push({
                  key: resVideo.Item.modulos,
                  title: resVideo.Item.modulos,
                  icon: null,
                  items: [
                    {
                      title: resVideo.Item.nombre,
                      url: resVideo.Item.url,
                    },
                  ],
                  type: 'group',
                });
              } else {
                menus[existCategory].items.push({
                  title: resVideo.Item.nombre,
                  url: resVideo.Item.url,
                });
              }
            }
          }
        }
      }
      //setCurrentHelpMenus(currentItemHelp);
    }

    /* currentItemHelp.push(
			getItem(resVideo.Item.nombre, resVideo.Item.url)
		); */

    const itemsHelps: MenuItem[] = [];

    for (const menu of menus) {
      const items: MenuItem[] = [];
      if (menu.items.length > 0) {
        for (const item of menu.items) {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          items.push(getItem(item.title, item.url));
        }
        itemsHelps.push(getItem(menu.title, menu.key, null, items, 'divider'));
      } else {
        itemsHelps.push(getItem(menu.title, menu.url));
      }
    }

    setHelpMenus([
      getItem('Ayuda', 'help', <HelpIcon style={{ fontSize: '20px' }} />, [
        getItem(
          'Video tutoriales',
          'video-tutoriales',
          null,
          itemsHelps,
          'group'
        ),
      ]),
      getItem('Salir', 'signout', <SignoutIcon style={{ fontSize: '20px' }} />),
    ]);
  };

  const handleCollapsedLeftPanel = (collapsed: boolean) => {
    dispatch(setCollapsedLeftPanel(collapsed));
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // Menu-Modal Help videos
  const currentUser = useSelector(selectCurrentUser);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClick: MenuProps['onClick'] = (e) => {
    //const selectedMenuHelp = selectMenus.find((menu) => menu.key === e.key);
    let selectedMenuHelp: any = currentHelpMenus.find(
      (menu) => menu?.key === e.key
    );
    if (selectedMenuHelp === undefined) {
      for (let menu of currentHelpMenus) {
        let subMenu: any = menu;
        if (subMenu?.children !== undefined) {
          selectedMenuHelp = subMenu?.children.find(
            (menu: { key: string }) => menu?.key === e.key
          );
          if (selectedMenuHelp !== undefined) break;
        } else {
          selectedMenuHelp = currentHelpMenus.find(
            (menu) => menu?.key === e.key
          );
        }
      }
    }

    setTitleVideo(selectedMenuHelp?.label);
    if (e.key === 'signout') {
      userService.removeCurrentSession();
      dispatch(removeCurrentUser());
      dispatch(closeAllOpenedChats());
      dispatch(resetUi());
      navigate('/signin', { replace: true });
    }
    setUrlVideo(e.key);
    showModal();
  };

  const onRightClickMenu: MenuProps['onMouseDown'] = (e) => {
    e.preventDefault();
  };

  const onClickMenu: MenuProps['onClick'] = (e) => {
    dispatch(setIsOnlineTracking(false));
    if (e.keyPath.length === 1) {
      const selectedMenu = selectMenus.find((menu) => menu.key === e.key);
      if (selectedMenu) {
        setSelectedKeys([e.key]);
        dispatch(setLastMenuKeyOnlineTracking([e.key]));
        dispatch(setCollapsedLeftPanel(true));
        navigate(
          selectedMenu.url +
            (selectedMenu.is_legacy ? '?key=' + selectedMenu.key : ''),
          {
            state: {
              selectedMenu,
            },
          }
        );
      }
    } else {
      const selectedMenu = selectMenus.find(
        (menu) => menu.key === e.keyPath[1]
      );
      if (selectedMenu) {
        const childSelected = selectedMenu.childs.find(
          (child) => child.key === e.key
        );
        setSelectedKeys([selectedMenu.key, e.key]);
        dispatch(setLastMenuKeyOnlineTracking([selectedMenu.key, e.key]));
        if (childSelected) {
          dispatch(setCollapsedLeftPanel(true));
          navigate(
            childSelected.url +
              '' +
              (childSelected.is_legacy ? '?key=' + childSelected.key : ''),
            {
              state: {
                selectedMenu: childSelected,
              },
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!isCollapsedRightChatPanel) {
      dispatch(setCollapsedLeftPanel(true));
    }
  }, [isCollapsedRightChatPanel, dispatch]);

  useEffect(() => {
    if (!isCollapsedRightChatPanel || !isCollapsedRightOnlineTrackingPanel) {
      dispatch(setCollapsedLeftPanel(true));
    }
  }, [
    isCollapsedRightChatPanel,
    isCollapsedRightOnlineTrackingPanel,
    dispatch,
  ]);

  useEffect(() => {
    const currentMenu: MenuProps['items'] = [];
    selectMenus.forEach((menux) => {
      if (menux.childs.length > 0) {
        currentMenu.push(
          getItem(
            menux.label,
            menux.key,
            menux.icon ? menuIcons[menux.icon] : null,
            menux.childs.map((child) =>
              getItem(
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href={
                    child.is_legacy
                      ? `${child.url}?key=${child.key}`
                      : child.url
                  }
                  onClick={(e) => e.preventDefault()}
                >
                  {child.label}
                </a>,
                child.key,
                child.icon ? menuIcons[child.icon] : null
              )
            )
          )
        );
      } else {
        currentMenu.push(
          getItem(
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href={
                menux.is_legacy ? `${menux.url}?key=${menux.key}` : menux.url
              }
              onClick={(e) => e.preventDefault()}
            >
              {menux.label}
            </a>,
            menux.key,
            menux.icon ? menuIcons[menux.icon] : null
          )
        );
      }
    });
    setRootSubmenuKeys(currentMenu.map((item) => item?.key) as string[]);
    setMenus(currentMenu);

    return () => {
      dispatch(setLastMenuKeyOnlineTracking(['dashboard']));
      setSelectedKeys(['dashboard']);
    };
  }, [selectMenus]);

  useEffect(() => {
    if (isOnlineTracking) {
      setSelectedKeys(['']);
    } else {
      setSelectedKeys(lastMenuKeyAfterOnlineTracking);
    }
  }, [isOnlineTracking]);

  useEffect(() => {
    fetchVideosData();
  }, []);

  useEffect(() => {
    if (location.state && currentUser && menus.length > 0) {
      const selectedMenu = (location.state as any).selectedMenu;
      if (selectedMenu) {
        setSelectedKeys([selectedMenu.key]);
      }
    }

    if (!location.state && currentUser && menus.length > 0) {
      const path = location.pathname.replace('/', '');
      if (path) setSelectedKeys([location.pathname.replace('/', '')]);
    }
  }, [location, currentUser, menus]);

  return isTabletOrMobile ? (
    <DrawerStyled
      placement="left"
      theme={theme}
      title={
        <div style={{ textAlign: 'center' }}>
          <Image
            src={theme === Themes.DARK_THEME ? LOGO_DARK : LOGO_LIGHT}
            alt="LOGO"
            preview={false}
            width={140}
          />
        </div>
      }
      visible={!isCollapsedLeftPanel}
      closable={false}
      width={measurements.leftPanel.width}
      bodyStyle={{ padding: 0 }}
      onClose={() => handleCollapsedLeftPanel(true)}
    >
      <div className="wrap_menu_items_left_panel">
        <div className="wrap_main_menu">
          <Menu
            mode="inline"
            items={menus}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            defaultSelectedKeys={['dashboard']}
            selectedKeys={selectedKeys}
            onClick={onClickMenu}
            onMouseDown={onRightClickMenu}
            style={{
              color:
                theme === Themes.DARK_THEME
                  ? colors.DARK_THEME.leftPanel.color
                  : colors.LIGHT_THEME.leftPanel.color,
              fontSize: '14px',
            }}
            className="menu_left_panel"
            contextMenu="menu_left_panel"
          />
        </div>
        <div>
          <Menu
            mode="vertical"
            className="menu_left_panel_helper"
            inlineIndent={0}
            items={helpMenus}
            onOpenChange={() => {
              setIsOpenHelpMenu(true);
            }}
            onClick={onClick}
            style={{
              color:
                theme === Themes.DARK_THEME
                  ? colors.DARK_THEME.leftPanel.color
                  : colors.LIGHT_THEME.leftPanel.color,
              fontSize: '14px',
            }}
          />
        </div>
      </div>
    </DrawerStyled>
  ) : (
    <LeftPanelStyled
      width={measurements.leftPanel.width}
      collapsed={isCollapsedLeftPanel}
      collapsedWidth={measurements.leftPanel.collapsible.width}
      theme={theme}
    >
      <div className="button_left_collapsible">
        <ButtonCollapse
          onClick={handleCollapsedLeftPanel}
          theme={theme}
          isCollapsed={isCollapsedLeftPanel}
          position="left"
        />
      </div>
      <Row className="container_left_panel">
        <Col
          span={24}
          style={{ textAlign: isCollapsedLeftPanel ? 'center' : 'center' }}
        >
          <Image
            src={theme === Themes.DARK_THEME ? LOGO_MINI_DARK : LOGO_MINI_LIGHT}
            alt="LOGO"
            preview={false}
            width={35}
            hidden={!isCollapsedLeftPanel}
          />
          <Image
            src={theme === Themes.DARK_THEME ? LOGO_DARK : LOGO_LIGHT}
            alt="LOGO"
            preview={false}
            width={140}
            hidden={isCollapsedLeftPanel}
          />
        </Col>
      </Row>
      <div className="wrap_menu_items_left_panel">
        <div className="wrap_main_menu">
          {isLoadingSession && !currentUser && (
            <div>
              <Menu mode="inline">
                {Array.from({ length: 8 }).map((_, index) => (
                  <Menu.Item key={index}>
                    <Skeleton active />
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          )}
          <Menu
            mode="inline"
            items={menus}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            defaultSelectedKeys={['dashboard']}
            selectedKeys={selectedKeys}
            onClick={onClickMenu}
            style={{
              color:
                theme === Themes.DARK_THEME
                  ? colors.DARK_THEME.leftPanel.color
                  : colors.LIGHT_THEME.leftPanel.color,
              fontSize: '14px',
            }}
            className="menu_left_panel"
            contextMenu="menu_left_panel"
          />
        </div>
        <div>
          <Menu
            mode="vertical"
            items={helpMenus}
            inlineIndent={0}
            className="menu_left_panel_helper"
            onOpenChange={() => {
              setIsOpenHelpMenu(true);
            }}
            selectedKeys={[]}
            onClick={onClick}
            style={{
              color:
                theme === Themes.DARK_THEME
                  ? colors.DARK_THEME.leftPanel.color
                  : colors.LIGHT_THEME.leftPanel.color,
              fontSize: '14px',
            }}
          />
          <Modal
            className={theme}
            title={titleVideo}
            centered
            visible={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            bodyStyle={{
              padding: 0,
              backgroundColor:
                theme === Themes.DARK_THEME
                  ? colors.LIGHT_THEME.leftPanel.color
                  : colors.DARK_THEME.leftPanel.color,
              fontSize: '20px',
            }}
            width="80vw"
            destroyOnClose={true}
          >
            <div className="player-wrapper">
              <ReactPlayer
                className="player"
                url={urlVideo} //player.vimeo.com/video/363812958
                //width="150vw"
                //width="1067px"
                //height="600px"
                width="100%"
                height="100%"
                controls={true}
                playing={true}
              />
            </div>
          </Modal>
        </div>
      </div>
    </LeftPanelStyled>
  );
};

export default LeftPanel;
