import React from "react"
import logo_onfield_negro from '../images/logo_onfield_negro.png'
import error1 from './images/error1.png'
import error2 from './images/error2.png'
//import './index.css';

function codigodebarras() {
    return (
            
              <body className="backgroundPage fullScroll" style={{
                backgroundImage: `url(https://source.unsplash.com/collection/145103/computers-phones-and-tech)`
                }} >
              <div className="sp"></div>
              <div className="box-page login-box-page ">
                <div className="box">
                <div style={{textAlign:"center"}}>
                <img style={{
                  position: `relative`,
                  top:`-Xpx`,
                  width: `200px`
                }}
                  className="nav_logo" src={logo_onfield_negro}
                  alt=""
                  title=""
                />
              </div>
              <div className="form-container document"  style={{textAlign:"center"}}>
              {/*<!--<br>-->*/}
              <h3>En caso que te salga el siguiente mensaje cuando trates de leer códigos de barra: "El motor de detección de códigos de barra no está listo para funcionar"</h3>
              {/*<!--<br>-->
              <!--<h3>"El motor de detección de códigos de barra no está listo para funcionar</h3>-->*/}

              <form id="loginForm" method="post" action="">
              <fieldset>
              <div className="form-group loginfield">
                <div>
                  <div className="section" id="trust-enterprise-certificates">

                  <ol className="arabic simple" start={1}>
                  <li style={{textAlign:"left"}}>Revisar que el celular esté conectado a internet y tenga buena conexión, idealmente por WIFI.</li>
                  </ol>
                  {/*<!--<a class="reference internal image-reference" href="images/paso1.png"><img alt="images/paso1.png" src="images/paso1.png" style="width: 300px;"></a>
                  </p>-->*/}
                  <ol className="arabic simple" start={2}>
                  <li style={{textAlign:"left"}}>Validar que el celular tenga más del 10% de espacio.</li>
                  </ol>
                  {/*<!--<a class="reference internal image-reference" href="images/paso2.png"><img alt="Configuración" src="images/paso2.png" style="width: 300px;"></a>-->
                  <!--<br>-->*/}
                  <ol className="arabic simple" start={3}>
                  <li style={{textAlign:"left"}}>Ir a Configuración/Ajustes -{">"} Aplicaciones y seleccionar Servicios de Google Play.</li>
                  </ol>
                  {/*<!--<br>-->*/}
                  <p>
                  <a className="reference internal image-reference" href={error1} ><img alt="images/error1.png" src={error1} style={{width:"300px"}} /></a>
                  </p>
                  <ol className="arabic simple" start={4}>
                  <li style={{textAlign:"left"}}>Presionar en almacenamiento y luego Borrar memoria caché.</li>
                  </ol>
                  <p>
                  <a className="reference internal image-reference" href={error2}><img alt="images/error2.png" src={error2} style={{width:"300px"}} /></a>
                  </p>
                  <ol className="arabic simple" start={5}>
                  <li style={{textAlign:"left"}}>Opcional si el celular lo permite: presionar botón deshabilitar, y luego presionar botón habilitar.</li>
                  </ol>
                  <ol className="arabic simple" start={6}>
                  <li style={{textAlign:"left"}}>Luego reinstalar la App Claro Onfield.</li>
                  </ol>
                  </div>
                </div>
                <br />
                </div>
                </fieldset>
                <div style={{width:"100%"}}>
                <a className="btn blue-btn" href="../android" style={{textDecoration: "none"}} >VOLVER</a>
                </div>
                <br/>
              <div>
                <p>Powered by <a href="https://assertsoft.com" style={{textDecoration: "none"}} target="_blank">Assertsoft</a></p>
              </div>
          </form>
          </div>
        </div>
      </div>
    <div className="sp"></div>
  </body>


    );
}

export default codigodebarras;