import './style.css';
import { Col, Row, Segmented, Select, Tag } from 'antd';
import Card from '../../../Card';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FC, useEffect, useRef, useState } from 'react';
import { SegmentedValue } from 'antd/lib/segmented';
import { http } from '../../../../http/axios';
import moment from 'moment';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import useLanguage from '../../../../hooks/useLanguage';
import { useMediaQuery } from 'react-responsive';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const initialData = {
  labels: [],
  datasets: [],
};

type Props = {
  clientsId: number[];
};

const ActivitiesPerDayWidget: FC<Props> = ({ clientsId }) => {
  const [data, setData] = useState(initialData);
  const [status, setStatus] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([-1]);
  const [versus, setVersus] = useState(false);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({ startAt: '', endAt: '' });
  const loadingRef = useRef<LoadingBarRef>(null);
  const t = useLanguage();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === '7d') {
      setFilterDate({
        startAt: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === '30d') {
      setFilterDate({
        startAt: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    }
  };

  const handleChangeStatus = (value: number[]) => {
    const hasAll = value.includes(-1);
    if (hasAll && value.length > 1) {
      value = value.filter((item) => item !== -1);
    }

    if (value.length === 0) {
      value = [-1];
    }
    setSelectedStatus(value);
  };

  const handleChangeVersus = (checked: boolean) => {
    setVersus(checked);
  };

  useEffect(() => {
    setFilterDate({
      startAt: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (filterDate.startAt !== '' && filterDate.endAt !== '') {
      if (loadingRef.current) loadingRef.current.continuousStart(0, 1000);
      setData(initialData);
      let query = '';
      if (selectedStatus.length > 0) {
        query += `status=${selectedStatus.join(',')}`;
      }

      if (versus) {
        query += `&versus=true`;
      }

      const clientsQuery = clientsId
        .map((client) => {
          return `client[]=${client}`;
        })
        .join('&');

      http
        .get<any>(
          `/widgets/activity-per-day/${filterDate.startAt}/${
            filterDate.endAt
          }/query?${clientsQuery}${query.length > 0 ? `&${query}` : ''}`,
          controller.signal
        )
        .then((res) => {
          setStatus(res.status);
          setData(res.data);
          if (loadingRef.current) loadingRef.current.complete();
        });
    }
    return () => {
      controller.abort();
    };
  }, [filterDate, versus, selectedStatus, clientsId]);

  return (
    <Card>
      <LoadingBar
        shadow={false}
        containerClassName="onfield-loading"
        ref={loadingRef}
        color="#1890ff"
      />
      <div
        style={{
          padding: '.5rem 1rem',
          width: '100%',
          height: '100%',
        }}
        className="text"
      >
        <Row>
          <Col
            xs={14}
            sm={10}
            md={10}
            style={{ fontSize: '16px', fontWeight: '500' }}
          >
            {t('activities_per_day')}
          </Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '2rem',
            }}
          >
            <div style={{ width: '100%' }}>
              <Select
                className="filter-activities-per-day text"
                size="small"
                value={selectedStatus}
                mode="multiple"
                maxTagCount="responsive"
                placeholder="Seleccione"
                style={{ width: '100%' }}
                onChange={handleChangeStatus}
                onSelect={(value: number) => {
                  if (value === -1) {
                    setSelectedStatus([-1]);
                  }
                }}
              >
                <Select.Option value={-1}>
                  {t('all', { ns: 'common' })}
                </Select.Option>
                {status.map((item) => (
                  <Select.Option key={item.id_estado} value={item.id_estado}>
                    {item.nombre}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <Tag.CheckableTag
                checked={versus}
                onChange={(checked) => handleChangeVersus(checked)}
                className="text"
              >
                V/S
              </Tag.CheckableTag>
            </div>
          </Col>
          <Col
            xs={{ span: 12, offset: 12 }}
            sm={{ span: 6, offset: 0 }}
            md={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              size="small"
              onChange={handleChangeSegmented}
              options={['7d', '30d']}
              defaultValue={'7d'}
            />
          </Col>
        </Row>
        <Row
          style={{
            height: isTabletOrMobile ? '85%' : '90%',
            paddingTop: '.5rem',
            overflowY: 'auto',
          }}
        >
          <Col span={24}>
            <Line
              options={{
                maintainAspectRatio: false,
                spanGaps: false,
                elements: {
                  line: {
                    tension: 0.3,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
              data={data}
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ActivitiesPerDayWidget;
