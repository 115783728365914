import { FC, useEffect, useRef, useState } from 'react';
import { OverlayViewF } from '@react-google-maps/api';
import { EndGPSIcon } from '../../../components/icons';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { Themes } from '../../../store/ui';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';

type Props = {
  color: string;
  position: google.maps.LatLng;
  tracksViewChanges?: boolean;
  local?: any;
  order?: any;
  idNumber?: number;
  showInfoWindow?: any;
  onClickEvent?: (local: any) => void;
  type?: string;
  id?: string;
};

const Marker: FC<Props> = ({
  color,
  local,
  idNumber,
  showInfoWindow,
  onClickEvent,
  position,
  id,
  order,
}) => {
  const [spacer] = useState(20);
  const infoWindowRef = useRef(null);
  const currentTheme = useSelector(selectUiTheme);
  const [langLocal, setLangLocal] = useState({
    ot: 'OT',
    comuna: 'Comuna',
  });
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_pedidos = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'sw_modulo_pedidos'
      );
      const sw_glosa_comuna = currentUser.instance?.parameters.find(
        (p) => p.parameter_id === 'glosa_comuna'
      );

      if (sw_glosa_comuna && sw_modulo_pedidos) {
        const langs = sw_modulo_pedidos.value2.split(',');
        setLangLocal({
          ot: langs[2],
          comuna: sw_glosa_comuna?.value1,
        });
      }
    }
  }, [currentUser]);

  if (color === 'black') {
    return (
      <OverlayViewF position={position} zIndex={50} mapPaneName={'floatPane'}>
        <EndGPSIcon
          style={{
            transform: 'translate(-50%, -50%)',
            fontSize: 20,
            zIndex: 50,
          }}
        />
      </OverlayViewF>
    );
  }

  return (
    <OverlayViewF
      position={position}
      zIndex={!local ? 1 : showInfoWindow === local.id_local ? 100 : 1}
      mapPaneName={'floatPane'}
    >
      <div
        id={id}
        style={{
          width: '20px',
          height: '20px',
          backgroundColor: 'white',
          border: `2px solid ${color}`,
          borderRadius: '50%',
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          fontSize: 10,
          zIndex: !local ? 1 : showInfoWindow === local.id_local ? 100 : 1,
        }}
        onClick={() => {
          if (onClickEvent) onClickEvent(local);
        }}
      >
        {idNumber || ''}
        {local && showInfoWindow === local.id_local && (
          <div
            ref={infoWindowRef}
            style={{
              width: '10rem',
              backgroundColor:
                currentTheme === Themes.DARK_THEME ? '#1e1e1e' : 'white',
              border: `1px solid ${color}`,
              color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              padding: '.5rem',
              borderRadius: '5px',
              position: 'absolute',
              bottom: spacer,
              left: '-5rem',
              zIndex: !local ? 1 : showInfoWindow === local.id_local ? 100 : 1,
            }}
          >
            {order && (
              <>
                <strong>{langLocal.ot}:</strong> {order.id_pedido}
                <br />
              </>
            )}
            <strong>Punto:</strong> ({local.sucursal}) {local.nombre}
            <br />
            <strong>Dirección:</strong> {local.direccion} - {local.direccion2}
            <br />
            <strong>{langLocal.comuna}:</strong> {local.commune.nombre}
            <br />
          </div>
        )}
      </div>
    </OverlayViewF>
  );
};

export default Marker;
