import React, { useState, useEffect } from "react";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { Select, Button, Tag } from 'antd';
import { http } from "../../../http/axios";
import { Themes } from '../../../store/ui';

const { Option } = Select;

function UserSelect({ value, load, users, setUsers, techniciansData }) {

  const currentTheme = useSelector(selectUiTheme);

  const [loadData, setLoadData] = useState(false);
  const [options, setOptions] = useState(techniciansData); // lista de usuarios que se muestran en el select

  const [selectedValues, setSelectedValues] = useState(techniciansData); // lista de usuarios seleccionados
  const [selectedOptions, setSelectedOptions] = useState([]);


  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOptions(techniciansData);
  }, [load])

  useEffect(() => {

    const usernames = techniciansData
      .filter((technician) => value.some((obj) => obj.id === technician.user_id))
      .map((technician) => technician.username);

    setSelectedValues(usernames);

  }, [value])

  const loadFrequencyUser = async (user_id) => { // consulta la informacion de las zonas de un usuario
    setLoadData(true);
    try {
      const frequencyZones = await http.get("/frequency-zones/user/" + user_id);
      return frequencyZones;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoadData(false);
    }
  };

  const onAdd = (user) => { // agrega un usuario a la tabla, filtra repetidos
    const uniqueArray = [];

    [...users, user].forEach((obj) => {
      if (!uniqueArray.some((item) => item.id === obj.id)) {
        uniqueArray.push(obj);
      }
    });

    setUsers(uniqueArray)
  };

  const onUsersAdd = (user) => {
    setUsers((prevUsers) => {
      const isDuplicate = prevUsers.some((prevUser) => prevUser.id === user.id);
      if (!isDuplicate) {
        return [...prevUsers, user];
      }
      return prevUsers;
    });
  };

  const handleDeselect = (value) => {
    const technical = techniciansData.find(technical => technical.username === value); // enceutra el usuario desseleccionado
    const filter = users.filter(user => user.id !== technical.user_id); // filtra el usuario desseleccionado
    setUsers(filter);
  };

  const handleSelect = (value) => {
    const technical = techniciansData.find(technical => technical.username === value); // enceutra el usuario seleccionado
    loadFrequencyUser(technical.user_id).then((response) => { // consulta la informacion de las zonas del usuario
      const user = {
        data: response,
        id: technical.user_id,
        username: technical.username,
        string: `${technical.firstname} ${technical.lastname}`
      }
      onAdd(user); // agrega el usuario a la tabla
    });
  };

  const handleAddUsers = async () => {
    setLoading(true);
    const promises = selectedOptions.map(async (value) => {
      const technical = techniciansData.find(
        (technical) => technical.username === value
      );
      const response = await loadFrequencyUser(technical.user_id);

      const user = {
        data: response,
        id: technical.user_id,
        username: technical.username,
        string: `${technical.firstname} ${technical.lastname}`,
        group: technical.group,
      };
      await onUsersAdd(user);
    });

    await Promise.all(promises);
    setSelectedOptions([]);
    setLoading(false);
  };


  const handleChange = (value) => {
    setSelectedOptions(value);
    setOptions(techniciansData)
  }

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const handleSearch = (newValue) => {

    const results = techniciansData.filter((user) => {
      const fullName = `${user.firstname} ${user.lastname}`;
      const normalizedFullName = removeAccents(fullName.toLowerCase());
      const normalizedValue = removeAccents(newValue.toLowerCase());

      return normalizedFullName.includes(normalizedValue);
    });

    if (newValue.length > 0) {
      setOptions(results)
    } else {
      setOptions(techniciansData)
    }
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: 1,
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="userSelect-ZoneFrequency">
      <Select
        className="custom-select-ZoneFrequency"
        dropdownClassName={currentTheme === Themes.DARK_THEME ? 'custom-dropdown-ZoneFrequency-dark' : ''}
        mode="multiple"
        //value={selectedValues}
        value={selectedOptions}
        allowClear
        style={{
          width: '100%',
        }}
        placeholder="Seleccione los usuarios"
        autoClearSearchValue={true} // borra la busqeuda al seleccionar un usuario
        //onSelect={handleSelect}
        //onDeselect={handleDeselect}
        onChange={handleChange}
        /*options={(options || []).map((opt) => ({
          value: opt.username,
          label: `${opt.firstname} ${opt.lastname}`,
        }))}*/
        loading={loadData || load}
        disabled={load || loading}
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={'...'}
        tagRender={tagRender}
        optionLabelProp="label"
      >
        {(options || []).map((opt) => (
          <Option
            key={opt.username}
            value={opt.username}
            label={`${opt.firstname} ${opt.lastname}`}
            className={currentTheme === Themes.DARK_THEME ? 'custom-option-ZoneFrequency-dark' : ''}
          >
            {`${opt.firstname} ${opt.lastname}`}
          </Option>
        ))}
      </Select>
      <Button
        type="text"
        icon={loading ? <LoadingOutlined style={{ color: currentTheme === Themes.DARK_THEME ? 'white' : 'black' }} /> : <PlusOutlined style={{ color: currentTheme === Themes.DARK_THEME ? 'white' : 'black' }} />}
        onClick={handleAddUsers}
        disabled={selectedOptions.length === 0 || loading}
      ></Button>
    </div>
  );
}

export default UserSelect
