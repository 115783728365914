import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { selectCurrentUser } from '../../store/auth/auth.selectors';
import { Themes } from '../../store/ui';
import encryption from '../../libs/encryption';
import { INFO_APP } from '../../const/info';

type Props = {
  url: string;
  theme: Themes;
  style?: React.CSSProperties;
  onlyUrl?: boolean;
};

const OnfieldFrame: FC<Props> = ({ url, theme, style, onlyUrl }) => {
  const [key, setKey] = useState(0);
  const [onfieldToken] = useState(localStorage.getItem('onfieldLegacyToken'));
  const currentUser = useSelector(selectCurrentUser);
  const loadingRef = useRef<LoadingBarRef>(null);
  const [absoluteURL, setAbsoluteURL] = useState(
    `${url}&token=${onfieldToken}&user_id=${currentUser?.user_id}&no_theme=1&theme_mode=` +
      (theme === Themes.LIGH_THEME ? 'light' : 'dark')
  );
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (currentUser) {
      if (loadingRef.current) loadingRef.current.continuousStart(0, 1000);
      setKey((oldKey) => oldKey + 1);
      if (onlyUrl) {
        setAbsoluteURL(`${url}`);
      } else {
        const urlSearch = new URL(url);
        setAbsoluteURL(
          `${urlSearch.origin}${urlSearch.pathname}?_token=${encryption.encrypt(
            JSON.stringify({
              route: urlSearch.searchParams.get('route'),
              token: onfieldToken,
              user_id: currentUser?.user_id,
              no_theme: 1,
              theme_mode: theme === Themes.LIGH_THEME ? 'light' : 'dark',
            }),
            INFO_APP.CRYPT_PASSWORD
          )}`
        );
      }
    }
  }, [url, onlyUrl, theme, currentUser, onfieldToken]);

  if (!currentUser) return <></>;

  return (
    currentUser && (
      <>
        <LoadingBar
          shadow={false}
          containerClassName="onfield-loading"
          ref={loadingRef}
          color="#1890ff"
        />
        <iframe
          key={key}
          onLoad={(ev) => {
            if (loadingRef.current) loadingRef.current.complete();
          }}
          width="100%"
          style={style ?? { height: '100vh' }}
          frameBorder={0}
          title="activities"
          src={absoluteURL}
          ref={iframeRef}
          allowFullScreen
          className="onfield-iframe"
        ></iframe>
      </>
    )
  );
};

export default OnfieldFrame;
