import ReactDragListView from 'react-drag-listview';
import { Col, List, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboardWidgets } from '../../store/dashboard/dashboard.selectors';
import { DashboardWidget } from '../../../domain/models/DashboardWidget';
import { MenuOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getDashboardSuccess } from '../../store/dashboard/dashboard.actions';

const DashboardWidgetSettings = () => {
  const currentDashboards = useSelector(selectDashboardWidgets);
  const [data, setData] = useState<DashboardWidget[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(currentDashboards);
  }, [currentDashboards]);

  const onDragEnd = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0) return;

    const items = [...data];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    let itemsNewOrder = items.map((item, index) => {
      return {
        ...item,
        position: index + 1,
      };
    });
    localStorage.setItem('dashboardWidgets', JSON.stringify(itemsNewOrder));
    dispatch(getDashboardSuccess(itemsNewOrder));
    setData(items);
  };

  return (
    <div
      style={{
        width: '22rem',
        height: '80vh',
        overflowY: 'auto',
        padding: '.5rem',
      }}
    >
      <Row gutter={[5, 5]}>
        <Col
          span={24}
          style={{
            textAlign: 'center',
            fontWeight: '600',
            marginBottom: '1rem',
          }}
        >
          Configuración en panel de control
        </Col>
        <Col span={24} style={{ fontStyle: 'italic', fontWeight: '500' }}>
          Orden de paneles a mostrar
        </Col>
        <Col span={24}>
          <ReactDragListView
            nodeSelector=".ant-list-item.draggble"
            onDragEnd={onDragEnd}
          >
            <List
              size="small"
              dataSource={data}
              renderItem={(item) => {
                return (
                  <List.Item
                    className="draggble"
                    style={{
                      border: '1px solid #ddd',
                      borderRadius: '5px',
                      marginTop: '5px',
                    }}
                    actions={[<MenuOutlined />]}
                  >
                    <List.Item.Meta
                      avatar={<MinusCircleOutlined />}
                      title={item.name}
                    />
                  </List.Item>
                );
              }}
            />
          </ReactDragListView>
        </Col>
        <Col span={24} style={{ fontStyle: 'italic', fontWeight: '500' }}>
          Otros paneles
        </Col>
      </Row>
    </div>
  );
};

export default DashboardWidgetSettings;
