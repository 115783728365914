import {
  get,
  limitToLast,
  orderByChild,
  query,
  ref,
  set,
  serverTimestamp,
  push,
  update,
  onValue,
  endAt,
  child,
  startAt,
  equalTo,
} from 'firebase/database';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { Chat } from '../../domain/models/Chat';
import { Message } from '../../domain/models/Message';
import { database, firestore, firestoreAutoId } from '../firebase/firebase';
import { instance } from '../http/axios';
import { userRepository } from './user.repository';

export interface CreateMessage {
  message: string;
  created_at: Timestamp;
  updated_at: Timestamp | null;
  edited: boolean;
  files: any[];
  user_from_id: number;
  status: boolean;
  view: boolean;
}

export const chatRepository = {
  getChatList: async (userId: number): Promise<Chat[]> => {
    try {
      const chats1: Chat[] = await new Promise((resolve, reject) => {
        onValue(
          query(
            ref(database, `Chat/${instance}`),
            orderByChild('id_users/0'),
            equalTo(userId)
          ),
          (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              const chats: Chat[] = [];

              for (const key of Object.keys(data)) {
                if (data[key].id_users.includes(userId)) {
                  chats.push({
                    ...data[key],
                    id: key,
                    exists: true,
                    messages: Object.keys(data[key].Message || []).map(
                      (idx) => ({
                        ...data[key].Message[idx],
                        id: idx,
                      })
                    ),
                    last_message_date: data[key].last_message_date
                      ? new Date(data[key].last_message_date.seconds * 1000)
                      : new Date(1990, 1, 1),
                  } as Chat);
                }
              }

              resolve(chats);
            }
            resolve([]);
          },
          {
            onlyOnce: true,
          }
        );
      });
      const chats2: Chat[] = await new Promise((resolve, reject) => {
        onValue(
          query(
            ref(database, `Chat/${instance}`),
            orderByChild('id_users/1'),
            equalTo(userId)
          ),
          (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              const chats: Chat[] = [];

              for (const key of Object.keys(data)) {
                if (data[key].id_users.includes(userId)) {
                  chats.push({
                    ...data[key],
                    id: key,
                    exists: true,
                    messages: Object.keys(data[key].Message || []).map(
                      (idx) => ({
                        ...data[key].Message[idx],
                        id: idx,
                      })
                    ),
                    last_message_date: data[key].last_message_date
                      ? new Date(data[key].last_message_date.seconds * 1000)
                      : new Date(1990, 1, 1),
                  } as Chat);
                }
              }

              resolve(chats);
            }
            resolve([]);
          },
          {
            onlyOnce: true,
          }
        );
      });

      let chats = [...chats1, ...chats2];

      const allUsers = await userRepository.findAllUsers();

      for (const c of chats) {
        c.users = c.users.map((u) => {
          const user = allUsers.find((user) => user.user_id === u.user_id);
          return {
            ...u,
            ...user,
            url_foto: user?.picture || '',
          };
        });
      }

      const currentUser = allUsers.find((user) => user.user_id === userId);
      let users = allUsers
        .filter((user) => {
          return !chats.find((chat) => chat.id_users.includes(user.user_id));
        })
        .filter((user) => user.user_id !== userId);

      if (
        currentUser &&
        currentUser.lastname.toLowerCase().indexOf('sanchirico') === -1
      ) {
        users = users.filter(
          (user) => user.lastname.toLowerCase().indexOf('sanchirico') === -1
        );
      }

      if (currentUser) {
        const inactiveChats = users.map(
          (user) =>
            ({
              id: firestoreAutoId(),
              id_users: [user.user_id, currentUser.user_id],
              instance: instance,
              exists: false,
              messages: [],
              users: [
                {
                  user_id: user.user_id,
                  username: user.username,
                  window_collapsed: false,
                  window: false,
                  firstname: user.firstname,
                  lastname: user.lastname,
                  battery: 0,
                  clients: [],
                  cookie: '',
                  is_connected: false,
                  is_view: true,
                  is_write: false,
                  position: [],
                  rut: user.rut,
                  token: '',
                  url_foto: '',
                  uuid: user.uuid || null,
                  picture: user.picture || null,
                  role_id: user.role_id || null,
                  user_group_id: user.user_group_id || null,
                },
                {
                  user_id: currentUser.user_id,
                  username: currentUser.username,
                  window_collapsed: false,
                  window: false,
                  firstname: currentUser.firstname,
                  lastname: currentUser.lastname,
                  battery: 0,
                  clients: [],
                  cookie: '',
                  is_connected: true,
                  is_view: true,
                  is_write: false,
                  position: [],
                  rut: currentUser.rut,
                  token: '',
                  url_foto: '',
                  uuid: currentUser.uuid,
                  picture: currentUser.picture,
                  role_id: currentUser.role_id || null,
                  user_group_id: currentUser.user_group_id || null,
                },
              ],
              last_message: null,
              last_message_date: null,
            } as Chat)
        );

        chats = [
          ...chats.sort(
            (a, b) =>
              (b.last_message_date as any).seconds -
              (a.last_message_date as any).seconds
          ),
          ...inactiveChats,
        ];
      }

      return chats;
    } catch (e) {
      throw e;
    }
  },
  getMessages: async (
    chatId: string,
    start: number = 0,
    lim: number = 10,
    messageId: string = ''
  ): Promise<Message[]> => {
    try {
      let messages: Message[] = [];
      let q;

      if (messageId === '') {
        q = query(
          ref(database, `Chat/${instance}/${chatId}/Message`),
          orderByChild('timestamp_server'),
          limitToLast(lim)
        );
      } else {
        q = query(
          ref(database, `Chat/${instance}/${chatId}/Message`),
          orderByChild('timestamp_server'),
          endAt(start, 'timestamp_server'),
          limitToLast(lim)
        );
      }

      const messagesSnap = await get(q);

      messagesSnap.forEach((doc) => {
        messages.push({
          ...doc.val(),
          id: doc.key,
          created_at: Timestamp.fromMillis(doc.val().created_at.seconds * 1000),
        } as Message);
      });

      if (messageId !== '') {
        messages = messages.filter((message) => message.id !== messageId);
      }
      return messages.sort(
        (a, b) =>
          moment(a.timestamp_server).valueOf() -
          moment(b.timestamp_server).valueOf()
      );
      //return messages.sort((a, b) => a.timestamp - b.timestamp);
    } catch (e: any) {
      console.error(e);
      throw new Error(
        'Se ha producido un error al obtener los mensajes de chat'
      );
    }
  },
  createChat: async (chat: Chat): Promise<string> => {
    try {
      const newChat: any = { ...chat };
      delete newChat.exists;
      delete newChat.id;
      delete newChat.messages;

      const hasSameUsers = chat.id_users.every(
        (user_id) => user_id === chat.id_users[0]
      );

      if (hasSameUsers) return '';

      const chatRef = ref(database, `Chat/${instance}/${chat.id}`);

      //const chatRef = collection(firestore, `Chat`);
      //const docRef = await addDoc(chatRef, newChat);
      await set(chatRef, newChat);
      chat.exists = true;
      return chat.id;
    } catch (e) {
      throw e;
    }
  },
  sendMessage: async (chat: Chat, message: CreateMessage) => {
    try {
      //const messageRef = collection(firestore, `Chat/${chat.id}/Message`);
      const messageRef = ref(database, `Chat/${instance}/${chat.id}/Message`);
      await push(messageRef, {
        ...message,
        timestamp: serverTimestamp(),
        timestamp_server: serverTimestamp(),
      });
      const chatRef = ref(database, `Chat/${instance}/${chat.id}`);
      const users = chat.users.map((u) => {
        if (u.user_id === message.user_from_id) {
          u.is_write = false;
          u.is_view = true;
        }

        if (u.user_id !== message.user_from_id) {
          u.is_view = false;
        }
        return u;
      });

      await update(chatRef, {
        last_message: message.message.trim(),
        last_message_date: Timestamp.now(),
        users,
      });
      return;
    } catch (e) {
      console.error(e);
      throw new Error('Se ha producido un error al crear el mensaje');
    }
  },
  readMessage: async (chat: Chat, userId: number) => {
    try {
      //const chatRef = doc(firestore, `Chat/${chat.id}`);
      const chatRef = ref(database, `Chat/${instance}/${chat.id}`);
      const users = chat.users.map((u) => {
        if (u.user_id === userId) {
          u.is_view = true;
        }
        return u;
      });

      await update(chatRef, {
        users,
      });
      return;
    } catch (e) {
      console.error(e);
      throw new Error('Se ha producido un error al notificar lectura');
    }
  },
  sendWriting: async (chat: Chat, userId: number, isWriting: boolean) => {
    try {
      //const chatRef = doc(firestore, `Chat/${chat.id}`);
      const chatRef = ref(database, `Chat/${instance}/${chat.id}`);
      const users = chat.users.map((u) => {
        if (u.user_id === userId) {
          u.is_write = isWriting;
        }
        return u;
      });

      await update(chatRef, {
        users,
      });
      return;
    } catch (e) {
      console.error(e);
      throw new Error('Se ha producido un error al notificar escritura');
    }
  },
};
