import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useNotification = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const requestPermission = async () => {
    try {
      const result = await Notification.requestPermission();
      setHasPermission(result === 'granted');
    } catch (error) {
      console.error(error);
      setHasPermission(false);
    }
  };

  const sendNotification = (title: string, options: NotificationOptions) => {
    if (hasPermission && !isTabletOrMobile) {
      return new Notification(title, options);
    }
    return null;
  };

  useEffect(() => {
    if ('Notification' in window) {
      if (
        Notification.permission === 'default' ||
        Notification.permission === 'denied'
      ) {
        requestPermission();
      } else {
        setHasPermission(true);
      }
    }
  }, []);

  return { hasPermission, sendNotification };
};

export default useNotification;
