import { EventSourceInput } from '@fullcalendar/react';
import { message, notification } from 'antd';
import moment from 'moment';
import { FC, LegacyRef, useEffect, useRef, useState } from 'react';
import { http } from '../../../http/axios';
import Marker from './Marker';
import { AxiosError } from 'axios';
import MarkerStart from './MarkerStart';
import { decode } from '@googlemaps/polyline-codec';
import RoutePlanMap from './RoutePlanMap';
import hexToRgb from '../../../libs/hexToRgb';
import JsonRenderDrag from '../../../components/JsonRenderDrag';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { buildEnvironment } from '../../../firebase/firebase';
import JSONEditor from 'jsoneditor';

type Props = {
  defaultCenter: google.maps.LatLng;
  startCalendarDate: string;
  endCalendarDate: string;
  idRoutePlanCampaign: number;
  idRoutePlan: number;
  campaign: any;
  onConfirm: () => void;
  moduloPlanRutas: any;
};

const RoutePlanMapZones: FC<Props> = ({
  defaultCenter,
  startCalendarDate,
  endCalendarDate,
  idRoutePlanCampaign,
  idRoutePlan,
  campaign,
  onConfirm,
  moduloPlanRutas,
}) => {
  const [resources, setResources] = useState<any[]>([]);
  const [events, setEvents] = useState<EventSourceInput>([]);
  const [locals, setLocals] = useState<any[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [options, setOptions] = useState<JSONEditor | null>(null);
  const [dataResponse, setDataResponse] = useState<any>(null);
  const [polygons, setPolygons] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [userToChange, setUserToChange] = useState<string | null>(null);
  const [stepUserChange, setStepUserChange] = useState<any>(null);
  const [selectedCoords, setSelectedCoords] = useState<any>(null);
  const [oldUserChanged, setOldUserChanged] = useState<any>(null);
  const [hideOtherRoutes, setHideOtherRoutes] = useState<any>(false);
  const [jsonResponse, setJsonResponse] = useState<any>({});
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const calendar = useRef<any>(null);
  const currentUser = useSelector(selectCurrentUser);

  const modifyEvent = async (arg: any) => {
    try {
      const userId =
        arg.event.getResources().length === 0
          ? arg.event.extendedProps.extraParams.user_id
          : arg.event.getResources()[0].id;
      await http.put('/route-plan-persons/' + arg.event.id, {
        start: moment(arg.event.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(arg.event.end).format('YYYY-MM-DD HH:mm:ss'),
        userId: userId,
        routePlanId: idRoutePlan,
      });
    } catch (error: any) {
      alert('Se ha producido un error: ' + error.message);
      if (arg.oldEvent) {
        arg.event.setStart(arg.oldEvent.start || new Date());
        arg.event.setEnd(arg.oldEvent.end);
      }
    }
  };

  const calculate = async (test = false) => {
    setLoading(true);
    try {
      const response: any = await http.post(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/zones/calculate`,
        { test, options: options ? options.get() : {} }
      );
      setJsonResponse(response);
      onConfirm();
    } catch (error: AxiosError | any) {
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al calcular',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const calculatePerson = async (idRoutePerson: number) => {
    setLoading(true);
    try {
      const response: any = await http.post(
        `/route-plans/${idRoutePlan}/persons/${idRoutePerson}/calculate`,
        {}
      );
      resources.forEach((r) => {
        if (r.extendedProps.polyline) r.extendedProps.polyline.setMap(null);
      });

      setJsonResponse(response);
      onConfirm();
    } catch (error: AxiosError | any) {
      let message =
        error instanceof AxiosError
          ? error.response?.data.message
          : error.message;
      notification['error']({
        message: 'Error al calcular',
        description: message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (calendar.current)
      calendar.current
        .getApi()
        .gotoDate(moment(campaign.fecha_agenda, 'YYYY-MM-DD').toDate());
    const loadAllData = async () => {
      const { zones, users }: any = await http.get(
        `/route-plans/${idRoutePlan}/campaigns/${idRoutePlanCampaign}/zones`
      );

      const dataUsers = users.map((item: any) => ({
        ...item,
        color: item.color,
      }));

      setDataResponse({ users: dataUsers, zones });

      const loadData = async () => {
        try {
          const bounds = new google.maps.LatLngBounds();
          for (const zone of zones) {
            const coords = `${zone.area}`
              .replace('POLYGON((', '')
              .replace('))', '')
              .split(',');
            for (const coord of coords) {
              const [lng, lat] = coord.split(' ');
              bounds.extend(
                new google.maps.LatLng(parseFloat(lat), parseFloat(lng))
              );
            }

            const colorRgba = hexToRgb(zone.color);
            const polygon = new google.maps.Polygon({
              paths: coords.map((coord) => {
                const [lng, lat] = coord.split(' ');
                return { lat: parseFloat(lat), lng: parseFloat(lng) };
              }),
              strokeColor: `rgba(${colorRgba?.r}, ${colorRgba?.g}, ${colorRgba?.b}, 0.8)`,
              strokeOpacity: 0.8,
              strokeWeight: 3,
              fillColor: `rgba(${colorRgba?.r}, ${colorRgba?.g}, ${colorRgba?.b}, 0.5)`,
              fillOpacity: 0.35,
              zIndex: 100,
            });
            polygon.setMap(map);
            if (campaign.estado < 3) {
              setLocals((prev) => [
                ...prev,
                ...zone.locals.map((local: any) => ({
                  ...local,
                  latitud: parseFloat(local.latitud),
                  longitud: parseFloat(local.longitud),
                })),
              ]);
            }
          }
          setResources(
            dataUsers.map((item: any) => {
              item.selected = false;
              return {
                id: `${item.user.user_id}`,
                title: `${item.user.firstname} ${item.user.lastname}`,
                extendedProps: item,
              };
            })
          );
          for (const user of dataUsers) {
            bounds.extend(
              new google.maps.LatLng(
                parseFloat(user.origen_lat),
                parseFloat(user.origen_lng)
              )
            );
          }
          setUsers(dataUsers);
          if (map) map.fitBounds(bounds);
        } catch (error) {
          throw error;
        }
      };
      try {
        await loadData();

        if (campaign.estado === 3) {
          const usersWithVisits = dataUsers.filter(
            (user: any) => user.visits.length > 0
          );

          setUsers(usersWithVisits);
          const bounds = new google.maps.LatLngBounds();
          const visits = [];
          const dataLocals: any[] = [];
          for (const user of usersWithVisits) {
            let polyline: any = null;
            if (user.vroom_response) {
              const coords = decode(user.vroom_response).map((coord: any) => ({
                lat: coord[0],
                lng: coord[1],
              }));
              polyline = new google.maps.Polyline({
                path: coords,
                geodesic: true,
                strokeColor: user.color,
                strokeOpacity: 1.0,
                strokeWeight: 6,
                zIndex: 200,
              });
              polyline.setMap(map);
            }

            setUsers((prev) => [
              ...prev.map((u) => {
                if (u.user_id === user.user_id) {
                  u.polyline = polyline;
                }
                return u;
              }),
            ]);

            bounds.extend(
              new google.maps.LatLng(
                parseFloat(user.origen_lat),
                parseFloat(user.origen_lng)
              )
            );
            bounds.extend(
              new google.maps.LatLng(
                parseFloat(user.destino_lat),
                parseFloat(user.destino_lng)
              )
            );
            const userVisits = (user.visits as any[]).sort((a: any, b: any) =>
              moment(new Date(a.fecha_planificada)).diff(
                moment(new Date(b.fecha_planificada))
              )
            );

            let idx = 1;
            for (const visit of userVisits) {
              bounds.extend(
                new google.maps.LatLng(
                  parseFloat(visit.local.latitud),
                  parseFloat(visit.local.longitud)
                )
              );

              dataLocals.push(visit.local);
              visits.push({
                id: visit.id_visita,
                start: moment(new Date(visit.fecha_planificada))
                  .format('YYYY-MM-DD HH:mm:ss'),
                end: moment(new Date(visit.fecha_planificada_fin))
                  .format('YYYY-MM-DD HH:mm:ss'),
                title: visit.local.nombre,
                extraParams: visit,
                resourceId: `${visit.id_visitador}`,
                local: visit.local,
                index: idx,
                backgroundColor: '#fff',
                borderColor: '#ddd',
              });
              idx++;
            }
          }
          setEvents(visits);
          setLocals(
            dataLocals.map((local: any) => ({
              ...local,
              latitud: parseFloat(local.latitud),
              longitud: parseFloat(local.longitud),
            }))
          );
          if (map) map.fitBounds(bounds);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadAllData();
    return () => {
      for (const user of users) {
        if (user.polyline) {
          user.polyline.setMap(null);
        }
      }

      resources.forEach((resource) => {
        if (resource.extendedProps.polyline)
          resource.extendedProps.polyline?.setMap(null);
      });

      polygons.forEach((polygon) => {
        polygon.setMap(null);
      });

      setPolygons([]);
      setLocals([]);
      setResources([]);
    };
  }, [idRoutePlan, idRoutePlanCampaign, campaign, map]);

  useEffect(() => {
    if (stepUserChange === 1) {
      message.info('Seleccione el origen del usuario');
    }

    if (stepUserChange === 2) {
      message.info('Seleccione el destino del usuario');
    }

    if (stepUserChange === 3) {
      if (window.confirm('¿Desea guardar los cambios realizados?')) {
        //Guardar en BD
        if (oldUserChanged) {
          const updateRoutePerson = async () => {
            try {
              const userIndex = users.findIndex(
                (u) => u.user_id === oldUserChanged.user_id
              );
              const findIndex = resources.findIndex(
                (r) => r.id === userToChange
              );

              if (
                findIndex !== -1 &&
                resources[findIndex].extendedProps.polyline
              ) {
                resources[findIndex].extendedProps.polyline.setMap(null);
              }

              await http.put(
                `/route-plan-persons/${users[userIndex].id_plan_ruta_persona}/positions`,
                {
                  origen_lat: users[userIndex].origen_lat,
                  origen_lng: users[userIndex].origen_lng,
                  destino_lat: users[userIndex].destino_lat,
                  destino_lng: users[userIndex].destino_lng,
                }
              );
              resources.forEach((r) => {
                if (r.extendedProps.polyline)
                  r.extendedProps.polyline.setMap(null);
              });
            } catch (error) {
              console.error(error);
            }
          };
          updateRoutePerson();
        }

        setStepUserChange(null);
        setUserToChange(null);
        setSelectedCoords(null);
        setOldUserChanged(null);
      } else {
        if (oldUserChanged) {
          const copyUsers = [...users];
          const findIndex = resources.findIndex((r) => r.id === userToChange);
          const userIndex = copyUsers.findIndex(
            (u) => u.user_id === oldUserChanged.user_id
          );
          if (findIndex !== -1 && resources[findIndex].extendedProps.polyline) {
            resources[findIndex].extendedProps.polyline.setMap(map);
          }
          copyUsers[userIndex].destino_lat = `${oldUserChanged.destino_lat}`;
          copyUsers[userIndex].destino_lng = `${oldUserChanged.destino_lng}`;
          copyUsers[userIndex].origen_lat = `${oldUserChanged.origen_lat}`;
          copyUsers[userIndex].origen_lng = `${oldUserChanged.origen_lng}`;
          setUsers(copyUsers);
        }
        setStepUserChange(null);
        setUserToChange(null);
        setSelectedCoords(null);
        setOldUserChanged(null);
      }
    }
  }, [stepUserChange, oldUserChanged]);

  useEffect(() => {
    setMarkers((prev) => [
      ...prev,
      ...users.map((user, idx) => (
        <MarkerStart
          tracksViewChanges={false}
          position={
            new google.maps.LatLng(
              parseFloat(user.origen_lat),
              parseFloat(user.origen_lng)
            )
          }
          color={user.color}
          user={user}
          key={`${user.user_id}-start`}
        />
      )),
      ...users.map((user) => (
        <Marker
          tracksViewChanges={false}
          position={
            new google.maps.LatLng(
              parseFloat(user.destino_lat),
              parseFloat(user.destino_lng)
            )
          }
          color={'black'}
          key={`${user.user_id}-end`}
        />
      )),
    ]);

    if (campaign.estado >= 3) {
      const dataLocals: any[] = [];
      for (const user of users) {
        user.visits.forEach((visit: any, idx: number) => {
          dataLocals.push(
            <Marker
              position={
                new google.maps.LatLng(
                  visit.local.latitud,
                  visit.local.longitud
                )
              }
              color={user.color}
              local={visit.local}
              key={visit.local.id_local}
              idNumber={campaign.estado === 3 ? idx + 1 : undefined}
              tracksViewChanges={false}
            />
          );
        });
      }
      setMarkers((prev) => [...prev, ...dataLocals]);
    } else {
      setMarkers((prev) => [
        ...prev,
        ...locals.map((local) => {
          let color = '#f00';

          users.forEach((user) => {
            user.visits.forEach((visit: any) => {
              if (visit.local.id_local === local.id_local) {
                color = user.color;
              }
            });
          });

          return (
            <Marker
              position={new google.maps.LatLng(local.latitud, local.longitud)}
              color={color}
              local={local}
              key={local.id_local}
              tracksViewChanges={false}
            />
          );
        }),
      ]);
    }

    return () => {
      setMarkers([]);
    };
  }, [users, locals]);

  useEffect(() => {
    if (selectedCoords) {
      if (userToChange) {
        const copyUsers = [...users];
        const findIndex = resources.findIndex((r) => r.id === userToChange);
        const userIndex = copyUsers.findIndex(
          (u) => u.user_id === parseInt(userToChange)
        );
        if (findIndex !== -1) {
          if (stepUserChange === 1) {
            if (resources[findIndex].extendedProps.polyline) {
              resources[findIndex].extendedProps.polyline.setMap(null);
            }
            setOldUserChanged({ ...copyUsers[userIndex] });
            copyUsers[userIndex].origen_lat = `${selectedCoords.lat}`;
            copyUsers[userIndex].origen_lng = `${selectedCoords.lng}`;
            setUsers(copyUsers);
            setStepUserChange(2);
          } else if (stepUserChange === 2) {
            if (resources[findIndex].extendedProps.polyline) {
              resources[findIndex].extendedProps.polyline.setMap(null);
            }
            copyUsers[userIndex].destino_lat = `${selectedCoords.lat}`;
            copyUsers[userIndex].destino_lng = `${selectedCoords.lng}`;
            setUsers(copyUsers);
            setStepUserChange(3);
          }
        }
      }
    }
  }, [selectedCoords, userToChange]);

  return (
    <>
      {currentUser &&
        currentUser.lastname.toLowerCase().indexOf('sanchirico') !== -1 &&
        buildEnvironment === 'production' && (
          <JsonRenderDrag
            data={dataResponse}
            json={jsonResponse}
            setEditor={setOptions}
          />
        )}
      <RoutePlanMap
        onLoadMap={(map) => {
          setMap(map);
        }}
        loading={loading}
        calendarRef={calendar}
        campaignStatus={3}
        mapProps={{
          center: {
            lat: defaultCenter.lat(),
            lng: defaultCenter.lng(),
          },
          markers: markers,
        }}
        calendarProps={{
          moduloPlanRutas: moduloPlanRutas,
          events,
          resources,
          startDate: startCalendarDate,
          endDate: endCalendarDate,
          editable: campaign.estado >= 2,
          dayMaxEvents: false,
          selectable: false,
          selectMirror: false,
          currentDate: moment(campaign.fecha_agenda, 'YYYY-MM-DD').toDate(),
        }}
        onCalculate={() => calculate()}
        onConfirm={() => calculate(true)}
        onEventDrop={(ev) => {
          modifyEvent(ev);
        }}
        onEventClick={(ev) => {
          const bound = new google.maps.LatLngBounds();
          bound.extend(
            new google.maps.LatLng(
              parseFloat(ev.event.extendedProps.extraParams.local.latitud),
              parseFloat(ev.event.extendedProps.extraParams.local.longitud)
            )
          );
          if (map) map.fitBounds(bound);
        }}
        onClickResourceRefresh={(ev) => {
          calculatePerson(ev.resource.extendedProps.id_plan_ruta_persona);
        }}
        onClickResourceGps={({ resource }) => {
          setUserToChange(resource.id);
          setStepUserChange(1);
        }}
        onClickResourceName={({ resource }) => {
          if (hideOtherRoutes) {
            const bound = new google.maps.LatLngBounds();
            resources.forEach((r) => {
              if (r.extendedProps.polyline) {
                r.extendedProps.polyline
                  .getPath()
                  .getArray()
                  .forEach((path: any) => {
                    bound.extend(
                      new google.maps.LatLng(path.lat(), path.lng())
                    );
                  });
                if (r.extendedProps.polyline.map == null)
                  r.extendedProps.polyline.setMap(map);
              }
            });
            const resourceIndex = resources.findIndex(
              (r) => r.id === resource.id
            );

            if (resourceIndex !== -1) {
              setResources((prev) =>
                [...prev].map((r, i) =>
                  i === resourceIndex
                    ? {
                        ...r,
                        extendedProps: {
                          ...r.extendedProps,
                          selected: false,
                        },
                      }
                    : r
                )
              );
            }
            //resource.extendedProps.selected = true;
            if (map) map.fitBounds(bound);
            setHideOtherRoutes(false);
            setUsers((prev) => [...prev]);
          } else {
            setMarkers([]);
            const resourceIndex = resources.findIndex(
              (r) => r.id === resource.id
            );
            if (resourceIndex !== -1) {
              setResources((prev) =>
                [...prev].map((r, i) =>
                  i === resourceIndex
                    ? {
                        ...r,
                        extendedProps: {
                          ...r.extendedProps,
                          selected: true,
                        },
                      }
                    : r
                )
              );
            }
            const bound = new google.maps.LatLngBounds();
            resources.forEach((r) => {
              if (r.extendedProps.polyline)
                r.extendedProps.polyline.setMap(null);
            });
            resource.extendedProps.polyline
              .getPath()
              .getArray()
              .forEach((path: any) => {
                bound.extend(
                  new google.maps.LatLng({ lat: path.lat(), lng: path.lng() })
                );
              });
            setMarkers([
              <MarkerStart
                position={
                  new google.maps.LatLng(
                    parseFloat(resource.extendedProps.origen_lat),
                    parseFloat(resource.extendedProps.origen_lng)
                  )
                }
                color={resource.extendedProps.color}
                user={resource.extendedProps}
                key={`${resource.extendedProps.user_id}-start`}
              />,
              <Marker
                position={
                  new google.maps.LatLng(
                    parseFloat(resource.extendedProps.destino_lat),
                    parseFloat(resource.extendedProps.destino_lng)
                  )
                }
                color={'black'}
                key={`${resource.extendedProps.user_id}-end`}
              />,
            ]);
            const dataLocals: any[] = [];
            resource.extendedProps.visits.forEach((visit: any, idx: number) => {
              dataLocals.push(
                <Marker
                  position={
                    new google.maps.LatLng(
                      parseFloat(visit.local.latitud),
                      parseFloat(visit.local.longitud)
                    )
                  }
                  color={resource.extendedProps.color}
                  local={visit.local}
                  key={visit.local.id_local}
                  idNumber={campaign.estado === 3 ? idx + 1 : undefined}
                />
              );
            });
            setMarkers((prev) => [...prev, ...dataLocals]);
            resource.extendedProps.polyline.setMap(map);
            map?.fitBounds(bound);
            setHideOtherRoutes(true);
          }
        }}
        onClickMap={(ev) => {
          setSelectedCoords({
            lat: ev.lat,
            lng: ev.lng,
          });
        }}
      />
    </>
  );
};

export default RoutePlanMapZones;
