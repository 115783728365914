import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const selectAuth = (state: AppState) => state.auth;
export const selectAuthLoading = createSelector(
  [selectAuth],
  (auth) => auth.loading
);
export const selectCurrentUser = createSelector(
  [selectAuth],
  (auth) => auth.currentUser
);
export const selectLoadingSession = createSelector(
  [selectAuth],
  (auth) => auth.loading
);
