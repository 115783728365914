import { ConfigProvider, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import esEs from 'antd/es/locale/es_ES';
import store from '../store';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';

const App: FC = () => {
  const [showCookiesPoli, setShowCookiesPoli] = useState(false);
  const [isCookieEnabled, setIsCookieEnabled] = useState(true);

  useEffect(() => {
    setIsCookieEnabled(navigator.cookieEnabled);
  }, []);

  useEffect(() => {
    if (!isCookieEnabled) {
      setShowCookiesPoli(true);
    }
  }, [isCookieEnabled]);

  useEffect(() => {
    if (!showCookiesPoli && !navigator.cookieEnabled) {
      setShowCookiesPoli(true);
    }
  }, [showCookiesPoli]);

  return (
    <ConfigProvider locale={esEs}>
      <Provider store={store}>
        <BrowserRouter>
          <Modal
            visible={showCookiesPoli}
            onOk={() => {
              setShowCookiesPoli(false);
            }}
            closable={false}
            okText="Aceptar"
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <h1>Esta página web usa cookies</h1>
            <p style={{ textAlign: 'justify' }}>
              Para el correcto funcionamiento de Onfield se necesita que
              habilites las cookies en el navegador.
              <br />
              Actualmente tus Cookies están: DESHABILITADAS
            </p>
          </Modal>
          <Router />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
