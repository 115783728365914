import { FC } from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #3498db;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    z-index: 1;
  }
`;

const Marker: FC<any> = ({ title }) => <Wrapper />;

export default Marker;
