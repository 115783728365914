import React, { useRef, useState, useEffect } from 'react'
import { Form, Input, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function AlternativeAdd({ action, load }) {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        action(values);
        form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            name="AlternativeAdd"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"

            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            style={{ maxWidth: 600 }}

            disabled={load}
        >
            <Form.Item
                label="Nombre"
                name="nombre"
            >
                <Input
                    placeholder="Nombre"
                    defaultValue={''}
                />
            </Form.Item>

            <Form.Item
                label="Teléfono"
                name="telefono"
            >
                <Input
                    placeholder="Teléfono"
                    defaultValue={''}
                />
            </Form.Item>

            <Form.Item
                label="Dirección"
                name="direccion"
            >
                <Input
                    placeholder="Dirección"
                    defaultValue={''}
                />
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
            >
                <Input
                    placeholder="Email"
                    defaultValue={''}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{backgroundColor: '#1e91cf', borderColor: '#1978ab', borderRadius: '10px', color: 'white'}}>
                    Agregar
                </Button>
            </Form.Item>
        </Form>
    )
}

export default AlternativeAdd
