import {
  Button,
  Modal,
  Segmented,
  Select,
  Switch,
  List,
  Input,
  Space,
  Typography,
} from 'antd';
import { FC, useEffect, useState } from 'react';
//import useLanguage from '../../../../hooks/useLanguage';
import useLanguage from '../../../hooks/useLanguage';
import { Chat as IChat } from '../../../../domain/models/Chat';
import * as mapActivityService from '../../../../domain/services/widgets/mapActivity.service';
import styled from 'styled-components';
import { Themes } from '../../../store/ui';
import colors from '../../../const/colors';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import ChatItem from '../ChatItem';
import ucfirst from '../../../libs/ucfirst';
import { chatService } from '../../../../domain/services/Chat.service';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../firebase/firebase';
import { instance, http } from '../../../http/axios';
import { INFO_APP } from '../../../const/info';
import { closeChat, massiveChats } from '../../../store/chat/chat.actions';

const { TextArea } = Input;
const { Text, Link } = Typography;

const TextAreaStyled = styled(TextArea)`
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: #fff;
  input {
    background: transparent;
    color: #fff;
  }
  border-radius: 5px;
`;

//const SelectStyled = styled(Select)``;

const SearchStyled = styled(Select)`
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: #fff;
  input {
    background: transparent;
    color: #fff;
  }
  border-radius: 5px;
`;

const Modalx = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#57606F' : '#748196'} !important;
    color: white !important;

    h3 {
      color: white !important;
    }
  }

  .ant-segmented {
    color: white;
    background-color: rgba(0, 0, 0, 0.15);
  }

  .ant-segmented-item-selected {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .ant-select-selector {
    background-color: ${(props) =>
      props.theme === Themes.DARK_THEME ? '#464D59' : '#5D6778'} !important;
    color: white;
  }

  .ant-select-selection-item {
    color: black;
  }

  .ant-select-item-option-content {
    color: white !important;
  }

  .ant-select-item {
    color: white !important;
  }

  .ant-select-item {
    color: white !important;
  }

  .ant-select-item:focus {
    color: red !important;
  }

  .ant-select-item-option-active {
    color: black !important;
    background-color: blue !important;
  }

  .ant-select-item-option:hover {
    color: red !important;
    background-color: blue !important;
  }

  ant-select-item-option-selected {
    background-color: black;
  }

  .ant-select-selection-item {
    color: black !important;
  }

  .ant-select-item-option-active {
    background-color: black !important;
  }

  .ant-select-dropdown {
    background-color: rgba(75, 85, 100);
  }

  .ant-select-item-option-selected {
    background-color: black !important;
  }

  .ant-select-clear {
    background: transparent;
    /*color: #fff;*/
  }
`;

type Props = {
  visible: boolean;
  onCancel?: (visible: boolean) => void;
  data: IChat[];
  update: {
    function: any;
    data: boolean;
  };
};

const SelectOptions = {
  ALL: 'all',
  USERS: 'users',
  TYPE: 'type',
  GROUP: 'group',
};

const MassChat: FC<Props> = ({ visible, onCancel, data, update }) => {
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  const [load, setLoad] = useState<boolean>(false);

  const [selectChats, setSelectChats] = useState<IChat[]>(data);
  const [message, setMessage] = useState('');

  const [select, setSelect] = useState<string>('all');

  const [all, setAllChatsAll] = useState<boolean>(true);
  const [users, setUsers] = useState<boolean>(false);
  const [type, setType] = useState<boolean>(false);
  const [group, setGroup] = useState<boolean>(false);

  const [dataUsers, setDataUsers] = useState<any[]>([]);
  const [dataType, setDataType] = useState<any[]>([]);
  const [dataGroup, setDataGroup] = useState<any[]>([]);

  useEffect(() => {
    //dispatch(closeAllOpenedChats());
  }, []);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const response: any[] = await http.get('/users');
        setDataUsers(response);
      })();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const response: any[] = await http.get('/users/skills');
        setDataGroup(response);
      })();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const response: any[] = await http.get('/users/groups');
        setDataType(response);
      })();
    }
  }, [currentUser]);

  const handlers = {
    [SelectOptions.ALL]: () => setAllChatsAll(true),
    [SelectOptions.USERS]: () => setUsers(true),
    [SelectOptions.TYPE]: () => setType(true),
    [SelectOptions.GROUP]: () => setGroup(true),
  };

  useEffect(() => {
    setSelectChats([]);

    // Establece todos los estados a `false`
    setAllChatsAll(false);
    setUsers(false);
    setType(false);
    setGroup(false);

    // Luego, establece el estado correspondiente a `select` a `true`
    const selectedHandler = handlers[select] || handlers[SelectOptions.ALL];
    selectedHandler();
  }, [select]);

  useEffect(() => {
    if (all) {
      setSelectChats(data);
    }
  }, [all, data]);

  useEffect(() => {
    backgroundColorSelect();
  }, [selectChats]);

  const handlerUsersSelect = (value: string[]) => {
    backgroundColorSelect();
    const selected = data.filter((chat) => value.includes(chat.id));
    setSelectChats(selected);
  };

  const handlerTypeSelect = (value: string[]) => {
    backgroundColorSelect();
    const currentUserID = currentUser?.user_id;

    const usersIds = dataUsers
      .filter((item) => value.includes(item.user_group_id))
      .map((item) => item.user_id);

    const selected = data.filter((item) => {
      return item.id_users.some((id) => {
        return id !== currentUserID && usersIds.includes(id);
      });
    });

    setSelectChats(selected);
  };

  const handlerGroupSelect = (value: string[]) => {
    backgroundColorSelect();
    const currentUserID = currentUser?.user_id;

    const usersIds = dataUsers
      .filter((item) => value.includes(item.group))
      .map((item) => item.user_id);

    const selected = data.filter((item) => {
      return item.id_users.some((id) => {
        return id !== currentUserID && usersIds.includes(id);
      });
    });

    setSelectChats(selected);
  };

  const userName = (chat: IChat) => {
    const user = chat.users.find(
      (user) => user.user_id !== currentUser?.user_id
    );

    const firstname = user?.firstname.toLocaleLowerCase() ?? '';
    const lastname = user?.lastname.toLocaleLowerCase() ?? '';

    return `${firstname} ${lastname}`;
  };

  const handleSendMessage = async (chat: IChat) => {
    if ((message.length >= 2 || message !== '\n') && currentUser) {
      const newMessage = message;

      try {
        if (!chat.exists) {
          const newId = await chatService.createChat(chat);
          chat.id = newId;
          chat.exists = true;
        }

        chat.last_message = newMessage;

        chatService.sendMessage(chat, newMessage, currentUser?.user_id);
        //dispatch(closeChat(chat.id));
      } catch (e) {
        console.error(e);
      } finally {
        //dispatch(closeChat(chat.id));
        //dispatch(massiveChats(false));
      }
    }
  };

  const sendMassMessage = async () => {
    setLoad(true);

    if (selectChats.length > 0) {
      for (const chat of selectChats) {
        dispatch(massiveChats(true));
        handleSendMessage(chat);
        //console.log(chat);
      }
      //dispatch(massiveChats(false));
    }

    update.function(!update.data);

    close();
  };

  const capitalizeEachWord = (str: string): string => {
    return str.replace(/\b(\w|ñ|[áéíóúü])\S*/g, (match: string) => {
      return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
    });
  };

  const backgroundColorSelect = () => {
    const elementosTrue = document.querySelectorAll(
      'div.ant-select-item.custom-hover-select-mass[aria-selected="true"]'
    );

    const elementosFalse = document.querySelectorAll(
      'div.ant-select-item.custom-hover-select-mass[aria-selected="false"]'
    );

    elementosTrue.forEach((elemento) => {
      (elemento as HTMLElement).style.backgroundColor = 'black';
    });

    elementosFalse.forEach((elemento) => {
      (elemento as HTMLElement).style.backgroundColor = 'unset';
    });
  };

  const close = () => {
    setSelect('all');
    setMessage('');
    setLoad(false);

    //dispatch(closeAllOpenedChats());

    return onCancel ? onCancel(false) : null;
  };

  Object.freeze(SelectOptions);

  const options = [
    {
      label: 'Mensaje a Todos',
      value: SelectOptions.ALL,
    },
    {
      label: 'Selección de Usuarios',
      value: SelectOptions.USERS,
    },
    {
      label: 'Tipo de Usuario',
      value: SelectOptions.TYPE,
    },
    {
      label: 'Grupos',
      value: SelectOptions.GROUP,
    },
  ];

  return (
    <>
      <Modalx
        theme={currentTheme}
        title={<h3 style={{ textAlign: 'center' }}>Mensajes Masivos</h3>}
        footer={null}
        visible={visible}
        closable={true}
        onCancel={close}
        bodyStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Segmented
          className="custom-segmented"
          options={options}
          defaultValue={'all'}
          value={select}
          onChange={(value) => setSelect(value.toString())}
          style={{
            borderRadius: '5px',
            padding: '0.3rem',
          }}
        />

        {users && (
          <Select
            mode="multiple"
            style={{
              width: '100%',
              color: 'black',
              background: 'rgba(0, 0, 0, 0.2)',
              border: 'none',
              borderRadius: '5px',
            }}
            dropdownStyle={{
              color: 'white',
              backgroundColor:
                currentTheme === Themes.DARK_THEME ? '#464D59' : '#5D6778',
            }}
            bordered={false}
            allowClear
            placeholder="Selecciona a los usuarios"
            onChange={handlerUsersSelect}
            filterOption={(input, option: any) => {
              if (!option) return false;
              const name = option.children.toLowerCase();
              return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {data.map((chat) => (
              <Select.Option
                key={chat.id}
                value={chat.id}
                style={{
                  color: 'white',
                }}
                className="custom-hover-select-mass"
              >
                {capitalizeEachWord(userName(chat))}
              </Select.Option>
            ))}
          </Select>
        )}

        {type && (
          <Select
            mode="multiple"
            style={{
              width: '100%',
              color: 'black',
            }}
            dropdownStyle={{
              color: 'white',
              backgroundColor:
                currentTheme === Themes.DARK_THEME ? '#464D59' : '#5D6778',
            }}
            bordered={false}
            allowClear
            placeholder="Selecciona tipo de usuarios"
            onChange={handlerTypeSelect}
            filterOption={(input, option: any) => {
              if (!option) return false;
              const name = option.children.toLowerCase();
              return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {dataType.map((type) => (
              <Select.Option
                key={type.user_group_id}
                value={type.user_group_id}
                style={{
                  color: 'white',
                }}
                className="custom-hover-select-mass"
              >
                {type.name}
              </Select.Option>
            ))}
          </Select>
        )}

        {group && (
          <Select
            mode="multiple"
            style={{
              width: '100%',
              color: 'black',
            }}
            dropdownStyle={{
              color: 'white',
              backgroundColor:
                currentTheme === Themes.DARK_THEME ? '#464D59' : '#5D6778',
            }}
            bordered={false}
            allowClear
            placeholder="Selecciona grupo de usuarios"
            onChange={handlerGroupSelect}
            filterOption={(input, option: any) => {
              if (!option) return false;
              const name = option.children.toLowerCase();
              return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {dataGroup.map((group) => (
              <Select.Option
                key={group.id_grupo}
                value={group.id_grupo}
                style={{
                  color: 'white',
                }}
                className="custom-hover-select-mass"
              >
                {`${group.nombre} (${group.abreviacion})`}
              </Select.Option>
            ))}
          </Select>
        )}

        <TextAreaStyled
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="mensaje .."
          autoSize={{ minRows: 3, maxRows: 5 }}
        />

        {selectChats.length === 0 && (
          <Space direction="vertical">
            <Text
              style={{ color: '#BBBBBC' }}
            >{`No hay usuarios seleccionados.`}</Text>
          </Space>
        )}

        {selectChats.length > 0 && (
          <Space direction="vertical">
            <Text
              style={{ color: '#BBBBBC' }}
            >{`Se enviará a ${selectChats.length} usuarios seleccionados.`}</Text>
          </Space>
        )}

        <Button
          type="primary"
          onClick={() => sendMassMessage()}
          loading={load}
          style={{ width: '10rem' }}
          disabled={selectChats.length === 0 || message.length === 0}
        >
          {load ? 'Enviando' : 'Enviar'}
        </Button>
      </Modalx>
    </>
  );
};

export default MassChat;
