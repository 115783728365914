import './style.css';
import Card from '../../../Card';
import { Col, Empty, Row, Segmented, Skeleton } from 'antd';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { FC, useEffect, useState } from 'react';
import { SegmentedValue } from 'antd/lib/segmented';
import { http } from '../../../../http/axios';
import moment from 'moment';
import { WidgetActivityTask } from '../../../../../domain/models/widgets/ActivityTask';
import hexToRgb from '../../../../libs/hexToRgb';
import useLanguage from '../../../../hooks/useLanguage';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { Themes } from '../../../../store/ui';

ChartJS.register(ArcElement, Tooltip, Legend);

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const initialData: any = {
  labels: [],
  datasets: [],
};

type Props = {
  clientsId: number[];
};

const OrdersWidget: FC<Props> = ({ clientsId }) => {
  const [data, setData] = useState(initialData);
  const [result, setResult] = useState<WidgetActivityTask[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterDate, setFilterDate] = useState<{
    startAt: string;
    endAt: string;
  }>({ startAt: '', endAt: '' });
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  const handleChangeSegmented = (value: SegmentedValue) => {
    if (value.toString() === t('today', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('week_short', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('week').format('YYYY-MM-DD'),
        endAt: moment().endOf('week').format('YYYY-MM-DD'),
      });
    } else if (value.toString() === t('month', { ns: 'common' })) {
      setFilterDate({
        startAt: moment().startOf('month').format('YYYY-MM-DD'),
        endAt: moment().endOf('month').format('YYYY-MM-DD'),
      });
    }
  };

  useEffect(() => {
    setFilterDate({
      startAt: moment().format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    if (filterDate.startAt && filterDate.endAt) {
      setLoading(true);
      setData(initialData);
      setResult([]);

      const clientsQuery = clientsId
        .map((client) => {
          return `client[]=${client}`;
        })
        .join('&');

      http
        .get<WidgetActivityTask[]>(
          `/widgets/orders/${filterDate.startAt}/${filterDate.endAt}/query?${clientsQuery}`,
          controller.signal
        )
        .then((res) => {
          setResult(res);
          const datasets: any = {
            label: '',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          };

          const total = res.reduce(
            (acc, cur) => acc + parseInt(`${cur.total}`),
            0
          );

          datasets.data = res.map((item) =>
            ((parseInt(`${item.total}`) / total) * 100).toFixed(2)
          );
          datasets.backgroundColor = res.map((item) => {
            const rgba = hexToRgb(item.color);
            return rgba
              ? `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.7)`
              : item.color;
          });
          datasets.borderColor = res.map((item) => {
            return currentTheme === Themes.DARK_THEME
              ? 'rgba(0,0,0,0.5)'
              : '#fff';
          });
          setData({
            labels: [],
            datasets: [datasets],
          });
          setLoading(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [filterDate, clientsId, currentTheme]);

  return (
    <Card>
      <div style={{ padding: '.5rem 1rem', width: '100%', height: '100%' }}>
        <Row>
          <Col
            span={8}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            {t('orders')}
          </Col>
          <Col
            span={16}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Segmented
              className="onfield-segmented text"
              size="small"
              onChange={handleChangeSegmented}
              options={['Hoy', 'Sem', 'Mes']}
              defaultValue={'Hoy'}
            />
          </Col>
        </Row>
        <Skeleton loading={loading} active>
          {result.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
              description={'Sin datos'}
            />
          ) : (
            <div
              style={{
                height: '80%',
                paddingTop: '.5rem',
                display: 'flex',
                flexDirection: 'row',
                gap: '.5rem',
              }}
            >
              <div
                style={{ width: 150, display: 'flex', alignContent: 'center' }}
              >
                <Doughnut
                  height={100}
                  width={100}
                  data={data}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (tooltipItem: any) => {
                            const idx = tooltipItem.dataIndex;
                            return tooltipItem.dataset.data[idx] + '%';
                          },
                        },
                      },
                    },
                    borderColor:
                      currentTheme === Themes.DARK_THEME
                        ? 'rgba(0,0,0,0.5)'
                        : '#f5f5f5',
                  }}
                />
              </div>
              <Row
                gutter={[0, 0]}
                style={{
                  overflowX: 'auto',
                  width: '100%',
                }}
              >
                {result.map((item, index) => (
                  <Col
                    span={24}
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      color: item.color,
                    }}
                  >
                    <div style={{ flex: 1 }}>{item.name}</div>
                    <div>{item.total}</div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Skeleton>
      </div>
    </Card>
  );
};

export default OrdersWidget;
