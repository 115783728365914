export const SET_COLLAPSED_LEFT_PANEL = 'SET_COLLAPSED_LEFT_PANEL';
export const SET_COLLAPSED_CHAT_RIGHT = 'SET_COLLAPSED_CHAT_RIGHT';
export const SET_COLLAPSED_ONLINE_TRACKING_RIGHT =
  'SET_COLLAPSED_ONLINE_TRACKING_RIGHT';
export const SET_KEY_MENU_AFTER_ONLINE_TRACKING =
  'SET_KEY_MENU_AFTER_ONLINE_TRACKING';
export const SET_IS_ONLINE_TRACKING = 'SET_IS_ONLINE_TRACKING';
export const SET_LOADING = 'SET_LOADING';
export const SET_THEME = 'SET_THEME';
export const SET_MENU_LEFT_PANEL = 'SET_MENU_LEFT_PANEL';
export const SET_IS_FIRST_LOAD_ONLINE_TRACKING =
  'SET_IS_FIRST_LOAD_ONLINE_TRACKING';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const RESET_UI = 'RESET_UI';
