import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import colors from '../../const/colors';
import { Themes } from '../../store/ui';
import './style.css';

type Props = {
  theme?: Themes;
  onClick: (collapsed: boolean) => void;
  isCollapsed: boolean;
  position: 'left' | 'right';
};

const Buttonx = styled(Button)`
  background-color: ${(props) => props.color};
  width: 24px;
  height: 24px;
  border-radius: 0.4rem;
  padding: 0;
  &:hover {
    background: ${(props) => props.color} !important;
  }

  &:focus {
    background: ${(props) => props.color} !important;
  }

  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background-color: #c8cfd7;
  }
`;

const ButtonCollapse: FC<Props> = ({
  theme,
  onClick,
  isCollapsed,
  position,
}) => {
  if (!theme) theme = Themes.LIGH_THEME;

  return (
    <Buttonx
      theme={theme}
      color={
        position === 'left'
          ? theme === Themes.LIGH_THEME
            ? colors.LIGHT_THEME.leftPanel.buttonCollapsed.backgroundColor
            : colors.DARK_THEME.leftPanel.buttonCollapsed.backgroundColor
          : theme === Themes.LIGH_THEME
          ? colors.LIGHT_THEME.rightPanel.buttonCollapsed.backgroundColor
          : colors.DARK_THEME.rightPanel.buttonCollapsed.backgroundColor
      }
      size="small"
      shape="default"
      type="text"
      className="button_left_collapsible_element"
      onClick={() => onClick(!isCollapsed)}
    >
      {position === 'left' ? (
        <>
          {isCollapsed ? (
            <RightOutlined
              style={{
                color: '#707070',
                fontSize: '14px',
              }}
            />
          ) : (
            <LeftOutlined
              style={{
                color: '#707070',
                fontSize: '14px',
              }}
            />
          )}
        </>
      ) : (
        <>
          {!isCollapsed ? (
            <RightOutlined
              style={{
                color: '#ddd',
                fontSize: '14px',
              }}
            />
          ) : (
            <LeftOutlined
              style={{
                color: '#ddd',
                fontSize: '14px',
              }}
            />
          )}
        </>
      )}
    </Buttonx>
  );
};

export default ButtonCollapse;
