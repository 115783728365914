import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Select, Button, Tag, message } from 'antd';
import { http } from "../../../http/axios";
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { Themes } from '../../../store/ui';
import { useSelector } from 'react-redux';

function ZoneSelect({ zonesData, zonesRender, addZones }) {

  const currentTheme = useSelector(selectUiTheme);

  // const filteredData = zonesData.filter((zone) => !zonesRender.some((renderedZone) => renderedZone.id_zona === zone.id));

  const [options, setOptions] = useState(zonesData);
  const [selected, setSelected] = useState([]);

  let feedback = () => { };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const handleSearch = (newValue) => {

    const normalizedValue = removeAccents(newValue.toLowerCase());

    const filteredData = zonesData; //zonesData.filter((zone) => !zonesRender.some((renderedZone) => renderedZone.id_zona === zone.id));

    const results = filteredData.filter((zone) =>
      removeAccents(zone.string.toLowerCase()).includes(normalizedValue)
    );

    if (newValue.length > 0) {
      setOptions(results)
    } else {
      setOptions(filteredData)
    }
  };

  const handleChange = (value) => {
    setSelected(value)
    setOptions(zonesData)
  };

  const onAdd = async (event) => {
    event.preventDefault()

    if (selected.length > 0) {
      const selectedZones = zonesData.filter(zone => selected.includes(zone.id));
      addZones(selectedZones);
      setSelected([]); // limpia la seleccion
    }
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    if (zonesRender.some(zone => zone.id_zona === value)) {
      onClose();
      feedback = message.warning(`La zona ya esta asignada al usuario este dia`, 2);
      return null
    }

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          margin: 1,
        }}
      >
        {label}
      </Tag>
    );
  };

  const defaultValue = (zonesRender || []).map((zone) => zone.label); // {return {value: zone.id_zona, label: zone.label}}

  return (
    <div className="userSelect-ZoneFrequency">
      <Select
        mode="multiple"
        allowClear
        size={"small"}
        style={{
          width: '100%',
        }}
        placeholder="Seleccione las zonas"
        autoClearSearchValue={true} // borra la busqeuda al seleccionar zona
        tagRender={tagRender}
        //defaultValue={defaultValue}
        value={selected}
        options={(options || []).map((opt) => ({
          value: opt.id,
          label: opt.string,
        }))}
        filterOption={false}
        notFoundContent={'...'}
        onSearch={handleSearch}
        onChange={handleChange}
        showSearch
      //loading={loadData}
      />

      {(
        <Button
          style={{
            //height: '100%',
          }}
          type="text"
          size={"small"}
          icon={<PlusOutlined style={{ color: currentTheme === Themes.DARK_THEME ? 'white' : 'black' }} />}
          onClick={onAdd}
          disabled={selected.length === 0}
        ></Button>
      )
      }
    </div>
  );
}

export default ZoneSelect
