import { useEffect, useState } from 'react';
import { database } from '../firebase/firebase';
import { Chat } from '../../domain/models/Chat';
import { instance } from '../http/axios';
import {
  get,
  onChildAdded,
  onChildChanged,
  onValue,
  ref,
} from 'firebase/database';

const useChat = () => {
  const [userId, setUserId] = useState(0);
  const [chat, setChat] = useState({
    event: '',
    chat: {} as Chat,
  });

  useEffect(() => {
    if (userId !== 0) {
      const unsubFromAdd = onChildAdded(
        ref(database, `Chat/${instance}`),
        (snapshot) => {
          if (snapshot.val()) {
            if (snapshot.val().id_users?.includes(userId)) {
              setChat({
                event: 'added',
                chat: {
                  id: snapshot.key,
                  exists: true,
                  messages: Object.keys(snapshot.val().Message || []).map(
                    (key) => ({
                      id: key,
                      ...snapshot.val().Message[key],
                    })
                  ),
                  ...snapshot.val(),
                } as Chat,
              });
            }
          }
        }
      );

      const unsubFormChanged = onChildChanged(
        ref(database, `Chat/${instance}`),
        (snapshot) => {
          if (snapshot.val()) {
            if (snapshot.val().id_users?.includes(userId)) {
              setChat({
                event: 'modified',
                chat: {
                  id: snapshot.key,
                  messages: Object.keys(snapshot.val().Message || []).map(
                    (key) => ({
                      id: key,
                      ...snapshot.val().Message[key],
                    })
                  ),
                  exists: true,
                  ...snapshot.val(),
                } as Chat,
              });
            }
          }
        }
      );

      return () => {
        unsubFormChanged();
        unsubFromAdd();
      };
    }
  }, [userId]);

  return [chat, setUserId] as const;
};

export default useChat;
