import { AppState } from '../rootReducer';
import { createSelector } from 'reselect';

const selectUi = (state: AppState) => state.ui;
export const selectCollapsedLeftPanel = createSelector(
  [selectUi],
  (ui) => ui.collapsedLeftPanel
);
export const selectCollapsedChatRightPanel = createSelector(
  [selectUi],
  (ui) => ui.collapsedChatRightPanel
);
export const selectIsFirstLoadOnlineTracking = createSelector(
  [selectUi],
  (ui) => ui.firstLoadOnlineTracking
);
export const selectCollapsedOnlineTrackingRightPanel = createSelector(
  [selectUi],
  (ui) => ui.collapsedOnlineTrackingRightPanel
);
export const selectIsOnlineTracking = createSelector(
  [selectUi],
  (ui) => ui.isOnlineTracking
);
export const selectLastMenuKeyAfterOnlineTracking = createSelector(
  [selectUi],
  (ui) => ui.lastMenuKeyAfterOnlineTracking
);
export const selectUiLoading = createSelector([selectUi], (ui) => ui.loading);
export const selectUiTheme = createSelector([selectUi], (ui) => ui.theme);
export const selectLeftMenu = createSelector([selectUi], (ui) => ui.leftMenu);
export const selectConfiguration = createSelector([selectUi], (ui) => ui.configuration);
