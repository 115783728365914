import './style.css';
import { Col, Empty, Row, Skeleton } from 'antd';
import Card from '../../../Card';
import { FC, useEffect, useState } from 'react';
import { http } from '../../../../http/axios';
import { WidgetActiveCampaign } from '../../../../../domain/models/widgets/ActiveCampaign';
import useLanguage from '../../../../hooks/useLanguage';
import { Themes } from '../../../../store/ui';
import { useSelector } from 'react-redux';
import { selectUiTheme } from '../../../../store/ui/ui.selectors';

type Props = {
  clientsId: number[];
};

const ActiveCampaignsWidget: FC<Props> = ({ clientsId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<WidgetActiveCampaign[]>([]);
  const t = useLanguage();
  const currentTheme = useSelector(selectUiTheme);

  useEffect(() => {
    console.log(clientsId);
    const controller = new AbortController();
    setLoading(true);
    const clientsQuery = clientsId
      .map((client) => {
        return `client[]=${client}`;
      })
      .join('&');
    http
      .get<WidgetActiveCampaign[]>(
        `/widgets/active-campaigns/query?${clientsQuery}`,
        controller.signal
      )
      .then((res) => {
        setData(res);
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [clientsId]);

  return (
    <Card>
      <div style={{ padding: '.5rem 1rem', width: '100%', height: '100%' }}>
        <Row>
          <Col
            span={24}
            style={{ fontSize: '16px', fontWeight: '500' }}
            className="text"
          >
            {t('active_campaigns')}
          </Col>
        </Row>
        <div style={{ padding: '.5rem' }} />
        <Skeleton active loading={loading}>
          {data.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{
                color: currentTheme === Themes.DARK_THEME ? '#fff' : '#000',
              }}
              description={'Sin datos'}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                //justifyContent: 'center',
                height: '70%',
              }}
            >
              <Row gutter={[10, 5]}>
                {data.map((item, index) => (
                  <Col
                    span={24}
                    key={item.type + index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: '18px',
                    }}
                    className="text"
                  >
                    <div style={{ flex: 1 }}>
                      {item.type.charAt(0).toUpperCase() +
                        item.type.toLocaleLowerCase().slice(1)}
                    </div>
                    <div>{item.total}</div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Skeleton>
      </div>
    </Card>
  );
};

export default ActiveCampaignsWidget;
