import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';

let firebaseConfig;

export let buildEnvironment = 'production';

if (buildEnvironment === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyAqfMgRsqygjjP-jbsuoZJG8rEVvJU2M6o',
    authDomain: 'onfield-769bc.firebaseapp.com',
    databaseURL: 'https://onfield-769bc.firebaseio.com',
    projectId: 'onfield-769bc',
    storageBucket: 'onfield-769bc.appspot.com',
    messagingSenderId: '395927312575',
    appId: '1:395927312575:web:dba70272b6518c400e4e0a',
    measurementId: 'G-MX22C3QNJT',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyCI0iYVhnvDbfAPVcokGbTbbyqtXrv2VxA',
    authDomain: 'onfield-qa.firebaseapp.com',
    databaseURL: 'https://onfield-qa-default-rtdb.firebaseio.com',
    projectId: 'onfield-qa',
    storageBucket: 'onfield-qa.appspot.com',
    messagingSenderId: '223805027645',
    appId: '1:223805027645:web:a6ca4b50c7c7feac684154',
    measurementId: 'G-C75QDJ4YZD',
  };
}

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const database = getDatabase(app);
export const analytics = getAnalytics(app);

export const firestoreAutoId = (): string => {
  const CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let autoId = '';

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

export default app;
