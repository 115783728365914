import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const selectDashboard = (state: AppState) => state.dashboard;
export const selectDashboardLoading = createSelector(
  [selectDashboard],
  (dashaboard) => dashaboard.loading
);
export const selectDashboardWidgets = createSelector(
  [selectDashboard],
  (dashaboard) => dashaboard.widgets
);
export const selectDashboardError = createSelector(
  [selectDashboard],
  (dashaboard) => dashaboard.error
);
