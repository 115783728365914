import React, { useState, useRef } from 'react'
import { Form, Input, Select, InputNumber, Button } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { http } from '../../../../http/axios';

function AlternativeItem({ item, onDelete }) {

    const [loading, setLoading] = useState(false);    

    const deleteItem = async () => {
        setLoading(true);

        try {
            await http.delete("/alternative-brand/" + item.id_marca_alternativa)
            onDelete(item.id_marca_alternativa);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <tr>
            <td>
                <Input
                    readOnly={true}
                    placeholder="Nombre"
                    defaultValue={item.nombre}
                    bordered={false}
                />
            </td>
            <td>
                <Input
                    readOnly={true}
                    placeholder="Teléfono"
                    defaultValue={item.telefono}
                    bordered={false}
                />
            </td>
            <td>
                <Input
                    readOnly={true}
                    placeholder="Dirección"
                    defaultValue={item.direccion}
                    bordered={false}
                />
            </td>
            <td>
                <Input
                    readOnly={true}
                    placeholder="Email"
                    defaultValue={item.email}
                    bordered={false}
                />
            </td>
            {/*<td>
                <Input
                    readOnly={true}
                    placeholder="URL Logo"
                    defaultValue={item.url_logo}
                    bordered={false}
                />
            </td>*/}
            <td className='button-cell'>
                <Button
                    type="text"
                    shape="circle"
                    size="small"
                    onClick={deleteItem}
                    icon={!loading ? <DeleteOutlined /> : <LoadingOutlined />}
                />
            </td>
        </tr>
    )
}

export default AlternativeItem