import { Themes } from '.';
import { Menu } from '../../../domain/models/Menu';
import * as uiTypes from './ui.types';

export const setCollapsedLeftPanel = (collapsed: boolean) => ({
  type: uiTypes.SET_COLLAPSED_LEFT_PANEL,
  payload: collapsed,
});

export const setCollapsedChatRightPanel = (collapsed: boolean) => ({
  type: uiTypes.SET_COLLAPSED_CHAT_RIGHT,
  payload: collapsed,
});

export const setCollapsedOnlineTrackingRightPanel = (collapsed: boolean) => ({
  type: uiTypes.SET_COLLAPSED_ONLINE_TRACKING_RIGHT,
  payload: collapsed,
});

export const setIsOnlineTracking = (isOnlineTracking: boolean) => ({
  type: uiTypes.SET_IS_ONLINE_TRACKING,
  payload: isOnlineTracking,
});

export const setIsFirstLoadOnlineTracking = (isFirstLoad: boolean) => ({
  type: uiTypes.SET_IS_FIRST_LOAD_ONLINE_TRACKING,
  payload: isFirstLoad,
});

export const setLastMenuKeyOnlineTracking = (keys: string[]) => ({
  type: uiTypes.SET_KEY_MENU_AFTER_ONLINE_TRACKING,
  payload: keys,
});

export const setLoading = (isLoading: boolean) => ({
  type: uiTypes.SET_LOADING,
  payload: isLoading,
});

export const setTheme = (theme: Themes) => ({
  type: uiTypes.SET_THEME,
  payload: theme,
});

export const setMenuLeftPanel = (menu: Menu[]) => ({
  type: uiTypes.SET_MENU_LEFT_PANEL,
  payload: menu,
});

export const setConfiguration = (configuration: any) => ({
  type: uiTypes.SET_CONFIGURATION,
  payload: configuration,
});

export const resetUi = () => ({
  type: uiTypes.RESET_UI,
});
