import React, { useState, useEffect } from 'react'
import { Form, Input, InputNumber, Card, message } from 'antd';
import { http } from '../../../http/axios';
const { TextArea } = Input;

function OtherParameters({ setUnsavedChanges, loadingSave, setLoadingSave, unsavedChanges }) {

    const [parameters, setParameters] = useState({});
    const [parameters6, setParameters6] = useState({});
    const [changes, setChanges] = useState(parameters6);
    const [load, setLoad] = useState(true);

    let feedback = () => { };

    const loadQuery = async () => {
        try {
            const data = await http.get('/parameter/sw_modulo_autoagendamiento');
            setParameters(data);
            setParameters6(JSON.parse(data.valor6))
            setChanges(JSON.parse(data.valor6))
            setLoad(false);
        } catch (error) {
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
            console.error(error);
        }
    };

    useEffect(() => {
        loadQuery();
    }, [])

    useEffect(() => {
        if (!unsavedChanges) {
            setChanges(parameters6);            
        }
    }, [unsavedChanges])

    const query = async (query) => {
        try {
            const response = await http.put('/parameter/sw_modulo_autoagendamiento', { query });
            setParameters(response);
            setParameters6(JSON.parse(response.valor6))
            setUnsavedChanges(false);
        } catch (error) {
            console.error(error);
            feedback = message.error("Ha ocurrido un error, por favor inténtelo de nuevo más tarde", 2);
        } finally {
            //feedback()
            setLoadingSave(false);
        }
    };

    useEffect(() => {
        if (loadingSave) {
            query({ valor6: JSON.stringify(changes, null, 2) });
        }
    }, [loadingSave])

    const editParameters = (value, name) => {
        let data = { ...changes };
        data[name] = value;

        setChanges(data)

        setUnsavedChanges(true);
    };

    return (
        <Card size="small" loading={load}>
            {/*<h2>Otros Parámetros</h2>*/}

            <Form
                name="generals"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="Texto fuera de garantía"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.texto_fuera_garantia}
                        onChange={(value) => editParameters(value.target.value, 'texto_fuera_garantia')}
                    />
                </Form.Item>

                <Form.Item
                    label="Texto posible garantía"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.texto_posible_garantia}
                        onChange={(value) => editParameters(value.target.value, 'texto_posible_garantia')}
                    />
                </Form.Item>

                <Form.Item
                    label="Texto cupo ya ocupado"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.mensaje_cupo_ya_ocupado}
                        onChange={(value) => editParameters(value.target.value, 'mensaje_cupo_ya_ocupado')}
                    />
                </Form.Item>

                <Form.Item
                    label="Texto mensaje horario por confirmar"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.mensaje_horario_confirmar}
                        onChange={(value) => editParameters(value.target.value, 'mensaje_horario_confirmar')}
                    />
                </Form.Item>

                <Form.Item
                    label="Texto inicio Email"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.email_confirm_texto_inicio}
                        onChange={(value) => editParameters(value.target.value, 'email_confirm_texto_inicio')}
                    />
                </Form.Item>

                <Form.Item
                    label="Texto fin Email"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.email_confirm_texto_fin}
                        onChange={(value) => editParameters(value.target.value, 'email_confirm_texto_fin')}
                    />
                </Form.Item>

                <Form.Item
                    label="Texto modulo apagado"
                >
                    <TextArea
                        rows={2}
                        style={{ resize: 'none' }}
                        defaultValue={parameters6.mensaje_modulo_apagado}
                        onChange={(value) => editParameters(value.target.value, 'mensaje_modulo_apagado')}
                    />
                </Form.Item>

                <Form.Item
                    label="Código SAP garantía por defecto"
                >
                    <InputNumber
                        min={0}
                        defaultValue={parameters6.tipo_garantia}
                        onChange={(value) => editParameters(value, 'tipo_garantia')}
                    />
                </Form.Item>

                <Form.Item
                    label="Código SAP sin garantía"
                >
                    <InputNumber
                        min={0}
                        defaultValue={parameters6.sin_garantia}
                        onChange={(value) => editParameters(value, 'sin_garantia')}
                    />
                </Form.Item>

                <Form.Item
                    label="Centro de costo por defecto"
                >
                    <InputNumber
                        defaultValue={parameters6.centro_costo}
                        min={0}
                        onChange={(value) => editParameters(value, 'centro_costo')}
                    />
                </Form.Item>

                <Form.Item
                    label="Giro por defecto"
                >
                    <Input
                        defaultValue={parameters6.giro}
                        onChange={(value) => editParameters(value.target.value, 'giro')}
                    />
                </Form.Item>

            </Form>
        </Card>
    )
}

export default OtherParameters