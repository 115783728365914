import { faCheck, faTimes, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Select } from 'antd';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Themes } from '../../../store/ui';
import { selectUiTheme } from '../../../store/ui/ui.selectors';
import { QuestionIcon } from '../../../components/icons';

type Resource = {
  title: string;
  distance?: string;
  description: string;
  order?: string;
  color?: string;
  id: string;
  type?: string;
  showCloseButton?: boolean;
  confirmation?: number;
  showConfirmation?: boolean;
};

type Props = {
  arg: Resource;
  onClose?: (id: string) => void;
  onChangeConfirmation?: (id: string, confirmation: number) => void;
};

const Event: FC<Props> = ({ arg, onClose, onChangeConfirmation }) => {
  const currentTheme = useSelector(selectUiTheme);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [confirmation, setConfirmation] = useState(arg.confirmation);

  const openModalConfirmation = () => {
    setShowModalConfirmation(true);
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          height: '100%',
          backgroundColor:
            currentTheme === Themes.DARK_THEME ? '#333' : 'white',
          color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
          boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
          borderRadius: '0.5rem',
          padding: '0.1rem 0.2rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.1rem',
            padding: '2px',
          }}
        >
          {arg.showCloseButton && (
            <Button
              type="text"
              size="small"
              onClick={() => {
                if (onClose) onClose(arg.id);
              }}
              icon={
                <FontAwesomeIcon
                  icon={faXmark}
                  color={currentTheme === Themes.DARK_THEME ? 'white' : 'black'}
                />
              }
            />
          )}
          <div
            style={{
              flex: 1,
              color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
            }}
            dangerouslySetInnerHTML={{ __html: arg.title }}
          ></div>
          {arg.order && (
            <div
              style={{
                border: '2px solid ' + (arg.color || 'gray'),
                borderRadius: '50%',
                width: 20,
                height: 20,
                fontWeight: '700',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              }}
            >
              {arg.order}
            </div>
          )}
        </div>

        <div style={{ flex: 1, overflowY: 'auto' }}>
          <div
            style={{
              padding: '0 5px',
              color: currentTheme === Themes.DARK_THEME ? 'white' : 'black',
              flex: 1,
            }}
            dangerouslySetInnerHTML={{
              __html: arg.description,
            }}
          ></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {arg.type && (
            <div
              style={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                color: '#0e90d2',
                paddingLeft: '0.2rem',
              }}
              dangerouslySetInnerHTML={{ __html: arg.type || '' }}
            />
          )}
          <div style={{ flex: 1 }}></div>
          {arg.distance && (
            <div
              style={{
                color: '#e67e22',
                fontWeight: '700',
                paddingRight: '0.2rem',
              }}
            >
              {arg.distance}
            </div>
          )}
          {arg.showConfirmation && (
            <Button
              type="text"
              size="small"
              style={{
                color:
                  !arg.confirmation || arg.confirmation === 0
                    ? currentTheme === Themes.DARK_THEME
                      ? '#fff'
                      : '#000'
                    : arg.confirmation === 1
                    ? '#3498db'
                    : '#e74c3c',
                width: 18,
                height: 18,
                fontSize: 13,
              }}
              onClick={() => {
                openModalConfirmation();
              }}
              icon={
                !arg.confirmation || arg.confirmation === 0 ? (
                  <QuestionIcon style={{ fontSize: 12 }} />
                ) : arg.confirmation === 1 ? (
                  <FontAwesomeIcon size="1x" icon={faCheck} />
                ) : (
                  <FontAwesomeIcon size="1x" icon={faTimes} />
                )
              }
            />
          )}
        </div>
      </div>
      <Modal
        visible={showModalConfirmation}
        onCancel={() => {
          setConfirmation(arg.confirmation);
          setShowModalConfirmation(false);
        }}
        title={<span dangerouslySetInnerHTML={{ __html: arg.title }}></span>}
        destroyOnClose
        onOk={() => {
          if (onChangeConfirmation)
            onChangeConfirmation(arg.id, confirmation || 0);
          setShowModalConfirmation(false);
        }}
      >
        <Select
          value={confirmation}
          onChange={(val) => {
            setConfirmation(val);
          }}
          style={{ width: '100%' }}
          options={[
            {
              label: 'Sin confirmación',
              value: 0,
            },
            {
              label: 'Confirmada',
              value: 1,
            },
            {
              label: 'Rechazada',
              value: 2,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default Event;
