import React, { useState, useEffect } from 'react'
import { LineOutlined, CloseOutlined, PauseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Select, Space, Tag, Button, } from 'antd';

import ZoneSelect from './zoneSelect'

import { http } from "../../../http/axios";

function Item({ zone, day, loadFrequencyUser }) {

    const [showTag, setShowTag] = useState(true);
    const [loading, setLoading] = useState(zone.newRecord ? true : false);

    const updateZonaFrecuencia = async (query) => {
        try {
            const update = await http.put("/frequency-zones/frequencyZone/" + zone.id_zona_frecuencia, { query: query })
            return update;
        } catch (error) {
            console.error("Se produjo un error al editar la zona: ", error);
        } finally {
            setLoading(false);
        }
    };

    const addZonaFrecuencia = async (add) => {
        try {
            const response = await http.post("/frequency-zones/frequencyZone", { add })
            return response;
        } catch (error) {
            console.error("Se produjo un error al agregar zona: ", error);
            setShowTag(false);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (zone.newRecord) {
            if (zone.id_zona_frecuencia === undefined) { // nuevo registro
                const add = {
                    [day.id]: true, // 1,
                    user_id: zone.user_id,
                    id_zona: zone.id_zona,
                }
                addZonaFrecuencia(add).then((response) => {
                    // console.log("response add: ", response); // mensaje de exito
                    zone.id_zona_frecuencia = response.id_zona_frecuencia;
                });

            } else { // actualizar registro
                const query = {
                    [day.id]: true, // 1,
                    user_id: zone.user_id,
                    id_zona: zone.id_zona,
                    id_zona_frecuencia: zone.id_zona_frecuencia
                }
                updateZonaFrecuencia(query).then((response) => {
                    // console.log("response update: ", response); // mensaje de exito
                });
            }

            delete zone.newRecord;
        }
    }, []);

    const checkFrequencyZone = async (frequencyZone) => {
        const keys = Object.keys(frequencyZone);
        const areAllFalse = keys.filter(key => Number.isInteger(Number(key))).every(key => !frequencyZone[key]);
        if (areAllFalse) {
            try {
                await http.delete("/frequency-zones/frequencyZone/" + frequencyZone.id_zona_frecuencia)
            } catch (error) {
                console.error("Se produjo un error al eliminar la frecuencia de zonas: ", error);
            }
        }
    }

    const onDelete = async (e) => {
        setLoading(true);
        e.preventDefault();

        const query = {
            [day.id]: false, // 0,
            user_id: zone.user_id,
            id_zona: zone.id_zona,
            id_zona_frecuencia: zone.id_zona_frecuencia
        }

        updateZonaFrecuencia(query).then((response) => {
            checkFrequencyZone(response) // veridicar si los dias de la zona de frecuencia
            setShowTag(false);
            loadFrequencyUser();
        });
    };

    const tagStyle = {
        borderRadius: '8px',
        border: '1px solid #555555',
    };

    return (
        <Tag
            className="custom-tag"
            closable={!loading}
            onClose={onDelete}
            visible={showTag}
            icon={loading ? <LoadingOutlined /> : <></>}
            closeIcon={<LineOutlined /> }
            style={tagStyle}
        >
            {zone.string}
        </Tag>
    )
}

export default Item
