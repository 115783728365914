import { FC, useEffect, useState } from 'react';
import { Form, Modal, Select, Switch, TimePicker, notification } from 'antd';
import { http } from '../../../http/axios';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import moment, { Moment } from 'moment';
import { useMediaQuery } from 'react-responsive';

type Props = {
  show: boolean;
  onClose?: () => void;
};

type FormProps = {
  repartir: boolean;
  prorratea_capacidad_repartir: boolean;
  habilidades: boolean;
  usuario_horario_inicio: Moment;
  usuario_horario_fin: Moment;
  habilidades_no_incluidas: number[];
  considera_zona_frecuente: boolean;
  considera_retorno: boolean;
  preferencia_horaria: boolean;
};

const Config: FC<Props> = ({ show, onClose }) => {
  const [form] = Form.useForm<FormProps>();
  const [skills, setSkills] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const currentUser = useSelector(selectCurrentUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleForm = async (data: FormProps) => {
    setIsLoading(true);
    try {
      await http.put('/route-plans/config/params', {
        repartir: data.repartir ? 1 : 0,
        prorratea_capacidad_repartir: data.prorratea_capacidad_repartir ? 1 : 0,
        habilidades: data.habilidades ? 1 : 0,
        habilidades_no_incluidas: data.habilidades_no_incluidas,
        considera_zona_frecuente: data.considera_zona_frecuente ? 1 : 0,
        considera_retorno: data.considera_retorno ? 1 : 0,
        preferencia_horaria: data.preferencia_horaria ? 1 : 0,
        usuario_horario: {
          hora_inicio: data.usuario_horario_inicio,
          hora_fin: data.usuario_horario_fin,
        },
      });
      onClose?.();
    } catch (error) {
      console.error(error);
      notification['error']({
        message: 'Error',
        description: 'Ocurrión un error al guardar la configuración',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const response: any[] = await http.get('/users/skills');
      setSkills(response);
    })();
  }, []);

  useEffect(() => {
    if (currentUser) {
      const sw_modulo_plan_ruta = currentUser.instance?.parameters.find(
        (p: any) => p.parameter_id === 'modulo_plan_de_ruta'
      );
      if (sw_modulo_plan_ruta) {
        const parameters = JSON.parse(sw_modulo_plan_ruta?.value6);
        form.setFieldsValue({
          repartir: parameters.repartir,
          prorratea_capacidad_repartir: parameters.prorratea_capacidad_repartir,
          habilidades: parameters.habilidades,
          usuario_horario_inicio: moment(
            parameters.usuario_horario.hora_inicio,
            'HH:mm'
          ),
          usuario_horario_fin: moment(
            parameters.usuario_horario.hora_fin,
            'HH:mm'
          ),
          habilidades_no_incluidas: parameters.habilidades_no_incluidas,
          considera_zona_frecuente: parameters.considera_zona_frecuente,
          considera_retorno: parameters.considera_retorno,
          preferencia_horaria: parameters.preferencia_horaria,
        });
        setOptions(parameters.config.options || []);
      }
    }
  }, [currentUser, form]);

  return (
    <Modal
      visible={show}
      title="Configuración plan de ruta"
      okText="Guardar"
      onOk={() => {
        form.submit();
      }}
      onCancel={onClose}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelButtonProps={{
        loading: isLoading,
        htmlType: 'button',
      }}
      width={isTabletOrMobile ? '80%' : '50%'}
    >
      <Form
        name="routePlanConfig"
        form={form}
        onFinish={handleForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          repartir: false,
          prorratea_capacidad_repartir: false,
          habilidades: false,
          usuario_horario_inicio: null,
          usuario_horario_fin: null,
          habilidades_no_incluidas: [],
          considera_zona_frecuente: false,
          considera_retorno: false,
          preferencia_horaria: false,
        }}
      >
        <Form.Item
          label={options.find((o) => o.repartir)?.repartir}
          name="repartir"
          valuePropName="checked"
          style={{
            display: options.find((o) => o.repartir) ? 'flex' : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.repartir)?.desc}
            </div>
          }
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={
            options.find((o) => o.prorratea_capacidad_repartir)
              ?.prorratea_capacidad_repartir
          }
          name="prorratea_capacidad_repartir"
          valuePropName="checked"
          style={{
            display: options.find((o) => o.prorratea_capacidad_repartir)
              ? 'flex'
              : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.prorratea_capacidad_repartir)?.desc}
            </div>
          }
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={options.find((o) => o.habilidades)?.habilidades}
          name="habilidades"
          valuePropName="checked"
          style={{
            display: options.find((o) => o.habilidades) ? 'flex' : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.habilidades)?.desc}
            </div>
          }
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={
            options.find((o) => o.habilidades_no_incluidas)
              ?.habilidades_no_incluidas
          }
          name="habilidades_no_incluidas"
          style={{
            display: options.find((o) => o.habilidades_no_incluidas)
              ? 'flex'
              : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.habilidades_no_incluidas)?.desc}
            </div>
          }
        >
          <Select mode="multiple">
            {skills.map((skill) => (
              <Select.Option key={skill.id_grupo} value={skill.id_grupo}>
                ({skill.abreviacion}) {skill.nombre}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            options.find((o) => o.considera_zona_frecuente)
              ?.considera_zona_frecuente
          }
          name="considera_zona_frecuente"
          valuePropName="checked"
          style={{
            display: options.find((o) => o.considera_zona_frecuente)
              ? 'flex'
              : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.considera_zona_frecuente)?.desc}
            </div>
          }
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={options.find((o) => o.considera_retorno)?.considera_retorno}
          name="considera_retorno"
          valuePropName="checked"
          style={{
            display: options.find((o) => o.considera_retorno) ? 'flex' : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.considera_retorno)?.desc}
            </div>
          }
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={
            options.find((o) => o.preferencia_horaria)?.preferencia_horaria
          }
          name="preferencia_horaria"
          valuePropName="checked"
          style={{
            display: options.find((o) => o.preferencia_horaria)
              ? 'flex'
              : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.preferencia_horaria)?.desc}
            </div>
          }
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={
            options.find((o) => o.usuario_horario_inicio)
              ?.usuario_horario_inicio
          }
          name="usuario_horario_inicio"
          valuePropName="value"
          style={{
            display: options.find((o) => o.usuario_horario_inicio)
              ? 'flex'
              : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.usuario_horario_inicio)?.desc}
            </div>
          }
        >
          <TimePicker
            format={'HH:mm'}
            onOk={(v) => {
              form.setFieldsValue({
                usuario_horario_inicio: v.format('HH:mm'),
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label={
            options.find((o) => o.usuario_horario_fin)?.usuario_horario_fin
          }
          name="usuario_horario_fin"
          valuePropName="value"
          style={{
            display: options.find((o) => o.usuario_horario_fin)
              ? 'flex'
              : 'none',
          }}
          help={
            <div
              style={{
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.45)',
                textAlign: 'justify',
              }}
            >
              {options.find((o) => o.usuario_horario_fin)?.desc}
            </div>
          }
        >
          <TimePicker
            format={'HH:mm'}
            onOk={(v) => {
              form.setFieldsValue({
                usuario_horario_fin: v.format('HH:mm'),
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Config;
