import { Chat } from '../../../domain/models/Chat';
import * as chatTypes from './chat.types';

export interface ChatState {
  chats: Chat[];
  openedChats: Chat[];
  loading: boolean;
  massive: boolean;
  error: string | null;
}

const initialState: ChatState = {
  chats: [],
  openedChats: [],
  loading: false,
  massive: false, 
  error: null,
};

const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case chatTypes.ADD_CHAT:
      return {
        ...state,
        chats: [action.payload, ...state.chats],
      };
    case chatTypes.ADD_CHAT_RDB:
      return {
        ...state,
        chats: [action.payload.chat, ...state.chats],
        openedChats: [action.payload.chat, ...state.openedChats],
      };
    case chatTypes.CLOSE_ALL_OPENED_CHATS:
      return {
        ...state,
        openedChats: [],
      };
    case chatTypes.MODIFY_CHAT:
      return {
        ...state,
        chats: [
          action.payload,
          ...state.chats
            .map((c) => {
              if (c.id === action.payload.id) {
                return action.payload;
              }
              return c;
            })
            .filter((c) => c.id !== action.payload.id),
        ],
      };
    case chatTypes.MODIFY_SPECIFIC_CHAT:
      return {
        ...state,
        chats: [
          ...state.chats
            .map((c) => {
              if (c.id === action.payload.id) {
                return {
                  ...c,
                  id: action.payload.chat.id,
                  last_message: action.payload.chat.last_message,
                  last_message_date: action.payload.chat.last_message_date,
                  exists: action.payload.chat.exists,
                };
              }
              return c;
            })
            .filter((c) => c.id !== action.payload.id),
        ],
      };
    case chatTypes.REMOVE_CHAT:
      return {
        ...state,
        chats: state.chats.filter((chat) => chat.id !== action.payload.id),
      };
    case chatTypes.GET_CHATS:
      return {
        ...state,
        loading: true,
      };
    case chatTypes.GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        loading: false,
      };
    case chatTypes.GET_CHATS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case chatTypes.GET_OPENED_CHATS:
      return {
        ...state,
        openedChats: action.payload,
      };
    case chatTypes.OPEN_INACTIVE_CHAT: {
      const exists = state.openedChats.find(
        (chat) => chat.id === action.payload.chat.id
      );

      if (exists) {
        return {
          ...state,
          openedChats: state.openedChats.map((c) => {
            if (c.id === action.payload.chat.id) {
              c.users.map((u) => {
                if (u.user_id === action.payload.userId) {
                  u.window_collapsed = true;
                }
                return u;
              });
            }
            return c;
          }),
        };
      }

      const layouts = document.getElementsByClassName('ant-layout');
      const widthPerChat = 220;
      const totalChats = Math.floor(layouts[1].clientWidth / widthPerChat);
      if (state.openedChats.length >= totalChats) {
        state.openedChats.shift();
      }

      const newChat: Chat = action.payload.chat;
      newChat.users.map((u) => {
        if (u.user_id === action.payload.userId) {
          u.window_collapsed = true;
        }
        return u;
      });

      return {
        ...state,
        openedChats: [...state.openedChats, newChat],
      };
    }
    case chatTypes.OPEN_CHAT:
      if (state.massive) {
        return {
          ...state,
          openedChats: [...state.openedChats],
        };
      }
      const exists = state.openedChats.find(
        (chat) => chat.id === action.payload.chatId
      );
      if (exists)
        return {
          ...state,
          openedChats: state.openedChats.map((c) => {
            if (c.id === action.payload.chatId) {
              c.users.map((u) => {
                if (u.user_id === action.payload.userId) {
                  u.window_collapsed = true;
                }
                return u;
              });
            }
            return c;
          }),
        };
      const layouts = document.getElementsByClassName('ant-layout');
      const widthPerChat = 220;
      const totalChats = Math.floor(layouts[1].clientWidth / widthPerChat);
      if (state.openedChats.length >= totalChats) {
        state.openedChats.shift();
      }
      const newChat = state.chats.find(
        (chat) => chat.id === action.payload.chatId
      );
      newChat?.users.map((u) => {
        if (u.user_id === action.payload.userId) {
          u.window_collapsed = true;
        }
        return u;
      });
      return {
        ...state,
        openedChats: [...state.openedChats, newChat],
      };
    case chatTypes.UPDATE_OPENED_CHAT:
      return {
        ...state,
        openedChats: state.openedChats.map((c) => {
          if (c.id === action.payload.id) {
            return action.payload;
          }
          return c;
        }),
      };
    case chatTypes.CLOSE_CHAT:
      return {
        ...state,
        openedChats: state.openedChats
          .map((chat) => {
            chat.users.map((user) => {
              if (chat.id === action.payload) user.window_collapsed = false;
              return user;
            });
            return chat;
          })
          .filter((chat) => chat.id !== action.payload),
      };
    case chatTypes.TOGGLE_CHAT:
      const { chatId, userId } = action.payload;
      return {
        ...state,
        openedChats: state.openedChats.map((chat) => {
          if (chat.id === chatId) {
            const userIdx = chat.users.findIndex(
              (user) => user.user_id === userId
            );
            if (userIdx !== -1) {
              chat.users[userIdx].window_collapsed =
                !chat.users[userIdx].window_collapsed;
            }
          }
          return chat;
        }),
      };
    case chatTypes.SET_MASSIVE:
      return {
        ...state,
        massive: action.payload,
      };
    default:
      return state;
  }
};

export default chatReducer;
